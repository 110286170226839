.aht-post-detail {
  &__title {
    span{
      color: $c-alter;
    }
  }
  &__detail-text {
    font-family: $fnt-family-3;
  }
  &__detail-icon {
    color: $c-active;
  }
  &__cats-name {
    font-family: $fnt-family-3;
    color: $c-dark-2;
  }
  &__cats-list {
    font-family: $fnt-family-3;
  }
  &__likes {
    font-family: $fnt-family-3;
  }
  &__author-title {
    font-family: $fnt-family-3;
    color: $c-dark-2;
  }
  .aheto-socials {
    &__link{
      &:hover{
        background: $c-active;
        i{
          color: $c-white;
        }
      }
    }
  }
}
