.aheto-services {
  $p: &;

  .c-active {
    color: $c-active;
  }

  .c-light {
    color: $c-light;
  }

  .c-grey {
    color: $c-grey;
  }

  .c-dark {
    color: $c-dark;
  }

  .c-dark-2 {
    color: $c-dark;
  }

  .c-alter {
    color: $c-alter;
  }

  .c-alter-2 {
    color: $c-alter-2;
  }

  .c-alter-3 {
    color: $c-alter-3;
  }

  &__link {
    color: $c-grey;
    &:hover {
      color: $c-active;
    }
  }

  &__label {
    color: $c-dark;
  }

  &__time {
    background: $c-alter;
    color: $c-dark;
  }

  &--events {
  	#{$p}__link {
  		color: $c-grey;
  		&:hover {
  			color: $c-active;
  		}
  	}
    #{$p}__label {
      font-family: $fnt-family-2;
    }
  }

  &--simple {
    .aheto-services__price {
      color: $c-active;
    }
  }

  &--busns-event {
    .aheto-services {

      &__time {
        background-color: $c-active;
        box-shadow: 0px 0px 32px 0px rgba($c-active, 0.3);
      }

      &__label {
        color: $c-grey;
      }

      &__link {
        color: $c-active;
        &:hover {
          color: $c-alter;
        }
      }
    }
  }

  &--construction {
    .aheto-services {
      &__link {
        color: $c-active;
      }
      &__title{
        color: $c-dark-2;
      }
    }
  }

  &--construction-right {
    .aheto-services {
      &__content {
        transform: translateY(-70px);
        float: right;
        border-radius: 5px 0 5px 5px;
        max-width: calc(100% - 30px);
      }

      &__job {
        color: $c-active;
        text-transform: uppercase;
      }
    }
  }

  &--construction-news {
    .aheto-services {
      &__label {
        color: $c-grey;
        font-size: 16px;
      }
      &__title{
        color: #222222;
      }
    }
  }

  &--construction_servPage {
    .aheto-services {
      &__title{
        color: $c-dark;
      }
    }
  }

  &--chess {
    .aheto-services {

      &__link {
        background-color: $c-active;
        &:hover {
          background-color: $c-alter;
        }
      }
    }
  }
}

.aht-service {

  &--edu {
    .aht-service {
      &__icon {
        color: $c-dark;
      }
      &__link {
        &:hover {
          color: $c-active!important;
        }
      }
      &__desc {
        font-family: $f-roboto;
      }
    }
  }

  &--trvl {
    .aht-service {
      &__link {
        &:hover {
          color: $c-alter;
        }
      }

      &__desc {
        font-weight: 300;
      }
    }

    .aheto-btn--underline {
      font-weight: 500;
    }

    &.first {
      .aht-service__img {
        margin-top: 3px;
      }
    }

    &.second {
      .aht-service__img {
        margin-top: 5px;
      }
    }
  }

  &--chr {

    .aht-service {
      &__link {
        &:hover {
          color: $c-dark-2;
        }
      }
    }
  }
}


.service--church-wrap-1{
  .aht-service {
    &--chr {

      .aht-service {
        &__link {
          &:hover {
            color: $c-dark-2;
          }
        }
      }
    }
  }
}

.church-ministries-wrap {
  .aheto-services {
    &__link{
      color: $c-active;

      &:hover {
        color: $c-dark-2;
      }
    }
  }
}

.wrap-yoga-7 {
  .aheto-services--chess {
    .aheto-services__link {
      border-radius: 25.05px;
      display: inline-block;
      position: relative;
      padding: 16px 41px;
      border-width: 1px;
      border-style: solid;
      border-color: rgba(127, 188, 72, 0.5);
      outline: none;
      background-color: $c-active;
      color: $c-white;
      font-family: 'Gilroy', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      letter-spacing: 0.5px;
      line-height: 1.15;

      &:hover {
        color: #7FBC48;
        background-color: transparent;
      }
    }
  }
}


.construction-news-contentWrap {
  .aheto-services {
    &__title {
      color: $c-dark;
    }
  }
}



// home agency
.aheto-services--agency {
  border-color: $c-light;
  &:hover {
    border-color: $c-alter;
    top: 0;
  }
  .aheto-services__caption {
    border-color: $c-light;
    &:after {
      background-color: $c-alter;
    }
  }

  .aheto-services__link {
    color: $c-active;
  }
}

// home agency end