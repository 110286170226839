.aht-sermon {
  &__icon-link {
    &:hover {
      color: $c-active;
    }
  }

  .aht-sermon__link {
    &:hover {
      color: $c-active;
    }
  }
}


.sermons--church-wrap {
  .aheto-btn {
    color: $c-active;
    border-color: #eeeeee;

    &:hover{
      color: white;
      background-color: $c-dark-2;
      border-color: $c-dark-2;
      
    }
    
  }
}

.aht-serm-det {
  &--chr {
    .aht-serm-det {
      &__detail-val {
        color: $c-active;
      }

      &__detail-text {
        color: $c-grey;
      }

      &__date-number {
        color: $c-active;
      }
    }
  }
}

.aht-sermon-det {
  &__icon-link {
    &:hover {
      color: $c-active;
    }
  }

  .aht-sermon-det__link {
    &:hover {
      color: $c-active;
    }
  }
}