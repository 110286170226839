// Themeing

// base/atomic
.color-primary { color:$c-active; }

::selection {
  background: lighten($c-dark, 70%);
}

// Blocks
@import '../blocks/blocks-skin';

// Typography
@import '../wrapp/wrapp-skin';
@import '../partials/partial_skin';
@import '../vendors/vendors-skin';
@import '../typography/typography-text-skin';
@import '../typography/typography-btn-skin';
// woocommerce-shop
@import '../wp/plugin-styles/plugin-styles-main';

// Widgets
@import '../widgets/widgets-main';
