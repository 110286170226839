.aht-blog-bsns {
  &__item {
	&--quote {
	  background-color: $c-dark;
	}
  }
  &__cat {
    font-size: 16px;
	&:hover {
	  color: $c-grey;
	}
  }

  &__link {
	&:not(:hover) {
	  color: $c-alter;
	}
	&:hover {
	  color: $c-active;
	}
  }

  &__video-link {
	color: $c-alter;
	&:hover {
	  color: $c-active;
	}
  }

  .mejs__controls {
	background: $c-dark;
  }
}

.aht-pag-edu {
  .wrap {
	.page-numbers {
	  color: $c-dark;
	  &.current,
	  &:hover {
		background-color: $c-dark;
	  }
	}
  }

  .prev,
  .next {
	color: $c-dark;
  }
}

.aht-blog-trvl {
	&__icon {
		padding-top: 7px;
	}
}
