.aheto-pf {

  &__filter {
	color: $c-dark;

	&:hover {
	  color: $c-active;
	}

	&.active {
	  color: $c-active;
	}
  }

  &__close {
	color: $c-light;
  }

  &--metro {
	.aheto-pf {
	  &__content {
		&:before {
		  background-color: rgba($c-dark, 0.9);
		}
	  }
	}
  }

  &--grid {
	.aheto-pf {
	  &__content {
		&:hover {
		  box-shadow: 0px 0px 43px 0px rgba($c-active, 0.09);
		}
	  }
	  &__title {
		color: $c-dark;
      &:hover {
        color: $c-active;
      }
	  }
	}
    &-political{
      .aheto-pf{
        &__title {
          color: $c-active;
          font-family: $fnt-family-1;
          font-weight: 500;
          line-height: 1.3;
          &:hover {
            color: $c-dark;
          }
        }
        &__cat {
          p {
            margin: 0;
            font-size: 16px;
            font-weight: 400;
          }
          &-wrap{
            margin-top: 0px;
          }
        }
      }
    }
  }

  &--masonry {

	.aheto-pf {

	  &__title {
		color: $c-dark;
      &:hover {
        color: $c-active;
      }
	  }
	}
  }

  &--new {

	.aheto-pf {

	  &__img {

		&:before {
		  color: $c-dark;
		}
	  }

	  &__title {
		color: $c-dark;
    &:hover {
      color: $c-active;
    }
	  }
	}
  }

  &--construction {
	.aheto-pf {
	  &__filter {
        color: $c-grey;
		&.active {
		  &:after {
			background-color: $c-active;
		  }
		}
	  }
	}
  }
  .aheto-pf__filter-wrap__construction {
    .aheto-pf {
      &__filter {
        color: $c-grey;
		font-weight: 600;
        &.active {
          color: $c-active;
		  font-weight: bold;
        }
      }
    }
  }

  &--grid-restaurant {
    .aheto-pf {
      &__zoom-icon,
      &__like-icon,
      &__share-icon {
        &:before {
          color: #fff;
        }
      }
      &__content:before {
        background-color: transparent;
        background-image: linear-gradient(to top, #000000 0%, rgba(0, 0, 0, 0) 100%);
      }
    }
  }



}

.construction-projects-wrap {

  .aheto-pf {
    &__content {
      .aheto-pf__img:before {
        color: #000;
      }
      .aheto-pf__cat-wrap {
        .aheto-pf__cat {
          p {
            color: $c-active;
          }
        }
      }
    }
  }
}

// Portfolio Nav
.portfolio-nav {
  &__dir-icon {
	color: $c-dark;
  }

  &__list-icon {
	color: $c-grey;
  }
}

.portfolio {
  &-new {
    .aheto-heading {
      &__desc {
        color: #cccccc;
        font-weight: 400;
        line-height: 4.57;
        letter-spacing: 1.4px;
        margin: 0px;
      }
    }
  }
}

.single {
  &__portfolio {
    &--3,
    &--4,
    &--5 {
      .aheto-blockquote__title {
        font-family:$fnt-family-2;
      }

      .aheto-socials {
        &--circle {
          .aht-socials__link {
            border-color: fade-out($c-grey, 0.7);

            &:hover {
              border-color:$c-active;
            }
          }
        }
      }
    }

    &--4 {
      .bg-wrap-c-dark {
        .aheto-heading {
          p {
            color:$c-grey;
          }
        }
      }
    }

    &--2,
    &--7 {
      .aheto-socials {
        &--circle {
          .aht-socials__link {
            border-color: fade-out($c-grey, 0.7);

            &:hover {
              border-color:$c-active;
            }
          }
        }
      }
    }
  }
}

.restaurant-gallery-wrap {
  .aheto-pf {
    &__filter {
      color: $c-grey;
    }
    &__filter.active {
      color: $c-active;
    }
  }
}
