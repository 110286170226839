body {
  font-family: $fnt-family-1;
}

// Headings
h1,
h2,
h3,
h4,
h5,
h6 {
  color: $h-color;
  font-family: $h-fnt-family;
  font-weight: $h-fnt-wgt;
  letter-spacing: $h-ltr-spacing;
}
h1 {
  font-size: $h1-fnt-size;
  line-height: $h1-ln-height;
}
h2 {
  font-size: $h2-fnt-size;
  line-height: $h2-ln-height;
}
h3 {
  font-size: $h3-fnt-size;
  line-height: $h3-ln-height;
}
h4 {
  font-size: $h4-fnt-size;
  line-height: $h4-ln-height;
}
h5 {
  font-size: $h5-fnt-size;
  line-height: $h5-ln-height;
}
h6 {
  font-size: $h6-fnt-size;
  line-height: $h6-ln-height;
}


// Paragraphs
p {
  color: $p-color;
  font-family: $p-fnt-family;
  font-size: $p-fnt-size;
  letter-spacing: $p-ltr-spng;
  line-height: $p-ln-height;
}

// Blockquote
blockquote {
  position: $blqt-pos;
  margin: 0;
  padding: $blqt-padding;
  background-color: $blqt-bg-default-color;
  text-align: $blqt-txt-algn;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
	margin: $blqt-quote-mrgn;
	color: $blqt-quote-fnt-color;
	font-family: $blqt-quote-fnt-family;
	font-style: $blqt-quote-fnt-style;
	font-weight: $blqt-quote-fnt-wgt;
	letter-spacing: $blqt-quote-ltr-spng;
  }
  &.medium {
	padding: $blqt-padding-md;
  }
  &.large {
	padding: $blqt-padding;
  }
  &.small {
	padding: $blqt-padding-xs;
  }
  p {
	margin-top: $blqt-author-mgrn-top;
	margin-bottom: $blqt-author-mgrn-bottom;
	color: $blqt-author-fnt-color;
	font-family: $blqt-author-fnt-family;
	font-size: $blqt-author-fnt-size;
	font-weight: $blqt-author-fnt-wgt;
	letter-spacing: $blqt-author-ltr-spng;
	text-transform: $blqt-author-txt-trf;
  }

  &.q-bg {
	background-color: $blqt-bg-color;
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
	  color: $blqt-bg-quote-fnt-color;
	  font-family: $blqt-bg-quote-fnt-family;
	}
	p {
	  color: $blqt-bg-author-fnt-color;
	  font-family: $blqt-bg-author-fnt-family;
	  font-size: $blqt-bg-author-fnt-size;
	  font-weight: $blqt-bg-author-fnt-weight;
	  letter-spacing: $blqt-bg-author-ltr-spng;
	  text-transform: $blqt-bg-author-txt-trf;
	}
	&:after {
	  color: $blqt-bg-smb-color;
	}
  }

  &.q-border {
	border: $blqt-border;
  }

  &.q-line {
	padding: $blqt-line-pdng;
	border-left: $blqt-line;
	background-color: $blqt-line-bg-color;
	@media screen and (max-width: 768px) {
	  padding: $blqt-line-pdng-xs;
	}
  }

  &:before {
	color: $c-dark;
  }

  @media screen and (max-width: 1230px) {
	padding: $blqt-padding-md;
  }
  @media screen and (max-width: 768px) {
	padding: $blqt-padding-xs;
  }
}

// List ol
ol {
  padding-left: 0;
  counter-reset: $ol-cnt-reset;
  li {
	display: $ol-li-display;
	padding-left: $ol-li-pdng-left;
	color: $ol-fnt-color;
	font-family: $ol-fnt-family;
	font-weight: $ol-fnt-wgt;
	&:before {
	  position: $ol-number-pos;
	  top: $ol-number-top;
	  left: $ol-number-left;
	  color: $ol-number-color;
	  font-family: $ol-number-fnt-family;
	  font-size: $ol-number-fnt-size;
	  font-weight: $ol-number-fnt-wgt;
	  content: counter(item) '. ';
	  counter-increment: item;
	}
  }
  &.ol--lg-nums {
	li {
	  margin-bottom: $ol-lg-number-li-mrgn-bottom;
	  padding-left: $ol-lg-number-li-pdng-left;
	  line-height: $ol-lg-number-li-ln-hgt;
	  &:before {
		color: $ol-lg-number-li-fnt-color;
		font-size: $ol-lg-number-li-fnt-size;
		line-height: $ol-lg-number-ln-hgt;
		content: $ol-lg-number-content;
	  }
	}
  }
}

.t-grey {
  color: $c-grey;
}
