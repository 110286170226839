.aht-filter {

  &--edu {
    .aht-filter {
      &__field {
        &--cat,
        &--prc-type {
          &:after {
            color: $c-dark;
          }
        }
      }

      &__icon {
        color: $c-active;
      }

      &__input,
      &__select {
        color: $c-dark;
        font-family: $f-roboto;
      }

      &__input {
        &::placeholder {
          color: $c-dark;
        }
      }

      &__select {
        option {
          color: $c-dark;
        }
      }

      &__submit {
        background-color: $c-dark;
        &:hover {
          background-color: $c-active;
          color: $c-dark;
        }
      }
    }
  }
}
