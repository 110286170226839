// Headings 1-6
$h-color: $c-dark;
$h-fnt-family: $fnt-family-1;
$h-ltr-spacing: 0;
$h-fnt-wgt: 400;

$h1-fnt-size: 50px;
$h1-ln-height: 1.2;
//max-width: 480
$xs-h1-fnt-size: 35px;

$h2-fnt-size: 40px;
$h2-ln-height: 1.2;

$h3-fnt-size: 30px;
$h3-ln-height: 1.2;

$h4-fnt-size: 24px;
$h4-ln-height: 1.2;

$h5-fnt-size: 20px;
$h5-ln-height: 1.5;

$h6-fnt-size: 16px;
$h6-ln-height: 1.5;

// Subtitle
$st-font-size: 14px;
$st-font-weight: bold;
$st-letter-space: 2.8px;
$st-margin-bottom: 20px;

// Paragraph
$p-color: $c-grey;
$p-fnt-family: $fnt-family-2;
$p-fnt-size: 16px;
$p-ln-height: 1.63;
$p-ltr-spng: 0;


// Blockquote
$blqt-pos: relative;
$blqt-txt-algn: center;
$blqt-padding: 70px 100px 40px;
$blqt-padding-md: 70px 50px 40px;
$blqt-padding-xs: 70px 40px 40px;
$blqt-quote-mrgn: 0;
$blqt-author-mgrn-top: 40px;
$blqt-author-mgrn-bottom: 0;
$blqt-bg-default-color: $c-light;
$blqt-quote-ltr-spng: 0;
$blqt-quote-fnt-family: $fnt-family-1;
$blqt-quote-fnt-color: $c-dark;
$blqt-quote-fnt-wgt: 400;
$blqt-quote-fnt-style: normal;
$blqt-author-fnt-family: $fnt-family-1;
$blqt-author-fnt-color: $c-active;
$blqt-author-fnt-size: 14px;
$blqt-author-fnt-wgt: bold;
$blqt-author-ltr-spng: 1px;
$blqt-author-txt-trf: uppercase;
$blqt-smb-color: transparent;

$blqt-bg-color: $c-dark;
$blqt-bg-quote-fnt-color: #fff;
$blqt-bg-quote-fnt-family: $fnt-family-1;
$blqt-bg-author-fnt-color: $c-active;
$blqt-bg-author-fnt-family: $fnt-family-1;
$blqt-bg-author-fnt-weight: bold;
$blqt-bg-author-fnt-size: 14px;
$blqt-bg-author-ltr-spng: 1px;
$blqt-bg-author-txt-trf: uppercase;
$blqt-bg-smb-color: #fff;

$blqt-border: 1px solid #eee;

$blqt-line: 2px solid #ebebeb;
$blqt-line-pdng: 0 100px 0 35px;
$blqt-line-pdng-xs: 0 40px 0 35px;
$blqt-line-bg-color: transparent;

// DELIMITER
$hr-color: #eee;

// Lists
$ul-ol-fnt-size: 16px;
$ul-ol-ln-height: $ul-ol-fnt-size * 2;
$ul-ol-li-pos: relative;

// List ul
$ul-fnt-family: $fnt-family-1;
$ul-fnt-color: $c-grey;
$ul-fnt-wgt: 400;
$ul-li-pdng-left: 30px;
$ul-list-style: none;
$ul-icon-fnt-family: $f-ionicons;
$ul-icon-fnt-size: 12px;
$ul-icon-content: '\f120';
$ul-icon-pos: absolute;
$ul-icon-top: -1px;
$ul-icon-left: 0;
$ul-icon-color: $c-active;
// List ul with mod ul--dotted
$ul-icon-dot-content: '\f21b';
$ul-icon-dot-fnt-size: 6px;
$ul-icon-dot-top: 0px;

// List ol
$ol-li-display: block;
$ol-li-pdng-left: 30px;
$ol-fnt-family: $fnt-family-1;
$ol-fnt-color: $c-grey;
$ol-fnt-wgt: 400;
$ol-cnt-reset: item;
$ol-number-pos: absolute;
$ol-number-top: 0;
$ol-number-left: 0;
$ol-number-fnt-family: $fnt-family-1;
$ol-number-fnt-size: $ul-ol-fnt-size;
$ol-number-fnt-wgt: bold;
$ol-number-color: $c-dark;

// List ol with mod ol--lg-nums
$ol-lg-number-li-pdng-left: 45px;
$ol-lg-number-li-mrgn-bottom: 44px;
$ol-lg-number-li-fnt-color: $ol-number-color;
$ol-lg-number-li-fnt-size: 24px;
$ol-lg-number-li-ln-hgt: 24px;
$ol-lg-number-content: '0' counter(item) '. ';
$ol-lg-number-ln-hgt: 28px;

// aheto-link
$aheto-link-primary-color: $c-active;
$aheto-link-default-color: $c-dark;
$aheto-link-font-family: $fnt-family-1;
$aheto-link-font-size: 16px;
$aheto-link-font-weight: 600;
$aheto-link-font-style: normal;
$aheto-link-letter-space: 0;

// btn
$btn-fnt-family: $fnt-family-1;
$btn-font-weight: 700;
$btn-ico-large-size: 24px;
$btn-ico-size: 14px;
$btn-ico-margin: 10px;
$btn-border-radius: 5px;
$btn-border-opacity: 0.5;
$btn-border-size: 1px;
$btn-line-height: 1.15;

// btn - standart
$btn-standart-size: 14px;
$btn-standart-letter-space: 1.5px;
$btn-standart-padd-vert: 18px;
$btn-standart-padd-horiz: 39px;

// btn - large
$btn-large-size: 16px;
$btn-large-letter-space: 1.5px;
$btn-large-padd-vert: 23px;
$btn-large-padd-horiz: 45px;

// btn - small
$btn-small-size: 14px;
$btn-small-letter-space: 1.5px;
$btn-small-padd-vert: 13px;
$btn-small-padd-horiz: 34px;

// Btn - inline
$btn-inline-size: 14px;
$btn-inline-letter-space: 1.5px;
$btn-inline-weight: 700;

// Btn - default color
$btn-default-bg: $c-active;
$btn-default-border: $c-active;
$btn-default-color: $c-white;

// btn - light
$btn-light-bg: $c-white;
$btn-light-border: $c-white;
$btn-light-color: $c-dark;

// btn - dark
$btn-dark-bg: $c-dark;
$btn-dark-border: $c-dark;
$btn-dark-color: $c-white;

// btn - gradient
$btn-gradient-circle-deg: 120deg;
$btn-gradient-default-deg: -90deg;
$btn-gradient-circle-bg: linear-gradient($btn-gradient-circle-deg, $c-dark 10%, $c-active 80%);
$btn-gradient-bg: linear-gradient($btn-gradient-default-deg, $c-dark, $c-active);

// btn - altertnative
$btn-alter-bg: $c-light;
$btn-alter-border: $c-light;
$btn-alter-color: $c-grey;

// btn - transparent
$btn-transparent-bg: transparent;

// btn shadow
$btn-shadow-top: 1.169px;
$btn-shadow-left: 3.825px;
$btn-shadow-blur: 15.66px;
$btn-shadow-size: 2.34px;
$btn-shadow-opacity: 0.2;
$btn-box-shadow: $btn-shadow-top $btn-shadow-left $btn-shadow-blur $btn-shadow-size rgba($c-active, $btn-shadow-opacity);

// btn big size (rectangle)
$btn-rect-padd-top: 60px;
$btn-rect-padd-right: 23px;
$btn-rect-padd-bottom: 30px;
$btn-rect-padd-left: 23px;
$btn-rect-font-size: 24px;
$btn-rect-letter-space: 1px;

// btn - circle
$btn-circle-width: 115px;
$btn-circle-height: 115px;
$btn-circle-icon-size: 24px;
// shadow for circle button
$btn-circle-shadow-top: 0;
$btn-circle-shadow-left: 7px;
$btn-circle-shadow-blur: 16px;
$btn-circle-shadow-size: 0;
$btn-circle-shadow-opacity: 0.44;
$btn-circle-shadow: $btn-circle-shadow-top $btn-circle-shadow-left $btn-circle-shadow-blur $btn-circle-shadow-size rgba($c-active, $btn-circle-shadow-opacity);

html, body {
  max-width: 100vw;
  overflow-x: hidden;
  width: auto;
  -webkit-overflow-scrolling: touch;
  padding: 0;
  margin: 0 auto;
}
body {
  overflow-y: hidden;
}
.aheto-banner--height-construction {
  height: 400px;
}
@include medium-size-max{
  .wrapper-costr-img {
    max-height: 640px;
  }
}
@include xs-size-max {
  .aheto-banner .aheto-heading--t-white .aheto-heading__title {
    font-size: 40px;
  }
  .aheto-heading--t-white .aheto-heading__title {
    font-size: 22px;
  }
  .construction-careersWrap .aheto-heading__title {
    font-size: 22px;
  }
  .constr-skills .aheto-heading__title {
    font-size: 22px;
  }
}
.aheto-header-10__menu-wrapper .aheto-header-10 .main-menu .menu-item a.active {
  @include menu-size-max {
    color: #EBB113;
  }
}
