.aheto-time-scale {

  &__item {
    &:before {
      background: rgba($c-active, 0.15);
    }

    &:after {
      background: $c-active;
    }
  }

  &__title {
    color: $c-active;
  }
}
