.aht-filter-trvl {
  &__select,
  &__input {
    font-weight: 600;
  }

  &__field {
    &:nth-child(2),
    &:nth-child(3) {
      select {
        border-radius: 0;
      }
    }

    &--month,
    &--type {
      &:after {
        color: $c-grey;
      }
    }
  }

  &__field {
    &--type {
      .aht-filter-trvl__icon {
        top: 40px;
      }
    }
    
    &--month {
      .aht-filter-trvl__icon {
        top: 37px;
      }
    }

    &--loc {
      .aht-filter-trvl__icon {
        top: 38px;
      }
    }
  }

  &__icon {
    color: $c-alter;
  }

  &__select {
    color: $c-grey;
    font-family: $fnt-family-2;

    &.selected {
      color: $c-dark;
    }

    option {
      color: $c-dark;
    }
  }

  &__input {
    color: $c-dark;
    font-family: $fnt-family-2;
    &::placeholder {
      color: $c-grey;
    }
  }

  &__submit {
    background-color: $c-active;
    font-family: $fnt-family-1;
    &:hover {
      background-color: $c-alter;
    }
  }
}
