.authentication {
  &__sign-in,
  &__sign-up {
    font-family: $fnt-family-1;
  }

  &__log-in,
  &__register {
    font-family: $fnt-family-1;
  }
}
