// Wrappers
.shop-content-wrap {
  padding: 0 100px;

  @media screen and (max-width: 1500px) {
    padding: 0 50px;
  }

  @media screen and (max-width: 767px) {
    padding: 0;
  }
}

.breadcrumb-wrap {
  padding: 10px 0;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}

.woocommerce-tabs-wrap {
  border-top: 1px solid #eee;
}
