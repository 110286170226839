.aheto-footer-3 {
  &__main {
    padding: 120px 0 50px;

    @include medium-size-max {
      padding: 50px 0 0;
    }
  }
  &__cr-text {
    font-size: 15px;
  }
}
