.aheto-footer-12 {
  background-color: $c-white;
  &__main {
    padding: 70px 0 0;
    @include medium-size-max {
      padding: 50px 0 0;
    }
  }
}
.aht-socials--circle {
  .aht-socials__link:hover {
    color:$c-white;

    .aht-socials__icon {
      color:$c-white;
    }
  }
}
