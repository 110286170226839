.aheto-progress {
  &__chart-circle {
    stroke: $c-active;
  }

  &__chart-icon {
    color: $c-active;
  }

  &__chart-number {
    color: $c-dark;
  }


  // Progress Bar
  &__bar-icon {
    color: $c-active;
  }

  &__bar-val {
    background-color: $c-active;
  }

  &--simple {
    .aheto-progress {
      &__chart {
        &-circle {
          stroke: $c-active;
        }
      }
    }
  }

  &--construction {
    .aheto-progress {
      &__bar {
        background-color: #e1e1e1;
      }
    }
  }
}
