.aht-author {
  &__pos {
    color: #999;
  }
}

.yoga {
  .aht-author__img-holder {
    margin-bottom: 40px;
  }
}

.author-yoga {
  .aht-author__pos {
    color: #999;
  }
}
