.aheto-accordion {
  &__title {
    &.active {
      border-bottom: 2px solid $c-active;
    }
  }
}

//construction
.construction-careersWrap {
  .aheto-accordion {
    &__plus {
      &:before {
        color: $c-dark;
      }
    }
  }
}