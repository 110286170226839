.aheto-btn-container {
  &--fullwidth {
    .aheto-btn {
      &:hover {
        border-color: $c-dark;
        background-color: $c-dark;
      }
    }
  }
}
.aheto-btn {
  &-evts {
    .aheto-btn--light {
      &:hover {
        color: $c-black!important;
        background-color: $c-white;
      }
    }
  }
}
