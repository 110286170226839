.product {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  font-family: $fnt-family-1;

  > .onsale {
    display: block;
    position: absolute;
    top: 20px;
    left: 0;
    width: 55px;
    height: 25px;
    padding: 7px 14px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    // background-color: $color-1;
    color: $c-white;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    z-index: 10;
  }

  // Photo gallery
  .woocommerce-product-gallery {
    flex: 1;
    width: 100%;
    max-width: 570px;
    margin-right: 30px;
  }


  // Product details

  // Product details summary
  .summary {
    flex: 1;

    .product_title {
      margin: 0;
    }

    .woocommerce-stars-rating {
      display: flex;
      flex-wrap: wrap;
      margin-top: 25px;

      .tm__stars {
        margin-right: 10px;
        i {
          color: #FFC015;
          font-size: 16px;
        }
      }

      .woocommerce-review-link {
       color: #ababab;
        font-size: 14px;
      }
    }

    .woocommerce-product-rating {
      margin-top: 20px;
      .star-rating {
        span {
          font-size: 14px;
        }
      }
      .woocommerce-review-link {
       // color: $color-1;
        font-size: 14px;
      }
    }

    .price {
      margin: 20px 0;
      color: #222;
      font-size: 20px;
      font-weight: 500;
      line-height: 26px;

      del {
        margin-right: 10px;
       color: #999;
      }

      ins {
        text-decoration: none;
      }
    }

    .product_meta {
      margin-top: 25px;
     color: #999;

      > span {
        display: flex;
        align-items: center;
      }

      &-title {
        width: 150px;
        color: #222;
        font-size: 14px;
        font-weight: 500;
        line-height: 2.71;
      }

      a {
       color: #999;
        font-size: 14px;
      }
      span{
        font-size: 14px;
      }
    }

    .woocommerce-share {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      &__title {
        width: 150px;
        margin: 0;
        font-weight: 500;
        line-height: 36px;
        font-size: 14px;
      }

      &__link {
        margin-right: 20px;
       color: #999;
        font-size: 14px;
        &:hover{
          color: #2a74ed;
        }

        &:last-child {
          margin-right: 0;
        }


      }
    }
  }

  // Product details cart
  .cart {

    .option-holder {
      margin: 20px 0 30px;
      border-top: 1px solid #eee;
      border-bottom: 1px solid #eee;
    }

    .quantity {
      display: flex;
      flex-wrap: wrap;
      margin: 25px 0 40px;
      border-radius: 3px;

      label {
        display: block;
        flex-basis: 100%;
        order: -1;
        margin-bottom: 20px;
        font-size: 16px;
        font-weight: 500;
      }

      .quantity-input {
        display: flex;
        background-color: #f7f7f7;

        span {
          display: inline-block;
          width: 42px;
          padding: 14px 0;
         color: #999;
          font-size: 20px;
          text-align: center;
          cursor: pointer;
          user-select: none;
        }

        input {
          width: 42px;
          border: 0;
          outline: none;
          background-color: #f7f7f7;
         color: #222;
          font-family: $fnt-family-1;
          font-size: 16px;
          font-weight: 500;
          text-align: center;

          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
          }
        }
      }
    }


  }

  .product-color{
    float: left;
    label{
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 36px;
    }
    margin: 25px 60px 40px 0px;
    input[type=checkbox]{
      display: none;
    }
    input[type=checkbox] + label:before {
      content: "\2713";
      color: transparent;
      display: inline-block;
      border: 1px solid #e0e0e0;
      font-size: 14px;
      line-height: 19px;
      margin: -6px 20px 0 0;
      height: 20px;
      width: 20px;
      border-radius: 3px;
      text-align: center;
      vertical-align: middle;
      transition: color ease .3s;
    }
    input[type=checkbox]:checked + label:before {
      color: #222;
    }
    input[id=black] + label:before{
      background: #181514;
    }
    input[id=black]:checked + label:before {
      color: #ffffff;
    }
    input[id=grey] + label:before{
      background: #d7d8da;
    }
  }

  // Description and review
  .woocommerce-tabs {
    position: relative;
    flex-basis: 100%;
    margin-bottom: 100px;
    @media screen and (max-width: 991px) {
      margin-bottom: 80px;
    }
    @media screen and (max-width: 991px) {
      margin-bottom: 50px;
    }

    .tabs {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      max-width: 400px;
      margin: 50px auto 55px;
      padding: 0;
      @media screen and (max-width: 768px) {
        max-width: 100%;
      }
      > li {
        margin: 15px 0;
        padding-left: 0;
        position: relative;
        @media screen and (max-width: 768px) {
          width: 100%;
        }
        &:first-child{
          &:before {
            content: '';
            border: 1px solid #eeeeee;
            position: absolute;
            height: 100%;
            right: -50px;
            @media screen and (max-width: 768px) {
              content: '';
              border: 1px solid #eeeeee;
              position: absolute;
              height: 1px;
              right: 0px;
              width: 100%;
              bottom: -14px;
            }
          }
        }
        &:before {
          content: none;
        }

        a {
         color: #222;
          font-size: 30px;
          font-weight: 300;
          line-height: 30px;
          &:hover{
            color: #2A74ED;
          }
        }

        &.active {
          a {
           color: #2a74ed;
          }
        }
      }
    }

    .woocommerce-Tabs-panel {
      max-width: 770px;
      margin: 0 auto;

      > h2 {
        display: none;
      }
    }

    // Reviews
    .woocommerce-Reviews {

      // Reviews comments
      #comments {
        .commentlist {
          margin: 0;
          padding: 0;

          .comment {
            margin-bottom: 30px;
            padding: 0 0 30px;
            border-bottom: 1px solid #eee;

            &:before {
              content: none;
            }

            &:last-child {
              margin-bottom: 0;
            }
          }

          .comment_container {
            display: flex;

            .avatar {
              margin-right: 15px;
              border-radius: 50%;
            }

            .comment-text {
              .meta {
                margin: 0;

                .woocommerce-review {
                  &__author {
                   color: #999;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 16px;
                  }

                  &__dash {
                   color: #999;
                  }

                  &__published-date {
                   color: #999;
                    font-size: 14px;
                    line-height: 16px;
                  }
                }
              }

              .description {
                p {
                  margin-top: 15px;
                  margin-bottom: 0;
                }
              }
            }
          }
        }
      }

      // Reviews add comment
      #review_form_wrapper {
        margin-top: 75px;
        text-align: center;

        .comment-respond {
          .comment-reply-title {
            font-size: 30px;
            font-weight: 300;
            line-height: 40px;
          }

          .comment-form {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            input {
              transition: all 0.3s;
              border: 1px solid #eee;
              border-radius: 5px;
              outline: none;
              font-family: $fnt-family-1;

              &::placeholder {
               color: #999;
                font-family: $fnt-family-1;
              }

              &:focus {
                border: 1px solid #2A74ED;
                border-radius: 5px;
                box-shadow: 0px 15px 30px 0px rgba(42, 116, 237, 0.1);
              }
            }

            textarea {
              width: 100%;
              height: 140px;
              padding: 20px;
              transition: all 0.3s;
              border: 1px solid #eee;
              border-radius: 5px;
              outline: none;
             color: #999;
              font-family: $fnt-family-1;
              resize: none;

              &::placeholder {
               color: #999;
                font-family: $fnt-family-1;
              }

              &:focus {
                border: 1px solid #2A74ED;
                border-radius: 5px;
                box-shadow: 0px 15px 30px 0px rgba(42, 116, 237, 0.1);
              }
            }

            .comment-notes {
              order: -1;
            }

            &-author {
              flex: 1;
              margin-right: 30px;

              label {
                display: none;
              }
            }

            &-email {
              flex: 1;

              label {
                display: none;
              }
            }

            &-rating {
              display: flex;
              flex-basis: 100%;
              order: -1;
              margin-top: 45px;
              margin-bottom: 15px;
              @media screen and (max-width: 991px) {
                margin-top: 25px;
              }
              label {
                margin-right: 20px;
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
              }

              // Stars
              p {
                margin: 0;

                span {
                  display: block;
                  direction: ltr;

                  & > a:hover:before,
                  & > a:hover ~ a:before {
                   color: #999;
                    content: '\f4b2';
                  }

                  a {
                    display: inline-block;
                    transition: all 0s ease 0s;
                   color: #999;
                    font-size: 0;
                    line-height: 24px;

                    &:before {
                      color: #999999;
                      font-family: $f-ionicons;
                      font-size: 16px;
                      content: '\f4b2';
                    }

                    &:hover {
                      opacity: 1;

                      &:before {
                        color: #FFC015;
                        content: '\f4b3';
                      }
                    }

                    &.active ~ a:before {
                     color: #999;
                      content: '\f4b2';
                    }
                  }
                }
              }
            }

            &-comment {
              flex-basis: 100%;
              margin: 15px 0 0;

              label {
                display: none;
              }
            }
          }

          .form-submit {
            input[type=submit] {
              display: inline-block;
              margin-top: 25px;
              padding: 21px 40px;
              border: 0;
              border-radius: 5px;
              // background-color: $color-1;
              color: $c-white;
              font-family: $fnt-family-1;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              letter-spacing: 1.5px;
              line-height: 1.15;
              text-transform: uppercase;
              cursor: pointer;
            }
          }
        }
      }
    }

    // Additional information
    .shop_attributes {
      tbody {
        tr {
          line-height: 40px;
          th {
            padding-right: 50px;
           color: #999;
            text-align: left;
          }
          td {
           color: #999;
            text-align: left;
            p {
              margin: 0;
             color: #999;
            }
          }
        }
      }
    }

  }


  // Desktop, tablets, mobiles styles

  @media screen and (max-width: 1500px) {
    // Photo gallery
    .woocommerce-product-gallery {
      max-width: 460px;
    }
  }

  @media screen and (max-width: 991px) {
    // Photo gallery
    .woocommerce-product-gallery {
      max-width: 345px;
    }
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;
    justify-content: center;

    > .onsale {
      display: inline-block;
      position: static;
      flex-basis: 100%;
      order: 2;
      width: auto;
      margin-top: 20px;
      margin-right: auto;
    }

    .woocommerce-product-gallery {
      flex-basis: 100%;
      order: 1;
      max-width: 460px;
      margin-right: auto;
      margin-left: auto;
    }

    .summary {
      flex-basis: 100%;
      order: 3;
      margin-top: 20px;

      .product_meta {
        &-title {
          width: auto;
          margin-right: 30px;
        }
      }

      .woocommerce-share {
        &__title {
          width: auto;
          margin-right: 30px;
          font-size: 14px;
          font-weight: 500;
          line-height: 2.71;

        }
      }
    }

    .woocommerce-tabs {
      order: 4;
      margin-bottom: 75px;

      .tabs {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 50px;
      }

      .woocommerce-Reviews {
        #review_form_wrapper {
          margin-top: 50px;

          .comment-respond {
            .comment-form {
              &-author {
                flex-basis: 100%;
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  }
}
.comment-shop{
  border: none;
  border-bottom: 1px solid #eeeeee;
  margin: 0px;
  padding-bottom: 10px;
  .comment-author{
    .author-photo {
      margin-right: 20px;
      margin-left: 0px;
    }
  }
  .btn-reply {
    font-size: 14px;
    color: #bbbbbb;
    line-height: 1.71;
    display: inline-block;
    padding-top: 10px;
    &:hover{
      color: #222;
    }
    i{
      padding-right: 10px;
    }
  }
  .comment-content{
    position: relative;
    .tm__stars{
      line-height: 1.5;
      letter-spacing: -1.2px;
      text-align: left;
      color: #ffc015;
      position: absolute;
      top: 0px;
      right: 0px;
      @media screen and (max-width: 450px) {
        top: 19px;
        right: auto;
        left: 0px;
      }
    }
  }
}
.swiper-margin{
  .swiper-wrapper{
    margin-left: 2px;
  }
}
.add_coment{
  width: 100%;
  .aheto-form--default{
    p{
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-box-align: start;
      -ms-flex-align: start;
      align-items: flex-start;
      margin: -14px;
      margin-top: 0;
      margin-bottom: 0;
      .wpcf7-submit{
        margin-top: 25px!important;
      }
    }
  }
  .Name{
    width: 50%;
  }
  .Email{
    width: 50%;
  }
}

.cart-related-products-wrap {
  @media (min-width: 990px) and (max-width: 1135px) {
    .aheto-products__item:last-child {
      display: none !important;
    }
  }
  @media (min-width: 840px) and (max-width: 950px) {
    .aheto-products__item:last-child {
      display: none !important;
    }
  }
  @media (min-width: 768px) and (max-width: 855px) {
    .aheto-products__item:last-child {
      display: block !important;
    }
  }
}
