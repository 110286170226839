.aheto-instagram-gallery {
  .aheto-btn {
    &.aheto-btn {
      &--light {
        &:hover {
          background: $c-active;
          border-color: $c-active;
        }
      }
    }
  }
  &--edu {
    .aheto-instagram-gallery {
      &__link {
        .aheto-btn {
          font-family: $f-roboto;
        }
        &-text {
          font-family: $f-roboto;
          color: $c-grey;
          font-weight: normal;
        }
      }
    } 
    
  }
}
