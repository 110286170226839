.aht-course {

  &--edu {
    .aht-course {

      &__price {
        background-color: $c-active;
        color: $c-dark;
        font-family: $f-roboto;
        &--free {
          background-color: $c-alter-3;
          color: $c-white;
        }
      }
      &__ovrl {
        background-color: rgba($c-dark, 0.9);
      }
      &__link {
        font-family: $f-roboto;
        letter-spacing: 1.4px;
        &:hover {
          color: $c-dark;
        }
      }
      &__icon {
        color: $c-dark;
      }
      &__text {
        color: $c-dark;
        font-family: $f-roboto;
      }
      &__star {
        color: $c-active;
      }
      &__title {
        font-family: $fnt-family-2;
      }
      &__author {
        font-family: $f-roboto;
      }
    }
  }
}

.aht-course-det {
  &--edu {

    .aht-course-det {
      &__content {

        b {
          color: $c-active;
        }
      }

      &__cmnts {
        .author {
          color: $c-dark;
        }
        .comment-reply-link {
          color: $c-active;
          &:hover {
            color: $c-dark;
          }
        }

        // Respond
        .comment {
          &-reply-title {
            b {
              color: $c-active;
            }
          }

          &-form {
            textarea {
              &::placeholder {
                color: $c-grey;
              }
              &:focus {
                border: 1px solid $c-dark;
                box-shadow: 0px 15px 30px 0px rgba($c-dark, 0.1);
              }
            }
          }
        }

        .column {
          input {
            color: $c-dark;
            &::placeholder {
              color: $c-grey;
            }
            &:focus {
              border: 1px solid $c-dark;
              box-shadow: 0px 15px 30px 0px rgba($c-dark, 0.1);
            }
          }
        }

        .submit-btn {
          background-color: $c-dark;
          &:hover {
            background-color: $c-active;
            color: $c-dark;
          }
        }
      }
    }
  }
}
.aht-course--edu-details {
  .aht-page--right-sb {
    .aht-page__content {
      p {
        font-family: $f-roboto;
        font-size: 16px;
        line-height: 24px;
        color: $c-grey;
      }
      blockquote {
        border-left: 2px solid $c-dark-2;
        font-family: $fnt-family-3;
        p {
          color: $c-dark;
        }
      }
      .img_text_container {
        h6 {
          b {
            font-family: $fnt-family-2;
          }
        }
      }
      .post-meta {
        .tags {
          span, a {
            font-family: $f-roboto;
          }
          a {
            color: $c-dark;
          }
        }
      }
      .likes {
        font-family: $f-roboto;
        i {
          color: $c-alter;
          margin-right: 12px;
        }
      }
      .post-author-info {
        p {
          b {
            color: $c-dark;
            font-family: $fnt-family-2;
          }
          &:last-child {
            a {
              font-family: $f-roboto;
            }
          }
        }
      }
      .aht-socials {
        &__icon {
          color: $c-dark;
        }
        &:hover {
          i {
            color: $c-dark;
          }
        }
      }
      &-inner {
        .comment {
          &-content {
            .author-name {
              font-family: $fnt-family-2;
              color: $c-dark;
            }
          }
          &-date {
            font-family: $f-roboto;
          }
          &-author {
            .btn-reply {
              font-family: $f-roboto;
              font-weight: normal;
              letter-spacing: normal;
              &:hover {
                color: $c-active;
                i {
                  color: inherit;
                }
              }
            }
          }
        }
      }
      .aheto-form {
        &--default {
          input, textarea {
            &:focus {
                box-shadow: 0px 15px 30px 0px rgba($c-dark, 0.1);
                border: 1px solid $c-dark;
              }
          }
          .aheto-btn {
            color: $c-white;
            background-color: $c-dark;
            box-shadow: none;
            text-transform: uppercase;
            border: 2px solid transparent;
            font-family: $f-roboto;
            letter-spacing: 1.4px;
            font-weight: bold;
            margin-top: 20px;
            &:hover {
              color: $c-dark;
              background-color: $c-white;
              border: 2px solid $c-dark;
            }
          }
        }
      }
      
    }
    .calendar {
      table tr td.active {
        background-color: $c-dark;
      }
      &_header {
        .month {
          color: $c-dark;
          font-family: $fnt-family-2;
          font-weight: bold;
          font-size: 20px;
        }
      }
    }
  }
}
