.events-place {
    &--item {
        &-content {
            &::before {
                position: absolute;
                top: 50%;
                left: 0;
                transform: translate(-100%, -50%);
                width: 0;
                height: 0;
                content: '';
                border-style: solid;
                border-width: 30px 40px 30px 0;
                border-color: transparent #ffffff transparent transparent;

            }
            
        }
        &-text {
            color: $c-grey;
        }
        &-price {
            .price-value {
                color: $c-active;
            }
        }
        &-details {
            color: $c-dark;
            &:hover {
                color: $c-active;
            }
        }
    }

    @media screen and (max-width: 1200px) {
        &--item {
            &-content {
                &::before {
                    content: none;
                }
            }
        }
    }
}