.aheto-footer-6 {
  background-image: url('../img/education/bg-2.png');
  background-repeat: no-repeat;
  background-position: right bottom;
  &__main {
    padding: 105px 0 30px;
    @include medium-size-max {
      padding: 50px 0 0;
    }
  }
  .aht-socials {
    margin-top: 40px;
    @include medium-size-max {
      margin-top: 0px;
    }
    &__link {
      color: $c-white!important;
      &:hover {
        i {
          color: $c-dark!important;
        }
        
      }
    }
  }
  &__bottom {
    &:before {
      display: block;
      position: absolute;
      width: 100%;
      border-top: 1px solid rgba(255, 255, 255, 0.1);
      content: '';
    }
    .aheto-footer-menu {
      .menu-item {
        a {
          font-family: $fnt-family-2;
          font-size: 15px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
        }
      }
    }
  }
  &__cr-text {
    color: rgba($c-light, 0.5);
  }
  .widget_mc4wp_form_widget p {
    color: $c-white;
  }
  .menu {
    &-item {
      a {
        color: $c-white!important;
      }
    }
    .current-menu-item, &-item:hover {
      a {
        color: $c-active!important;
      }
    }
  }
  .widget {
    &-text {
      .post-date {
        color: rgba($c-white, 0.5);
      }
    }
  }

  @media screen and (max-width: 1200px) {
    .aht-socials {
      &--circle {
        .aht-socials__link {
          width: 45px;
          height: 45px;
          line-height: 45px;
        }
      } 
    }
    .aheto-footer-menu {
      .menu-item {
        margin: 0;
        margin-right: 20px;
      }
    } 
  }
  
    
}
