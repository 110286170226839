.checkout_coupon {
  input {
    width: 100%;
    padding: 20px;
    transition: 0.3s all;
    border: 1px solid #eee;
    border-radius: 5px;
    outline: none;
    color: #999;
    font-family: $fnt-family-1;

    &::placeholder {
      color: #999;
      font-family: $fnt-family-1;
    }

    &:focus {
      border: 1px solid #2A74ED;
      border-radius: 5px;
      box-shadow: 0px 15px 30px 0px rgba(42, 116, 237, 0.1);
      transition: 0.3s all;
    }
  }
}
.product-name{
  color: #222222;
}
.woocommerce-checkout {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 95px;
  @media screen and (max-width: 991px) {
    margin-bottom: 60px;
  }
  @media screen and (max-width: 767px) {
    margin-bottom: 50px;
  }
  .woocommerce-Price-amount {
	color:#2A74ED;
  }

  .product-total {
    .woocommerce-Price-amount {
	  color:#ababab;
    }
  }
  .cart-subtotal {
    .woocommerce-Price-amount {
	  color:#222;
      font-weight: 500;
    }
  }

  .shipping {
	td {
	  color:#ababab;
	}
  }

  // Billing details
  .col2-set {
    display: flex;
    flex: 3;
    flex-direction: column;

    // .col-1 Billing
    .col-1,
    .col-2 {
      padding-left: 0px;
      max-width: 570px;
      margin-right: 30px;
    }
    .woocommerce-billing-fields {
      h3 {
        font-weight: 300;
      }

      &__field-wrapper {
        display: flex;
        flex-wrap: wrap;
        margin: -15px;

        p {
          display: flex;
          flex: 1;
          flex-direction: column;
          justify-content: flex-end;
          min-width: 200px;
          margin: 15px;

          label {
            margin-bottom: 8px;
            font-weight: 500;
          }

          abbr {
            text-decoration: none;
          }

          input {
            width: 100%;
            padding: 20px;
            transition: 0.3s all;
            border: 1px solid #eee;
            border-radius: 5px;
            outline: none;
            color: #222;
            font-family: $fnt-family-1;

            &::placeholder {
              color: #999;
              font-family: $fnt-family-1;
            }

            &:focus {
              border: 1px solid #2A74ED;
              border-radius: 5px;
              box-shadow: 0px 15px 30px 0px rgba(42, 116, 237, 0.1);
            }
          }

          select {
            width: 100%;
            height: 60px;
            padding: 18px;
            transition: 0.3s all;
            border: 1px solid #eee;
            border-radius: 5px;
            outline: none;
            color: #222;
            font-family: $fnt-family-1;
            background: #FFFFFF;
            &:focus {
              border: 1px solid #2A74ED;
              box-shadow: 0px 15px 30px 0px rgba(42, 116, 237, 0.1);
            }

            &.cf7-selected {
              color: #999;
            }
          }

          &#billing_first_name_field {
          }

          &#billing_last_name_field {
          }

          &#billing_company_field {
            flex-basis: 100%;
          }

          &#billing_country_field {
            flex-basis: 100%;
            order: -1;
          }

          &#billing_address_1_field {
            flex-basis: 100%;
          }

          &#billing_address_2_field {
          }

          &#billing_city_field {
            flex-basis: 100%;
          }

          &#billing_state_field {

          }

          &#billing_postcode_field {
            flex-basis: 100%;
          }

          &#billing_phone_field {

          }

          &#billing_email_field {

          }

        }
      }
    }

    #creat_an_account{
      font-size: 13px;
      label{
        color: #696969;
      }
    }
    // .col-2 Shipping
    .col-2 {
      margin-top: 20px;
    }

    .woocommerce-shipping-fields {
      h3 {
        font-size: 14px;
        label {

          input[type=checkbox] {
            margin-right: 10px;
            outline: none;
          }
        }
      }

      &__field-wrapper {
        display: flex;
        flex-wrap: wrap;
        margin: -15px;

        p {
          display: flex;
          flex: 1;
          flex-direction: column;
          justify-content: flex-end;
          min-width: 200px;
          margin: 15px;

          label {
            margin-bottom: 8px;
          }

          abbr {
            text-decoration: none;
          }

          input {
            width: 100%;
            padding: 20px;
            transition: 0.3s all;
            border: 1px solid #eee;
            border-radius: 5px;
            outline: none;
            color: #999;
            font-family: $fnt-family-1;

            &::placeholder {
              color: #999;
              font-family: $fnt-family-1;
            }

            &:focus {
              border: 1px solid #2A74ED;
              border-radius: 5px;
              box-shadow: 0px 15px 30px 0px rgba(42, 116, 237, 0.1);
            }
          }

          select {
            width: 100%;
            height: 60px;
            padding: 20px;
            transition: 0.3s all;
            border: 1px solid #eee;
            border-radius: 5px;
            outline: none;
            color: #999;
            font-family: $fnt-family-1;

            &:focus {
              border: 1px solid #2A74ED;
              box-shadow: 0px 15px 30px 0px rgba(42, 116, 237, 0.1);
            }

            &.cf7-selected {
              color: #999;
            }
          }

          &#shipping_first_name_field {
          }

          &#shipping_last_name_field {
          }

          &#shipping_company_field {
            flex-basis: 100%;
          }

          &#shipping_country_field {
            flex-basis: 100%;
            order: -1;
          }

          &#shipping_address_1_field {

          }

          &#shipping_address_2_field {
          }

          &#shipping_city_field {

          }

          &#shipping_state_field {

          }

          &#shipping_postcode_field {
            flex-basis: 100%;
          }
        }
      }
    }

    .select2 {
      width: 100%;
      padding: 20px;
      transition: 0.3s all;
      border: 1px solid #eee;
      border-radius: 5px;
      outline: none;
      color: #999;
      font-family: $fnt-family-1;

      &::placeholder {
        color: #999;
        font-family: $fnt-family-1;
      }

      &:focus {
        border: 1px solid #2A74ED;
        border-radius: 5px;
        box-shadow: 0px 15px 30px 0px rgba(42, 116, 237, 0.1);
      }

      &-selection {
        outline: none;
      }
    }
    .notes{
      label{
        font-size: 16px;
        font-weight: 500;
      }
    }
    .woocommerce-additional-fields {

      &__field-wrapper {
        display: flex;
        flex-wrap: wrap;
        margin: -15px;

        p {
          display: flex;
          flex: 1;
          flex-direction: column;
          justify-content: flex-end;
          min-width: 200px;
          margin: 15px;

          label {
            margin-bottom: 5px;
            color: #696969;
          }

          textarea {
            width: 100%;
            height: 200px;
            padding: 20px;
            transition: 0.3s all;
            border: 1px solid #eee;
            border-radius: 5px;
            outline: none;
            color: #999;
            font-family: $fnt-family-1;
            resize: none;

            &::placeholder {
              color: #999;
              font-family: $fnt-family-1;
            }

            &:focus {
              border: 1px solid #2A74ED;
              border-radius: 5px;
              box-shadow: 0px 15px 30px 0px rgba(42, 116, 237, 0.1);
            }
          }
        }
      }
    }
  }
  .ship-to-different-address{
    line-height: 8px;
  }
  // Order
  .checkout_order {
    display: flex;
    flex: 2;
    flex-direction: column;
    align-self: flex-start;
    padding: 70px 35px;
    background: #f9f9f9;
	border:1px solid #eeeeee;
	// background-color: lighten($color-4, 38);
    text-align: center;
    @media screen and (max-width: 767px) {
      padding: 50px 35px;
    }

    h3 {
      margin: 0;
      padding: 0 40px;
      font-weight: 300;
    }

    .woocommerce-checkout-review-order {
      margin-top: 30px;
      padding: 0px;
      border-top: 1px solid #eee;

      .shop_table {
        width: 100%;
        text-align: left;
        border-collapse: collapse;

        thead,
        tbody,
        tfoot {
          tr {
            th:last-child,
            td:last-child {
              text-align: right;
            }
          }
        }
        thead {
          tr {
            th {
              font-size: 16px;
              font-weight: 500;
              line-height: 50px;
            }
          }
        }

        tbody {
          tr {
            font-size: 14px;
            font-weight: 400;
            line-height: 40px;
            td {
              strong {
                font-weight: 400;
                color: #999;
              }

              &:last-child {
                color: #999;
              }
            }
          }
        }

        tfoot {
          tr {
            border-top: 1px solid #eee;
            line-height: 50px;

            th {
              font-weight: 500;
            }

            &.shipping {
              td {
                color: #999;
              }
            }

            &.order-total {
              border-bottom: 1px solid #eee;
              line-height: 80px;
              td {
                strong {
                  // color: $color-1;
                  font-size: 30px;
                  font-weight: 300;
                }
              }
            }
          }
        }
      }
    }

    .woocommerce-checkout-payment {
      .aheto-btn {
        cursor: pointer;
      }
    }
  }

  @include small-size-max {
    .col2-set {
      flex-basis: 100%;
      .col-1,
      .col-2 {
        max-width: none;
        margin: 0;
      }
    }

    .checkout_order {
      margin-top: 50px;
    }
  }
}

.woocommerce-checkout .col2-set .col-1, .woocommerce-checkout .col2-set .col-2 {
    display: table;
    width: 100%;
}
#creat_an_account{
  label{
    color: #696969;
  }
}
#ship-to-different-address-checkbox + span {
  font-size: 13px;
  font-weight: 400;
}

.cheque_payment{
  span{
    display: block;
  }
  label{
    font-size: 14px;
    color: #999;
    padding-right: 20px;
  }
  input[type=radio] {display: none;}
  input[type=radio] + label:before {
    content: "\f111";
    font-family: FontAwesome;
    border-radius: 50%;
    display: inline-block;
    border: 1px solid #ababab;
    line-height: 20px;
    margin: -5px 10px 0 0;
    height: 14px;
    width: 14px;
    text-align: center;
    vertical-align: middle;
    font-size: 0;
  }
  input[type=radio]:checked + label:before {
    font-size: 6px;
    line-height: 13px;
    color: #222222;
  }
}
