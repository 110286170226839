
// Structure
.shop {
  display: flex;
  flex-wrap: wrap;

  &__sidebar {
    width: 275px;
    margin-right: 70px;

    @media screen and (max-width: 1500px) {
      margin-right: 50px;
    }

    @include medium-size-max {
      flex-basis: 100%;
      margin-right: 0;
      margin-bottom: 50px;
    }
  }

  &__content {
    flex: 1;
  }

  &__details {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }

  &__products {
    min-width: 10px;
    margin-top: 15px;
    overflow: hidden;
  }

  &__pagination {
    display: flex;
    justify-content: flex-center;
    margin-top: 50px;
  }

  &__load-more {
    display: inline-flex;
    align-items: center;
    margin: 0 auto;
  }

  &__load-more-icon {
    height: 24px;
    margin-right: 15px;
    color: #999;
    font-size: 24px;
    line-height: 3px;
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(359deg); }
  }

  &__load-more-text {
    margin: 0;
    color: #999;
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 24px;
  }
}

// Sidebar
.widget-area {
  font-family: $fnt-family-1;

  // Filter
  .widget_price_filter {
    border-bottom: 1px solid #eeeeee;
    padding-bottom: 50px;
    @media screen and (max-width: 991px) {
      margin-bottom: 0px;
    }
    .widget-title {
      margin: 0 0 30px;
      font-family: $fnt-family-1;
      font-size: 30px;
      font-weight: 300;
      color: #222;
    }

    .ui-slider {
      width: 100%;
      height: 3px;
      margin-bottom: 85px;
      background-color: #eee;
    }

    .ui-slider-range {
      height: 3px;
      // background-color: $color-2;
    }

    .ui-slider-handle {
      display: block;
      top: -3px;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      // background-color: $color-2;
    }

    button {
      &[type=submit] {
        border-radius: 5px;
        display: inline-block;
        position: relative;
        padding: 16px 38px;
        border-width: 1px;
        border-style: solid;
        border-color: rgba(42, 116, 237, 0.5);
        outline: none;
        background-color: #2A74ED;
        color: #fff;
        font-family: "Roboto", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        letter-spacing: 1.4px;
        line-height: normal;
        box-shadow: 1.2px 3.8px 15.7px 2.3px rgba(42, 116, 237, 0.2);
        text-transform: uppercase;
        cursor: pointer;
        &:hover{
          background-color: #fff;
          color: #2A74ED;
        }
      }
    }

    .price_label {
      color: #999;
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
      margin-bottom: 16px;

      .from,
      .to {
        color: #999;
      }
    }
  }

  // Categories
  .widget_product_categories {
    border-bottom: 1px solid #eeeeee;
    margin: 0;
    padding: 50px 0px;


    .product-categories {
      margin: 0;
      padding: 0;
    }

    .widget-title {
      margin: 0 0 33px;
      font-family: $fnt-family-1;
      font-size: 30px;
      font-weight: 300;
      color: #222222;
    }
    .product-categories-item{
      padding-botom:50px;
    }
    .product-categories-item.active{
      color: #2a74ed;

    }
    .accordion {
      cursor: pointer;
      border: none;
      text-align: left;
      outline: none;
      font-size: 18px;
      transition: 0.5s;

    }
    .panel {
      padding: 10px 18px 0px;
      background-color: #fff;
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.3s ease-out;
      li{
        font-family: Roboto;
        font-size: 16px;
        line-height: 2.5;
        color: #ababab;
        cursor: pointer;
      }
      .color__blue{
        color: #2a74ed;
      }
      li:last-child {
        margin-bottom: 50px;
        @media screen and (max-width: 766px) {
          margin-bottom: 25px;
        }
      }
    }
  }
}

// Details
.woocommerce-result-count {
  margin: 0;
  padding-bottom: 10px;

  span {
  color:#222;
  }

  @include small-size-max {
    flex-basis: 100%;
    order: 2;
    margin-top: 20px;
  }
}

.woocommerce-ordering {

  .orderby {
    padding: 0px 18px;
    border: 1px solid #eee;
    border-radius: 5px;
    background-color: $c-white;
    color: #999;
    font-family: $fnt-family-1;
    height: 50px;
    margin-right: 30px;
    &:focus {
      outline: none;
    }

    @include small-size-max {
      flex-basis: 100%;
      padding: 10px;
    }
  }
}
.sort{
 &-colum{
   color: #999999;
   margin-right: 15px;
 }
  &-colum.active{
    color: #2a74ed;
  }
  &-row{
    color: #999999;
    margin-right: 5px;
  }
}
.price_slider_wrapper{
  .slider-container{
    .scale{
      display: none;
    }
    .back-bar{
      height: 3px;
      background-color: #eeeeee;
      .pointer-label{
        top: auto;
        bottom: -30px;
      }
      .selected-bar{
        background-color: #222222;
        background-image: linear-gradient(to bottom, #222222, #222222);
      }
      .pointer{
        width: 16px;
        height: 16px;
        top: -8px;
        background-color: #222222;
        background-image: linear-gradient(to bottom, #222222, #222222);
        border: 1px solid #222222;
        cursor: pointer;
      }
      .pointer-label{
        font-family: Roboto;
        font-size: 14px;
        font-weight: 500;
        color: #222222;
      }
      .pointer.low {
        margin-left: 3px;
      }
      .pointer-label.low {
        &::before{
          content: '$';
          padding-left: 5px;
        }
        &::after{
          content: '.00';
        }
      }
      .pointer{
        font-family: Roboto;
        font-size: 14px;
        font-weight: 500;
        color: #222222;
        margin-left: 3px;
      }
      .pointer-label.high {
        &::before{
          content: '$';
          padding-left: 5px;
        }
        &::after{
          content: '.00';
        }
      }
    }
  }
}
.widget_product_color{
  padding-top: 50px;
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 50px;
  .widget-title{
    color: #222222;
    margin-bottom: 30px;
    font-size: 30px;
    font-weight: 300;
  }
  .color-items{
    flex-wrap: wrap;
    .color-item{
      width: 20px;
      height: 20px;
      border-radius: 3px;
      border: solid 1px #e0e0e0;
      margin-right: 20px;
      cursor: pointer;
      @media screen and (max-width: 450px) {
        margin-right: 15px;
      }
    }
    .border-color{
      border-color: #2a74ed;
    }
    .color-black{
      background-color: #333333;
    }
    .color-white{
      background-color: #ffffff;
    }
    .color-green{
      background-color: #706b55;
    }
    .color-pink{
      background-color: #e4beb4;
    }
    .color-orange{
      background-color: #eed5b6;
    }
    .color-blue{
      background-color: #b5ccd8;
    }
    .color-grey{
      background-color: #ababab;
    }
    .color-brown{
      background-color: #c47d5e;
    }
    .color-purple{
      background-color: #715781;
    }
  }
}
.widget_product_size {
  padding-top: 50px;
  .widget-title {
    color: #222222;
    margin-top: 0px;
    margin-bottom: 30px;
    font-size: 30px;
    font-weight: 300;
  }
  .size-items{
    flex-wrap: wrap;
    .size-item{
      width: 50%;
    }
  }
  label{
    margin-bottom: 12px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: 0.7px;
    text-align: left;
    color: #222222;
  }

  input[type=checkbox]{display: none;}
  input[type=checkbox] + label:before {
    content: "\2713";
    color: #fff;
    display: inline-block;
    border: 1px solid #eeeeee;
    font-size: 15px;
    line-height: 16px;
    margin: -4px 15px 0 0;
    height: 16px;
    width: 16px;
    text-align: center;
    border-radius: 3px;
    vertical-align: middle;
    transition: color ease .3s;
  }
  input[type=checkbox]:checked + label{
    color: #2a74ed;
  }
  input[type=checkbox]:checked + label:before {
    color: #2a74ed;
  }
}
.lightgallery{
  .aheto-products__btn{
    margin-right: 20px;
  }
}
