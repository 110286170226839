.aheto-heading {

  &__title {
    b {
      color: $c-active;
    }

    &:after {
      background-color: $c-alter;
    }

    span {
      color:$c-active;
    }

    &--agency {
      span {
        background: linear-gradient(330deg, $c-active, $c-alter);
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }

  &__desc {

    &--drop-caps {
      &::first-letter {
        color: $c-active;
      }
    }
  }

  & > &__subtitle {
    &--alter {
      color: $c-active;
    }
  }

  &--divider-t1 {
    position: relative;

    .aheto-heading__title {
      &::after {
        background-color: $c-active;
      }
    }
  }

  &--divider-t2 {
    &--color-1 {
      .aheto-heading__title {
        &::after {
          background-color: $c-dark;
        }
      }
    }
  }

  &--simple {
    .aheto-heading__subtitle {
      color: $c-active;
    }

  }

  &--classic {
    .aheto-heading__subtitle {
      color: $c-active;

      &:after {
        background-color: $c-alter-3;
      }
    }

    p {
      b {
        color: $c-dark;
      }
    }
  }

  &--yoga {
    .aheto-heading {

      &__title {
        font-family: $f-futura;
        font-size: $h1-fnt-size;
        letter-spacing: 2.5px;
        color: #555555;
      }

      &__subtitle {
        color: $c-active;
        font-weight: bold;

        &.services-subtitle {
          margin-bottom: 5px;
        }
      }
    }

    &.motto {
      .aheto-heading__title {
        font-family: $f-futura;
        font-size: 30px;
        font-weight: bold;
        line-height: 1.33;
        letter-spacing: 0.4px;
      }
    }
  }


  &--political{
    .aheto-heading__title {
      span {
        color:$c-alter;
      }
    }
  }

  &--construction-tm {
    .aheto-heading {
      &__title {
        color: $c-active;
      }
      &__desc {
        font-family: $fnt-family-1;
      }
    }
  }
  &--construction {
    .aheto-heading {
      &__title {
        color: $c-dark-2;
        @include xs-size-max {
          font-size: 22px;
        }
      }
    }
  }
  &--construction_about {
    .aheto-heading {
      &__title {
        color: $c-dark;
      }
    }
  }

  &--restaurant {
    .aheto-heading {
      &__subtitle {
        color: $c-active;
      }
      &__title {
        color: $c-dark;
      }
    }
  }

  &--restaurant-awards {
    .aheto-heading {
      &__title {
        color: #eee;
      }
      &__desc {
        color: $c-grey;
      }
    }
  }

  &--restaurant-speciality {
    .aheto-heading {
      &__title {
        color: #fff;
        -webkit-font-smoothing: antialiased;
      }
    }
  }

  &--single-gallery {
    .aheto-heading {
      &__title {
        color: #eee;
      }
    }
  }

}

.aht-heading {

  b {
    color: $c-active;
  }

  &--travel {
    .aht-heading {

      &__subtitle {
        color: $c-active;
        font-family: $f-sign-painter, sans-serif;
      }

      &__title {
        font-weight: 800;
        letter-spacing: 4px;
        line-height: 52px;
      }
    }
  }

  &--travel-2 {
    background-position: left 350px center !important;
  }

  &--chr {
    .aht-heading {
      &__subtitle {
        color: $c-active;
      }
    }
  }

  &--travel-video {
    position: relative;

    &:before {
      background-image: url('../img/travel/decor-4.png');
      background-repeat: no-repeat;
      background-position: center;
      display: block;
      content: "";
      position: absolute;
      left: -160px;
      top: -30px;
      width: 221px;
      height: 119px;

      @include small-size-max {
        left: initial;
        right: 0;
      }
    }

    .aht-heading__title {
      font-family: $f-mukta;
      font-size: 40px;
      font-weight: 800;
      letter-spacing: 4px;
      color: $c-dark;
      text-transform: none;
      letter-spacing: 3px;
      margin-bottom: 15px;
      text-transform: uppercase;
    }

    .aht-heading__subtitle {
      font-size: 50px;
      font-family: $f-sign-painter;
      text-transform: lowercase;
    }

    .aht-heading__desc {
      line-height: 1.6;
      margin-top: 44px;

      @include medium-size-min {
        max-width: 350px;
      }
    }
  }

  &--travel-decor {
    position: relative;

    &:after {
      display: block;
      content: "";
      position: absolute;
      top: 0;
      right: 50%;
      height: 120px;
      width: 120px;
      background-position: right center;
      background-repeat: no-repeat;
    }
  }

  &--travel-services {
    &:after {
      background-image: url('../img/travel/decor-1.png');
      transform: translate(161px, -50px);
    }
  }

  &--travel-directions {
    &:after {
      background-image: url('../img/travel/decor-3.png');
      transform: translate(161px, -50px);
    }
  }
  
}
.section-yoga {
  .aheto-heading__title {
    word-spacing: 5px;
    letter-spacing: 3px;
  }
}
.aheto-heading--yoga {
  .aheto-heading__title {
    font-family: $f-futura;
    font-size: 50px;
    font-weight: bold;
    letter-spacing: 2.5px;
  }
}
