.aheto-404 {
  .fil1 {
    fill: lighten($c-active, 20%);
  }

  .fil2 {
    fill: $c-active;
  }

  &__wrap {
  .aheto-btn {
    &.aheto-btn {
      &--outline {
        &:hover {
          color:$c-white;
        }
      }
    }
  }
 }
}
