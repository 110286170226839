.wpml-ls-legacy-dropdown-click,
.wpml-ls-legacy-dropdown {
	a {
		&.wpml-ls-item-toggle {
			&:after {
			    border: 1px solid $c-grey;
			}
		}
	}
}
