.aht-blog-trvl {

  &__title {
    color: $c-dark;
    font-family: $f-mukta;
    font-size: 24px;
    font-weight: bold;
    line-height: 1.33;
  }

  &__text {
    font-family: $f-open-sans;
  }
  
  &__icon {
    color: $c-alter;
  }

  &__text {
    color: $c-grey;
  }
}

.aht-post-trvl {

  @include menu-size-max {
    &__info {
      margin-top: 0;        
    }

    &__author {
      margin-top: 50px;
    }
  }

  &__socials {
    display: flex;
    justify-content: center;

    .aheto-socials__link {
      height: 50px;
      width: 50px;
      border: 1px solid #eee;
      border-radius: 50%;
      margin-right: 10px;
      margin-left: 0;
      display: inline-block;
      float: left;
      position: relative;
      padding-top: 11px;

      &:hover {
        background-color: $c-active;
        border-color: $c-active;
        .icon {
          color: $c-white;          
        }
      }
    }
  }

  &__cat {
    background-color: $c-active;
    height: 22px;
    padding: 0 13px;
    line-height: 21px;
    font-family: $f-open-sans;
  }

  &__title {
    font-weight: 800;
    line-height: 1.3;
  }

  &__detail {
    font-family: $f-open-sans;
    line-height: 1.73;
  }

  &__tb-author-name {
    line-height: 1.63;
  }

  &__page {
    width: 100%;
    max-width: initial;
    margin: initial;
    padding: 0;
  }

  &__content {
    & > * {
      &:last-child {
        margin-bottom: 0;
      }
    }

    > p {
      line-height: 1.6;
      margin: 0 auto 50px auto;
      width: calc(100% - 200px);

      @include menu-size-max {
        width: 100%;
      }
    }

    img {
      margin-bottom: 50px;
    }

    h3 {
      line-height: 1.33;
      margin: 0 auto 50px auto;
      width: calc(100% - 200px);

      @include menu-size-max {
        width: 100%;
      }
    }

    blockquote {
      padding: 100px 0 0 0;
      margin-bottom: 50px;
      display: flex;
      justify-content: center;

      p {
        font-family: $f-open-sans;
        font-size: 30px;
        line-height: 1.33;
        text-align: center;
        color: #262527;
      }
    }

    figure {
      img {
        margin-bottom: 50px;
      }

      h6 {
        font-size: 18px;
        line-height: 1.44;
        margin-bottom: 18px;
      }

      p {
        line-height: 1.6;
        margin: 0 0 50px 0;
        font-weight: 400;
      }
    }
  }

  &__tags {
    &-name {
      color: $c-dark;
    }

    &-list {
      color: $c-active;
    }

    &-link {
      &:hover {
        color: $c-alter;
      }
    }
  }

  &__likes {
    color: $c-dark;

    &:hover {
      box-shadow: 0 0 20px 0 rgba($c-dark, 0.13);
    }
  }

 &__author-desc {
  line-height: 1.6;
 }

  &__author-link {
    &:hover {
      color: $c-alter;
    }
  }
}



