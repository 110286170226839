.aheto-product-images {

  &__main {
    margin-bottom: 20px;
  }

  &__main-img {
    height: 730px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  &__main-link {
    display: block;
    width: 100%;
    height: 100%;
  }

  &__thumbs {

  }

  &__thumbs-img {
    width: 105px;
    height: 130px;
    margin-top: 10px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  @media screen and (max-width: 1500px) {
    .aheto-product-images__main-img {
      height: 500px;
    }
  }
}
