.aheto-toolbar {
  .aht-socials {
    &__item {
      a {
        color: $c-grey;
        &:hover {
          color: $c-active;
        }
      }
    }
  }
}
