.aheto-tab {
  &__list {
    &-link {
      span {
        color: $c-dark;
      }
    }
  }

  &__box {
    &--overlay {
      &-1 {
        &:before {
          background-color: rgba($c-active, 0.8);
        }
      }

      &-2 {
        &:before {
          background: linear-gradient(to right, $c-active, $c-alter);
        }
      }

      &-3 {
        &:before {
          background: linear-gradient(to right, transparent, transparent 35%, $c-active);
          @include large-size-max {
            background: linear-gradient(to right, transparent, $c-active);
          }
          @include medium-size-max {
            background-color: $c-active;
          }
        }
      }

      &-4 {
        &:before {
          background-color: rgba($c-black, 0.5);
        }
      }
    }
  }

  &--simple {
    .aheto-tab {

      &__head {
        box-shadow: 0px 10px 30px 0px rgba($c-active, 0.1);
      }

      &__list {
        &-link {
          border-right: 1px solid rgba($btn-dark-bg, 0.1);
          border-bottom: 1px solid rgba($btn-dark-bg, 0.1);
          color: $p-color;

          &:hover {
            border-color: rgba($btn-default-bg, 0.33);
            background: $btn-default-bg;
            box-shadow: 1.169px 3.825px 15.66px 2.34px rgba($btn-default-bg, 0.2);
          }
        }

        &-item {
          &.active {
            a {
              border-color: rgba($btn-default-bg, 0.33);
              background: $btn-default-bg;
              box-shadow: 1.169px 3.825px 15.66px 2.34px rgba($btn-default-bg, 0.2);
            }
          }
        }
      }
    }
  }

  &--business {

	.aheto-tab__content {
      background-color: $c-dark;
    }
    .aheto-tab__list-link{
      span{
        color: $c-active;
      }
    }
    .aheto-tab__list-item {
      background-color: #f6f3f3;
    }

    .aheto-tab__list-link {
      color: $c-alter;
    }

    .aheto-btn--light.aheto-btn--trans {
      &:hover {
        color: $c-alter;
      }
    }
  }

  &--construction-home,
  &--construction-news {
    .aheto-tab {

      &__list-item {
        &.active {
          .aheto-tab {
            &__list-link {
              color: $c-black;

              &:after {
                background-color: $c-active;
              }
            }
          }
        }
      }

      &__list-link {
        color: $c-grey;
      }
    }
  }

&--construction-news {
    .aheto-tab {

      &__list-item {
        &.active {
          .aheto-tab {
            &__list-link {
              color: $c-dark;
            }
          }
        }
      }
    }
  }


      &--event {
    box-shadow: 0 0 73px 0 rgba($c-black, 0.08);
    .aheto-tab {
      &__head {
        box-shadow: none;
        overflow: initial;
      }
      &__list {
        //border-bottom: 2px solid #eee;
        &-item.active {
          position: relative;
          &::after {
            position: absolute;
            bottom: -29px;
            left: 50%;
            width: 0;
            height: 0;
            -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
            border-top: 15px solid $c-active;
            border-right: 15px solid transparent;
            border-bottom: 15px solid transparent;
            border-left: 15px solid transparent;
            content: '';
          }
        }
        &-item {
          .event-title {
            display: block;
            color: $c-dark;
            font-size: 18px;
          }
          .event-date {
            color: $c-grey;
            display: block;
            font-size: 12px;
          }
        }
        &-item.active, &-item:hover {
          .event-title, .event-date {
            color: $c-light;
          }
        }
        &-item.active {
          a {
            box-shadow: none;
          }
        }
      }
    }

  }
  &--edu {
    .aheto-tab {
      &__list {
        &-item.active {
          font-family: $fnt-family-2;
          &::after {
            content: '';
            border-top-color: $c-dark;
          }
        }
        &-item {
          .event-title {
            font-family: $fnt-family-2;
          }
          .event-date {
            font-family: $f-roboto;
          }
        }
      }
    }
  }
}
