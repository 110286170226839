.aht-blog-chr {
  &__date {
    background-color: $c-active;
  }

  .aht-blog-chr__link {
    color: $c-active;

    &:hover {
      color: $c-dark;
    }
  }
}

.blog--church-wrap {
  .blog--church-title {
    background-color: $c-dark-2;

    .aht-heading{
      &__subtitle{
        color: $c-grey;

        &:before{
          background-color: $c-grey;
        }
      }

      &__title{
        color: white;
      }
    }
  }
}

.blog--single {
  &__chr {
   
    // Quotes
    .aht-widget-quotes {
      
      &__desc {
        font-family: $f-source-sans-pro;
        font-weight: normal;
      }

      &__author {
        color: $c-active;
        font-weight: bold;
        letter-spacing: 1.4px;
      }
    }

    // Posts
    .aht-widget-posts {
      &__main-title {
        color: #333333;
      }

      &__title {
        letter-spacing: -0.4px;
        
        &:hover {
          color: $c-active;
        }
      }

      &__date {
        font-size: 12px;
        color: $c-active;
        font-weight: bold;
        letter-spacing: 1.2px;
        text-transform: uppercase;
      }
    }

    .widget_categories {
      .widget-title {
        color: #333333;
      }

      ul {
        li {
          a {
            font-weight: normal;

            &:hover {
              color: $c-active;
            }
          }
        }

        li.active {
          color: $c-active;

          a {
            color: $c-active;
          }
        }
      }
    }

    .aht-page--right-sb {
      .aht-page {
        &__sb {
          background: #f7f7f7;


          &:before {
            background: #f7f7f7;
          }
        }

        &__content {
          blockquote {
            border-left: 2px solid $c-active;

            h3 {
              color: $c-dark;
            }
          }
        }
      }
    }

    .post-meta {
      .likes {
        
        &:hover {
          border-color: $c-active;
          color: $c-active;
        }
      }
    }
  }
}

.church-news-blog-wrap,
.church-events-wrap {
  .aheto-btn {
    &:hover {
      color: white;
      background-color: $c-dark-2;
      border-color: $c-dark-2;
    }
  }
}