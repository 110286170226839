// Home event
.home-event-wrap-upc-event {
  background-image: linear-gradient( 140deg, rgb(108,32,124) 0%, $c-active 100%);
  .aheto-btn-container {
    padding-bottom: 5px;
  }
}

.home-event-wrap-tickets {
  background-color: rgba($c-light, 0.4);
}

.author-bio-wrap {
  background-color: $c-active;
}

.bg-wrap-c-active {
  background-color: $c-active;
}

.bg-wrap-c-light {
  background-color: $c-light;
}
.bg-wrap-c-light-cons {
  background-color: #f9f9f9;
}
.bg-wrap-c-grey {
  background-color: $c-grey;
}

.bg-wrap-c-dark {
  background-color: $c-dark;
}

.bg-wrap-c-dark-2 {
  background-color: $c-dark-2;
}

.bg-wrap-c-alter {
  background-color: $c-alter;
}

.bg-wrap-c-alter-2 {
  background-color: $c-alter-2;
}

.bg-wrap-c-alter-3 {
  background-color: $c-alter-3;
}

hr {
  background: rgba($hr-color, 1);
  background-image: linear-gradient(to right, rgba($hr-color, 0), rgba($hr-color, 1), rgba($hr-color, 0));
  height: 1px;
}

.edu-wrap-2 {
  background-image: linear-gradient(to right, $c-active, $c-active);
}


.home-edu-wrap-pricing {
  &--heading {
    background-color: $c-active;
  }
}
