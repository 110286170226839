.aht-blog-rest {
	&__item {
		&--quote {
			background-color: #222;
			&::before {
				content: '”';
				position: absolute;
				font-family: $fnt-family-3;
				font-size: 190px;
				font-weight: bold;
				top: 64px;
				right: 40px;
				color: #3f3e3e;
				line-height: 120px;
			  }
		  }
	}
	&__bq {
		font-family: $fnt-family-3;
		font-style: italic;
		padding-right: 20px;
		-webkit-font-smoothing: antialiased;
	}
	&__author {
		font-family: $fnt-family-1;
		color: $c-active;
	}
	
	&__imgs {

	}
	&__cat {
		background-color: $c-active;
		font-family: $fnt-family-1;
		color: $c-white;
		-webkit-font-smoothing: antialiased;
		 &:hover {
		 	background-color: $c-active;
		 	color: $c-white;
		 }
	}

	&__date {
		font-family: $fnt-family-1;
	}
	&__desc {
		font-family: $fnt-family-1;
	}

	&__link {
		font-family: $fnt-family-1;
		&:not(:hover) {
		color: $c-active;
		}
	}

	&__video-link {
		color: $c-dark;
		&:hover {
		color: $c-active;
		}
	}
}

.aht-pag-rest {
  .wrap {
	.page-numbers {
		font-family: $fnt-family-1;
		&.current,
		&:hover {
			background-color: $c-active;
			color: $c-white;
		}
	}
	.page-numbers.no-hover {
		background-color: $c-white!important;
		color: #222!important;
	}
  }

}

.aht-post-rest {
  &__cat {
	background-color: $c-dark;
	font-family: $fnt-family-1;
	&:hover {
	  background-color: $c-active;
	  color: $c-white;
	}
  }

  &__title {
	b {
	  color: $c-active;
	}
  }

  &__date,
  &__read {
	color: $c-grey;
	font-family: $fnt-family-1;
  }

  &__tags {
	&-name {
	  color: #222;
	  font-family: $fnt-family-1;
	}

	&-list {
	  color: $c-dark;
	}

	&-link {
		font-family: $fnt-family-1;
		letter-spacing: normal;
		font-size: 16px;
		&:hover {
			color: $c-active;
		}
	}
  }

  &__likes {
	color: #222;
	font-family: $fnt-family-1;
	font-size: 16px;
	&:hover {
	  box-shadow: 0 0 20px 0 rgba($c-dark, 0.13);
	}
  }

  &__author {
	  &-desc, &-more, &-link {
		  font-family: $fnt-family-1;
		  letter-spacing: normal;
	  }
	  &-link {
		text-decoration: underline!important;
	  }
  }

  &__content {

	blockquote {
		p {
			font-family: $fnt-family-3;
		}
	}
	p {
		font-family: $fnt-family-1;
	}
  }
  &__socials {
	  .aheto-socials {
		  &__link {
			&:hover {
				.icon {
					color: $c-dark!important;
				}
				background-color: $c-active;
			}
		  }
		  &__icon.icon {
			color: $c-dark;
			&:hover {
				color: $c-dark!important;
			}
		  }
	  }
  }
}

.aht-comm-rest {

  h2 {
	b {
	  color: $c-active;
	}
  }
  .author {
	color: $c-dark;
	font-family: $fnt-family-2;
  }
	.date-post {
		font-family: $fnt-family-1;
	}
	p {
		font-family: $fnt-family-1;
	}

  .comment-reply-link {
	color: $c-grey;
	font-family: $fnt-family-1;
	&:hover {
	  color: $c-active;
	}
  }

  .comment {
	&-reply-title {
	  b {
		color: $c-active;
	  }
	}

	&-form {
	  textarea {
		color: $c-dark;
		font-family: $fnt-family-1;
		&::placeholder {
		  color: $c-grey;
		  font-family: $fnt-family-1;
		}
		&:focus {
		  border: 1px solid $c-dark;
		  box-shadow: 0px 15px 30px 0px rgba($c-dark, 0.1);
		}
	  }
	}
  }

  .column {
	input {
	  color: $c-dark;
	  font-family: $fnt-family-1;
	  &::placeholder {
		color: $c-grey;
		font-family: $fnt-family-1;
	  }

	  &:focus {
		border: 1px solid $c-dark;
		box-shadow: 0px 15px 30px 0px rgba($c-dark, 0.1);
	  }
	}
  }

  .submit-btn {
	background-color: $c-dark;
	font-family: $fnt-family-1;
	letter-spacing: 1.4px;
	&:hover {
	  background-color: $c-active;
	  color: $c-dark;
	}
  }
}

.blog--single {
	&__edu {
		.aht-widget-posts {
			&__title {
				font-family: $fnt-family-2;
				font-weight: normal;
				letter-spacing: normal;
				&:hover {
					color: $c-dark;
				}
			}
			&__date {
				font-family: $fnt-family-1;
				font-size: 12px;
				font-weight: bold;
			}
			&__button {
				font-family: $fnt-family-1;
			}
			&__button.active {
				color: $c-black;
				border-bottom-color: $c-dark;
			}
		}
		.aht-page--dual-sb .aht-page__content {
			padding: 0 0 0;
		}
		.aht-widget-advert {
			min-height: 300px;
			height: auto;
			padding: 28px 30px 36px;
			&__subtitle {
				font-family: $fnt-family-1;
				font-weight: normal;
			}
			&__desc {
				font-family: $fnt-family-1;
			}
			&__title {
				margin-bottom: 34px;
			}
			&__link {
				font-family: $fnt-family-1;
			}
		}

		.aht-page__sb .widget_mc4wp_form_widget {
			margin-top: 60px;
			background: $c-dark url(../img/inner-pages/blog/icon-envelope.png) no-repeat bottom right;
			padding: 42px 28px 95px;
			input[type="submit"] {
				font-family: $fnt-family-1;
				font-weight: bold;
			}
			input[type="email"] {
				font-family: $fnt-family-1;
				font-size: 15px;
			}
			p {
				font-family: $fnt-family-1;
				letter-spacing: normal;
			}
			.widget-title {
				font-family: $fnt-family-2;
				font-weight: 700;
			}
		}

		.aht-twitter {
			&__link {
				background-color: $c-dark;
			}
			&__follow {
				font-family: $fnt-family-1;
				letter-spacing: 1.4px;
			}
			&__slider {
				.btn {
					&-left, &-right {
						&:hover {
							color: $c-dark;
						}
						
					}
				}
			}
			&__content {
				.tweets {
					font-family: $fnt-family-1;
					.date {
						font-family: $fnt-family-1;
						color: #bbb;
					}
				}
			}
		}
		.widget_tag_cloud {
			.widget-title {
				font-family: $fnt-family-1;
				font-size: 16px;
				font-weight: 500;
				letter-spacing: normal;
				color: #333333;
			}
			
		}
		.tagcloud {
			a {
				display: inline-block;
				padding: 3px 18px;
				border: 1px solid #eee;
				background: $c-white;
				border-radius: 3px;
				font-size: 13px!important;
				font-family: $fnt-family-1;
				font-weight: normal;
				color: $c-grey;
				margin-right: 6px;
				margin-bottom: 4px;
				&:hover {
					background-color: $c-dark;
					color: $c-white;
				}
			}
		}
		.widget_categories {
			.widget-title {
				font-family: $fnt-family-1;
				font-size: 16px;
				font-weight: 500;
				line-height: 32px;
				letter-spacing: normal;
				color: #333333;
			}
			.cat-item {
				font-family: $fnt-family-1;
				a {
					font-family: $fnt-family-1;
					font-size: 16px;
					font-weight: normal;
					letter-spacing: normal;
					color: $c-grey;
				}
				&:hover {
					color: $c-dark;
					cursor: pointer;
					a {
						color: $c-dark;
					}
				}
				
			}
		}
		.calendar {
			table tr td.active {
			  background-color: $c-dark;
			}
			&_header {
			  .month {
				color: $c-dark;
				font-family: $fnt-family-2;
				font-weight: bold;
				font-size: 20px;
			  }
			}
			@include large-size-max {
				max-width: 100%;
			}
		}
		.search {
			padding: 0;
		}
	}
}
