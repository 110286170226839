.aheto-footer-8 {
  &__main {
    padding: 90px 0 45px;
    @include medium-size-max {
      padding: 50px 0 0;
    }
  }
  &__cr-text {
    opacity: 1;
    color: rgba($c-white, 0.6);
    font-size: 15px;
  }
  .aheto-socials {
    &__link {
      margin-left: 25px;
      .icon {
        color: rgba($c-white, 0.4);
        font-size: 20px;
        &:hover {
          color: rgba($c-white, 1);
        }
      }
    }
  }
}
