.aheto-footer-5 {
  background-image: url('../img/yoga/footer_bg.jpg');
  background-repeat: no-repeat;
  background-position: top left;
  background-size:cover;
  &__main {
    padding: 105px 20px 55px;
    @include medium-size-max {
      padding: 50px 20px 0;
    }
  }
  &__bottom {
    background-color: rgba($c-black, 0.09);
    padding: 0 20px;
  }

  &__main {
    @include small-size-max {
      padding-bottom: 0 !important;
    }
  }

  @include small-size-max {
    .aheto-footer-menu {
      .menu-item {
        margin-bottom: 0;
      }
    }
  }
}
