.aht-deal {

  &--trvl-1 {
    .aht-deal {
      &__price {
        color: $c-active;
      }
      &__desc {
        color: $c-grey;
        font-family: $f-open-sans, sans-serif;
      }
    }
  }
  
  &--trvl-2 {
    .aht-deal {

      &__promo {
        border-radius: 15px;
        background-image: linear-gradient(to right, #ff961a, $c-active);
        padding: 4px 10px;
        min-width: 125px;
        font-family: $f-mukta;
        font-size: 15px;
        font-weight: bold;
        text-align: center;
        color: $c-white;
      }

      &__price {
        color: $c-active;
        font-size: 24px;
      }

      &__title {
        font-size: 24px;
      }

      &__content {
        box-sizing: border-box;
      }
    }
  }
}
