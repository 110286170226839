.aheto-footer-13 {
  &__main {
    padding: 115px 0 20px;
    @include medium-size-max {
      padding: 50px 0 0;
    }
  }

  &__cr-text {
    font-size: 14px;
    line-height: 1.86;
    color: #777777;
  }

  .aheto-socials {
    &__link {
      margin-left: 25px;
      .icon {
        color: rgba($c-white, 0.4);
        font-size: 16px;
      }
    }
  }

  .widget_aheto__desc {
    font-size: 14px;
    line-height: 1.71;
    color: #777777;
  }

  .widget_aheto__info {
    font-size: 14px;
    line-height: 1.86;
    color: #777777 !important;
    padding-left: 28px;
    margin-bottom: 10px;

    a {
      font-size: 14px;
      line-height: 1.86;
      color: #777777 !important;     

      &:hover {
        color: $c-white !important;
      } 
    }

    .widget_aheto__icon {
      color: $c-active;
    }
  }

  .widget-title {
    font-size: 24px;
  }

  .widget_nav_menu {
    .menu-item {
      position: relative;
      padding-left: 20px;

      &:before {
        display: block;
        content: "";
        width: 5px;
        height: 5px;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background-color: $c-active;
        border-radius: 5px;
      }

      a {
        font-size: 14px;
        color: #777777;

        &:hover {
          color: $c-white;
        } 
      }
    }
  }

  .widget_media_gallery {
    figure {
      position: relative !important;
    }

    @include medium-size-max {
      .gallery {
        height: auto !important;
      }
    }
  }
}
