.aht-info {
  &__subtitle {
    &.c-active {
      color: $c-active;
    }
    
    &.c-light {
      color: $c-light;
    }

    &.c-grey {
      color: $c-grey;
    }

    &.c-dark {
      color: $c-dark;
    }

    &.c-dark-2 {
      color: $c-dark;
    }
    &.c-alter {
      color: $c-alter;
    }

    &.c-alter-2 {
      color: $c-alter-2;
    }

    &.c-alter-3 {
      color: $c-alter-3;
    }
  }
  &__detail-text {
    color: $c-dark;
  }

  &__detail-icon {
    color: $c-active;
  }

  &--yoga-course {
    .color-1 {
      color: #fc81a9 !important;
    }
    .color-2 {
      color: #7fbc48 !important; 
    }
    .color-3 {
      color: #ffc326 !important;
    }
    .color-4 {
      color: $c-alter-3 !important;
    }
    .aht-info__desc {
      font-weight: 400;
    }
    .aht-info {
      &__subtitle {
        color: $c-alter-2;
        font-family: $fnt-family-2;
        margin-top: 0;
      }
      &__link {
        &:not(:hover) {
          color: $c-dark;
        }
      }
    }

    .aheto-btn {
      &:hover {
        background-color: $c-active;
        color: $c-white;
      }
    }
  }

  &--yoga-event {
    .aht-info {
      &__link {
        &:not(:hover) {
          color: $c-dark;
        }
      }

      &__detail {
        &--date {
          .aht-info__detail-text {
            &:first-line {
              color: $c-active;
            }
          }
        }
      }

      &__desc {
        font-weight: 400;
      }
  }

  .aheto-btn {
    &:hover {
      background-color: $c-active;
      color: $c-white;
    }
  }
}
}
