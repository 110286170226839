@charset "UTF-8";
.container {
  width: 100% !important;
  max-width: 1200px !important;
}

html, body {
  max-width: 100vw;
  overflow-x: hidden;
  width: auto;
  -webkit-overflow-scrolling: touch;
  padding: 0;
  margin: 0 auto;
}

body {
  overflow-y: hidden;
}

.aheto-banner--height-construction {
  height: 400px;
}

@media screen and (max-width: 991px) {
  .wrapper-costr-img {
    max-height: 640px;
  }
}

@media screen and (max-width: 480px) {
  .aheto-banner .aheto-heading--t-white .aheto-heading__title {
    font-size: 40px;
  }
  .aheto-heading--t-white .aheto-heading__title {
    font-size: 22px;
  }
  .construction-careersWrap .aheto-heading__title {
    font-size: 22px;
  }
  .constr-skills .aheto-heading__title {
    font-size: 22px;
  }
}

@media screen and (max-width: 1024px) {
  .aheto-header-10__menu-wrapper .aheto-header-10 .main-menu .menu-item a.active {
    color: #EBB113;
  }
}

.color-primary {
  color: #EBB113;
}

::selection {
  background: #c2c6ed;
}

.aheto-titlebar h1 b,
.aheto-titlebar h2 b,
.aheto-titlebar h3 b,
.aheto-titlebar h4 b,
.aheto-titlebar h5 b,
.aheto-titlebar h6 b,
.aheto-titlebar p b {
  color: #EBB113;
}

.aheto-titlebar__overlay--grad {
  background: linear-gradient(36deg, #000 0%, #111539 100%);
}

.aheto-titlebar__input form input[type=text] {
  font-family: "Source Sans Pro", sans-serif;
}

.aheto-titlebar__input form input[type=text]::placeholder {
  font-family: "Source Sans Pro", sans-serif;
}

.aheto-titlebar__input form input[type=submit] {
  background-color: #EBB113;
  font-family: "Source Sans Pro", sans-serif;
}

.aheto-titlebar__breadcrumbs li.current:before {
  color: #999999;
}

.aheto-titlebar .c-active {
  background-color: #EBB113;
}

.aheto-titlebar .c-grey {
  background-color: #999999;
}

.aheto-titlebar .c-light {
  background-color: #EBEBEB;
}

.aheto-titlebar .c-dark {
  background-color: #111539;
}

.aheto-titlebar .c-dark-2 {
  background-color: #222222;
}

.aheto-titlebar .c-alter {
  background-color: #2A74ED;
}

.aheto-titlebar .c-alter-2 {
  background-color: #FF704F;
}

.aheto-titlebar .c-alter-3 {
  background-color: #FFC015;
}

.aheto-titlebar__intro {
  background-color: #EBB113;
}

.aheto-titlebar__breadcrumbs li:last-child {
  color: #fff;
}

.aheto-titlebar__breadcrumbs2 li:last-child {
  color: #999999;
}

.aheto-titlebar--yoga-details .aheto-titlebar__breadcrumbs, .aheto-titlebar--yoga-contact .aheto-titlebar__breadcrumbs {
  margin-bottom: 25px;
}

.aheto-titlebar--yoga-details .aheto-titlebar__content, .aheto-titlebar--yoga-contact .aheto-titlebar__content {
  margin-top: 15px;
}

@media screen and (max-width: 767px) {
  .aheto-titlebar--yoga-contact .aheto-titlebar__breadcrumbs {
    margin-bottom: 135px;
  }
  .aheto-titlebar--yoga-contact .aheto-titlebar__content {
    margin-top: 70px;
  }
}

.aheto-titlebar--construction .aheto-titlebar__title {
  font-size: 70px;
  font-weight: 600;
  color: #EBB113;
}

.aheto-titlebar--construction .aheto-titlebar__title span {
  color: #fff;
}

.aheto-titlebar--restaurant .aheto-titlebar__subtitle {
  color: #999999;
  opacity: 0.8;
}

.aheto-titlebar--restaurant:after {
  background-color: rgba(0, 0, 0, 0.7);
}

.aheto-pf__filter {
  color: #111539;
}

.aheto-pf__filter:hover {
  color: #EBB113;
}

.aheto-pf__filter.active {
  color: #EBB113;
}

.aheto-pf__close {
  color: #EBEBEB;
}

.aheto-pf--metro .aheto-pf__content:before {
  background-color: rgba(17, 21, 57, 0.9);
}

.aheto-pf--grid .aheto-pf__content:hover {
  box-shadow: 0px 0px 43px 0px rgba(235, 177, 19, 0.09);
}

.aheto-pf--grid .aheto-pf__title {
  color: #111539;
}

.aheto-pf--grid .aheto-pf__title:hover {
  color: #EBB113;
}

.aheto-pf--grid-political .aheto-pf__title {
  color: #EBB113;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 500;
  line-height: 1.3;
}

.aheto-pf--grid-political .aheto-pf__title:hover {
  color: #111539;
}

.aheto-pf--grid-political .aheto-pf__cat p {
  margin: 0;
  font-size: 16px;
  font-weight: 400;
}

.aheto-pf--grid-political .aheto-pf__cat-wrap {
  margin-top: 0px;
}

.aheto-pf--masonry .aheto-pf__title {
  color: #111539;
}

.aheto-pf--masonry .aheto-pf__title:hover {
  color: #EBB113;
}

.aheto-pf--new .aheto-pf__img:before {
  color: #111539;
}

.aheto-pf--new .aheto-pf__title {
  color: #111539;
}

.aheto-pf--new .aheto-pf__title:hover {
  color: #EBB113;
}

.aheto-pf--construction .aheto-pf__filter {
  color: #999999;
}

.aheto-pf--construction .aheto-pf__filter.active:after {
  background-color: #EBB113;
}

.aheto-pf .aheto-pf__filter-wrap__construction .aheto-pf__filter {
  color: #999999;
  font-weight: 600;
}

.aheto-pf .aheto-pf__filter-wrap__construction .aheto-pf__filter.active {
  color: #EBB113;
  font-weight: bold;
}

.aheto-pf--grid-restaurant .aheto-pf__zoom-icon:before, .aheto-pf--grid-restaurant .aheto-pf__like-icon:before, .aheto-pf--grid-restaurant .aheto-pf__share-icon:before {
  color: #fff;
}

.aheto-pf--grid-restaurant .aheto-pf__content:before {
  background-color: transparent;
  background-image: linear-gradient(to top, #000000 0%, rgba(0, 0, 0, 0) 100%);
}

.construction-projects-wrap .aheto-pf__content .aheto-pf__img:before {
  color: #000;
}

.construction-projects-wrap .aheto-pf__content .aheto-pf__cat-wrap .aheto-pf__cat p {
  color: #EBB113;
}

.portfolio-nav__dir-icon {
  color: #111539;
}

.portfolio-nav__list-icon {
  color: #999999;
}

.portfolio-new .aheto-heading__desc {
  color: #cccccc;
  font-weight: 400;
  line-height: 4.57;
  letter-spacing: 1.4px;
  margin: 0px;
}

.single__portfolio--3 .aheto-blockquote__title, .single__portfolio--4 .aheto-blockquote__title, .single__portfolio--5 .aheto-blockquote__title {
  font-family: "Source Sans Pro", sans-serif;
}

.single__portfolio--3 .aheto-socials--circle .aht-socials__link, .single__portfolio--4 .aheto-socials--circle .aht-socials__link, .single__portfolio--5 .aheto-socials--circle .aht-socials__link {
  border-color: rgba(153, 153, 153, 0.3);
}

.single__portfolio--3 .aheto-socials--circle .aht-socials__link:hover, .single__portfolio--4 .aheto-socials--circle .aht-socials__link:hover, .single__portfolio--5 .aheto-socials--circle .aht-socials__link:hover {
  border-color: #EBB113;
}

.single__portfolio--4 .bg-wrap-c-dark .aheto-heading p {
  color: #999999;
}

.single__portfolio--2 .aheto-socials--circle .aht-socials__link, .single__portfolio--7 .aheto-socials--circle .aht-socials__link {
  border-color: rgba(153, 153, 153, 0.3);
}

.single__portfolio--2 .aheto-socials--circle .aht-socials__link:hover, .single__portfolio--7 .aheto-socials--circle .aht-socials__link:hover {
  border-color: #EBB113;
}

.restaurant-gallery-wrap .aheto-pf__filter {
  color: #999999;
}

.restaurant-gallery-wrap .aheto-pf__filter.active {
  color: #EBB113;
}

.aht-course--edu .aht-course__price {
  background-color: #EBB113;
  color: #111539;
  font-family: "Roboto", sans-serif;
}

.aht-course--edu .aht-course__price--free {
  background-color: #FFC015;
  color: #fff;
}

.aht-course--edu .aht-course__ovrl {
  background-color: rgba(17, 21, 57, 0.9);
}

.aht-course--edu .aht-course__link {
  font-family: "Roboto", sans-serif;
  letter-spacing: 1.4px;
}

.aht-course--edu .aht-course__link:hover {
  color: #111539;
}

.aht-course--edu .aht-course__icon {
  color: #111539;
}

.aht-course--edu .aht-course__text {
  color: #111539;
  font-family: "Roboto", sans-serif;
}

.aht-course--edu .aht-course__star {
  color: #EBB113;
}

.aht-course--edu .aht-course__title {
  font-family: "Source Sans Pro", sans-serif;
}

.aht-course--edu .aht-course__author {
  font-family: "Roboto", sans-serif;
}

.aht-course-det--edu .aht-course-det__content b {
  color: #EBB113;
}

.aht-course-det--edu .aht-course-det__cmnts .author {
  color: #111539;
}

.aht-course-det--edu .aht-course-det__cmnts .comment-reply-link {
  color: #EBB113;
}

.aht-course-det--edu .aht-course-det__cmnts .comment-reply-link:hover {
  color: #111539;
}

.aht-course-det--edu .aht-course-det__cmnts .comment-reply-title b {
  color: #EBB113;
}

.aht-course-det--edu .aht-course-det__cmnts .comment-form textarea::placeholder {
  color: #999999;
}

.aht-course-det--edu .aht-course-det__cmnts .comment-form textarea:focus {
  border: 1px solid #111539;
  box-shadow: 0px 15px 30px 0px rgba(17, 21, 57, 0.1);
}

.aht-course-det--edu .aht-course-det__cmnts .column input {
  color: #111539;
}

.aht-course-det--edu .aht-course-det__cmnts .column input::placeholder {
  color: #999999;
}

.aht-course-det--edu .aht-course-det__cmnts .column input:focus {
  border: 1px solid #111539;
  box-shadow: 0px 15px 30px 0px rgba(17, 21, 57, 0.1);
}

.aht-course-det--edu .aht-course-det__cmnts .submit-btn {
  background-color: #111539;
}

.aht-course-det--edu .aht-course-det__cmnts .submit-btn:hover {
  background-color: #EBB113;
  color: #111539;
}

.aht-course--edu-details .aht-page--right-sb .aht-page__content p {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: #999999;
}

.aht-course--edu-details .aht-page--right-sb .aht-page__content blockquote {
  border-left: 2px solid #222222;
  font-family: "Source Sans Pro", sans-serif;
}

.aht-course--edu-details .aht-page--right-sb .aht-page__content blockquote p {
  color: #111539;
}

.aht-course--edu-details .aht-page--right-sb .aht-page__content .img_text_container h6 b {
  font-family: "Source Sans Pro", sans-serif;
}

.aht-course--edu-details .aht-page--right-sb .aht-page__content .post-meta .tags span, .aht-course--edu-details .aht-page--right-sb .aht-page__content .post-meta .tags a {
  font-family: "Roboto", sans-serif;
}

.aht-course--edu-details .aht-page--right-sb .aht-page__content .post-meta .tags a {
  color: #111539;
}

.aht-course--edu-details .aht-page--right-sb .aht-page__content .likes {
  font-family: "Roboto", sans-serif;
}

.aht-course--edu-details .aht-page--right-sb .aht-page__content .likes i {
  color: #2A74ED;
  margin-right: 12px;
}

.aht-course--edu-details .aht-page--right-sb .aht-page__content .post-author-info p b {
  color: #111539;
  font-family: "Source Sans Pro", sans-serif;
}

.aht-course--edu-details .aht-page--right-sb .aht-page__content .post-author-info p:last-child a {
  font-family: "Roboto", sans-serif;
}

.aht-course--edu-details .aht-page--right-sb .aht-page__content .aht-socials__icon {
  color: #111539;
}

.aht-course--edu-details .aht-page--right-sb .aht-page__content .aht-socials:hover i {
  color: #111539;
}

.aht-course--edu-details .aht-page--right-sb .aht-page__content-inner .comment-content .author-name {
  font-family: "Source Sans Pro", sans-serif;
  color: #111539;
}

.aht-course--edu-details .aht-page--right-sb .aht-page__content-inner .comment-date {
  font-family: "Roboto", sans-serif;
}

.aht-course--edu-details .aht-page--right-sb .aht-page__content-inner .comment-author .btn-reply {
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  letter-spacing: normal;
}

.aht-course--edu-details .aht-page--right-sb .aht-page__content-inner .comment-author .btn-reply:hover {
  color: #EBB113;
}

.aht-course--edu-details .aht-page--right-sb .aht-page__content-inner .comment-author .btn-reply:hover i {
  color: inherit;
}

.aht-course--edu-details .aht-page--right-sb .aht-page__content .aheto-form--default input:focus, .aht-course--edu-details .aht-page--right-sb .aht-page__content .aheto-form--default textarea:focus {
  box-shadow: 0px 15px 30px 0px rgba(17, 21, 57, 0.1);
  border: 1px solid #111539;
}

.aht-course--edu-details .aht-page--right-sb .aht-page__content .aheto-form--default .aheto-btn {
  color: #fff;
  background-color: #111539;
  box-shadow: none;
  text-transform: uppercase;
  border: 2px solid transparent;
  font-family: "Roboto", sans-serif;
  letter-spacing: 1.4px;
  font-weight: bold;
  margin-top: 20px;
}

.aht-course--edu-details .aht-page--right-sb .aht-page__content .aheto-form--default .aheto-btn:hover {
  color: #111539;
  background-color: #fff;
  border: 2px solid #111539;
}

.aht-course--edu-details .aht-page--right-sb .calendar table tr td.active {
  background-color: #111539;
}

.aht-course--edu-details .aht-page--right-sb .calendar_header .month {
  color: #111539;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: bold;
  font-size: 20px;
}

.aht-event--edu .aht-event__day {
  color: #EBB113;
  font-family: "Roboto Slab", serif;
}

.aht-event--edu .aht-event__month {
  color: #999999;
  font-family: "Roboto", sans-serif;
}

.aht-event--edu .aht-event__icon {
  color: #EBB113;
}

.aht-event--edu .aht-event__link {
  font-family: "Roboto Slab", serif;
  letter-spacing: normal;
}

.aht-event--edu .aht-event__text {
  font-family: "Roboto", sans-serif;
}

.aht-event--edu-2 .aht-event__day {
  color: #EBB113;
  font-family: "Source Sans Pro", sans-serif;
}

.aht-event--edu-2 .aht-event__month {
  color: #999999;
  font-family: "Roboto", sans-serif;
}

.aht-event--edu-2 .aht-event__icon {
  color: #EBB113;
}

.aht-event--edu-2 .aht-event__text {
  color: #111539;
  font-family: "Roboto", sans-serif;
}

.aht-event--edu-2 .aht-event__desc, .aht-event--edu-2 .aht-event__detail {
  font-family: "Roboto", sans-serif;
}

.aht-event--edu-2 .aht-event__link {
  color: #111539;
  border: 2px solid #111539;
  font-family: "Roboto", sans-serif;
}

.aht-event--edu-2 .aht-event__link:hover {
  background-color: #111539;
}

.aht-ev-det--edu b {
  color: #EBB113;
}

.aht-ev-det--edu .aht-ev-det__content p {
  font-family: "Roboto", sans-serif;
}

.aht-ev-det--edu .aht-ev-det__prog-item {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  color: #999999;
}

.aht-ev-det--edu .aht-ev-det__prog-item::before {
  content: '';
  display: inline-block;
  border-radius: 50%;
  margin-right: 25px;
  width: 7px;
  height: 7px;
  background-color: #111539;
}

.aht-ev-det--edu .aht-ev-det__sched-unit.active {
  border-bottom: 2px solid #999999;
}

.aht-ev-det--edu .aht-ev-det__sched-day {
  color: #999999;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
}

.aht-ev-det--edu .aht-ev-det__sched-date {
  font-weight: 600;
  font-family: "Roboto", sans-serif;
}

.aht-ev-det--edu .aht-ev-det__sched-time {
  font-family: "Roboto", sans-serif;
  color: #454545;
  font-weight: 400;
}

.aht-ev-det--edu .aht-ev-det__sched-text {
  font-family: "Roboto", sans-serif;
}

.aht-ev-det--edu .aht-ev-det__sb {
  box-shadow: 0px 0px 40px 0px rgba(17, 21, 57, 0.1);
}

.aht-ev-det--edu .aht-ev-det__detail-icon {
  color: #EBB113;
}

.aht-ev-det--edu .aht-ev-det__detail-text {
  color: #111539;
  font-family: "Roboto", sans-serif;
}

.aht-ev-det--edu .aht-ev-det__label {
  color: #111539;
  font-family: "Roboto", sans-serif;
}

.aht-ev-det--edu .aht-ev-det__select {
  color: #999999;
}

.aht-ev-det--edu .aht-ev-det__select:focus {
  border-color: #111539;
  box-shadow: 0px 15px 30px 0px rgba(17, 21, 57, 0.1);
}

.aht-ev-det--edu .aht-ev-det__number {
  color: #111539;
}

.aht-ev-det--edu .aht-ev-det__number-q {
  border: 2px solid #111539;
  color: #111539;
}

.aht-ev-det--edu .aht-ev-det__number-q:hover {
  background-color: #111539;
}

.aht-ev-det--edu .aht-ev-det__price {
  color: #EBB113;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
}

.aht-ev-det--edu .aht-ev-det__submit:hover {
  color: #111539;
}

.aht-ev-det--yoga .aht-ev-det__detail-icon {
  color: #EBB113;
}

.aht-ev-det--yoga .aht-ev-det__detail-text {
  color: #111539;
}

.aht-ev-det--yoga .aht-ev-det__date-number {
  color: #EBB113;
}

@media screen and (max-width: 1024px) {
  .aht-ev-det--yoga .aht-ev-det__content {
    margin-top: 50px;
  }
}

.aht-event--chr .aht-event__date {
  background-color: #EBB113;
  box-shadow: 0 5px 20px 0 rgba(252, 142, 68, 0.4);
}

.aht-event--chr .aht-event__icon {
  color: #EBB113;
}

.aht-event--chr-1 .aht-event__link {
  color: #EBB113;
}

.aht-event--chr-1 .aht-event__link:hover {
  border-color: #222222;
  background-color: #222222;
}

.aht-event--chr-2 .aht-event__link:hover {
  border-color: #222222;
  background-color: #222222;
}

.event-slider--church-wrap-2 .aheto-btn-container .aheto-btn--inline {
  background-color: transparent;
  color: #EBB113;
  border: none;
  padding: 0;
}

.event-slider--church-wrap-2 .aheto-btn-container .aheto-btn--inline:hover {
  color: #222222;
}

.event-slider--church-wrap-2 .aht-event--chr-2 .aht-event__link {
  color: #EBB113;
  border-color: #eeeeee;
}

.event-slider--church-wrap-2 .aht-event--chr-2 .aht-event__link:hover {
  color: #fff;
}

.church-events-wrap .aht-event--chr .aht-event__date {
  box-shadow: 0 0 32px 0 rgba(252, 142, 68, 0.3);
}

.church-events-wrap .aht-event--chr-2 .aht-event__link {
  border-color: #eeeeee;
}

.church-events-wrap .church-events-btn .aheto-btn-container .aheto-btn {
  border-color: #eeeeee;
}

.aht-ev-det--political .aht-ev-det__title span {
  color: #2A74ED;
}

.aht-ev-det--political .aht-ev-det__detail-icon {
  color: #EBB113;
}

.aht-ev-det--political .aht-ev-det__detail-text {
  font-family: "Source Sans Pro", sans-serif;
  color: #999999;
}

.aht-ev-det--political .aht-ev-det__date-number {
  font-family: "Source Sans Pro", sans-serif;
  color: #fff;
  background: #EBB113;
}

.aht-ev-det--political .aht-ev-det__date-text {
  font-family: "Source Sans Pro", sans-serif;
  background: #2A74ED;
  color: #fff;
}

.aht-grid-1--edu .aht-grid-1__filter {
  font-family: "Roboto", sans-serif;
}

.aht-grid-1--edu .aht-grid-1__filter:hover {
  color: #111539;
}

.aht-grid-1--edu .aht-grid-1__filter.active {
  color: #111539;
}

@media screen and (min-width: 1025px) {
  .aht-grid-1--edu .aht-grid-1__item-inner:hover {
    box-shadow: 0px 0px 43px 0px rgba(17, 21, 57, 0.09);
  }
}

@media screen and (max-width: 1024px) {
  .aht-grid-1--edu .aht-grid-1__item-inner {
    box-shadow: 0px 0px 43px 0px rgba(17, 21, 57, 0.09);
  }
}

.aht-grid-1--edu .aht-grid-1__item-cat {
  color: #999999;
  font-family: "Roboto", sans-serif;
  letter-spacing: normal;
}

.aht-grid-1--edu .aht-grid-1__item-title {
  font-family: "Source Sans Pro", sans-serif;
  line-height: 26px;
}

.aht-sermon__icon-link:hover {
  color: #EBB113;
}

.aht-sermon .aht-sermon__link:hover {
  color: #EBB113;
}

.sermons--church-wrap .aheto-btn {
  color: #EBB113;
  border-color: #eeeeee;
}

.sermons--church-wrap .aheto-btn:hover {
  color: white;
  background-color: #222222;
  border-color: #222222;
}

.aht-serm-det--chr .aht-serm-det__detail-val {
  color: #EBB113;
}

.aht-serm-det--chr .aht-serm-det__detail-text {
  color: #999999;
}

.aht-serm-det--chr .aht-serm-det__date-number {
  color: #EBB113;
}

.aht-sermon-det__icon-link:hover {
  color: #EBB113;
}

.aht-sermon-det .aht-sermon-det__link:hover {
  color: #EBB113;
}

.aht-blog-yoga__img:after {
  background-color: rgba(17, 21, 57, 0.8);
}

.aht-blog-yoga__cat {
  background-color: #FF704F;
  height: 22px;
  line-height: 24px;
  padding: 0 18px;
}

.aht-blog-yoga__cat--c-1 {
  background-color: #EBB113;
}

.aht-blog-yoga__cat--c-2 {
  background-color: #2A74ED;
}

.aht-blog-yoga__cat--c-4 {
  background-color: #FFC015;
}

.aht-blog-yoga__cat--c-5 {
  background-color: #111539;
}

.aht-blog-yoga__cat--c-6 {
  background-color: #999999;
}

.aht-blog-yoga__link {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
}

@media screen and (min-width: 1025px) {
  .aht-blog-yoga__link:hover {
    border-color: #EBB113;
    background: none;
    color: #EBB113;
  }
}

.aht-blog-yoga__det-icon {
  color: #EBB113;
}

.aht-blog-yoga__det-text {
  color: #111539;
}

.aht-post-yoga__detail-icon {
  color: #EBB113;
  transform: scaleX(-1);
}

.aht-post-yoga__author-link {
  font-size: 16px;
}

.aht-post-yoga__detail-text {
  color: #111539;
}

.aht-post-yoga__cats-name {
  color: #111539;
}

.aht-post-yoga__cats-list {
  color: #EBB113;
}

.aht-post-yoga__likes {
  color: #111539;
}

.aht-post-yoga__likes:active {
  border-color: #EBB113;
}

.aht-post-yoga__likes:hover {
  box-shadow: 0 0 20px 0 rgba(235, 177, 19, 0.13);
}

.aht-sidebar-yoga .widget_categories a {
  color: #999999;
}

.aht-sidebar-yoga .widget_categories a:hover {
  border-color: #EBB113;
  background-color: #EBB113;
}

.aht-sidebar-yoga .search-field {
  color: #111539;
}

.aht-sidebar-yoga .search-field::placeholder {
  color: #999999;
}

.aht-sidebar-yoga .search-field:focus {
  border: 1px solid #EBB113;
  box-shadow: 0px 15px 30px 0px rgba(235, 177, 19, 0.1);
}

.aht-sidebar-yoga .aht-widget-advert__link:hover {
  border-color: #EBB113;
  background-color: #EBB113;
}

.aht-sidebar-yoga .aht-widget-posts__button {
  color: #111539;
}

.aht-sidebar-yoga .aht-widget-posts__button.active {
  border-bottom: 2px solid #EBB113;
  color: #EBB113;
}

.aht-sidebar-yoga .aht-widget-posts__title {
  color: #111539;
}

.aht-blog-edu__item--quote {
  background-color: #222;
}

.aht-blog-edu__item--quote::before {
  content: '”';
  position: absolute;
  font-family: 'Times New Roman';
  font-size: 180px;
  font-weight: bold;
  top: 67px;
  right: 50px;
  color: #3f3e3e;
  line-height: 120px;
}

.aht-blog-edu__bq {
  font-family: "Source Sans Pro", sans-serif;
  font-style: italic;
  padding-right: 20px;
}

.aht-blog-edu__author {
  font-family: "Roboto", sans-serif;
  letter-spacing: 1.4px;
}

.aht-blog-edu__cat {
  background-color: #111539;
  font-family: "Roboto", sans-serif;
}

.aht-blog-edu__cat.tips {
  background-color: #EBB113;
  color: #fff;
}

.aht-blog-edu__date {
  font-family: "Roboto", sans-serif;
}

.aht-blog-edu__desc {
  font-family: "Roboto", sans-serif;
}

.aht-blog-edu__link {
  font-family: "Roboto", sans-serif;
}

.aht-blog-edu__link:not(:hover) {
  color: #EBB113;
}

.aht-blog-edu__video-link {
  color: #111539;
}

.aht-blog-edu__video-link:hover {
  color: #EBB113;
}

.aht-pag-edu .wrap .page-numbers {
  font-family: "Roboto", sans-serif;
}

.aht-pag-edu .wrap .page-numbers.current, .aht-pag-edu .wrap .page-numbers:hover {
  background-color: #111539;
  color: #fff;
}

.aht-pag-edu .wrap .page-numbers.no-hover {
  background-color: #fff !important;
  color: #222 !important;
}

.aht-pag-edu .prev,
.aht-pag-edu .next {
  font-family: "Roboto", sans-serif;
  color: #222 !important;
}

.aht-post-edu__cat {
  background-color: #111539;
  font-family: "Roboto", sans-serif;
}

.aht-post-edu__cat:hover {
  background-color: #EBB113;
  color: #fff;
}

.aht-post-edu__title b {
  color: #EBB113;
}

.aht-post-edu__date, .aht-post-edu__read {
  color: #999999;
  font-family: "Roboto", sans-serif;
}

.aht-post-edu__tags-name {
  color: #222;
  font-family: "Roboto", sans-serif;
}

.aht-post-edu__tags-list {
  color: #111539;
}

.aht-post-edu__tags-link {
  font-family: "Roboto", sans-serif;
  letter-spacing: normal;
  font-size: 16px;
}

.aht-post-edu__tags-link:hover {
  color: #EBB113;
}

.aht-post-edu__likes {
  color: #222;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
}

.aht-post-edu__likes:hover {
  box-shadow: 0 0 20px 0 rgba(17, 21, 57, 0.13);
}

.aht-post-edu__author-desc, .aht-post-edu__author-more, .aht-post-edu__author-link {
  font-family: "Roboto", sans-serif;
  letter-spacing: normal;
}

.aht-post-edu__author-link {
  text-decoration: underline !important;
}

.aht-post-edu__content blockquote p {
  font-family: "Source Sans Pro", sans-serif;
}

.aht-post-edu__content p {
  font-family: "Roboto", sans-serif;
}

.aht-post-edu__socials .aheto-socials__link:hover {
  background-color: #EBB113;
}

.aht-post-edu__socials .aheto-socials__link:hover .icon {
  color: #111539 !important;
}

.aht-post-edu__socials .aheto-socials__icon.icon {
  color: #111539;
}

.aht-post-edu__socials .aheto-socials__icon.icon:hover {
  color: #111539 !important;
}

.aht-comm-edu h2 b {
  color: #EBB113;
}

.aht-comm-edu .author {
  color: #111539;
  font-family: "Source Sans Pro", sans-serif;
}

.aht-comm-edu .date-post {
  font-family: "Roboto", sans-serif;
}

.aht-comm-edu p {
  font-family: "Roboto", sans-serif;
}

.aht-comm-edu .comment-reply-link {
  color: #999999;
  font-family: "Roboto", sans-serif;
}

.aht-comm-edu .comment-reply-link:hover {
  color: #EBB113;
}

.aht-comm-edu .comment-reply-title b {
  color: #EBB113;
}

.aht-comm-edu .comment-form textarea {
  color: #111539;
  font-family: "Roboto", sans-serif;
}

.aht-comm-edu .comment-form textarea::placeholder {
  color: #999999;
  font-family: "Roboto", sans-serif;
}

.aht-comm-edu .comment-form textarea:focus {
  border: 1px solid #111539;
  box-shadow: 0px 15px 30px 0px rgba(17, 21, 57, 0.1);
}

.aht-comm-edu .column input {
  color: #111539;
  font-family: "Roboto", sans-serif;
}

.aht-comm-edu .column input::placeholder {
  color: #999999;
  font-family: "Roboto", sans-serif;
}

.aht-comm-edu .column input:focus {
  border: 1px solid #111539;
  box-shadow: 0px 15px 30px 0px rgba(17, 21, 57, 0.1);
}

.aht-comm-edu .submit-btn {
  background-color: #111539;
  font-family: "Roboto", sans-serif;
  letter-spacing: 1.4px;
}

.aht-comm-edu .submit-btn:hover {
  background-color: #EBB113;
  color: #111539;
}

.blog--single__edu .aht-widget-posts__title {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: normal;
  letter-spacing: normal;
}

.blog--single__edu .aht-widget-posts__title:hover {
  color: #111539;
}

.blog--single__edu .aht-widget-posts__date {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: bold;
}

.blog--single__edu .aht-widget-posts__button {
  font-family: "Roboto", sans-serif;
}

.blog--single__edu .aht-widget-posts__button.active {
  color: #000;
  border-bottom-color: #111539;
}

.blog--single__edu .aht-page--dual-sb .aht-page__content {
  padding: 0 0 0;
}

.blog--single__edu .aht-widget-advert {
  min-height: 300px;
  height: auto;
  padding: 28px 30px 36px;
}

.blog--single__edu .aht-widget-advert__subtitle {
  font-family: "Roboto", sans-serif;
  font-weight: normal;
}

.blog--single__edu .aht-widget-advert__desc {
  font-family: "Roboto", sans-serif;
}

.blog--single__edu .aht-widget-advert__title {
  margin-bottom: 34px;
}

.blog--single__edu .aht-widget-advert__link {
  font-family: "Roboto", sans-serif;
}

.blog--single__edu .aht-page__sb .widget_mc4wp_form_widget {
  margin-top: 60px;
  background: #111539 url(../img/inner-pages/blog/icon-envelope.png) no-repeat bottom right;
  padding: 42px 28px 95px;
}

.blog--single__edu .aht-page__sb .widget_mc4wp_form_widget input[type="submit"] {
  font-family: "Roboto", sans-serif;
  font-weight: bold;
}

.blog--single__edu .aht-page__sb .widget_mc4wp_form_widget input[type="email"] {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
}

.blog--single__edu .aht-page__sb .widget_mc4wp_form_widget p {
  font-family: "Roboto", sans-serif;
  letter-spacing: normal;
}

.blog--single__edu .aht-page__sb .widget_mc4wp_form_widget .widget-title {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 700;
}

.blog--single__edu .aht-twitter__link {
  background-color: #111539;
}

.blog--single__edu .aht-twitter__follow {
  font-family: "Roboto", sans-serif;
  letter-spacing: 1.4px;
}

.blog--single__edu .aht-twitter__slider .btn-left:hover, .blog--single__edu .aht-twitter__slider .btn-right:hover {
  color: #111539;
}

.blog--single__edu .aht-twitter__content .tweets {
  font-family: "Roboto", sans-serif;
}

.blog--single__edu .aht-twitter__content .tweets .date {
  font-family: "Roboto", sans-serif;
  color: #bbb;
}

.blog--single__edu .widget_tag_cloud .widget-title {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: normal;
  color: #333333;
}

.blog--single__edu .tagcloud a {
  display: inline-block;
  padding: 3px 18px;
  border: 1px solid #eee;
  background: #fff;
  border-radius: 3px;
  font-size: 13px !important;
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  color: #999999;
  margin-right: 6px;
  margin-bottom: 4px;
}

.blog--single__edu .tagcloud a:hover {
  background-color: #111539;
  color: #fff;
}

.blog--single__edu .widget_categories .widget-title {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: normal;
  color: #333333;
}

.blog--single__edu .widget_categories .cat-item {
  font-family: "Roboto", sans-serif;
}

.blog--single__edu .widget_categories .cat-item a {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: normal;
  color: #999999;
}

.blog--single__edu .widget_categories .cat-item:hover {
  color: #111539;
  cursor: pointer;
}

.blog--single__edu .widget_categories .cat-item:hover a {
  color: #111539;
}

.blog--single__edu .calendar table tr td.active {
  background-color: #111539;
}

.blog--single__edu .calendar_header .month {
  color: #111539;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: bold;
  font-size: 20px;
}

@media screen and (max-width: 1230px) {
  .blog--single__edu .calendar {
    max-width: 100%;
  }
}

.blog--single__edu .search {
  padding: 0;
}

.aht-blog-rest__item--quote {
  background-color: #222;
}

.aht-blog-rest__item--quote::before {
  content: '”';
  position: absolute;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 190px;
  font-weight: bold;
  top: 64px;
  right: 40px;
  color: #3f3e3e;
  line-height: 120px;
}

.aht-blog-rest__bq {
  font-family: "Source Sans Pro", sans-serif;
  font-style: italic;
  padding-right: 20px;
  -webkit-font-smoothing: antialiased;
}

.aht-blog-rest__author {
  font-family: "Source Sans Pro", sans-serif;
  color: #EBB113;
}

.aht-blog-rest__cat {
  background-color: #EBB113;
  font-family: "Source Sans Pro", sans-serif;
  color: #fff;
  -webkit-font-smoothing: antialiased;
}

.aht-blog-rest__cat:hover {
  background-color: #EBB113;
  color: #fff;
}

.aht-blog-rest__date {
  font-family: "Source Sans Pro", sans-serif;
}

.aht-blog-rest__desc {
  font-family: "Source Sans Pro", sans-serif;
}

.aht-blog-rest__link {
  font-family: "Source Sans Pro", sans-serif;
}

.aht-blog-rest__link:not(:hover) {
  color: #EBB113;
}

.aht-blog-rest__video-link {
  color: #111539;
}

.aht-blog-rest__video-link:hover {
  color: #EBB113;
}

.aht-pag-rest .wrap .page-numbers {
  font-family: "Source Sans Pro", sans-serif;
}

.aht-pag-rest .wrap .page-numbers.current, .aht-pag-rest .wrap .page-numbers:hover {
  background-color: #EBB113;
  color: #fff;
}

.aht-pag-rest .wrap .page-numbers.no-hover {
  background-color: #fff !important;
  color: #222 !important;
}

.aht-post-rest__cat {
  background-color: #111539;
  font-family: "Source Sans Pro", sans-serif;
}

.aht-post-rest__cat:hover {
  background-color: #EBB113;
  color: #fff;
}

.aht-post-rest__title b {
  color: #EBB113;
}

.aht-post-rest__date, .aht-post-rest__read {
  color: #999999;
  font-family: "Source Sans Pro", sans-serif;
}

.aht-post-rest__tags-name {
  color: #222;
  font-family: "Source Sans Pro", sans-serif;
}

.aht-post-rest__tags-list {
  color: #111539;
}

.aht-post-rest__tags-link {
  font-family: "Source Sans Pro", sans-serif;
  letter-spacing: normal;
  font-size: 16px;
}

.aht-post-rest__tags-link:hover {
  color: #EBB113;
}

.aht-post-rest__likes {
  color: #222;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
}

.aht-post-rest__likes:hover {
  box-shadow: 0 0 20px 0 rgba(17, 21, 57, 0.13);
}

.aht-post-rest__author-desc, .aht-post-rest__author-more, .aht-post-rest__author-link {
  font-family: "Source Sans Pro", sans-serif;
  letter-spacing: normal;
}

.aht-post-rest__author-link {
  text-decoration: underline !important;
}

.aht-post-rest__content blockquote p {
  font-family: "Source Sans Pro", sans-serif;
}

.aht-post-rest__content p {
  font-family: "Source Sans Pro", sans-serif;
}

.aht-post-rest__socials .aheto-socials__link:hover {
  background-color: #EBB113;
}

.aht-post-rest__socials .aheto-socials__link:hover .icon {
  color: #111539 !important;
}

.aht-post-rest__socials .aheto-socials__icon.icon {
  color: #111539;
}

.aht-post-rest__socials .aheto-socials__icon.icon:hover {
  color: #111539 !important;
}

.aht-comm-rest h2 b {
  color: #EBB113;
}

.aht-comm-rest .author {
  color: #111539;
  font-family: "Source Sans Pro", sans-serif;
}

.aht-comm-rest .date-post {
  font-family: "Source Sans Pro", sans-serif;
}

.aht-comm-rest p {
  font-family: "Source Sans Pro", sans-serif;
}

.aht-comm-rest .comment-reply-link {
  color: #999999;
  font-family: "Source Sans Pro", sans-serif;
}

.aht-comm-rest .comment-reply-link:hover {
  color: #EBB113;
}

.aht-comm-rest .comment-reply-title b {
  color: #EBB113;
}

.aht-comm-rest .comment-form textarea {
  color: #111539;
  font-family: "Source Sans Pro", sans-serif;
}

.aht-comm-rest .comment-form textarea::placeholder {
  color: #999999;
  font-family: "Source Sans Pro", sans-serif;
}

.aht-comm-rest .comment-form textarea:focus {
  border: 1px solid #111539;
  box-shadow: 0px 15px 30px 0px rgba(17, 21, 57, 0.1);
}

.aht-comm-rest .column input {
  color: #111539;
  font-family: "Source Sans Pro", sans-serif;
}

.aht-comm-rest .column input::placeholder {
  color: #999999;
  font-family: "Source Sans Pro", sans-serif;
}

.aht-comm-rest .column input:focus {
  border: 1px solid #111539;
  box-shadow: 0px 15px 30px 0px rgba(17, 21, 57, 0.1);
}

.aht-comm-rest .submit-btn {
  background-color: #111539;
  font-family: "Source Sans Pro", sans-serif;
  letter-spacing: 1.4px;
}

.aht-comm-rest .submit-btn:hover {
  background-color: #EBB113;
  color: #111539;
}

.blog--single__edu .aht-widget-posts__title {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: normal;
  letter-spacing: normal;
}

.blog--single__edu .aht-widget-posts__title:hover {
  color: #111539;
}

.blog--single__edu .aht-widget-posts__date {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 12px;
  font-weight: bold;
}

.blog--single__edu .aht-widget-posts__button {
  font-family: "Source Sans Pro", sans-serif;
}

.blog--single__edu .aht-widget-posts__button.active {
  color: #000;
  border-bottom-color: #111539;
}

.blog--single__edu .aht-page--dual-sb .aht-page__content {
  padding: 0 0 0;
}

.blog--single__edu .aht-widget-advert {
  min-height: 300px;
  height: auto;
  padding: 28px 30px 36px;
}

.blog--single__edu .aht-widget-advert__subtitle {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: normal;
}

.blog--single__edu .aht-widget-advert__desc {
  font-family: "Source Sans Pro", sans-serif;
}

.blog--single__edu .aht-widget-advert__title {
  margin-bottom: 34px;
}

.blog--single__edu .aht-widget-advert__link {
  font-family: "Source Sans Pro", sans-serif;
}

.blog--single__edu .aht-page__sb .widget_mc4wp_form_widget {
  margin-top: 60px;
  background: #111539 url(../img/inner-pages/blog/icon-envelope.png) no-repeat bottom right;
  padding: 42px 28px 95px;
}

.blog--single__edu .aht-page__sb .widget_mc4wp_form_widget input[type="submit"] {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: bold;
}

.blog--single__edu .aht-page__sb .widget_mc4wp_form_widget input[type="email"] {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 15px;
}

.blog--single__edu .aht-page__sb .widget_mc4wp_form_widget p {
  font-family: "Source Sans Pro", sans-serif;
  letter-spacing: normal;
}

.blog--single__edu .aht-page__sb .widget_mc4wp_form_widget .widget-title {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 700;
}

.blog--single__edu .aht-twitter__link {
  background-color: #111539;
}

.blog--single__edu .aht-twitter__follow {
  font-family: "Source Sans Pro", sans-serif;
  letter-spacing: 1.4px;
}

.blog--single__edu .aht-twitter__slider .btn-left:hover, .blog--single__edu .aht-twitter__slider .btn-right:hover {
  color: #111539;
}

.blog--single__edu .aht-twitter__content .tweets {
  font-family: "Source Sans Pro", sans-serif;
}

.blog--single__edu .aht-twitter__content .tweets .date {
  font-family: "Source Sans Pro", sans-serif;
  color: #bbb;
}

.blog--single__edu .widget_tag_cloud .widget-title {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: normal;
  color: #333333;
}

.blog--single__edu .tagcloud a {
  display: inline-block;
  padding: 3px 18px;
  border: 1px solid #eee;
  background: #fff;
  border-radius: 3px;
  font-size: 13px !important;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: normal;
  color: #999999;
  margin-right: 6px;
  margin-bottom: 4px;
}

.blog--single__edu .tagcloud a:hover {
  background-color: #111539;
  color: #fff;
}

.blog--single__edu .widget_categories .widget-title {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: normal;
  color: #333333;
}

.blog--single__edu .widget_categories .cat-item {
  font-family: "Source Sans Pro", sans-serif;
}

.blog--single__edu .widget_categories .cat-item a {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: normal;
  color: #999999;
}

.blog--single__edu .widget_categories .cat-item:hover {
  color: #111539;
  cursor: pointer;
}

.blog--single__edu .widget_categories .cat-item:hover a {
  color: #111539;
}

.blog--single__edu .calendar table tr td.active {
  background-color: #111539;
}

.blog--single__edu .calendar_header .month {
  color: #111539;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: bold;
  font-size: 20px;
}

@media screen and (max-width: 1230px) {
  .blog--single__edu .calendar {
    max-width: 100%;
  }
}

.blog--single__edu .search {
  padding: 0;
}

.aht-blog-trvl__title {
  color: #111539;
  font-family: "Mukta", sans-serif;
  font-size: 24px;
  font-weight: bold;
  line-height: 1.33;
}

.aht-blog-trvl__text {
  font-family: "Open Sans", sans-serif;
}

.aht-blog-trvl__icon {
  color: #2A74ED;
}

.aht-blog-trvl__text {
  color: #999999;
}

@media screen and (max-width: 1024px) {
  .aht-post-trvl__info {
    margin-top: 0;
  }
  .aht-post-trvl__author {
    margin-top: 50px;
  }
}

.aht-post-trvl__socials {
  display: flex;
  justify-content: center;
}

.aht-post-trvl__socials .aheto-socials__link {
  height: 50px;
  width: 50px;
  border: 1px solid #eee;
  border-radius: 50%;
  margin-right: 10px;
  margin-left: 0;
  display: inline-block;
  float: left;
  position: relative;
  padding-top: 11px;
}

.aht-post-trvl__socials .aheto-socials__link:hover {
  background-color: #EBB113;
  border-color: #EBB113;
}

.aht-post-trvl__socials .aheto-socials__link:hover .icon {
  color: #fff;
}

.aht-post-trvl__cat {
  background-color: #EBB113;
  height: 22px;
  padding: 0 13px;
  line-height: 21px;
  font-family: "Open Sans", sans-serif;
}

.aht-post-trvl__title {
  font-weight: 800;
  line-height: 1.3;
}

.aht-post-trvl__detail {
  font-family: "Open Sans", sans-serif;
  line-height: 1.73;
}

.aht-post-trvl__tb-author-name {
  line-height: 1.63;
}

.aht-post-trvl__page {
  width: 100%;
  max-width: initial;
  margin: initial;
  padding: 0;
}

.aht-post-trvl__content > *:last-child {
  margin-bottom: 0;
}

.aht-post-trvl__content > p {
  line-height: 1.6;
  margin: 0 auto 50px auto;
  width: calc(100% - 200px);
}

@media screen and (max-width: 1024px) {
  .aht-post-trvl__content > p {
    width: 100%;
  }
}

.aht-post-trvl__content img {
  margin-bottom: 50px;
}

.aht-post-trvl__content h3 {
  line-height: 1.33;
  margin: 0 auto 50px auto;
  width: calc(100% - 200px);
}

@media screen and (max-width: 1024px) {
  .aht-post-trvl__content h3 {
    width: 100%;
  }
}

.aht-post-trvl__content blockquote {
  padding: 100px 0 0 0;
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
}

.aht-post-trvl__content blockquote p {
  font-family: "Open Sans", sans-serif;
  font-size: 30px;
  line-height: 1.33;
  text-align: center;
  color: #262527;
}

.aht-post-trvl__content figure img {
  margin-bottom: 50px;
}

.aht-post-trvl__content figure h6 {
  font-size: 18px;
  line-height: 1.44;
  margin-bottom: 18px;
}

.aht-post-trvl__content figure p {
  line-height: 1.6;
  margin: 0 0 50px 0;
  font-weight: 400;
}

.aht-post-trvl__tags-name {
  color: #111539;
}

.aht-post-trvl__tags-list {
  color: #EBB113;
}

.aht-post-trvl__tags-link:hover {
  color: #2A74ED;
}

.aht-post-trvl__likes {
  color: #111539;
}

.aht-post-trvl__likes:hover {
  box-shadow: 0 0 20px 0 rgba(17, 21, 57, 0.13);
}

.aht-post-trvl__author-desc {
  line-height: 1.6;
}

.aht-post-trvl__author-link:hover {
  color: #2A74ED;
}

.aht-blog-bsns__item--quote {
  background-color: #111539;
}

.aht-blog-bsns__cat {
  font-size: 16px;
}

.aht-blog-bsns__cat:hover {
  color: #999999;
}

.aht-blog-bsns__link:not(:hover) {
  color: #2A74ED;
}

.aht-blog-bsns__link:hover {
  color: #EBB113;
}

.aht-blog-bsns__video-link {
  color: #2A74ED;
}

.aht-blog-bsns__video-link:hover {
  color: #EBB113;
}

.aht-blog-bsns .mejs__controls {
  background: #111539;
}

.aht-pag-edu .wrap .page-numbers {
  color: #111539;
}

.aht-pag-edu .wrap .page-numbers.current, .aht-pag-edu .wrap .page-numbers:hover {
  background-color: #111539;
}

.aht-pag-edu .prev,
.aht-pag-edu .next {
  color: #111539;
}

.aht-blog-trvl__icon {
  padding-top: 7px;
}

.aht-blog-chr__date {
  background-color: #EBB113;
}

.aht-blog-chr .aht-blog-chr__link {
  color: #EBB113;
}

.aht-blog-chr .aht-blog-chr__link:hover {
  color: #111539;
}

.blog--church-wrap .blog--church-title {
  background-color: #222222;
}

.blog--church-wrap .blog--church-title .aht-heading__subtitle {
  color: #999999;
}

.blog--church-wrap .blog--church-title .aht-heading__subtitle:before {
  background-color: #999999;
}

.blog--church-wrap .blog--church-title .aht-heading__title {
  color: white;
}

.blog--single__chr .aht-widget-quotes__desc {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: normal;
}

.blog--single__chr .aht-widget-quotes__author {
  color: #EBB113;
  font-weight: bold;
  letter-spacing: 1.4px;
}

.blog--single__chr .aht-widget-posts__main-title {
  color: #333333;
}

.blog--single__chr .aht-widget-posts__title {
  letter-spacing: -0.4px;
}

.blog--single__chr .aht-widget-posts__title:hover {
  color: #EBB113;
}

.blog--single__chr .aht-widget-posts__date {
  font-size: 12px;
  color: #EBB113;
  font-weight: bold;
  letter-spacing: 1.2px;
  text-transform: uppercase;
}

.blog--single__chr .widget_categories .widget-title {
  color: #333333;
}

.blog--single__chr .widget_categories ul li a {
  font-weight: normal;
}

.blog--single__chr .widget_categories ul li a:hover {
  color: #EBB113;
}

.blog--single__chr .widget_categories ul li.active {
  color: #EBB113;
}

.blog--single__chr .widget_categories ul li.active a {
  color: #EBB113;
}

.blog--single__chr .aht-page--right-sb .aht-page__sb {
  background: #f7f7f7;
}

.blog--single__chr .aht-page--right-sb .aht-page__sb:before {
  background: #f7f7f7;
}

.blog--single__chr .aht-page--right-sb .aht-page__content blockquote {
  border-left: 2px solid #EBB113;
}

.blog--single__chr .aht-page--right-sb .aht-page__content blockquote h3 {
  color: #111539;
}

.blog--single__chr .post-meta .likes:hover {
  border-color: #EBB113;
  color: #EBB113;
}

.church-news-blog-wrap .aheto-btn:hover,
.church-events-wrap .aheto-btn:hover {
  color: white;
  background-color: #222222;
  border-color: #222222;
}

.aht-blog-evts__date {
  color: #EBB113;
}

.aht-blog-evts__title h3:hover {
  color: #999999;
  transition: color 0.5s;
}

.aht-blog-evts__icon {
  color: #EBB113;
}

.aht-blog-evts__text {
  color: #999999;
}

.aht-blog-evts__details {
  border-top: 1px solid #EBEBEB;
}

.aht-post-detail__title span {
  color: #2A74ED;
}

.aht-post-detail__detail-text {
  font-family: "Source Sans Pro", sans-serif;
}

.aht-post-detail__detail-icon {
  color: #EBB113;
}

.aht-post-detail__cats-name {
  font-family: "Source Sans Pro", sans-serif;
  color: #222222;
}

.aht-post-detail__cats-list {
  font-family: "Source Sans Pro", sans-serif;
}

.aht-post-detail__likes {
  font-family: "Source Sans Pro", sans-serif;
}

.aht-post-detail__author-title {
  font-family: "Source Sans Pro", sans-serif;
  color: #222222;
}

.aht-post-detail .aheto-socials__link:hover {
  background: #EBB113;
}

.aht-post-detail .aheto-socials__link:hover i {
  color: #fff;
}

.c-1 {
  background: #EBB113;
}

.church-post-wrap-1__icon-link:hover {
  color: #EBB113;
}

.church-post-wrap-1 .church-post-wrap-1__link:hover {
  color: #EBB113;
}

.main-header__toolbar-wrap h1,
.main-header__toolbar-wrap h2,
.main-header__toolbar-wrap h3,
.main-header__toolbar-wrap h4,
.main-header__toolbar-wrap h5,
.main-header__toolbar-wrap h6 {
  color: #111539;
}

.main-header__toolbar-wrap .widget a,
.main-header__toolbar-wrap .registartion a,
.main-header__toolbar-wrap .log-in a {
  color: #111539;
}

.main-header__toolbar-wrap .widget a:hover,
.main-header__toolbar-wrap .registartion a:hover,
.main-header__toolbar-wrap .log-in a:hover {
  color: #EBB113;
}

.main-header__toolbar-wrap--solid {
  background-color: #EBEBEB;
}

.main-header__toolbar-wrap--light {
  background-color: #111539;
}

.aheto-header-1__toolbar {
  border-bottom: 1px solid #FFC015;
}

.aheto-header-1__detail-icon {
  color: #2A74ED;
}

.aheto-header-1__detail-text, .aheto-header-1__detail-link {
  color: #111539;
}

.aheto-header-1__nav-wrap {
  background: linear-gradient(#2A74ED, #2A74ED) no-repeat 950px 0;
}

@media screen and (max-width: 1230px) {
  .aheto-header-1__nav-wrap {
    background-color: #2A74ED;
    box-shadow: 0px 0px 30px 0px rgba(42, 116, 237, 0.3);
  }
}

.aheto-header-1__nav {
  background-color: #2A74ED;
  box-shadow: 0px 0px 30px 0px rgba(42, 116, 237, 0.3);
}

.aheto-header-1 .sub-menu .dropdown-btn {
  color: #111539;
}

.aheto-header-1 .sub-menu .menu-item a {
  color: #111539;
}

.aheto-header-1 .sub-menu .menu-item:hover > .dropdown-btn,
.aheto-header-1 .sub-menu .menu-item:hover > a {
  color: #EBB113;
}

.aheto-header-1 .mega-menu__title {
  border-bottom: 1px solid #FFC015;
  color: #2A74ED;
}

@media screen and (min-width: 1025px) {
  .aheto-header-1 .mega-menu__col {
    border-right: 1px solid #FFC015;
  }
}

.aheto-header-1 .aheto-socials__icon {
  color: #bfbfbf;
}

.aheto-header-1 .aheto-socials__icon:hover {
  color: #999999;
}

@media screen and (max-width: 1024px) {
  .aheto-header-2__line {
    background-color: #111539;
  }
}

@media screen and (max-width: 1024px) {
  .aheto-header-2__menu {
    border-bottom: 1px solid rgba(17, 21, 57, 0.1);
  }
}

@media screen and (min-width: 1025px) {
  .aheto-header-2 .main-menu > .menu-item > a:hover {
    color: #EBB113;
  }
  .aheto-header-2 .main-menu > .menu-item > a.active {
    color: #EBB113;
  }
}

.aheto-header-2 .aht-socials__link {
  color: #fff !important;
}

.aheto-header-2 .aht-socials__link:hover {
  color: #EBB113 !important;
}

.aheto-header-2 .sub-menu .menu-item a {
  color: #111539;
}

.aheto-header-2 .sub-menu .menu-item a.active {
  color: #EBB113;
}

.aheto-header-2 .sub-menu .menu-item:hover > a,
.aheto-header-2 .sub-menu .menu-item:hover .dropdown-btn {
  color: #EBB113;
}

@media screen and (min-width: 1025px) {
  .aheto-header-2 .sub-menu .dropdown-btn {
    color: #111539;
  }
}

@media screen and (min-width: 1025px) {
  .aheto-header-2 .mega-menu__title {
    border-bottom: 1px solid rgba(17, 21, 57, 0.1);
  }
  .aheto-header-2 .mega-menu__col {
    padding: 30px 20px;
    border-right: 1px solid rgba(17, 21, 57, 0.1);
  }
}

.aheto-header-2 .logo__img-holder {
  background-color: #111539;
}

.aheto-header-2 .logo__text {
  color: #111539;
}

@media screen and (max-width: 1024px) {
  .aheto-header-3__menu {
    background: linear-gradient(to left, #6c207c 0%, #E32682 100%);
  }
}

.aheto-header-3__search {
  color: #222222;
}

@media screen and (max-width: 1024px) {
  .aheto-header-3__inner {
    background: linear-gradient(to left, #6c207c 0%, #E32682 100%);
  }
}

.aheto-header-3 .main-menu .menu-item a {
  color: #fff;
}

.aheto-header-3__search-icon:hover {
  color: #EBB113;
  transition: 0.3s all;
}

.aheto-header-3 .sub-menu .menu-item a:hover {
  color: #EBB113;
}

.aheto-header-3 .sub-menu .menu-item:hover > a {
  color: #EBB113;
}

.aheto-header-3 .sub-menu .menu-item:hover > a + span {
  color: #EBB113;
}

.aheto-header-3 .main-menu > .menu-item a + span {
  transition: all 0.05s;
}

.aheto-header-3 .main-menu > .menu-item a:hover {
  color: #EBB113;
}

.aheto-header-3 .main-menu > .menu-item a:hover + span {
  color: #EBB113;
  transition: all 0.02s;
}

.aheto-header-3 .main-menu > .menu-item:hover > a {
  color: #EBB113;
}

.aheto-header-3 .main-menu > .menu-item:hover > a + span {
  color: #EBB113;
  transition: all 0.02s;
}

.aheto-header-3 .main-menu > .menu-item:after {
  background-color: #EBB113;
}

.aheto-header-3__fix .main-menu > .menu-item:hover > a {
  color: #222222;
}

.aheto-header-3__fix .main-menu > .menu-item:after {
  background-color: #222222;
}

@media screen and (min-width: 1025px) {
  .aheto-header-3 .sub-menu {
    box-shadow: 1.169px 3.825px 15.66px 2.34px rgba(235, 177, 19, 0.2);
  }
}

.aheto-header-3 .aheto-btn--light:hover {
  border: 1px solid rgba(255, 255, 255, 0.5);
  background-color: #fff;
  color: #EBB113 !important;
}

.aheto-header-4__logo {
  background-color: #EBEBEB;
}

.aheto-header-4__button-icon {
  color: #111539;
  transition: 0.3s all;
}

.aheto-header-4__button-icon:hover {
  color: #EBB113;
  transition: 0.3s all;
}

@media screen and (min-width: 1025px) {
  .aheto-header-4 .main-menu > .menu-item > a {
    color: #111539;
  }
}

@media screen and (max-width: 1024px) {
  .aheto-header-4 .main-menu .menu-item a {
    color: #111539;
  }
}

@media screen and (min-width: 1025px) {
  .aheto-header-4 .sub-menu .menu-item a {
    color: #111539;
  }
  .aheto-header-4 .sub-menu .menu-item:hover > a {
    color: #EBB113;
  }
  .aheto-header-4 .sub-menu .menu-item:hover > .dropdown-btn {
    color: #EBB113;
  }
}

@media screen and (min-width: 1025px) {
  .aheto-header-4 .mega-menu__title {
    color: #999999;
  }
}

@media screen and (max-width: 1024px) {
  .aheto-header-4 .mega-menu__title {
    color: #EBB113;
  }
}

.aheto-header-4 .logo__text {
  color: #EBB113;
}

.aheto-header-4 .logo__sub-text {
  color: #EBB113;
}

.aheto-header-4 .hamburger-inner {
  background-color: #111539;
}

.aheto-header-4 .hamburger-inner::before, .aheto-header-4 .hamburger-inner::after {
  background-color: #111539;
}

.aheto-header-5 h3 {
  line-height: 1.7;
}

.aheto-header-5 .main-menu .menu-item a {
  font-family: "Futura", sans-serif !important;
  font-size: 16px;
  letter-spacing: 1px;
}

.aheto-header-5 .menu-depth-1 a {
  font-size: 14px !important;
}

.aheto-header-5__button-icon {
  color: #111539;
  transition: 0.3s all;
}

.aheto-header-5__button-icon:hover {
  color: #EBB113;
  transition: 0.3s all;
}

.aheto-header-5__button-number {
  background-color: #EBB113;
}

.aheto-header-5 .main-menu .menu-item a {
  color: #111539;
  font-family: "Source Sans Pro", sans-serif;
}

@media screen and (min-width: 1025px) {
  .aheto-header-5 .main-menu .menu-item:hover > a,
  .aheto-header-5 .main-menu .menu-item:hover > .dropdown-btn {
    color: #EBB113;
  }
}

@media screen and (min-width: 1025px) {
  .aheto-header-5 .sub-menu {
    border-top: 2px solid #EBB113;
  }
}

@media screen and (min-width: 1025px) {
  .aheto-header-5 .mega-menu__col {
    border-top: 2px solid #EBB113;
  }
}

@media screen and (max-width: 1024px) {
  .aheto-header-5 .mega-menu__title {
    color: #EBB113;
  }
}

.aheto-header-5 .dropdown-btn {
  color: #111539;
}

.aheto-header-5 .logo__text {
  font-family: "Source Sans Pro", sans-serif;
}

.aheto-header-5 .hamburger-inner {
  background-color: #111539;
}

.aheto-header-5 .hamburger-inner::before, .aheto-header-5 .hamburger-inner::after {
  background-color: #111539;
}

.aheto-header-6__toolbar {
  background-color: #111539;
}

.aheto-header-6__toolbar a:hover,
.aheto-header-6__toolbar button:hover {
  color: #EBB113;
}

.aheto-header-6 .wpml-ls-native {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
}

.aheto-header-6__toolbar-text, .aheto-header-6__toolbar-tel, .aheto-header-6__toolbar-email, .aheto-header-6__toolbar-authentication {
  font-family: "Roboto", sans-serif;
}

.aheto-header-6__search input {
  color: #111539;
}

.aheto-header-6__search input[type=text] {
  background-color: #EBEBEB;
}

.aheto-header-6__search input[type=text]::placeholder {
  color: #999999;
}

.aheto-header-6__search button[type="submit"] {
  color: #111539;
}

.aheto-header-6__search button[type="submit"]:hover {
  color: #EBB113;
}

.aheto-header-6 .main-menu .menu-item a {
  font-family: "Source Sans Pro", sans-serif;
}

.aheto-header-6 .main-menu .current-menu-item a::before {
  width: 100%;
}

@media screen and (max-width: 1024px) {
  .aheto-header-6 .main-menu .current-menu-item {
    position: relative;
    width: fit-content;
  }
  .aheto-header-6 .main-menu .current-menu-item::after {
    display: inline-block !important;
    position: absolute;
    bottom: 5px;
    left: 0;
    width: 100%;
    height: 2px;
    -webkit-transition: all 0.2s;
    background-color: #EBB113;
    transition: all 0.2s;
    content: '';
  }
}

@media screen and (min-width: 1025px) {
  .aheto-header-6 .main-menu {
    display: flex;
  }
  .aheto-header-6 .main-menu > .menu-item > a:before {
    background-color: #EBB113;
  }
}

@media screen and (min-width: 1025px) {
  .aheto-header-6 .sub-menu {
    background-color: #111539;
  }
  .aheto-header-6 .sub-menu .menu-item:hover > a,
  .aheto-header-6 .sub-menu .menu-item:hover > .dropdown-btn {
    color: #EBB113;
  }
}

.aheto-header-6 .dropdown-btn {
  color: #111539;
}

.aheto-header-6 .logo__sub-text {
  color: #999999;
  font-family: "Source Sans Pro", sans-serif;
}

.aheto-header-6 .authentication__log-in, .aheto-header-6 .authentication__register {
  font-family: "Roboto", sans-serif;
}

.aheto-header-6 .aht-socials.aheto-socials--circle .aht-socials {
  display: flex;
}

.aheto-header-6 .aht-socials.aheto-socials--circle .aht-socials__link {
  color: #111539;
}

.aheto-header-6 .aht-socials.aheto-socials--circle .aht-socials__link:hover {
  border-color: #EBB113;
  background-color: #EBB113;
  color: #111539;
}

.aheto-header-6 .hamburger-inner {
  background-color: #111539;
}

.aheto-header-6 .hamburger-inner:before, .aheto-header-6 .hamburger-inner:after {
  background-color: #111539;
}

.aheto-header-7__inner {
  background-color: #222222;
}

@media screen and (max-width: 1024px) {
  .aheto-header-7__inner {
    background: linear-gradient(to right, #222222, #343434);
  }
}

.aheto-header-7__aside-close {
  color: #fff;
}

.aheto-header-7__contact .logo__text {
  color: #222222;
}

.aheto-header-7__email, .aheto-header-7__tel {
  color: #111539;
}

.aheto-header-7__email:hover, .aheto-header-7__tel:hover {
  color: #EBB113;
}

@media screen and (max-width: 1024px) {
  .aheto-header-7__menu {
    background: linear-gradient(to right, #222222, #343434);
  }
}

@media screen and (min-width: 1025px) {
  .aheto-header-7 .main-menu .menu-item a {
    color: #999999;
  }
}

@media screen and (min-width: 1025px) {
  .aheto-header-7 .sub-menu {
    background-color: #222222;
  }
}

.aheto-header-7 .dropdown-btn {
  color: #999999;
}

.aheto-header-7 .aheto-copyright p {
  color: #999999;
}

.aheto-header-7__aside-menu li a:hover {
  color: #EBB113;
}

@media screen and (max-width: 1024px) {
  .aheto-header-8__inner {
    background: linear-gradient(to right, #bc8d0f, #EBB113);
  }
}

@media screen and (max-width: 1024px) {
  .aheto-header-8__menu {
    background: linear-gradient(to right, #bc8d0f, #EBB113);
  }
}

@media screen and (min-width: 1025px) {
  .aheto-header-8 .sub-menu .menu-item a {
    color: #999999;
  }
  .aheto-header-8 .sub-menu .menu-item:hover > a,
  .aheto-header-8 .sub-menu .menu-item:hover > .dropdown-btn {
    color: #EBB113;
  }
  .aheto-header-8 .sub-menu .dropdown-btn {
    color: #999999;
  }
}

.aheto-header-8 .aheto-btn {
  color: #EBB113;
}

.aheto-header-8 .aheto-btn:hover {
  background-color: #FF704F;
}

.aheto-header-8__search-icon {
  color: #fff;
  font-size: 24px;
}

.aheto-header-8__search-icon:hover {
  color: #FF704F;
}

@media screen and (max-width: 1024px) {
  .aheto-header-9__line {
    background-color: #EBB113;
    background-image: linear-gradient(to right, #69b9f2, #559be5, #417fda, #3065c8, #2052c9);
    padding: 10px 0px;
  }
}

@media screen and (max-width: 1024px) {
  .aheto-header-9__menu {
    background-color: #EBB113;
    background-image: linear-gradient(to right, #69b9f2, #559be5, #417fda, #3065c8, #2052c9);
  }
}

.aheto-header-9 .menu-item .sub-menu a.active {
  color: #2A74ED;
}

.aheto-header-9 .menu-item > a + span {
  transition: all 0.02s;
}

.aheto-header-9 .menu-item > a:hover {
  color: rgba(255, 255, 255, 0.75);
}

.aheto-header-9 .menu-item > a:hover + span {
  color: rgba(255, 255, 255, 0.75);
  transition: all 0.02s;
}

.aheto-header-9 .menu-item:hover > a {
  color: rgba(255, 255, 255, 0.75);
}

.aheto-header-9 .menu-item:hover > a + span {
  color: rgba(255, 255, 255, 0.75);
  transition: all 0.02s;
}

.aheto-header-9 .sub-menu .menu-item:hover > a {
  color: #2A74ED;
}

.aheto-header-9 .sub-menu .menu-item:hover > a + span {
  color: #2A74ED;
}

.aheto-header-9 .sub-menu .menu-item a:hover {
  color: #2A74ED;
}

.aheto-header-9 .sub-menu .menu-item a:hover + span {
  color: #2A74ED;
}

.aheto-header-9 .authentication__sign-in:hover {
  color: #2A74ED;
}

.aheto-header-9 .authentication__sign-up {
  color: #EBB113;
}

.aheto-header-9 .authentication__sign-up:hover {
  background-color: #2A74ED;
  color: #fff;
}

.aheto-header-10__toolbar {
  background-color: #111539;
}

.aheto-header-10__toolbar-icon {
  color: #EBB113;
}

.aheto-header-10__toolbar-text {
  color: #999999;
  font-family: "Source Sans Pro", sans-serif;
}

.aheto-header-10__toolbar-text a {
  color: #999999;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;
}

.aheto-header-10__toolbar-text a:hover {
  color: #EBB113;
}

.aheto-header-10__detail-icon {
  color: #EBB113;
}

.aheto-header-10__detail-target {
  color: #999999;
  font-family: "Source Sans Pro", sans-serif;
}

.aheto-header-10__button-icon {
  color: #111539;
  transition: 0.2s all;
}

.aheto-header-10__button-icon:hover {
  color: #EBB113;
  transition: 0.2s all;
}

.aheto-header-10__button-number {
  background-color: #EBB113;
}

@media screen and (min-width: 1025px) {
  .aheto-header-10__menu {
    background-color: #EBB113;
  }
  .aheto-header-10__menu .main-menu > .menu-item > a:hover {
    background-color: rgba(17, 21, 57, 0.15);
  }
  .aheto-header-10__menu .main-menu > .menu-item:hover > a {
    background-color: rgba(17, 21, 57, 0.15);
  }
}

@media screen and (max-width: 1024px) {
  .aheto-header-10 .main-menu .menu-item a {
    color: #111539;
  }
}

@media screen and (min-width: 1025px) {
  .aheto-header-10 .sub-menu .menu-item a {
    color: #fff;
  }
  .aheto-header-10 .sub-menu .menu-item:hover > a,
  .aheto-header-10 .sub-menu .menu-item:hover .dropdown-btn {
    color: #111539;
  }
}

.aheto-header-10 .aht-socials__icon:hover {
  color: #EBB113;
}

.aheto-header-10 .hamburger-inner {
  background-color: #111539;
}

.aheto-header-10 .hamburger-inner:before, .aheto-header-10 .hamburger-inner:after {
  background-color: #111539;
}

.aheto-header-11__socials-title {
  color: #EBB113;
}

.aheto-header-11 .main-menu .menu-item a {
  color: #111539;
}

.aheto-header-11 .main-menu .menu-item a:before {
  background-color: #EBB113 !important;
}

.aheto-header-11 .main-menu .menu-item a:hover {
  color: #EBB113;
}

.aheto-header-11 .main-menu .menu-item a:hover > a {
  color: #EBB113;
}

.aheto-header-11 .main-menu > .menu-item > a:before {
  background-color: #111539;
}

.aheto-header-11 .sub-menu .menu-item a:hover {
  color: #EBB113;
}

.aheto-header-11 .dropdown-btn {
  transition: all 0.05s;
  border: 1px solid transparent;
  border-radius: 3px;
  line-height: 13px;
  position: relative;
  top: 3px;
}

.aheto-header-11 .dropdown-btn:hover {
  transition: all 0.05s;
  border: 1px solid transparent;
  color: #EBB113;
}

@media screen and (max-width: 1024px) {
  .aheto-header-11 .dropdown-btn {
    border: 1px solid #ddd;
    color: #111539;
  }
}

.aheto-header-11 .mega-menu__title {
  color: #999999;
}

.aheto-header-11 .dropdown-btn {
  font-size: 16px;
}

.aheto-header-11 .hamburger-inner {
  border-top: 1px solid rgba(17, 21, 57, 0.5);
  border-bottom: 1px solid rgba(17, 21, 57, 0.5);
  background-color: #111539;
}

.aheto-header-11 .hamburger-inner:before, .aheto-header-11 .hamburger-inner:after {
  background-color: #111539;
}

.aheto-header-11 .aheto-socials__icon {
  color: #EBEBEB;
}

.aheto-header-11 .aheto-socials__icon:hover {
  color: #EBB113;
}

@media screen and (min-width: 1025px) {
  .aheto-header-12 .main-menu .menu-item a {
    color: #999999;
  }
  .aheto-header-12 .main-menu .menu-item:hover > a,
  .aheto-header-12 .main-menu .menu-item:hover > .dropdown-btn {
    color: #EBB113;
  }
  .aheto-header-12 .main-menu > .menu-item > a {
    color: #111539;
  }
}

@media screen and (max-width: 1024px) {
  .aheto-header-12 .main-menu .menu-item a {
    color: #EBB113;
  }
  .aheto-header-12 .main-menu > .menu-item > a {
    color: #111539;
  }
}

@media screen and (min-width: 1025px) {
  .aheto-header-12 .sub-menu {
    box-shadow: 0px 0px 5px 0px rgba(235, 177, 19, 0.1);
  }
}

@media screen and (min-width: 1025px) {
  .aheto-header-12 .mega-menu__title {
    color: #111539;
  }
  .aheto-header-12 .mega-menu__col:before {
    background-color: #EBEBEB;
  }
}

@media screen and (max-width: 1024px) {
  .aheto-header-12 .mega-menu__title {
    color: #999999;
  }
}

.aheto-header-12 .hamburger-inner:before, .aheto-header-12 .hamburger-inner:after {
  background-color: #111539;
}

@media screen and (min-width: 1025px) {
  .aheto-header-12 .hamburger.is-active .hamburger-inner {
    background-color: #111539;
  }
}

@media screen and (max-width: 1024px) {
  .aheto-header-12 .hamburger.is-active .hamburger-inner {
    background-color: #111539;
  }
}

.aheto-header-12 .logo__text {
  color: #EBB113;
}

.aheto-header-12 .logo__text::first-letter {
  color: #111539;
}

.aheto-header-12 form {
  font-family: "Source Sans Pro", sans-serif;
}

.aheto-header-12 form input,
.aheto-header-12 form button {
  color: #111539;
}

.aheto-header-12 form input {
  border-bottom: 1px solid #EBEBEB;
}

.aheto-header-12 form input:focus {
  border-color: #EBB113;
}

.aheto-header-12 form i:hover {
  color: #EBB113;
}

@media screen and (max-width: 1024px) {
  .aheto-header-12 .dropdown-btn {
    border: 1px solid #EBEBEB;
    color: #111539;
  }
}

@media screen and (max-width: 1024px) {
  .aheto-header-12__aside-overlay.is-open {
    background-color: rgba(17, 21, 57, 0.7);
  }
}

.aheto-header-12__aside-close-icon {
  color: #111539;
}

.aheto-header-12__cart-link {
  color: #111539;
}

.aheto-header-12__cart-link:hover {
  color: #EBB113;
}

@media screen and (max-width: 1024px) {
  .aheto-header-13__inner {
    background-color: #111539;
  }
}

.aheto-header-13__button-link:hover {
  color: #EBB113;
}

@media screen and (max-width: 1024px) {
  .aheto-header-13__menu {
    background: linear-gradient(to bottom, #111539, rgba(17, 21, 57, 0.85));
  }
}

@media screen and (min-width: 1025px) {
  .aheto-header-13 .main-menu > .menu-item.active:before {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 4px;
    background-color: #EBB113;
  }
  .aheto-header-13 .main-menu > .menu-item:before {
    background-color: #EBB113;
  }
}

@media screen and (min-width: 1025px) {
  .aheto-header-13 .sub-menu .menu-item a {
    color: #111539;
  }
  .aheto-header-13 .sub-menu .menu-item:hover > a,
  .aheto-header-13 .sub-menu .menu-item:hover > .dropdown-btn {
    color: #EBB113;
  }
  .aheto-header-13 .sub-menu .dropdown-btn {
    color: #111539;
  }
}

.aheto-header-14 {
  background-color: #EBB113;
}

.aheto-header-14__logo {
  background-color: #2A74ED;
}

.aheto-header-14__button-icon:hover {
  color: #2A74ED;
}

.aheto-header-14 .main-menu .menu-item a {
  font-family: "Source Sans Pro", sans-serif;
}

@media screen and (min-width: 1025px) {
  .aheto-header-14 .main-menu > .menu-item:hover > a,
  .aheto-header-14 .main-menu > .menu-item:hover > .dropdown-btn {
    color: #2A74ED;
  }
}

@media screen and (min-width: 1025px) {
  .aheto-header-14 .sub-menu {
    border-top: 3px solid #2A74ED;
  }
  .aheto-header-14 .sub-menu .menu-item a {
    color: #EBB113;
  }
  .aheto-header-14 .sub-menu .menu-item:hover > a,
  .aheto-header-14 .sub-menu .menu-item:hover > .dropdown-btn {
    color: #2A74ED;
  }
  .aheto-header-14 .sub-menu .dropdown-btn {
    color: #EBB113;
  }
}

@media screen and (min-width: 1025px) {
  .aheto-header-14 .mega-menu__title {
    color: #999999;
  }
}

@media screen and (max-width: 1024px) {
  .aheto-header-14 .mega-menu__title {
    color: #EBB113;
  }
}

.aheto-header-14 .aht-socials__icon:hover {
  color: #2A74ED;
}

.main-header .hamburger-inner {
  background-color: #111539;
}

.main-header__icons {
  padding-right: 15px;
  padding-top: 24px;
}

.main-header__icons .icons-widget__item {
  color: #111539;
}

.main-header__icons .icons-widget__item:hover {
  color: #EBB113;
}

.main-header .dropdown-btn {
  color: #999999;
}

@media screen and (min-width: 1025px) {
  .main-header .main-menu--ci-rounded-solid-bg > .menu-item:hover > a {
    background-color: rgba(235, 177, 19, 0.1);
  }
}

.main-header .main-menu--ddt-default .sub-menu .menu-item > a {
  color: #999999;
  font-weight: 400;
}

.main-header .main-menu--ddt-default .sub-menu .menu-item > a:hover {
  color: #111539;
}

.main-header .main-menu--ddt-default .sub-menu .menu-item > a.active {
  color: #111539;
}

@media screen and (min-width: 1025px) {
  .main-header .main-menu--ddt-default .sub-menu {
    box-shadow: 0px 0px 13px 0px rgba(235, 177, 19, 0.09);
  }
  .main-header .main-menu--ddt-default .sub-menu .menu-item > a {
    color: #999999;
  }
  .main-header .main-menu--ddt-default .sub-menu .menu-item > a:hover {
    color: #111539;
  }
  .main-header .main-menu--ddt-default .sub-menu .menu-item > a.active {
    color: #111539;
  }
  .main-header .main-menu--ddt-default .sub-menu .menu-item:hover > .dropdown-btn {
    color: #111539;
  }
  .main-header .main-menu--ddt-default.main-menu--ddc-light .sub-menu {
    background-color: #EBEBEB;
  }
  .main-header .main-menu--ddt-default.main-menu--ddc-dark .sub-menu {
    background-color: #111539;
  }
  .main-header .main-menu--ddt-classic .sub-menu {
    box-shadow: 0px 0px 2px 0px rgba(235, 177, 19, 0.2);
  }
  .main-header .main-menu--ddt-classic .sub-menu .menu-item > a,
  .main-header .main-menu--ddt-classic .sub-menu .menu-item > .dropdown-btn {
    color: #111539;
  }
  .main-header .main-menu--ddt-classic .sub-menu .menu-item:hover > a,
  .main-header .main-menu--ddt-classic .sub-menu .menu-item:hover > .dropdown-btn {
    color: #EBB113;
  }
  .main-header .main-menu--ddt-classic .mega-menu__title {
    color: #999999;
  }
  .main-header .main-menu--ddt-classic.main-menu--ddc-light .sub-menu {
    background-color: #EBEBEB;
  }
  .main-header .main-menu--ddt-classic.main-menu--ddc-dark .sub-menu {
    background-color: #111539;
  }
  .main-header .main-menu--ddt-clean .sub-menu {
    box-shadow: 0px 0px 5px 0px rgba(235, 177, 19, 0.1);
  }
  .main-header .main-menu--ddt-clean.main-menu--ddc-light .sub-menu {
    background-color: #EBEBEB;
  }
  .main-header .main-menu--ddt-clean.main-menu--ddc-dark .sub-menu {
    background-color: #111539;
  }
  .main-header .main-menu--ddt-clean.main-menu--ddc-dark .mega-menu__title {
    color: #EBEBEB;
  }
}

.main-header .menu-item:before {
  background-color: #EBB113;
}

.main-header .menu-item a {
  color: #111539;
}

.main-header .menu-item.current-menu-item > a {
  color: #EBB113;
}

@media screen and (min-width: 1025px) {
  .main-header .menu-item a.active {
    color: #EBB113;
  }
  .main-header .menu-item:hover > a {
    color: #EBB113;
  }
  .main-header .menu-item:hover > .dropdown-btn {
    color: #EBB113;
  }
  .main-header .menu-item.current-menu-item > .dropdown-btn:before {
    color: #EBB113;
  }
}

@media screen and (max-width: 1024px) {
  .main-header .menu-item > a:hover {
    color: #EBB113;
  }
}

.main-header .mega-menu__title {
  color: #EBB113;
}

.main-header--solid .main-header__menu-wrap {
  background-color: #EBB113;
}

@media screen and (max-width: 1024px) {
  .main-header--solid .main-header__menu-box {
    background-color: #EBB113;
  }
}

.main-header--grad .main-header__menu-wrap {
  background-image: linear-gradient(to bottom, #fbefcf 0%, #fff 100%);
}

.main-header--grad-hor .main-header__menu-wrap {
  background-image: linear-gradient(to right, #fff 0%, #fbefcf 100%);
}

@media screen and (max-width: 1024px) {
  .main-header--grad-hor .main-header__menu-box {
    background-image: linear-gradient(to right, #fff 0%, #fbefcf 100%);
  }
}

@media screen and (min-width: 1025px) {
  .main-header--fullscreen-menu .dropdown-btn {
    color: #111539 !important;
  }
  .main-header--fullscreen-menu .dropdown-btn:hover {
    color: #EBB113 !important;
  }
  .main-header--fullscreen-menu .menu-item a {
    color: #111539 !important;
  }
  .main-header--fullscreen-menu .menu-item a:hover {
    color: #EBB113 !important;
  }
  .main-header--fullscreen-menu .mega-menu__title {
    color: #111539 !important;
  }
  .main-header--fullscreen-menu.is-open .hamburger-inner {
    background-color: #111539;
  }
}

@media screen and (min-width: 1025px) {
  .main-header--fullscreen-menu.main-header--menu-dark .dropdown-btn:hover {
    color: #EBB113 !important;
  }
  .main-header--fullscreen-menu.main-header--menu-dark .main-header__menu-box {
    background-color: rgba(17, 21, 57, 0.95);
  }
  .main-header--fullscreen-menu.main-header--menu-dark .menu-item a:hover {
    color: #EBB113 !important;
  }
}

.main-header__widgets-area .aht-socials__item a {
  border-color: #999999;
  color: #999999;
}

.main-header .button-number {
  background-color: #EBB113;
}

.main-header .wpml-ls-legacy-dropdown:hover > ul > li > a .wpml-ls-native {
  color: #EBB113;
}

.main-header .wpml-ls-native {
  color: #111539;
}

.main-header .wpml-ls-native:hover {
  color: #EBB113;
}

.main-header .wpml-ls-native i {
  color: #999999;
}

.main-header .wpml-ls-sub-menu li a:hover {
  color: #EBB113;
}

.aheto-header--edu-contact .label {
  font-family: "Source Sans Pro", sans-serif;
}

.home-event-wrap-upc-event {
  background-image: linear-gradient(140deg, #6c207c 0%, #EBB113 100%);
}

.home-event-wrap-upc-event .aheto-btn-container {
  padding-bottom: 5px;
}

.home-event-wrap-tickets {
  background-color: rgba(235, 235, 235, 0.4);
}

.author-bio-wrap {
  background-color: #EBB113;
}

.bg-wrap-c-active {
  background-color: #EBB113;
}

.bg-wrap-c-light {
  background-color: #EBEBEB;
}

.bg-wrap-c-light-cons {
  background-color: #f9f9f9;
}

.bg-wrap-c-grey {
  background-color: #999999;
}

.bg-wrap-c-dark {
  background-color: #111539;
}

.bg-wrap-c-dark-2 {
  background-color: #222222;
}

.bg-wrap-c-alter {
  background-color: #2A74ED;
}

.bg-wrap-c-alter-2 {
  background-color: #FF704F;
}

.bg-wrap-c-alter-3 {
  background-color: #FFC015;
}

hr {
  background: #eeeeee;
  background-image: linear-gradient(to right, rgba(238, 238, 238, 0), #eeeeee, rgba(238, 238, 238, 0));
  height: 1px;
}

.edu-wrap-2 {
  background-image: linear-gradient(to right, #EBB113, #EBB113);
}

.home-edu-wrap-pricing--heading {
  background-color: #EBB113;
}

body .swiper-button-prev {
  color: rgba(235, 177, 19, 0.2);
}

body .swiper-button-prev:hover {
  color: #EBB113;
}

body .swiper-button-next {
  color: rgba(235, 177, 19, 0.2);
}

body .swiper-button-next:hover {
  color: #EBB113;
}

body .swiper-pagination-bullet-active {
  background-color: #EBB113;
}

body .swiper-pagination-progressbar-fill {
  background-color: #EBB113;
}

body .swiper--events-posts .swiper-pagination-bullet-active {
  background-color: #999999;
}

body .swiper--home-event .swiper-button-prev, body .swiper--home-event .swiper-button-next {
  border: 1px solid rgba(17, 21, 57, 0.1);
}

body .swiper--home-event .swiper-button-prev i:before, body .swiper--home-event .swiper-button-next i:before {
  color: #111539;
}

body .swiper--home-event .swiper-button-prev:hover, body .swiper--home-event .swiper-button-next:hover {
  background-color: #111539;
}

body .swiper--shop-item .swiper-button-prev,
body .swiper--shop-item .swiper-button-next {
  color: #999999;
}

body .swiper--shop-item .gallery-thumbs .swiper-slide-active > div {
  border: 1px solid #111539;
}

body .swiper--banner-hp .swiper-button-prev:hover i, body .swiper--banner-hp .swiper-button-next:hover i {
  color: #111539;
}

body .swiper--edu .swiper-button-prev, body .swiper--edu .swiper-button-next {
  color: #111539;
}

body .swiper--edu .swiper-button-prev i, body .swiper--edu .swiper-button-next i {
  color: #111539;
}

body .swiper--edu .swiper-button-prev:hover:hover, body .swiper--edu .swiper-button-next:hover:hover {
  background-color: #111539;
  color: #EBB113;
}

body .swiper--edu .swiper-button-prev:hover::before, body .swiper--edu .swiper-button-next:hover::before {
  color: #EBEBEB;
}

body .swiper--edu .swiper-pagination-bullet-active {
  background-color: #111539 !important;
}

body .swiper--edu-tm .swiper-pagination-bullet-active {
  background-color: #111539 !important;
}

body .swiper--edu-courses .swiper-slide > div {
  height: 100%;
}

body .swiper--edu-courses .swiper-button-prev, body .swiper--edu-courses .swiper-button-next {
  color: #111539;
  font-size: 16px;
}

body .swiper--edu-courses .swiper-button-prev:hover, body .swiper--edu-courses .swiper-button-next:hover {
  background-color: #111539;
  color: #EBEBEB;
}

body .swiper--edu-posts .swiper-pagination-bullet {
  margin: 0 5px;
}

body .swiper--edu-posts .swiper-pagination-bullet-active {
  background-color: #111539;
}

body .swiper--edu-class-table {
  background-color: #fff;
}

body .swiper--edu-events .swiper-pagination-progressbar-fill {
  background-color: #111539;
}

body .swiper--edu-events .swiper-button-next, body .swiper--edu-events .swiper-button-prev {
  color: #111539;
}

body .swiper--edu-events .swiper-button-next:hover, body .swiper--edu-events .swiper-button-prev:hover {
  opacity: 0.5;
}

body .swiper--edu-images .swiper-button-prev i, body .swiper--edu-images .swiper-button-next i {
  color: #111539;
}

body .swiper--edu-images .swiper-button-prev:hover, body .swiper--edu-images .swiper-button-next:hover {
  background-color: #111539;
}

body .swiper--edu-blog .swiper-button-prev::before, body .swiper--edu-blog .swiper-button-next::before {
  color: #fff;
}

body .swiper--rest-blog .swiper-button-prev::before, body .swiper--rest-blog .swiper-button-next::before {
  color: #fff;
}

body .swiper--websites .swiper-slide img {
  box-shadow: 0px 10px 30px 0px rgba(235, 177, 19, 0.1);
}

body .swiper--saas {
  width: 100%;
}

body .swiper--saas .swiper-button-prev,
body .swiper--saas .swiper-button-next {
  color: #cccccc;
  top: calc(50% - 43px);
}

@media screen and (max-width: 1024px) {
  body .swiper--saas .swiper-button-prev,
  body .swiper--saas .swiper-button-next {
    display: none;
  }
}

body .swiper--testimonials .swiper-pagination-bullet-active {
  background: rgba(17, 21, 57, 0.4);
}

body .swiper--trvl .swiper-button-next, body .swiper--trvl .swiper-button-prev {
  color: #111539;
}

body .swiper--trvl .swiper-button-next:hover, body .swiper--trvl .swiper-button-prev:hover {
  color: #999999;
}

body .swiper--trvl-3 .swiper-button-prev, body .swiper--trvl-3 .swiper-button-next {
  color: #111539;
}

body .swiper--trvl-3 .swiper-button-prev:hover, body .swiper--trvl-3 .swiper-button-next:hover {
  background-color: #EBB113;
}

body .swiper--business .swiper-container {
  padding: 30px;
}

body .swiper--business .swiper-pagination-bullet {
  border: 1px solid #2A74ED;
}

body .swiper--business .swiper-pagination-bullet-active {
  background-color: #2A74ED;
}

body .swiper--vert-pag .swiper-pagination-bullet {
  border: 1px solid #999999;
}

body .swiper--vert-pag .swiper-pagination-bullet-active {
  background-color: #999999;
}

body .swiper--yoga-2 .swiper-button-prev:before {
  background-image: url("../img/yoga/home-yoga/ion-ios-arrow-thin-left-ionicons.png");
}

body .swiper--yoga-2 .swiper-button-next:before {
  background-image: url("../img/yoga/home-yoga/ion-ios-arrow-thin-right-ionicons.png");
}

body .swiper--yoga-2 .swiper-button-prev, body .swiper--yoga-2 .swiper-button-next {
  background-color: #f7f7f7;
  border: 1px solid #f7f7f7;
  background-repeat: no-repeat;
  background-position: center;
}

body .swiper--yoga-2 .swiper-button-prev:hover, body .swiper--yoga-2 .swiper-button-next:hover {
  background: none;
}

body .swiper--yoga-2 .swiper-button-prev:before, body .swiper--yoga-2 .swiper-button-next:before {
  content: "";
  width: 16px;
  height: 10px;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

body .swiper--yoga-2 .swiper-button-prev i, body .swiper--yoga-2 .swiper-button-next i {
  color: #111539;
}

body .swiper--constrution-service .swiper-button-prev, body .swiper--constrution-service .swiper-button-next {
  border: 1px solid #d9d9d9;
  color: #222222;
}

body .swiper--constrution-service .swiper-button-prev:hover, body .swiper--constrution-service .swiper-button-next:hover {
  background-color: #eeeeee;
  border: 1px solid #eeeeee;
}

body .swiper--twitter .swiper-button-prev, body .swiper--twitter .swiper-button-next {
  color: #999999;
}

body .swiper--twitter .swiper-button-prev:hover, body .swiper--twitter .swiper-button-next:hover {
  box-shadow: 0px 5px 10px 0px rgba(17, 21, 57, 0.1);
}

body .swiper--chr-2 .swiper-pagination-bullet {
  border: 1px solid #c2c2c2;
}

body .swiper--chr-2 .swiper-pagination-bullet-active {
  background-color: #929292;
}

body .swiper--constrution .swiper-button-prev, body .swiper--constrution .swiper-button-next {
  color: #fff;
}

body .swiper--constrution .swiper-button-prev:hover, body .swiper--constrution .swiper-button-next:hover {
  color: #ebb113;
  opacity: 1;
}

body .swiper--chr-3 .swiper-pagination-bullet {
  border: 1px solid #222222;
}

body .swiper--chr-3 .swiper-pagination-bullet-active {
  background-color: #222222;
}

body .swiper--chr-3 .swiper-button-prev:hover, body .swiper--chr-3 .swiper-button-next:hover {
  color: #EBB113;
}

body .swiper--restaurant .swiper-button-prev {
  background-color: #EBB113;
  color: #fff;
}

body .swiper--restaurant .swiper-button-prev:hover {
  background-color: #fff;
  color: #111539;
}

body .swiper--restaurant .swiper-button-next {
  background-color: #fff;
  color: #111539;
}

body .swiper--restaurant .swiper-button-next:hover {
  background-color: #EBB113;
  color: #fff;
}

.swiper.home-banner .swiper-button-prev:hover {
  color: #111539;
}

.swiper.home-banner .swiper-button-next:hover {
  color: #111539;
}

.aheto-footer {
  background-color: #111539;
}

.aheto-footer-4 {
  background-color: #EBEBEB;
}

.aheto-footer-4 .aheto-socials__link .icon:hover {
  color: #EBB113;
}

.aheto-footer-5 {
  background-color: #090b1e;
}

.aheto-footer-5__main {
  padding-bottom: 70px !important;
}

.aheto-footer-5 .widget_mc4wp_form_widget .widget-title {
  margin-bottom: 40px;
}

.aheto-footer-5 .menu-main-container,
.aheto-footer-5 .aheto-footer-5__cr-text {
  line-height: 2.5;
}

.aheto-footer-5 .textwidget li {
  margin-bottom: 14px;
}

.aheto-footer-5 .textwidget li:last-child {
  margin-top: 50px;
  margin-bottom: 6px;
}

.aheto-footer-5 .menu-item {
  margin-bottom: 14px;
}

.aheto-footer-5 .aheto-footer-menu .menu-item a {
  font-family: "Futura", sans-serif, sans-serif;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 1px;
}

.aheto-footer-5 .widget_aheto__info--address {
  color: #999;
}

.aheto-footer-5 .widget-title {
  font-family: "Futura", sans-serif;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.8px;
}

.aheto-footer-5 .contact .widget-title {
  margin-bottom: 50px;
}

.aheto-footer-5 .contact .widget_aheto__info {
  line-height: 1.88;
  margin-bottom: 8px;
}

@media screen and (min-width: 769px) {
  .aheto-footer-5__bottom p, .aheto-footer-5__bottom ul {
    margin-bottom: 0;
  }
}

.aheto-footer-6 {
  background-color: #111539;
}

.aheto-footer-6 .widget_mc4wp_form_widget p {
  font-family: "Roboto", sans-serif;
}

.aheto-footer-6__cr-text {
  font-family: "Roboto", sans-serif;
}

.aheto-footer-6 .aheto-footer-menu .menu-item {
  font-family: "Source Sans Pro", sans-serif;
}

.aheto-footer-7 .aht-socials__icon:hover {
  color: #999999;
}

.aheto-footer-8 {
  background-color: #EBB113;
}

.aheto-footer-9 .aht-socials__icon {
  color: rgba(153, 153, 153, 0.5);
}

.aheto-footer-9 .aht-socials__icon:hover {
  color: #EBB113;
}

.aheto-footer-9__bottom .aheto-footer-menu .menu-item a {
  color: #999999;
}

.aheto-footer-9__bottom .aheto-footer-menu .menu-item a:hover {
  color: #111539;
}

.aheto-footer-9__bottom .aheto-footer-menu .menu-item.current-menu-item a {
  color: #111539;
}

.aheto-footer-9__cr-text {
  font-size: 15px;
}

.aheto-footer-9__cr-text a {
  color: #EBB113;
}

.aheto-footer-10 .aheto-socials__link .icon:hover {
  color: #EBB113;
}

.aheto-footer-11__cr-text {
  color: #999999;
}

.aheto-footer-12 .aht-socials--circle .aht-socials__link:hover {
  color: #fff;
}

.aheto-footer-13 .aheto-socials__link:first-child {
  margin-left: 0;
}

.aheto-footer-13 .aheto-socials__link .icon:hover {
  color: #EBB113;
}

.aheto-footer button[type=submit],
.aheto-footer input[type=submit] {
  transition: .3s;
  cursor: pointer;
}

.aheto-footer :hover {
  text-decoration: none;
}

.aheto-footer [class*="__bottom"] {
  position: relative;
}

@media screen and (max-width: 767px) {
  .aheto-footer [class*="__bottom"] {
    text-align: center;
  }
}

.aheto-footer-1__main {
  padding: 105px 0 35px;
}

@media screen and (max-width: 991px) {
  .aheto-footer-1__main {
    padding: 50px 0 0;
  }
}

.aheto-footer-2__main {
  padding: 45px 0 30px;
}

@media screen and (max-width: 991px) {
  .aheto-footer-2__main {
    padding: 50px 0 0;
  }
}

.cheurch-footer-wrap {
  padding-top: 52px;
  margin-bottom: -22px;
}

.footer-church-about .aheto-footer-2__main,
.footer-church-news .aheto-footer-2__main,
.footer-church-ministries .aheto-footer-2__main,
.footer-church-events .aheto-footer-2__main,
.footer-church-contact .aheto-footer-2__main,
.footer-church-gallery .aheto-footer-2__main,
.footer-church-sermons .aheto-footer-2__main,
.footer-church-ministries-det .aheto-footer-2__main {
  padding: 45px 0 30px;
}

@media screen and (max-width: 1024px) {
  .footer-church-sermons .aheto-footer-2__main,
  .footer-church-ministries-det .aheto-footer-2__main {
    padding: 42px 0 30px;
  }
}

@media screen and (max-width: 991px) {
  .footer-church-about .aheto-footer-2__main,
  .footer-church-news .aheto-footer-2__main,
  .footer-church-ministries .aheto-footer-2__main,
  .footer-church-events .aheto-footer-2__main,
  .footer-church-contact .aheto-footer-2__main,
  .footer-church-gallery .aheto-footer-2__main,
  .footer-church-sermons .aheto-footer-2__main,
  .footer-church-ministries-det .aheto-footer-2__main {
    padding: 22px 0 30px;
  }
}

@media screen and (max-width: 425px) {
  .footer-church-about .aheto-footer-2__main,
  .footer-church-news .aheto-footer-2__main,
  .footer-church-ministries .aheto-footer-2__main,
  .footer-church-events .aheto-footer-2__main,
  .footer-church-contact .aheto-footer-2__main,
  .footer-church-gallery .aheto-footer-2__main,
  .footer-church-sermons .aheto-footer-2__main,
  .footer-church-ministries-det .aheto-footer-2__main {
    padding: 0;
  }
}

.aheto-footer-3__main {
  padding: 120px 0 50px;
}

@media screen and (max-width: 991px) {
  .aheto-footer-3__main {
    padding: 50px 0 0;
  }
}

.aheto-footer-3__cr-text {
  font-size: 15px;
}

.aheto-footer-4__main {
  padding: 105px 0 35px;
}

@media screen and (max-width: 991px) {
  .aheto-footer-4__main {
    padding: 50px 0 0;
  }
}

.aheto-footer-5 {
  background-image: url("../img/yoga/footer_bg.jpg");
  background-repeat: no-repeat;
  background-position: top left;
  background-size: cover;
}

.aheto-footer-5__main {
  padding: 105px 20px 55px;
}

@media screen and (max-width: 991px) {
  .aheto-footer-5__main {
    padding: 50px 20px 0;
  }
}

.aheto-footer-5__bottom {
  background-color: rgba(0, 0, 0, 0.09);
  padding: 0 20px;
}

@media screen and (max-width: 767px) {
  .aheto-footer-5__main {
    padding-bottom: 0 !important;
  }
}

@media screen and (max-width: 767px) {
  .aheto-footer-5 .aheto-footer-menu .menu-item {
    margin-bottom: 0;
  }
}

.aheto-footer-6 {
  background-image: url("../img/education/bg-2.png");
  background-repeat: no-repeat;
  background-position: right bottom;
}

.aheto-footer-6__main {
  padding: 105px 0 30px;
}

@media screen and (max-width: 991px) {
  .aheto-footer-6__main {
    padding: 50px 0 0;
  }
}

.aheto-footer-6 .aht-socials {
  margin-top: 40px;
}

@media screen and (max-width: 991px) {
  .aheto-footer-6 .aht-socials {
    margin-top: 0px;
  }
}

.aheto-footer-6 .aht-socials__link {
  color: #fff !important;
}

.aheto-footer-6 .aht-socials__link:hover i {
  color: #111539 !important;
}

.aheto-footer-6__bottom:before {
  display: block;
  position: absolute;
  width: 100%;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  content: '';
}

.aheto-footer-6__bottom .aheto-footer-menu .menu-item a {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}

.aheto-footer-6__cr-text {
  color: rgba(235, 235, 235, 0.5);
}

.aheto-footer-6 .widget_mc4wp_form_widget p {
  color: #fff;
}

.aheto-footer-6 .menu-item a {
  color: #fff !important;
}

.aheto-footer-6 .menu .current-menu-item a, .aheto-footer-6 .menu-item:hover a {
  color: #EBB113 !important;
}

.aheto-footer-6 .widget-text .post-date {
  color: rgba(255, 255, 255, 0.5);
}

@media screen and (max-width: 1200px) {
  .aheto-footer-6 .aht-socials--circle .aht-socials__link {
    width: 45px;
    height: 45px;
    line-height: 45px;
  }
  .aheto-footer-6 .aheto-footer-menu .menu-item {
    margin: 0;
    margin-right: 20px;
  }
}

.aheto-footer-7__main {
  padding: 145px 0 0;
}

@media screen and (max-width: 991px) {
  .aheto-footer-7__main {
    padding: 50px 0 0;
  }
}

.aheto-footer-7 .aht-socials__link {
  margin-right: 15px;
  margin-left: 15px;
}

.aheto-footer-7 .aht-socials__icon {
  color: #fff;
  font-size: 20px;
}

.aheto-footer-7__bottom {
  padding: 35px 0 80px;
  text-align: center;
}

@media screen and (max-width: 480px) {
  .aheto-footer-7__bottom {
    padding: 35px 0 25px;
  }
}

.aheto-footer-8__main {
  padding: 90px 0 45px;
}

@media screen and (max-width: 991px) {
  .aheto-footer-8__main {
    padding: 50px 0 0;
  }
}

.aheto-footer-8__cr-text {
  opacity: 1;
  color: rgba(255, 255, 255, 0.6);
  font-size: 15px;
}

.aheto-footer-8 .aheto-socials__link {
  margin-left: 25px;
}

.aheto-footer-8 .aheto-socials__link .icon {
  color: rgba(255, 255, 255, 0.4);
  font-size: 20px;
}

.aheto-footer-8 .aheto-socials__link .icon:hover {
  color: white;
}

.aheto-footer-9 {
  background-color: #fff;
}

.aheto-footer-9__main {
  padding: 110px 0 65px;
}

@media screen and (max-width: 991px) {
  .aheto-footer-9__main {
    padding: 50px 0 40px;
  }
}

.aheto-footer-9 .aht-socials__icon {
  font-size: 20px;
}

.aheto-footer-9__bottom .aheto-footer-menu .menu-item {
  margin-right: 50px;
}

@media screen and (max-width: 991px) {
  .aheto-footer-9__bottom .aheto-footer-menu .menu-item {
    margin-right: 0px;
    margin-left: 20px;
  }
}

@media screen and (max-width: 767px) {
  .aheto-footer-9__bottom .aheto-footer-menu .menu-item {
    margin-right: 10px;
    margin-left: 10px;
  }
}

.aheto-footer-9__bottom .aheto-footer-menu .menu-item a {
  font-weight: 500;
}

.aheto-footer-10 {
  padding: 0 15px;
}

.aheto-footer-10__main {
  padding: 105px 15px 30px;
}

@media screen and (max-width: 991px) {
  .aheto-footer-10__main {
    padding: 50px 0 0;
  }
}

.aheto-footer-10 .aheto-socials__link {
  margin-left: 25px;
}

.aheto-footer-10 .aheto-socials__link .icon {
  color: rgba(255, 255, 255, 0.4);
}

.aheto-footer-10 .widget_recent_entries_3 li {
  border-color: #222222;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

@media screen and (max-width: 767px) {
  .aheto-footer-10 .widget_recent_entries_3 li {
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
}

.aheto-footer-10 .widget_recent_entries_3 a {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.5;
}

.aheto-footer-11 {
  padding: 145px 0 100px;
}

@media screen and (max-width: 991px) {
  .aheto-footer-11 {
    padding: 50px 0;
  }
}

.aheto-footer-11__cr-text {
  font-size: 12px;
  letter-spacing: 0.2px;
  line-height: 2;
  opacity: 1;
  font-weight: 600;
}

.aheto-footer-12 {
  background-color: #fff;
}

.aheto-footer-12__main {
  padding: 70px 0 0;
}

@media screen and (max-width: 991px) {
  .aheto-footer-12__main {
    padding: 50px 0 0;
  }
}

.aht-socials--circle .aht-socials__link:hover {
  color: #fff;
}

.aht-socials--circle .aht-socials__link:hover .aht-socials__icon {
  color: #fff;
}

.aheto-footer-13__main {
  padding: 115px 0 20px;
}

@media screen and (max-width: 991px) {
  .aheto-footer-13__main {
    padding: 50px 0 0;
  }
}

.aheto-footer-13__cr-text {
  font-size: 14px;
  line-height: 1.86;
  color: #777777;
}

.aheto-footer-13 .aheto-socials__link {
  margin-left: 25px;
}

.aheto-footer-13 .aheto-socials__link .icon {
  color: rgba(255, 255, 255, 0.4);
  font-size: 16px;
}

.aheto-footer-13 .widget_aheto__desc {
  font-size: 14px;
  line-height: 1.71;
  color: #777777;
}

.aheto-footer-13 .widget_aheto__info {
  font-size: 14px;
  line-height: 1.86;
  color: #777777 !important;
  padding-left: 28px;
  margin-bottom: 10px;
}

.aheto-footer-13 .widget_aheto__info a {
  font-size: 14px;
  line-height: 1.86;
  color: #777777 !important;
}

.aheto-footer-13 .widget_aheto__info a:hover {
  color: #fff !important;
}

.aheto-footer-13 .widget_aheto__info .widget_aheto__icon {
  color: #EBB113;
}

.aheto-footer-13 .widget-title {
  font-size: 24px;
}

.aheto-footer-13 .widget_nav_menu .menu-item {
  position: relative;
  padding-left: 20px;
}

.aheto-footer-13 .widget_nav_menu .menu-item:before {
  display: block;
  content: "";
  width: 5px;
  height: 5px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: #EBB113;
  border-radius: 5px;
}

.aheto-footer-13 .widget_nav_menu .menu-item a {
  font-size: 14px;
  color: #777777;
}

.aheto-footer-13 .widget_nav_menu .menu-item a:hover {
  color: #fff;
}

.aheto-footer-13 .widget_media_gallery figure {
  position: relative !important;
}

@media screen and (max-width: 991px) {
  .aheto-footer-13 .widget_media_gallery .gallery {
    height: auto !important;
  }
}

.aheto-footer-14__main {
  padding: 120px 0 30px;
}

@media screen and (max-width: 991px) {
  .aheto-footer-14__main {
    padding: 50px 0 0;
  }
}

.aheto-footer-14__main .aht-socials--circle .aht-socials__link {
  width: 45px;
  height: 45px;
  color: #fff;
  line-height: 3em;
  font-size: 14px;
}

.aheto-footer-14 .aht-socials__link {
  color: #fff;
}

.aheto-footer-14 p {
  font-size: 15px;
}

.aheto-footer-15 {
  background-color: #222222;
}

.aheto-footer-15__main {
  padding: 105px 0 75px;
}

@media screen and (max-width: 991px) {
  .aheto-footer-15__main {
    padding: 50px 0 0;
  }
}

.aheto-footer-15 .aht-socials {
  margin-top: 40px;
}

@media screen and (max-width: 991px) {
  .aheto-footer-15 .aht-socials {
    margin-top: 0px;
  }
}

.aheto-footer-15 .aht-socials__link {
  color: #fff;
}

.aheto-footer-15__bottom:before {
  display: block;
  position: absolute;
  width: 100%;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  content: '';
}

.aheto-footer-15 .aheto-footer-15__cr-text {
  font-size: 15px;
}

.home-event-wrap-upc-event {
  background-image: linear-gradient(140deg, #6c207c 0%, #EBB113 100%);
}

.home-event-wrap-upc-event .aheto-btn-container {
  padding-bottom: 5px;
}

.home-event-wrap-tickets {
  background-color: rgba(235, 235, 235, 0.4);
}

.author-bio-wrap {
  background-color: #EBB113;
}

.bg-wrap-c-active {
  background-color: #EBB113;
}

.bg-wrap-c-light {
  background-color: #EBEBEB;
}

.bg-wrap-c-light-cons {
  background-color: #f9f9f9;
}

.bg-wrap-c-grey {
  background-color: #999999;
}

.bg-wrap-c-dark {
  background-color: #111539;
}

.bg-wrap-c-dark-2 {
  background-color: #222222;
}

.bg-wrap-c-alter {
  background-color: #2A74ED;
}

.bg-wrap-c-alter-2 {
  background-color: #FF704F;
}

.bg-wrap-c-alter-3 {
  background-color: #FFC015;
}

hr {
  background: #eeeeee;
  background-image: linear-gradient(to right, rgba(238, 238, 238, 0), #eeeeee, rgba(238, 238, 238, 0));
  height: 1px;
}

.edu-wrap-2 {
  background-image: linear-gradient(to right, #EBB113, #EBB113);
}

.home-edu-wrap-pricing--heading {
  background-color: #EBB113;
}

.aheto-heading__title b {
  color: #EBB113;
}

.aheto-heading__title:after {
  background-color: #2A74ED;
}

.aheto-heading__title span {
  color: #EBB113;
}

.aheto-heading__title--agency span {
  background: linear-gradient(330deg, #EBB113, #2A74ED);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.aheto-heading__desc--drop-caps::first-letter {
  color: #EBB113;
}

.aheto-heading > .aheto-heading__subtitle--alter {
  color: #EBB113;
}

.aheto-heading--divider-t1 {
  position: relative;
}

.aheto-heading--divider-t1 .aheto-heading__title::after {
  background-color: #EBB113;
}

.aheto-heading--divider-t2--color-1 .aheto-heading__title::after {
  background-color: #111539;
}

.aheto-heading--simple .aheto-heading__subtitle {
  color: #EBB113;
}

.aheto-heading--classic .aheto-heading__subtitle {
  color: #EBB113;
}

.aheto-heading--classic .aheto-heading__subtitle:after {
  background-color: #FFC015;
}

.aheto-heading--classic p b {
  color: #111539;
}

.aheto-heading--yoga .aheto-heading__title {
  font-family: "Futura", sans-serif;
  font-size: 50px;
  letter-spacing: 2.5px;
  color: #555555;
}

.aheto-heading--yoga .aheto-heading__subtitle {
  color: #EBB113;
  font-weight: bold;
}

.aheto-heading--yoga .aheto-heading__subtitle.services-subtitle {
  margin-bottom: 5px;
}

.aheto-heading--yoga.motto .aheto-heading__title {
  font-family: "Futura", sans-serif;
  font-size: 30px;
  font-weight: bold;
  line-height: 1.33;
  letter-spacing: 0.4px;
}

.aheto-heading--political .aheto-heading__title span {
  color: #2A74ED;
}

.aheto-heading--construction-tm .aheto-heading__title {
  color: #EBB113;
}

.aheto-heading--construction-tm .aheto-heading__desc {
  font-family: "Source Sans Pro", sans-serif;
}

.aheto-heading--construction .aheto-heading__title {
  color: #222222;
}

@media screen and (max-width: 480px) {
  .aheto-heading--construction .aheto-heading__title {
    font-size: 22px;
  }
}

.aheto-heading--construction_about .aheto-heading__title {
  color: #111539;
}

.aheto-heading--restaurant .aheto-heading__subtitle {
  color: #EBB113;
}

.aheto-heading--restaurant .aheto-heading__title {
  color: #111539;
}

.aheto-heading--restaurant-awards .aheto-heading__title {
  color: #eee;
}

.aheto-heading--restaurant-awards .aheto-heading__desc {
  color: #999999;
}

.aheto-heading--restaurant-speciality .aheto-heading__title {
  color: #fff;
  -webkit-font-smoothing: antialiased;
}

.aheto-heading--single-gallery .aheto-heading__title {
  color: #eee;
}

.aht-heading b {
  color: #EBB113;
}

.aht-heading--travel .aht-heading__subtitle {
  color: #EBB113;
  font-family: "SignPainter", sans-serif, sans-serif;
}

.aht-heading--travel .aht-heading__title {
  font-weight: 800;
  letter-spacing: 4px;
  line-height: 52px;
}

.aht-heading--travel-2 {
  background-position: left 350px center !important;
}

.aht-heading--chr .aht-heading__subtitle {
  color: #EBB113;
}

.aht-heading--travel-video {
  position: relative;
}

.aht-heading--travel-video:before {
  background-image: url("../img/travel/decor-4.png");
  background-repeat: no-repeat;
  background-position: center;
  display: block;
  content: "";
  position: absolute;
  left: -160px;
  top: -30px;
  width: 221px;
  height: 119px;
}

@media screen and (max-width: 767px) {
  .aht-heading--travel-video:before {
    left: initial;
    right: 0;
  }
}

.aht-heading--travel-video .aht-heading__title {
  font-family: "Mukta", sans-serif;
  font-size: 40px;
  font-weight: 800;
  letter-spacing: 4px;
  color: #111539;
  text-transform: none;
  letter-spacing: 3px;
  margin-bottom: 15px;
  text-transform: uppercase;
}

.aht-heading--travel-video .aht-heading__subtitle {
  font-size: 50px;
  font-family: "SignPainter", sans-serif;
  text-transform: lowercase;
}

.aht-heading--travel-video .aht-heading__desc {
  line-height: 1.6;
  margin-top: 44px;
}

@media screen and (min-width: 768px) {
  .aht-heading--travel-video .aht-heading__desc {
    max-width: 350px;
  }
}

.aht-heading--travel-decor {
  position: relative;
}

.aht-heading--travel-decor:after {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  right: 50%;
  height: 120px;
  width: 120px;
  background-position: right center;
  background-repeat: no-repeat;
}

.aht-heading--travel-services:after {
  background-image: url("../img/travel/decor-1.png");
  transform: translate(161px, -50px);
}

.aht-heading--travel-directions:after {
  background-image: url("../img/travel/decor-3.png");
  transform: translate(161px, -50px);
}

.section-yoga .aheto-heading__title {
  word-spacing: 5px;
  letter-spacing: 3px;
}

.aheto-heading--yoga .aheto-heading__title {
  font-family: "Futura", sans-serif;
  font-size: 50px;
  font-weight: bold;
  letter-spacing: 2.5px;
}

.aht-dest__number {
  color: #999999;
}

.aht-tb--trvl .aht-tb__title {
  font-weight: 800;
}

.aht-tb--trvl .aht-tb__subtitle {
  font-family: "Source Sans Pro", sans-serif;
}

.aht-text--classic .aht-text__text {
  font-family: "Source Sans Pro", sans-serif;
}

.aht-text--trvl-2 .aht-text__text {
  font-family: "Roboto", sans-serif;
  letter-spacing: normal;
  font-size: 300px;
  text-shadow: 0px 18px 46px rgba(235, 177, 19, 0.15);
}

@media screen and (max-width: 991px) {
  .aht-text--trvl-2 .aht-text__text {
    font-size: 200px;
  }
}

@media screen and (max-width: 767px) {
  .aht-text--trvl-2 .aht-text__text {
    font-size: 100px;
  }
}

.list li {
  color: #999999;
}

.construction-about-blocks-list li:before {
  background-color: #dba30a;
}

.aheto-portfolio-single-item--construction .aheto-portfolio-single-item__title {
  color: #EBB113;
}

.aheto-portfolio-single-item__cover-icon {
  background-color: #fff;
  color: #111539;
}

.aht-filter--edu .aht-filter__field--cat:after, .aht-filter--edu .aht-filter__field--prc-type:after {
  color: #111539;
}

.aht-filter--edu .aht-filter__icon {
  color: #EBB113;
}

.aht-filter--edu .aht-filter__input, .aht-filter--edu .aht-filter__select {
  color: #111539;
  font-family: "Roboto", sans-serif;
}

.aht-filter--edu .aht-filter__input::placeholder {
  color: #111539;
}

.aht-filter--edu .aht-filter__select option {
  color: #111539;
}

.aht-filter--edu .aht-filter__submit {
  background-color: #111539;
}

.aht-filter--edu .aht-filter__submit:hover {
  background-color: #EBB113;
  color: #111539;
}

.aht-filter-yoga__filter.active .aht-filter-yoga__title {
  color: #EBB113;
}

.aht-filter-yoga__filter.active .aht-filter-yoga__select:before {
  background-color: #EBB113;
}

.aht-filter-yoga__select:after {
  background-color: #EBB113;
}

.aht-filter-yoga__inner {
  justify-content: center;
}

.aht-filter-trvl__select, .aht-filter-trvl__input {
  font-weight: 600;
}

.aht-filter-trvl__field:nth-child(2) select, .aht-filter-trvl__field:nth-child(3) select {
  border-radius: 0;
}

.aht-filter-trvl__field--month:after, .aht-filter-trvl__field--type:after {
  color: #999999;
}

.aht-filter-trvl__field--type .aht-filter-trvl__icon {
  top: 40px;
}

.aht-filter-trvl__field--month .aht-filter-trvl__icon {
  top: 37px;
}

.aht-filter-trvl__field--loc .aht-filter-trvl__icon {
  top: 38px;
}

.aht-filter-trvl__icon {
  color: #2A74ED;
}

.aht-filter-trvl__select {
  color: #999999;
  font-family: "Source Sans Pro", sans-serif;
}

.aht-filter-trvl__select.selected {
  color: #111539;
}

.aht-filter-trvl__select option {
  color: #111539;
}

.aht-filter-trvl__input {
  color: #111539;
  font-family: "Source Sans Pro", sans-serif;
}

.aht-filter-trvl__input::placeholder {
  color: #999999;
}

.aht-filter-trvl__submit {
  background-color: #EBB113;
  font-family: "Source Sans Pro", sans-serif;
}

.aht-filter-trvl__submit:hover {
  background-color: #2A74ED;
}

.aht-info__subtitle.c-active {
  color: #EBB113;
}

.aht-info__subtitle.c-light {
  color: #EBEBEB;
}

.aht-info__subtitle.c-grey {
  color: #999999;
}

.aht-info__subtitle.c-dark {
  color: #111539;
}

.aht-info__subtitle.c-dark-2 {
  color: #111539;
}

.aht-info__subtitle.c-alter {
  color: #2A74ED;
}

.aht-info__subtitle.c-alter-2 {
  color: #FF704F;
}

.aht-info__subtitle.c-alter-3 {
  color: #FFC015;
}

.aht-info__detail-text {
  color: #111539;
}

.aht-info__detail-icon {
  color: #EBB113;
}

.aht-info--yoga-course .color-1 {
  color: #fc81a9 !important;
}

.aht-info--yoga-course .color-2 {
  color: #7fbc48 !important;
}

.aht-info--yoga-course .color-3 {
  color: #ffc326 !important;
}

.aht-info--yoga-course .color-4 {
  color: #FFC015 !important;
}

.aht-info--yoga-course .aht-info__desc {
  font-weight: 400;
}

.aht-info--yoga-course .aht-info__subtitle {
  color: #FF704F;
  font-family: "Source Sans Pro", sans-serif;
  margin-top: 0;
}

.aht-info--yoga-course .aht-info__link:not(:hover) {
  color: #111539;
}

.aht-info--yoga-course .aheto-btn:hover {
  background-color: #EBB113;
  color: #fff;
}

.aht-info--yoga-event .aht-info__link:not(:hover) {
  color: #111539;
}

.aht-info--yoga-event .aht-info__detail--date .aht-info__detail-text:first-line {
  color: #EBB113;
}

.aht-info--yoga-event .aht-info__desc {
  font-weight: 400;
}

.aht-info--yoga-event .aheto-btn:hover {
  background-color: #EBB113;
  color: #fff;
}

.aht-bq-img__bq {
  background-color: #EBB113;
}

.aht-bq-img__bq:before, .aht-bq-img__bq:after {
  color: #b8000b;
  line-height: 100px;
}

.aheto-btn-container--fullwidth .aheto-btn:hover {
  border-color: #111539;
  background-color: #111539;
}

.aheto-btn-evts .aheto-btn--light:hover {
  color: #000 !important;
  background-color: #fff;
}

.aht-cta__title b {
  color: #EBB113;
}

.aht-cta__tel-icon {
  background-color: #EBB113;
}

.aht-cta__tel-icon:after {
  color: #EBB113;
}

.aht-cta__tel:hover {
  color: #EBB113 !important;
}

.aht-cta__tel:hover i {
  background-color: #fff;
}

.aht-cta__tel:hover i:after {
  color: #fff;
}

.aht-cta--simple .aht-cta__link:not(:hover) {
  color: #111539;
}

.aht-cta--classic .aht-cta__link {
  border: 1px solid #EBB113;
}

.aht-cta--classic .aht-cta__link:hover {
  color: #EBB113;
  border: 1px solid #EBB113;
}

.aht-cta--modern .aht-cta__link {
  color: #EBB113;
}

.aht-cta--modern .aht-cta__link.aheto-btn--trans {
  background-color: #EBB113;
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.aht-cta--modern .aht-cta__link.aheto-btn--trans:hover {
  color: #EBB113;
}

.aht-cta--modern .aht-cta__link:hover {
  background-color: #EBB113;
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.aht-cta--t-white .aht-cta__sub-title,
.aht-cta--t-white .aht-cta__title,
.aht-cta--t-white .aht-cta__desc,
.aht-cta--t-white .aht-cta__tel {
  color: #fff;
}

.aht-cta--yoga.motto .aheto-btn {
  background-color: #EBB113;
  border: 1px solid #EBB113;
  color: #fff;
}

.aht-cta--yoga.motto .aheto-btn:hover {
  color: #EBB113;
  background-color: transparent;
  border-color: #EBB113;
}

.aht-cta--yoga .aht-cta__title {
  font-size: 50px;
  font-weight: bold;
  line-height: 1.33;
  text-transform: initial;
  letter-spacing: 0;
}

@media screen and (max-width: 991px) {
  .aht-cta--yoga .aht-cta__title {
    font-size: 40px;
  }
}

@media screen and (max-width: 767px) {
  .aht-cta--yoga .aht-cta__title {
    font-size: 25px;
  }
}

.aht-cta--yoga .aht-cta__sub-title {
  color: #EBB113;
}

.aht-cta--yoga .aht-cta__link:hover {
  border-color: #fff;
  color: #fff;
  background: none;
}

.aht-cta--yoga .aht-cta__links {
  margin-top: 0;
}

.aht-cta--construction .aht-cta__title {
  color: #fff;
}

.aht-cta--construction .aht-cta__title a {
  color: #EBB113;
}

.aht-cta--construction .aht-cta__title a:before {
  background-color: #EBB113;
}

.aht-cta--trvl-2 .aht-cta__subtitle {
  color: #EBB113;
  font-family: "Source Sans Pro", sans-serif;
}

.aht-cta--trvl-2 .aht-cta__form [type=email] {
  color: #111539;
  font-family: "Source Sans Pro", sans-serif;
}

.aht-cta--trvl-2 .aht-cta__form [type=email]::placeholder {
  color: #999999;
}

.aht-cta--trvl-2 .aht-cta__form [type=submit] {
  background-color: #EBB113;
  font-family: "Source Sans Pro", sans-serif;
}

.aht-cta--trvl-2 .aht-cta__form [type=submit]:hover {
  background-color: #2A74ED;
}

.aht-cta--trvl {
  background-color: #EBB113;
}

.aht-cta--business .aht-cta__sub-title {
  position: relative;
  display: inline-block;
}

.aht-cta--business .aht-cta__sub-title:after {
  display: block;
  position: absolute;
  bottom: -8px;
  left: 0;
  width: 100%;
  height: 2px;
  content: '';
  background: #EEEEEE;
}

.aht-cta--chr .aht-cta__title {
  font-family: "Source Sans Pro", sans-serif;
}

.aht-cta--chr .aht-cta__link:hover {
  color: #EBB113;
}

.aht-cta.custom-yoga-btn .aht-cta__links .aht-cta__link:last-child {
  padding: 15px 33px;
}

.aht-cta.custom-yoga-btn .aht-cta__links .aht-cta__link:first-child {
  padding: 16px 41px !important;
}

.aht-td__menu-item {
  font-family: "Open Sans", sans-serif;
}

@media screen and (max-width: 1230px) {
  .aht-td__sb {
    padding-bottom: 0;
  }
}

@media screen and (max-width: 767px) {
  .aht-td__units {
    padding-bottom: 0;
  }
}

@media screen and (max-width: 767px) {
  .aht-td__unit {
    margin-top: 50px;
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 991px) {
  .aht-td__content {
    width: 100%;
    margin-bottom: 50px;
  }
}

.aht-td__unit-title {
  font-weight: 800;
  line-height: 0.6;
}

.aht-td__detail-check {
  color: #2A74ED;
}

@media screen and (max-width: 767px) {
  .aht-td__detail-left, .aht-td__detail-right {
    width: 100% !important;
  }
}

.aht-td__specs {
  position: relative;
}

@media screen and (min-width: 1025px) {
  .aht-td__specs:before, .aht-td__specs:after {
    display: block;
    content: "";
    position: absolute;
    top: 50%;
    left: 24%;
    width: 1px;
    height: 58px;
    background-color: #eee;
    transform: translateY(-50%);
  }
  .aht-td__specs:before {
    left: 24%;
  }
  .aht-td__specs:after {
    left: 54%;
  }
}

.aht-td__spec {
  position: relative;
  padding-left: 30px;
}

.aht-td__spec-icon {
  position: absolute;
  top: 5px;
  left: 0;
}

.aht-td__spec-text {
  font-family: "Open Sans", sans-serif;
}

.aht-td__info {
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  font-weight: 600;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.aht-td__info-icon {
  color: #ffbb49;
  font-size: 24px;
}

.aht-td__tb-star {
  color: #2A74ED;
}

.aht-td__tb__tb-title {
  font-weight: 800;
}

.aht-td__spec-icon {
  color: #2A74ED;
}

.aht-td__spec-text {
  color: #111539;
}

@media screen and (min-width: 768px) {
  .aht-td__menu:before {
    width: 500%;
  }
}

.aht-td__menu:before {
  background-color: #EBEBEB;
}

.aht-td__menu-text {
  color: #111539;
}

.aht-td__menu-text:before {
  background-color: #EBB113;
}

.aht-td__menu-text:hover, .aht-td__menu-text.active {
  color: #EBB113;
}

.aht-td__detail-left {
  width: 56%;
}

.aht-td__detail-right {
  width: 44%;
}

.aht-td__detail-close {
  color: #999999;
}

.aht-td__route-caption .aht-td__route-icon {
  transform: rotate(180deg);
}

.aht-td__route-caption.active .aht-td__route-title {
  color: #EBB113;
}

.aht-td__route-caption.active .aht-td__route-icon {
  transform: rotate(0);
}

.aht-td__route-icon {
  color: #999999;
}

.aht-td__route-desc {
  line-height: 1.6;
}

.aht-td__book-caption--discount:before {
  background-color: #EBB113;
}

.aht-td__book-cost {
  background-image: linear-gradient(24deg, #2A74ED, #EBB113, #EBB113);
}

.aht-td__book-discount {
  font-family: "Source Sans Pro", sans-serif;
  flex-direction: column;
  font-size: 15px;
  line-height: 1.2;
}

.aht-td__book-discount span {
  font-size: 18px;
}

.aht-td__btn {
  flex: 1;
  color: #111539;
  font-family: "Source Sans Pro", sans-serif;
}

.aht-td__btn.active, .aht-td__btn:hover {
  color: #EBB113;
}

.aht-td__element {
  color: #111539;
  font-family: "Source Sans Pro", sans-serif;
  -webkit-appearance: none;
  height: 40px;
}

.aht-td__element:focus {
  border: 1px solid #EBB113;
  box-shadow: 0px 10px 20px 0px rgba(17, 21, 57, 0.07);
}

.aht-td__submit {
  font-family: "Source Sans Pro", sans-serif;
  height: 55px;
  padding: 0 50px;
  line-height: 55px;
  border-radius: 28px;
}

.aht-td__submit:hover {
  border-color: #EBB113;
  background-color: #EBB113;
}

.aht-td__why-icon {
  color: #2A74ED;
  position: absolute;
  top: 2px;
}

.aht-td__why-title {
  line-height: 1.78;
}

.aht-td__why-item {
  position: relative;
  height: 53px;
  line-height: 53px;
}

.aht-td__why-item p {
  padding-left: 30px;
}

.aht-td__element-wrapper {
  width: 100%;
}

.aht-td__element-wrapper .aht-td__element {
  width: 100%;
}

.aht-td__element-wrapper.select-wrapper {
  position: relative;
}

.aht-td__element-wrapper.select-wrapper:before {
  display: block;
  content: "";
  position: absolute;
  right: 20px;
  top: 20px;
  width: 6px;
  height: 1px;
  background-color: #999;
  transform: rotate(40deg);
}

.aht-td__element-wrapper.select-wrapper:after {
  display: block;
  content: "";
  position: absolute;
  right: 16px;
  top: 20px;
  width: 6px;
  height: 1px;
  background-color: #999;
  transform: rotate(-40deg);
}

.aht-td__wrapper-half {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.aht-td__wrapper-half .aht-td__element-wrapper {
  min-width: 150px;
  max-width: 47%;
}

.aht-td__widgets {
  max-width: 400px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.aht-td__widgets .aht-widget-advert {
  height: 340px;
  padding: 50px;
}

.aht-td__widgets .aht-widget-advert__title {
  margin-bottom: 95px;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 20px;
  font-weight: 800;
  line-height: 0.7;
  letter-spacing: 4px;
}

.aht-td__widgets .aht-widget-advert__desc-wrap {
  font-weight: bold;
}

.aht-td__widgets .aht-widget-advert__desc-wrap p {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 18px;
  letter-spacing: 1.8px;
}

.aht-tour--trvl .aht-tour__detail-text {
  font-family: "Source Sans Pro", sans-serif;
}

.aht-tour--trvl .aht-tour__price {
  color: #EBB113;
}

.aht-tour--trvl .aht-tour__link {
  color: #111539;
}

.aht-tour--trvl .aht-tour__link:hover {
  border-color: #EBB113;
  background-color: #EBB113;
}

.aht-tour--trvl-2 .aht-tour__caption {
  box-sizing: border-box;
}

.aht-tour--trvl-2 .aht-tour__promo {
  width: 70px;
  height: 70px;
  background-image: linear-gradient(to left, #fa6742, #ff961a);
  box-sizing: border-box;
  line-height: 1.07;
}

.aht-tour--trvl-2 .aht-tour:hover .aht-tour__promo:hover {
  background-image: linear-gradient(to left, #fa6742, #ff961a);
}

.aht-tour--trvl-2 .aht-tour__link {
  color: #111539;
  background-color: #fff;
  border: 1px solid #eee;
}

.aht-tour--trvl-2 .aht-tour__link:hover {
  color: #fff;
  border-color: #EBB113;
  background-color: #EBB113;
}

.aht-tour--trvl-2 .aht-tour__per {
  font-size: 16px;
}

.aht-tour--trvl .aht-tour__caption {
  box-sizing: border-box;
}

.aht-tour--trvl .aht-tour__icon {
  color: #2A74ED;
}

.aht-tour--trvl .aht-tour__detail-text {
  font-family: "Mukta", sans-serif;
}

.aht-deal--trvl-1 .aht-deal__price {
  color: #EBB113;
}

.aht-deal--trvl-1 .aht-deal__desc {
  color: #999999;
  font-family: "Open Sans", sans-serif, sans-serif;
}

.aht-deal--trvl-2 .aht-deal__promo {
  border-radius: 15px;
  background-image: linear-gradient(to right, #ff961a, #EBB113);
  padding: 4px 10px;
  min-width: 125px;
  font-family: "Mukta", sans-serif;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  color: #fff;
}

.aht-deal--trvl-2 .aht-deal__price {
  color: #EBB113;
  font-size: 24px;
}

.aht-deal--trvl-2 .aht-deal__title {
  font-size: 24px;
}

.aht-deal--trvl-2 .aht-deal__content {
  box-sizing: border-box;
}

.authentication__sign-in, .authentication__sign-up {
  font-family: "Source Sans Pro", sans-serif;
}

.authentication__log-in, .authentication__register {
  font-family: "Source Sans Pro", sans-serif;
}

.aheto-services .c-active {
  color: #EBB113;
}

.aheto-services .c-light {
  color: #EBEBEB;
}

.aheto-services .c-grey {
  color: #999999;
}

.aheto-services .c-dark {
  color: #111539;
}

.aheto-services .c-dark-2 {
  color: #111539;
}

.aheto-services .c-alter {
  color: #2A74ED;
}

.aheto-services .c-alter-2 {
  color: #FF704F;
}

.aheto-services .c-alter-3 {
  color: #FFC015;
}

.aheto-services__link {
  color: #999999;
}

.aheto-services__link:hover {
  color: #EBB113;
}

.aheto-services__label {
  color: #111539;
}

.aheto-services__time {
  background: #2A74ED;
  color: #111539;
}

.aheto-services--events .aheto-services__link {
  color: #999999;
}

.aheto-services--events .aheto-services__link:hover {
  color: #EBB113;
}

.aheto-services--events .aheto-services__label {
  font-family: "Source Sans Pro", sans-serif;
}

.aheto-services--simple .aheto-services__price {
  color: #EBB113;
}

.aheto-services--busns-event .aheto-services__time {
  background-color: #EBB113;
  box-shadow: 0px 0px 32px 0px rgba(235, 177, 19, 0.3);
}

.aheto-services--busns-event .aheto-services__label {
  color: #999999;
}

.aheto-services--busns-event .aheto-services__link {
  color: #EBB113;
}

.aheto-services--busns-event .aheto-services__link:hover {
  color: #2A74ED;
}

.aheto-services--construction .aheto-services__link {
  color: #EBB113;
}

.aheto-services--construction .aheto-services__title {
  color: #222222;
}

.aheto-services--construction-right .aheto-services__content {
  transform: translateY(-70px);
  float: right;
  border-radius: 5px 0 5px 5px;
  max-width: calc(100% - 30px);
}

.aheto-services--construction-right .aheto-services__job {
  color: #EBB113;
  text-transform: uppercase;
}

.aheto-services--construction-news .aheto-services__label {
  color: #999999;
  font-size: 16px;
}

.aheto-services--construction-news .aheto-services__title {
  color: #222222;
}

.aheto-services--construction_servPage .aheto-services__title {
  color: #111539;
}

.aheto-services--chess .aheto-services__link {
  background-color: #EBB113;
}

.aheto-services--chess .aheto-services__link:hover {
  background-color: #2A74ED;
}

.aht-service--edu .aht-service__icon {
  color: #111539;
}

.aht-service--edu .aht-service__link:hover {
  color: #EBB113 !important;
}

.aht-service--edu .aht-service__desc {
  font-family: "Roboto", sans-serif;
}

.aht-service--trvl .aht-service__link:hover {
  color: #2A74ED;
}

.aht-service--trvl .aht-service__desc {
  font-weight: 300;
}

.aht-service--trvl .aheto-btn--underline {
  font-weight: 500;
}

.aht-service--trvl.first .aht-service__img {
  margin-top: 3px;
}

.aht-service--trvl.second .aht-service__img {
  margin-top: 5px;
}

.aht-service--chr .aht-service__link:hover {
  color: #222222;
}

.service--church-wrap-1 .aht-service--chr .aht-service__link:hover {
  color: #222222;
}

.church-ministries-wrap .aheto-services__link {
  color: #EBB113;
}

.church-ministries-wrap .aheto-services__link:hover {
  color: #222222;
}

.wrap-yoga-7 .aheto-services--chess .aheto-services__link {
  border-radius: 25.05px;
  display: inline-block;
  position: relative;
  padding: 16px 41px;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(127, 188, 72, 0.5);
  outline: none;
  background-color: #EBB113;
  color: #fff;
  font-family: 'Gilroy', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 1.15;
}

.wrap-yoga-7 .aheto-services--chess .aheto-services__link:hover {
  color: #7FBC48;
  background-color: transparent;
}

.construction-news-contentWrap .aheto-services__title {
  color: #111539;
}

.aheto-services--agency {
  border-color: #EBEBEB;
}

.aheto-services--agency:hover {
  border-color: #2A74ED;
  top: 0;
}

.aheto-services--agency .aheto-services__caption {
  border-color: #EBEBEB;
}

.aheto-services--agency .aheto-services__caption:after {
  background-color: #2A74ED;
}

.aheto-services--agency .aheto-services__link {
  color: #EBB113;
}

.aheto-toolbar .aht-socials__item a {
  color: #999999;
}

.aheto-toolbar .aht-socials__item a:hover {
  color: #EBB113;
}

.wpml-ls-legacy-dropdown-click a.wpml-ls-item-toggle:after,
.wpml-ls-legacy-dropdown a.wpml-ls-item-toggle:after {
  border: 1px solid #999999;
}

.site-search .search-field {
  color: #111539;
}

.site-search .search-field::-webkit-input-placeholder {
  color: #111539;
}

.site-search .search-field:-moz-placeholder {
  color: #111539;
}

.site-search .search-field::-moz-placeholder {
  color: #111539;
}

.site-search .search-field:-ms-input-placeholder {
  color: #111539;
}

.icons-widget__link {
  color: #111539;
}

.icons-widget__link:hover {
  color: #EBB113;
}

.aht-banner b {
  color: #EBB113;
}

.aht-banner__overlay--2 {
  background: linear-gradient(36deg, #000 0%, #2A74ED 100%);
}

.aht-banner__overlay--3 {
  background: linear-gradient(to right, transparent 10%, black);
}

.aht-banner--with-promo .aht-banner__promo {
  background: linear-gradient(24deg, #2A74ED, #EBB113);
}

.aht-banner--travel-1 .aht-banner__link:not(:hover) {
  color: #EBB113;
}

.aht-banner--travel-2 .aht-banner__sub-title {
  font-family: "Source Sans Pro", sans-serif;
}

.aht-banner--travel-2 .aht-banner__link {
  border-color: #EBB113;
  background-color: #EBB113;
}

.aht-banner--travel-2 .aht-banner__link:hover {
  color: #111539;
}

.aht-banner--travel-3 .aht-banner__sub-title {
  font-family: "Source Sans Pro", sans-serif;
}

.aht-banner--travel-3 .aht-banner__link {
  border-color: #EBB113;
  background-color: #EBB113;
}

.aht-banner--travel-3 .aht-banner__link:hover {
  color: #111539;
}

.aht-banner--yoga .aht-banner__link {
  border-color: #EBB113;
  background-color: #EBB113;
}

.aht-banner--yoga .aht-banner__link:hover {
  color: #111539;
}

.aht-banner--yoga-2 .aht-banner__link:nth-child(odd) {
  border-color: #EBB113;
  background-color: #EBB113;
}

.aht-banner--yoga-2 .aht-banner__link:hover {
  border-color: #111539;
  background-color: #111539;
  color: #fff;
}

.aht-banner--yoga-3 .aht-banner__title {
  color: #EBB113;
}

.aht-banner--yoga-3 .aht-banner__link {
  border-color: #111539;
  background-color: #111539;
}

.aht-banner--yoga-3 .aht-banner__link:nth-child(even) {
  border-color: #111539;
  background-color: #111539;
}

.aht-banner--yoga-3 .aht-banner__link:hover {
  color: #fff;
  border-color: #EBB113;
  background-color: #EBB113;
}

.aht-banner--construction .aht-banner__title span {
  color: #EBB113;
}

.aht-banner--construction .aht-banner__links a:nth-child(odd) {
  border-color: #EBB113;
  background-color: #EBB113;
}

.aht-banner--construction .aht-banner__links a:nth-child(even) {
  color: #222222;
}

.aht-banner--edu--1 .aht-banner__sub-title {
  font-family: "Roboto", sans-serif;
}

.aht-banner--edu--1 .aht-banner__link {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
}

.aht-banner--edu--1 .aht-banner__link:not(:hover) {
  border-color: #EBB113;
  background-color: #EBB113;
  color: #111539;
}

.aht-banner--edu--2 .aht-banner__link {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
}

.aht-banner--edu--2 .aht-banner__link {
  color: #111539;
}

.aht-banner--edu--3 .aht-banner__link:nth-child(odd) {
  color: #111539;
}

.aht-banner--edu--3 .aht-banner__link:hover {
  border-color: #111539;
  background-color: #111539;
}

.aht-banner--edu--3 .aht-banner__link:nth-child(even) {
  border-color: #EBB113;
  background-color: #EBB113;
  color: #111539;
}

.aht-ban--main .aht-ban__video {
  color: #EBB113;
}

.aht-ban--main .aht-ban__video:hover {
  color: #111539;
}

.aht-ban--main-1 .aht-ban__link-prime:hover {
  color: #EBB113;
}

.aht-ban--main-1 .aht-ban__link-second:hover {
  color: #EBB113;
}

.aht-ban--main-1 .aht-ban__link-second:hover:after {
  background-color: #EBB113;
}

.aht-ban--chr .aht-ban__title {
  font-family: "Source Sans Pro", sans-serif;
}

.aht-ban--chr .aht-ban__link:nth-child(odd):hover {
  color: #111539;
}

.aht-ban--chr .aht-ban__link:nth-child(even):hover {
  color: #EBB113;
}

.aht-ban--chr .aht-ban__link:nth-child(even):hover:before {
  background-color: #EBB113;
}

.aht-ban--rest .aht-ban__title {
  font-family: "Source Sans Pro", sans-serif;
}

.aht-ban--rest .aht-ban__link:nth-child(odd):hover {
  color: #111539;
}

.aht-ban--rest .aht-ban__link:nth-child(even):hover {
  color: #EBB113;
}

.aht-ban--rest .aht-ban__link:nth-child(even):hover:before {
  background-color: #EBB113;
}

.aht-ban--rest-third .aht-ban__title {
  font-family: "Catamaran", sans-serif;
}

.swiper--trvl .aht-banner__promo {
  box-sizing: border-box;
  background-image: linear-gradient(to left, #fa6742, #ff961a);
}

.swiper--trvl .switzerland-banner .aht-banner__link {
  color: #222;
}

.swiper--trvl .switzerland-banner .aht-banner__link:hover {
  background-color: #EBB113;
  color: #fff;
}

.swiper--trvl .swiper-button-prev:hover:before,
.swiper--trvl .swiper-button-next:hover:before {
  color: #ddd;
}

.about-us-agency__title .aht-ban__subtitle {
  color: #EBB113;
}

.google-marker:hover {
  color: #EBB113;
}

.aheto-pricing--alternative .aheto-pricing__header {
  background: rgba(235, 177, 19, 0.04);
}

.aheto-pricing--alternative .aheto-pricing__cost {
  color: #EBB113;
}

.aheto-pricing--alternative .aheto-pricing__btn {
  border-color: rgba(153, 153, 153, 0.2);
  color: #EBB113;
}

@media screen and (min-width: 768px) {
  .aheto-pricing--alternative:hover {
    background-color: #EBB113;
  }
  .aheto-pricing--alternative:hover .aheto-pricing__content {
    border-color: #EBB113;
  }
  .aheto-pricing--alternative:hover .aheto-pricing__btn {
    background-color: #fff;
    color: #111539;
  }
  .aheto-pricing--alternative:hover .aheto-pricing__header {
    background-color: #cf9c11;
  }
}

.aheto-pricing .aheto-pricing__description ul li {
  color: #999999;
}

.aheto-pricing .aheto-pricing__description ul li:before {
  background-color: #EBB113;
}

.aheto-pricing--home-saas .aheto-pricing__title {
  color: #EBB113;
}

.aheto-pricing--legend .aheto-pricing__title {
  color: #111539;
  letter-spacing: 0.6px;
  justify-content: start;
  padding-left: 70px;
}

.aheto-pricing__ico {
  color: #EBB113;
}

.aheto-pricing__footer .aheto-btn:hover {
  background-color: #EBB113;
  color: #fff;
}

.aheto-pricing__options-item {
  background: #EBB113;
}

.aheto-pricing--tableHead .aheto-pricing__header {
  background: #EBB113;
}

.aheto-pricing--tableHead .aheto-pricing__list-item:before {
  background: #EBB113;
}

.aheto-pricing--tableHead .aheto-pricing__list-item h6:before {
  color: #EBB113;
}

@media screen and (min-width: 768px) {
  .aheto-pricing--tableColumn:hover .aheto-pricing__header {
    background-color: #cf9c11;
  }
}

.aheto-pricing--tableColumn .aheto-pricing__btn:hover {
  background-color: #EBB113;
  color: #fff;
}

.aheto-pricing--tableColumn .aheto-pricing__header {
  background-color: #EBB113;
}

.aheto-pricing--home-event {
  background-color: transparent;
}

.aheto-pricing--home-event .aheto-pricing__header {
  border-bottom: 1px dotted #EBB113;
}

.aheto-pricing--home-event .aheto-pricing__cost-value {
  color: #EBB113;
}

.aheto-pricing--home-event .aheto-pricing__detail::after {
  border-bottom: 1px solid rgba(235, 177, 19, 0.2);
}

.aheto-pricing--home-event .aheto-pricing__btn {
  color: #fff !important;
}

.aheto-pricing--home-event .aheto-pricing__btn:hover {
  border-color: #EBB113;
  background-color: #EBB113;
  color: #fff !important;
}

.aheto-pricing--home-education {
  border: 1px solid #e2aa12;
  background-color: #EBB113;
}

@media screen and (max-width: 991px) {
  .aheto-pricing--home-education {
    margin: 0;
  }
  .aheto-pricing--home-education:hover {
    background-color: #EBB113;
  }
}

.aheto-pricing--home-education .aheto-pricing__description {
  color: #EBB113;
}

.aheto-pricing--home-education .aheto-pricing__price-period {
  color: #EBB113;
}

.aheto-pricing--home-education .aheto-pricing__btn:hover {
  border-color: #EBB113;
  background-color: #EBB113;
  color: #EBB113;
}

.aheto-pricing--home-construction .aheto-pricing:hover .aheto-pricing__footer .aheto-btn {
  color: #fff;
  background: #EBB113;
}

.aheto-pricing--home-construction .aheto-pricing__title {
  color: #222222;
  margin: 0 0 29px;
}

.aheto-pricing--home-construction .aheto-pricing__cost-value {
  color: #EBB113;
  font-family: "Source Sans Pro", sans-serif;
}

.aheto-pricing--home-construction .aheto-pricing__description li:after {
  background-color: #EBB113;
}

.aheto-pricing--home-construction .aheto-pricing__footer .aheto-btn:hover {
  color: #fff;
  background: #EBB113;
}

.aht-pricing--business .aht-pricing__head-content {
  border-right: 1px solid #e3e3e3;
}

.aht-pricing--business .aht-pricing__head-caption {
  background-color: #e3e3e3;
}

@media screen and (min-width: 768px) {
  .aht-pricing--business .aht-pricing__item:hover .aht-pricing__item-caption {
    background-color: #2A74ED;
  }
}

.aht-pricing--business .aht-pricing__item-caption {
  background-color: #75a5f3;
}

.aht-pricing--business .aht-pricing__item-link {
  border-color: #e6e6e6;
  background-color: #e6e6e6;
  color: #999999;
}

@media screen and (max-width: 991px) {
  .aht-pricing--business .aht-pricing__item-link {
    border-color: #EBB113;
    background-color: #EBB113;
    color: #fff;
  }
}

.aht-pricing--business .aht-pricing__item-link:hover {
  border-color: #EBB113;
  background-color: #EBB113;
  box-shadow: 0px 0px 32px 0px rgba(235, 177, 19, 0.3);
}

.aht-pricing--yoga .aht-pricing__item {
  box-shadow: 0px 4px 35px 0px rgba(0, 0, 0, 0.1);
}

.aht-pricing--yoga .aht-pricing__item .aht-pricing__item-item {
  position: relative;
}

.aht-pricing--yoga .aht-pricing__item .aht-pricing__item-item:before {
  left: -5px;
  position: absolute;
  color: #EBB113;
}

.aht-pricing--yoga .aht-pricing__item--active:before {
  background-color: #2A74ED;
}

.aht-pricing--yoga .aht-pricing__item-price {
  color: #EBB113;
}

.aht-pricing--yoga .aht-pricing__item-desc {
  font-family: "Source Sans Pro", sans-serif;
}

.aht-pricing--yoga .aht-pricing__item-item {
  color: #999999;
}

.aht-pricing--yoga .aht-pricing__item-link {
  color: #222222;
}

.aht-pricing--yoga .aht-pricing__item-link:hover {
  background-color: #111539;
}

.aht-pricing--edu .aht-pricing__item {
  background-color: #EBEBEB;
}

.aht-pricing--edu .aht-pricing__item:hover {
  box-shadow: 0px 0px 46.98px 7.02px rgba(34, 34, 34, 0.05);
}

.aht-pricing--edu .aht-pricing__icon {
  color: #111539;
}

.aht-pricing--edu .aht-pricing__desc {
  color: #EBB113;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
}

.aht-pricing--edu .aht-pricing__price {
  color: #111539;
  font-family: "Roboto Slab", serif;
}

.aht-pricing--edu .aht-pricing__per {
  color: #111539;
  font-family: "Karla", sans-serif;
}

.aht-pricing--edu .aht-pricing__link {
  font-family: "Source Sans Pro", sans-serif;
}

.aht-pricing--edu .aht-pricing__link:hover {
  border-color: #EBB113;
  background-color: #EBB113;
  color: #111539;
}

.aht-pricing--trvl .aht-pricing__inner {
  background-color: #EBEBEB;
}

.aht-pricing--trvl .aht-pricing__desc {
  color: #999999;
  font-weight: 500;
}

.aht-pricing--trvl .aht-pricing__price {
  color: #EBB113;
}

.aht-pricing--trvl .aht-pricing__link:hover {
  border-color: #EBB113;
  background-color: #EBB113;
}

.aht-pricing--trvl .aht-pricing__content {
  padding-top: 23px;
}

.aht-pricing--trvl .aht-pricing__list-item {
  padding: 15px 0;
  letter-spacing: 0.3px;
  color: #999;
}

.corporate {
  color: #999999;
}

.aheto-time-scale__item:before {
  background: rgba(235, 177, 19, 0.15);
}

.aheto-time-scale__item:after {
  background: #EBB113;
}

.aheto-time-scale__title {
  color: #EBB113;
}

.aheto-instagram-gallery .aheto-btn.aheto-btn--light:hover {
  background: #EBB113;
  border-color: #EBB113;
}

.aheto-instagram-gallery--edu .aheto-instagram-gallery__link .aheto-btn {
  font-family: "Roboto", sans-serif;
}

.aheto-instagram-gallery--edu .aheto-instagram-gallery__link-text {
  font-family: "Roboto", sans-serif;
  color: #999999;
  font-weight: normal;
}

.aheto-single-item:before {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 40px;
  line-height: 1.2;
}

.aheto-single-item__ico {
  box-shadow: 0px 15px 30px 0px rgba(235, 177, 19, 0.1);
}

.aheto-single-item__ico:hover {
  background: #EBB113;
}

.aheto-single-item--withIco h1:before,
.aheto-single-item--withIco h2:before,
.aheto-single-item--withIco h3:before,
.aheto-single-item--withIco h4:before,
.aheto-single-item--withIco h5:before,
.aheto-single-item--withIco h6:before {
  color: #EBB113;
}

.aheto-single-item--bgIco:hover {
  background-color: #EBB113;
  box-shadow: 0px 15px 30px 0px rgba(235, 177, 19, 0.1);
}

@media screen and (max-width: 1230px) {
  .service-single .swiper-button-prev {
    left: 10px;
  }
  .service-single .swiper-button-next {
    right: 10px;
  }
}

.aheto-content-block * {
  transition: 0.2s all;
}

.aheto-content-block__ico {
  color: #EBB113;
}

.aheto-content-block-hover hr {
  background: #EBB113;
}

.aheto-content-block--bgImg:hover:after {
  background-color: #EBB113;
}

.aheto-content-block--slider:before {
  color: #EBB113;
  font-family: "Source Sans Pro", sans-serif;
}

.aheto-content-block--slider:hover:before {
  color: #fff;
}

.aheto-content-block--slider:hover {
  background-color: #EBB113;
}

.aheto-content-block--slider:hover .aheto-content-block__title {
  color: #fff;
}

.aheto-content-block--slider:hover .aheto-content-block__info-text {
  color: #fff;
}

.aheto-content-block--slider:hover .aheto-content-block__ico {
  color: rgba(255, 255, 255, 0.1);
}

.aheto-content-block--list:before {
  color: #EBB113;
  font-family: "Source Sans Pro", sans-serif;
}

.aheto-content-block--list-icon:hover .aheto-content-block__ico {
  background-color: #EBB113;
}

.aheto-content-block--list-icon .aheto-content-block__title:before {
  color: #EBB113;
  font-family: "Source Sans Pro", sans-serif;
}

.aheto-content-block--business .aheto-btn {
  color: #EBB113;
}

.aheto-content-block--business .aheto-btn:hover {
  color: #2A74ED;
}

.aheto-content-block--construction {
  border-bottom: 1px solid #EBEBEB;
  margin: 0 20px;
}

.aheto-content-block--construction.active:after {
  background-color: #EBB113;
}

.aheto-content-block--restaurant {
  background-color: #fff;
  box-shadow: 0px 15px 38px 0 rgba(0, 0, 0, 0.08);
}

.aheto-content-block--restaurant .aheto-content-block__ico {
  background-color: #EBB113;
}

.aheto-content-block--restaurant .aheto-content-block__ico:before {
  color: #fff;
}

.aheto-content-block--restaurant .aheto-content-block__title {
  color: #111539;
}

.aheto-content-block--restaurant .aheto-content-block__info-text {
  color: #999999;
}

.tm--default {
  padding: 26px 25px 40px;
  transition: 0.2s all;
  background: #fff;
}

.tm--default:after {
  transition: 0.2s all;
  background: #EBB113;
}

.tm--default:hover {
  box-shadow: 0px 15px 30px 0px rgba(235, 177, 19, 0.1);
}

.tm--default .tm__avatar {
  width: 50px;
  height: 50px;
}

.tm--default .tm__name {
  margin: 0;
  color: #111539;
  font-size: 18px;
}

.tm--default .tm__stars i {
  color: #ffc015;
  font-size: 16px;
}

.tm--home-event .tm__stars i {
  color: #ffc015;
}

.tm--barbershop .tm__stars .ion {
  color: #EBB113;
}

.tm--barbershop .tm__position {
  color: #EBB113;
}

.tm--business .tm__content {
  box-shadow: 0.5px 4px 50px 0 rgba(0, 0, 0, 0.07);
}

.tm--construction .tm__name {
  color: #222222;
}

.tm--yoga .tm__text {
  font-weight: 400;
}

.tm--yoga .tm__stars {
  margin-bottom: 10px;
}

.tm--yoga .tm__name {
  letter-spacing: 0.8px;
}

.tm--yoga .tm__position {
  color: #EBB113;
  font-family: "Source Sans Pro", sans-serif;
  letter-spacing: 0;
}

.tm--yoga .tm__content:before {
  color: #FFC015;
}

@media screen and (min-width: 1025px) {
  .tm--edu:hover {
    background-color: #111539;
  }
  .tm--edu:hover:before {
    color: #EBB113;
  }
}

.tm--edu-2 .tm__content {
  box-shadow: 0px 0px 15px 10px rgba(17, 21, 57, 0.08);
}

.tm--edu-2 .tm__text, .tm--edu-2 .tm__position {
  font-family: "Roboto", sans-serif;
}

.tm--restaurant .tm__stars {
  color: #fff;
}

.tm--restaurant .tm__name {
  color: #fff;
}

.aht-tm--trvl .aht-tm__stars {
  color: #2A74ED;
}

.swiper .swiper-slide:hover .aht-tm--agency {
  background-color: #EBB113;
}

.swiper .swiper-slide:hover .aht-tm__desc {
  color: #fff;
}

.swiper .swiper-slide:hover .aht-tm__name {
  color: #fff;
}

.swiper .swiper-slide:hover .aht-tm__pos {
  color: #fff;
}

.aheto-tab__list-link span {
  color: #111539;
}

.aheto-tab__box--overlay-1:before {
  background-color: rgba(235, 177, 19, 0.8);
}

.aheto-tab__box--overlay-2:before {
  background: linear-gradient(to right, #EBB113, #2A74ED);
}

.aheto-tab__box--overlay-3:before {
  background: linear-gradient(to right, transparent, transparent 35%, #EBB113);
}

@media screen and (max-width: 1230px) {
  .aheto-tab__box--overlay-3:before {
    background: linear-gradient(to right, transparent, #EBB113);
  }
}

@media screen and (max-width: 991px) {
  .aheto-tab__box--overlay-3:before {
    background-color: #EBB113;
  }
}

.aheto-tab__box--overlay-4:before {
  background-color: rgba(0, 0, 0, 0.5);
}

.aheto-tab--simple .aheto-tab__head {
  box-shadow: 0px 10px 30px 0px rgba(235, 177, 19, 0.1);
}

.aheto-tab--simple .aheto-tab__list-link {
  border-right: 1px solid rgba(17, 21, 57, 0.1);
  border-bottom: 1px solid rgba(17, 21, 57, 0.1);
  color: #999999;
}

.aheto-tab--simple .aheto-tab__list-link:hover {
  border-color: rgba(235, 177, 19, 0.33);
  background: #EBB113;
  box-shadow: 1.169px 3.825px 15.66px 2.34px rgba(235, 177, 19, 0.2);
}

.aheto-tab--simple .aheto-tab__list-item.active a {
  border-color: rgba(235, 177, 19, 0.33);
  background: #EBB113;
  box-shadow: 1.169px 3.825px 15.66px 2.34px rgba(235, 177, 19, 0.2);
}

.aheto-tab--business .aheto-tab__content {
  background-color: #111539;
}

.aheto-tab--business .aheto-tab__list-link span {
  color: #EBB113;
}

.aheto-tab--business .aheto-tab__list-item {
  background-color: #f6f3f3;
}

.aheto-tab--business .aheto-tab__list-link {
  color: #2A74ED;
}

.aheto-tab--business .aheto-btn--light.aheto-btn--trans:hover {
  color: #2A74ED;
}

.aheto-tab--construction-home .aheto-tab__list-item.active .aheto-tab__list-link, .aheto-tab--construction-news .aheto-tab__list-item.active .aheto-tab__list-link {
  color: #000;
}

.aheto-tab--construction-home .aheto-tab__list-item.active .aheto-tab__list-link:after, .aheto-tab--construction-news .aheto-tab__list-item.active .aheto-tab__list-link:after {
  background-color: #EBB113;
}

.aheto-tab--construction-home .aheto-tab__list-link, .aheto-tab--construction-news .aheto-tab__list-link {
  color: #999999;
}

.aheto-tab--construction-news .aheto-tab__list-item.active .aheto-tab__list-link {
  color: #111539;
}

.aheto-tab--event {
  box-shadow: 0 0 73px 0 rgba(0, 0, 0, 0.08);
}

.aheto-tab--event .aheto-tab__head {
  box-shadow: none;
  overflow: initial;
}

.aheto-tab--event .aheto-tab__list-item.active {
  position: relative;
}

.aheto-tab--event .aheto-tab__list-item.active::after {
  position: absolute;
  bottom: -29px;
  left: 50%;
  width: 0;
  height: 0;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  border-top: 15px solid #EBB113;
  border-right: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-left: 15px solid transparent;
  content: '';
}

.aheto-tab--event .aheto-tab__list-item .event-title {
  display: block;
  color: #111539;
  font-size: 18px;
}

.aheto-tab--event .aheto-tab__list-item .event-date {
  color: #999999;
  display: block;
  font-size: 12px;
}

.aheto-tab--event .aheto-tab__list-item.active .event-title, .aheto-tab--event .aheto-tab__list-item.active .event-date, .aheto-tab--event .aheto-tab__list-item:hover .event-title, .aheto-tab--event .aheto-tab__list-item:hover .event-date {
  color: #EBEBEB;
}

.aheto-tab--event .aheto-tab__list-item.active a {
  box-shadow: none;
}

.aheto-tab--edu .aheto-tab__list-item.active {
  font-family: "Source Sans Pro", sans-serif;
}

.aheto-tab--edu .aheto-tab__list-item.active::after {
  content: '';
  border-top-color: #111539;
}

.aheto-tab--edu .aheto-tab__list-item .event-title {
  font-family: "Source Sans Pro", sans-serif;
}

.aheto-tab--edu .aheto-tab__list-item .event-date {
  font-family: "Roboto", sans-serif;
}

.aht__sidebar .search-field {
  font-family: "Source Sans Pro", sans-serif;
  background-color: #fff;
  color: #111539;
  font-weight: normal;
}

.aht__sidebar .search-field::placeholder {
  color: #999999;
}

.aht__sidebar .search-field:focus {
  border: 1px solid #EBB113;
  box-shadow: 0px 15px 30px 0px rgba(235, 177, 19, 0.1);
}

.aht__sidebar .search-submit {
  background-color: #fff;
}

.aht__sidebar .aht-widget-posts__buttons {
  box-shadow: 0px 15px 30px 0px rgba(235, 177, 19, 0.1);
}

.aht__sidebar .aht-widget-posts__button {
  border-bottom: 2px solid #fff;
  background-color: #fff;
  color: #111539;
  font-family: "Source Sans Pro", sans-serif;
}

.aht__sidebar .aht-widget-posts__button.active {
  border-bottom: 2px solid #EBB113;
  color: #EBB113;
}

.aht__sidebar .aht-widget-posts__title {
  color: #111539;
}

.aht__sidebar .aht-widget-posts__title:hover {
  color: #EBB113;
}

.aht__sidebar .calendar {
  background: #fff;
  box-shadow: 0px 15px 30px 0 rgba(235, 177, 19, 0.1);
  background-color: #fff;
}

.aht__sidebar .calendar table tr th {
  font-family: "Source Sans Pro", sans-serif;
  color: #333333;
  padding: 10px 0px;
}

.aht__sidebar .calendar table tr th:first-child {
  color: #e04040;
}

.aht__sidebar .calendar table tr td {
  font-family: "Source Sans Pro", sans-serif;
}

.aht__sidebar .calendar table tr td.active {
  background: #2A74ED;
  color: #fff;
}

.aht__sidebar .calendar table tr td:first-child {
  color: #e04040;
}

.aht__sidebar .calendar .calendar_header {
  color: #cccccc;
}

.aht__sidebar .calendar .calendar_header .month {
  color: #EBB113;
}

.aht__sidebar .calendar .calendar_header .month i {
  color: #cccccc;
}

.aht__sidebar .widget_tags .widget-title {
  font-family: "Source Sans Pro", sans-serif;
  color: #333333;
}

.aht__sidebar .widget_tags a {
  background: #fff;
  border: 1px solid #eee;
  color: #999999;
}

.aht__sidebar .widget_tags a:hover {
  color: #fff;
  background: #EBB113;
}

.aht__sidebar .aht-widget-advert__title {
  font-family: "Source Sans Pro", sans-serif;
}

.aht__sidebar .aht-widget-advert__link:hover {
  color: #fff;
}

.aht__sidebar .aht-twitter {
  background-color: #fff;
  box-shadow: 0px 15px 30px 0px rgba(235, 177, 19, 0.1);
}

.aht__sidebar .aht-twitter .tweets {
  font-family: "Source Sans Pro", sans-serif;
}

.aht__sidebar .aht-twitter .tweets span {
  color: #999999;
}

.aht__sidebar .aht-twitter__follow:after {
  background: #EBB113;
}

.aht__sidebar .aht-twitter__link {
  background: #EBB113;
  color: #fff;
}

.aht__sidebar .aht-twitter__post, .aht__sidebar .aht-twitter__hashtag {
  color: #111539;
}

.aht__sidebar .aht-twitter__date {
  color: #bbb;
}

.aht__sidebar .aht-twitter .btn {
  border-top: 1px solid #eeeeee;
}

.aht__sidebar .aht-twitter .btn-left {
  border-right: 1px solid #eeeeee;
}

.aht__sidebar .aht-twitter .btn-left:hover i {
  color: #EBB113;
}

.aht__sidebar .aht-twitter .btn-right:hover i {
  color: #EBB113;
}

.aheto-progress__chart-circle {
  stroke: #EBB113;
}

.aheto-progress__chart-icon {
  color: #EBB113;
}

.aheto-progress__chart-number {
  color: #111539;
}

.aheto-progress__bar-icon {
  color: #EBB113;
}

.aheto-progress__bar-val {
  background-color: #EBB113;
}

.aheto-progress--simple .aheto-progress__chart-circle {
  stroke: #EBB113;
}

.aheto-progress--construction .aheto-progress__bar {
  background-color: #e1e1e1;
}

.aheto-blockquote--author-bio {
  background-color: #EBB113;
}

.aheto-blockquote--portfolio-01 {
  background-color: #EBB113;
}

.aheto-blockquote--restaurant blockquote {
  background-color: #fff;
}

.aheto-blockquote--restaurant .aheto-heading--double_item_top .aheto-heading__subtitle {
  color: #EBB113;
}

.aheto-blockquote--restaurant .aheto-heading--double_item_top .aheto-heading__title {
  color: #111539;
}

.aheto-blockquote--restaurant .aheto-heading--double_item_top .aheto-heading__desc {
  color: #999999;
}

.aheto-blockquote--restaurant .aheto-heading--double_item_bottom .aheto-heading__desc {
  color: #999999;
}

.aheto-form--default .wpcf7-form-control {
  color: #111539;
}

.aheto-form--default .wpcf7-form-control::placeholder {
  color: #999999;
}

.aheto-form--default .wpcf7-form-control:focus {
  border: 1px solid #EBB113;
  box-shadow: 0px 15px 30px 0px rgba(235, 177, 19, 0.1);
}

.aheto-form--default .wpcf7-form-control:focus::placeholder {
  color: #111539;
}

.aheto-form--default .wpcf7-form-control.wpcf7-select {
  color: #999999;
  background: #fff;
}

.aheto-form--default .wpcf7-form-control.wpcf7-select:focus {
  border: 1px solid #EBB113;
}

.aheto-form--default .wpcf7-form-control.wpcf7-select.cf7-selected {
  color: #111539;
}

.aheto-form--default .wpcf7-not-valid-tip {
  color: #EBB113;
}

.aheto-form--default .wpcf7-form-control.wpcf7-submit {
  border-color: rgba(235, 177, 19, 0);
  background-color: #EBB113;
  box-shadow: 1.169px 3.825px 15.66px 2.34px rgba(235, 177, 19, 0.2);
}

.aheto-form--default .wpcf7-form-control.wpcf7-submit:hover {
  background-color: transparent;
  color: #EBB113;
}

.aheto-form--default .wpcf7-not-valid-tip {
  color: #EBB113;
}

.aheto-form--default .wpcf7-response-output {
  color: #EBB113;
}

.aheto-form--political .wpcf7-form-control:focus {
  border: 1px solid #2A74ED;
  box-shadow: 0px 15px 30px 0px rgba(42, 116, 237, 0.1);
}

.aheto-form--political .wpcf7-form-control.wpcf7-select:focus {
  border: 1px solid #2A74ED;
}

.aheto-form--political .wpcf7-form-control.wpcf7-submit {
  box-shadow: none;
  border: 1px solid #EBB113;
  font-weight: 700;
  font-size: 18px;
  padding: 20px 30px;
}

.aheto-form--pop-up .wpcf7-form .wpcf7-form-control {
  color: #EBB113;
}

.aheto-form--pop-up .wpcf7-form .wpcf7-form-control::placeholder {
  color: #111539;
}

.aheto-form--pop-up .wpcf7-form .wpcf7-form-control:focus {
  border-bottom: 1px solid #111539;
}

.aheto-form--pop-up .wpcf7-form .wpcf7-not-valid-tip {
  color: #EBB113;
}

.aheto-form--pop-up .wpcf7-form .wpcf7-form-control.wpcf7-select {
  color: #111539;
}

.aheto-form--pop-up .wpcf7-form .wpcf7-form-control.wpcf7-select.cf7-selected {
  color: #EBB113;
}

.aheto-form--pop-up .wpcf7-form .wpcf7-form-control.wpcf7-submit {
  border-color: rgba(235, 177, 19, 0.5);
  background-color: #EBB113;
}

.aheto-form--pop-up .wpcf7-form .wpcf7-not-valid-tip {
  color: #EBB113;
}

.aheto-form--pop-up .wpcf7-form .wpcf7-response-output {
  color: #EBB113;
}

.aheto-form--saas .wpcf7-form .wpcf7-form-control {
  color: #111539;
}

.aheto-form--saas .wpcf7-form .wpcf7-form-control.wpcf7-select {
  color: #999999;
}

.aheto-form--saas .wpcf7-form .wpcf7-form-control.wpcf7-select.cf7-selected {
  color: #111539;
}

.aheto-form--saas .wpcf7-form .wpcf7-form-control::placeholder {
  color: #999999;
}

.aheto-form--saas .wpcf7-form .wpcf7-form-control.wpcf7-submit {
  border-color: #2A74ED;
  background: #2A74ED;
  color: #fff;
  box-shadow: 1.169px 3.825px 15.66px 2.34px rgba(42, 116, 237, 0.2);
}

.aheto-form--saas .wpcf7-form .wpcf7-form-control.wpcf7-submit:hover {
  background-color: transparent;
  color: #2A74ED;
}

.aheto-form--business .wpcf7-form-control {
  color: #111539;
}

.aheto-form--business .wpcf7-form-control:focus {
  border: 1px solid #2A74ED;
  box-shadow: 0px 15px 30px 0px rgba(42, 116, 237, 0.1);
}

.aheto-form--business .wpcf7-form-control.wpcf7-submit {
  border-color: #2A74ED;
  background: #2A74ED;
  box-shadow: 0px 0px 32px 0px rgba(42, 116, 237, 0.3);
}

.aheto-form--business .wpcf7-form-control.wpcf7-submit:hover {
  color: #2A74ED;
}

.aheto-form--business .wpcf7-form-control.wpcf7-select {
  border-radius: 0px;
}

.aheto-form--business .wpcf7-form-control.wpcf7-select:focus {
  border: 1px solid #2A74ED;
  box-shadow: 0px 15px 30px 0px rgba(42, 116, 237, 0.1);
}

.aheto-form--business .wpcf7-form-control.wpcf7-select.selected {
  color: #111539;
}

.aheto-form--yoga .wpcf7-form-control {
  color: #111539;
  -webkit-appearance: none;
}

.aheto-form--yoga .wpcf7-form-control:focus {
  border-color: #EBB113;
}

.aheto-form--yoga .wpcf7-form-control.wpcf7-select.selected {
  color: #111539;
}

.aheto-form--yoga .wpcf7-form-control-wrap.Budget {
  position: relative;
}

.aheto-form--yoga .wpcf7-form-control-wrap.Budget:before {
  display: block;
  content: "";
  position: absolute;
  right: 30px;
  top: 32px;
  width: 6px;
  height: 1px;
  background-color: #676767;
  transform: rotate(40deg);
}

.aheto-form--yoga .wpcf7-form-control-wrap.Budget:after {
  display: block;
  content: "";
  position: absolute;
  right: 26px;
  top: 32px;
  width: 6px;
  height: 1px;
  background-color: #676767;
  transform: rotate(-40deg);
}

.aheto-form--yoga .wpcf7-form-control.wpcf7-select {
  -webkit-appearance: none;
}

.aheto-form--yoga .wpcf7-form-control.wpcf7-textarea {
  border-radius: 10px;
}

.aheto-form--chr .wpcf7-form-control {
  color: #999999;
}

.aheto-form--chr .wpcf7-form-control::placeholder {
  color: #999999;
}

.aheto-form--chr .wpcf7-form-control:focus {
  color: #111539;
  border: 1px solid #EBB113;
  box-shadow: 0px 15px 30px 0px rgba(235, 177, 19, 0.1);
}

.aheto-form--restaurant .wpcf7-form-control:focus {
  box-shadow: none;
}

.aheto-form--restaurant .form-bth-holder .wpcf7-form-control.wpcf7-submit {
  border: 2px solid #80261b;
  background-color: #80261b;
  color: #fff;
  box-shadow: none;
}

.aheto-form--restaurant .form-bth-holder .wpcf7-form-control.wpcf7-submit:hover {
  background-color: #f7f7f7;
  color: #80261b;
}

.aheto-footer-5 .mc4wp-form-fields {
  height: 130px;
}

.aheto-form-email .mc4wp-form-fields input[type=email] {
  color: #111539;
}

.aheto-form-email .mc4wp-form-fields input[type=email]::placeholder {
  color: #999999;
}

.aheto-form-email--event .mc4wp-form .mc4wp-form-fields input[type=email] {
  color: #999999;
}

.aheto-form-email--event .mc4wp-form .mc4wp-form-fields input[type=email]::placeholder {
  color: #999999;
}

.aheto-form-email--event .mc4wp-form .mc4wp-form-fields input[type=submit] {
  background-color: #EBB113;
}

.aheto-form-email--education .mc4wp-form .mc4wp-form-fields input[type=email] {
  color: #999999;
}

.aheto-form-email--education .mc4wp-form .mc4wp-form-fields input[type=email]::placeholder {
  color: #999999;
}

.aheto-form-email--education .mc4wp-form .mc4wp-form-fields input[type=submit] {
  background-color: #EBB113;
  color: #111539;
}

.aheto-form-email--business .mc4wp-form-fields {
  background-color: #FFC015;
}

.aheto-form-email--business .mc4wp-form-fields input[type=submit] {
  color: #EBB113;
}

@media screen and (max-width: 450px) {
  .aheto-form-email--business .mc4wp-form-fields input[type=submit] {
    border: 1px solid;
  }
}

.aheto-form-email--business .mc4wp-form-fields input:hover[type=submit] {
  color: #2A74ED;
}

.aht-form--edu .wpcf7-form-control-wrap .wpcf7-form-control {
  color: #999999;
  font-family: "Roboto", sans-serif;
}

.aht-form--edu .wpcf7-form-control-wrap .wpcf7-form-control::placeholder {
  color: #999999;
  font-family: "Roboto", sans-serif;
}

.aht-form--edu .wpcf7-form-control-wrap .wpcf7-form-control:focus {
  border-color: #111539;
  box-shadow: 0px 15px 30px 0px rgba(17, 21, 57, 0.1);
}

.aht-form--edu .wpcf7-submit {
  border-color: #111539;
  background-color: #111539;
  font-family: "Roboto", sans-serif;
  letter-spacing: 1.4px;
}

.aht-form--edu .wpcf7-submit:hover {
  border-color: #EBB113;
  background-color: #EBB113;
  color: #111539;
}

.aht-form--trvl .Budget {
  position: relative;
}

.aht-form--trvl .Budget::after {
  font-family: Ionicons;
  content: '\f123';
  font-size: 10px;
  position: absolute;
  top: 21px;
  right: 23px;
  color: #999999;
  pointer-events: none;
}

.aht-form--trvl .wpcf7-form-control {
  color: #111539;
}

.aht-form--trvl .wpcf7-form-control::placeholder {
  color: #999999;
}

.aht-form--trvl .wpcf7-form-control:focus {
  border-color: #EBB113;
  box-shadow: 0px 15px 30px 0 rgba(235, 177, 19, 0.1);
}

.aht-form--trvl .wpcf7-select {
  color: #999999;
  height: 60px;
  padding-top: 0;
  padding-bottom: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.aht-form--trvl .wpcf7-select.selected {
  color: #111539;
}

.aht-form--trvl .wpcf7-select option {
  color: #111539;
}

.aht-form--trvl .wpcf7-submit {
  background-color: #EBB113;
  color: #fff;
  font-family: "Source Sans Pro", sans-serif;
}

.aht-form--trvl .wpcf7-submit:hover {
  background-color: #2A74ED;
}

.church-sermons-det-wrap .aheto-form--default .wpcf7-form-control.wpcf7-submit {
  box-shadow: 0 0 32px 0 rgba(235, 177, 19, 0.3);
}

.construction-contact-form .aheto-form .wpcf7-submit {
  box-shadow: none;
}

.construction-contact-form .aheto-form .wpcf7-submit:hover {
  border: 1px solid #EBB113;
}

.construction-contact-form .aheto-form .Budget:after {
  color: #999999;
}

.aht-breadcrumbs__item:before {
  color: #999999;
}

.aht-breadcrumbs--edu {
  border-top: 1px solid #eee;
  padding-top: 23px;
  padding-bottom: 21px;
}

.aht-breadcrumbs--edu .aht-breadcrumbs__link, .aht-breadcrumbs--edu .aht-breadcrumbs__item {
  font-family: "Roboto", sans-serif;
}

.aht-breadcrumbs--yoga .aht-breadcrumbs__item {
  color: #999999;
  font-family: "Roboto", sans-serif;
}

.aht-breadcrumbs--yoga .aht-breadcrumbs__link {
  color: #EBB113;
}

.aheto-socials__icon:hover {
  color: #EBB113;
}

.aheto-socials--circle .aht-socials__link {
  border: 1px solid #eeeeee;
  color: #999999;
}

.aheto-socials--circle .aht-socials__link:hover {
  border-color: #EBB113;
  background-color: #EBB113;
}

.aheto-socials--circle .active {
  border-color: #EBB113;
  background-color: #EBB113;
}

.aheto-socials--i-color .aht-socials__link {
  color: #999999;
}

.aheto-socials--events .aht-socials__link {
  color: #fff !important;
}

.aheto-socials--events .aht-socials__link:hover {
  color: #EBB113 !important;
}

.aheto-socials__item a:hover {
  border-color: #EBB113;
  background-color: #EBB113;
}

.aht-socials__link {
  color: #999999;
}

.aht-socials .aht-btn--dark {
  color: #000;
}

.aht-socials--circle .aht-socials__link {
  border: 1px solid rgba(153, 153, 153, 0.2);
}

.aht-socials--circle .aht-socials__link:hover {
  background-color: #EBB113;
}

.aht-socials--edu .aheto-socials__link .icon {
  color: #111539;
}

@media screen and (max-width: 570px) {
  .aheto-footer-5 .aht-socials {
    text-align: center;
  }
}

.aht-socials--yoga-details.aht-socials--circle {
  margin-top: 0;
  margin-bottom: 0;
}

.aht-socials--yoga-details .aheto-socials__link {
  height: 50px;
  width: 50px;
  border: 1px solid #eee;
  border-radius: 50%;
  margin-right: 10px;
  margin-left: 0;
  display: inline-block;
  float: left;
  position: relative;
  padding-top: 12px;
}

.aht-socials--yoga-details .aheto-socials__link i {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.aht-socials--yoga-details .aheto-socials__link:last-child {
  margin-right: 0;
}

.aht-socials--yoga-details .aheto-socials__link:hover {
  background-color: #EBB113;
  border-color: #EBB113;
}

.aht-socials--yoga-details .aheto-socials__link:hover i {
  color: #fff;
}

.aheto-header-7__aside-socials .aht-socials__link {
  color: #fff;
}

.aheto-contact--home-event .aheto-contact__info {
  color: #111539;
}

.aheto-contact--home-event .aheto-contact__icon {
  color: #EBB113;
}

.aheto-contact--home-event .aheto-contact__link {
  color: #111539;
}

.aheto-contact--home-event .aheto-contact__link:hover {
  text-shadow: 0px 0px 0.5px #111539;
}

.aheto-contact--home-event__content {
  background-color: #fff;
}

.aheto-contact--home-event__content .aheto-pop-up-btn {
  color: #fff !important;
}

.aheto-contact--home-event__content .aheto-pop-up-btn:hover {
  color: #EBB113 !important;
}

.aheto-contact--simple .aheto-contact__icon {
  color: #EBB113;
}

.aheto-contact--simple .aheto-contact__info {
  color: #999999;
}

.aheto-contact--simple .aheto-contact__link:hover {
  color: #EBB113;
}

.aheto-contact--modern .aheto-contact__link, .aheto-contact--sidebar .aheto-contact__link {
  color: #111539;
}

.aheto-contact--modern .aheto-contact__link:hover, .aheto-contact--sidebar .aheto-contact__link:hover {
  color: #EBB113;
}

.aheto-contact--multi-loc .aheto-contact__icon {
  color: #EBB113;
}

.aheto-contact--multi-loc .aheto-contact__link {
  color: #999999;
}

.aheto-contact--author-bio .aheto-contact__mail {
  color: #EBB113;
}

.aheto-contact--author-bio .aheto-contact__tel {
  color: #999999;
}

.aheto-contact--saas .aheto-contact__info {
  color: #999999;
}

.aheto-contact--business .aheto-contact__title {
  color: #2A74ED;
}

.aheto-contact--business .aheto-contact__title:hover {
  color: #EBB113;
}

.aheto-contact--business .aheto-contact__link-map {
  color: #EBB113;
}

.aheto-contact--business .aheto-contact__link-map.aheto-btn--underline:hover {
  color: #2A74ED;
}

.aheto-contact--business .aheto-contact__icon {
  color: #d29900;
}

.aheto-contact--business .aheto-contact__info {
  color: #111539;
}

.aheto-contact--business .aheto-contact__link {
  color: #111539;
}

.aheto-contact--business .aheto-contact__link--email {
  color: #2A74ED;
}

.aht-contact--edu .aht-contact__subtitle {
  color: #EBB113;
  font-family: "Roboto", sans-serif;
  letter-spacing: 1.4px;
}

.aht-contact--edu .aht-contact__link {
  font-family: "Roboto", sans-serif;
  letter-spacing: 1.4px;
}

.aht-contact--edu .aht-contact__link:hover {
  color: #EBB113;
}

.aht-contact--edu .aht-contact__email, .aht-contact--edu .aht-contact__tel {
  font-family: "Source Sans Pro", sans-serif;
  letter-spacing: normal;
}

.aht-contact--yoga .aht-contact__icon {
  margin-bottom: 13px;
}

.aht-contact--yoga .aht-contact__icon-inner {
  color: #EBB113;
}

.aht-contact--yoga .aht-contact__touch-inner {
  color: #999999;
  font-weight: 400;
}

.aht-contact--trvl .aht-contact__icon {
  color: #2A74ED;
}

.aht-contact--trvl .aht-contact__text {
  color: #999999;
}

.aht-contact--trvl .aht-contact__text--link:hover {
  color: #EBB113;
}

.aht-contact--trvl .aht-contact__link:hover, .aht-contact--trvl .aht-contact__marker:hover {
  color: #2A74ED;
}

.aht-contact--trvl .aht-contact__link:hover:after, .aht-contact--trvl .aht-contact__marker:hover:after {
  background-color: #2A74ED;
}

.aht-contact--restaurant-contact-right .aht-contact__icon-wrap {
  background-color: #EBB113;
}

.aht-contact--restaurant-contact-right .aht-contact__title {
  color: #111539;
}

.aht-socials__link:hover {
  color: #EBB113;
}

.church-contact-us-wrap .aht-heading__title {
  color: #333333;
}

.aheto-form--yoga .wpcf7-form-control.wpcf7-submit {
  margin-top: 34px;
  box-shadow: none;
}

.aheto-form--yoga .wpcf7-form-control.wpcf7-submit:hover {
  border-color: #EBB113;
}

.aheto-accordion__title.active {
  border-bottom: 2px solid #EBB113;
}

.construction-careersWrap .aheto-accordion__plus:before {
  color: #111539;
}

.aheto-counter--classic .aheto-counter__number {
  color: #EBB113;
}

.aheto-counter--modern .aheto-counter__icon {
  color: #EBB113;
}

.aheto-counter--modern .aheto-counter__number {
  color: #111539;
}

.aheto-counter--divider::after {
  background-color: rgba(17, 21, 57, 0.1);
}

.aheto-counter--yoga .aheto-counter__number {
  font-family: 'Gilroy', sans-serif;
  color: #EBB113;
}

.aheto-counter--yoga .aheto-counter__desc {
  font-weight: 400;
}

.aheto-counter--yoga.aheto-counter--active .aheto-counter__number {
  position: relative;
}

.aheto-counter--yoga.aheto-counter--active .aheto-counter__number:before {
  color: #EBB113;
}

.aheto-counter--yoga.aheto-counter--active .aheto-counter__number:after {
  position: absolute;
  left: 100px;
  top: -15px;
  display: block;
  content: "+";
  font-size: 30px;
  font-family: 'Gilroy', sans-serif;
}

.aheto-counter--edu-1 .aheto-counter__number {
  color: #EBB113;
}

.aheto-counter--edu-2 .aheto-counter__number {
  color: #EBB113;
}

.aheto-counter--edu-2 .aheto-counter__title {
  font-family: "Roboto", sans-serif;
  letter-spacing: 1.6px;
}

.aheto-counter--simple {
  background-color: #EBB113;
}

.aheto-counter--restaurant .aheto-counter__number {
  color: #EBB113;
}

.aheto-counter--restaurant .aheto-counter__title {
  color: #3c3c3c;
}

.aheto-counter--restaurant .aheto-counter__icon-holder {
  background-color: #f9f9f9;
}

.aheto-counter--agency .aheto-counter__number {
  color: #EBB113;
}

.aheto-counter--agency .aheto-counter__desc {
  color: #111539;
}

.aheto-video__play {
  background: #EBB113;
}

.aheto-video__play:before {
  border: 4px solid #EBB113;
}

.aheto-video__pause {
  background: #EBB113;
}

.aht-video--trvl .aht-video__link {
  color: #111539;
}

.aht-video--trvl .aht-video__link:hover {
  color: #EBB113;
}

.aht-video--construction .aht-video__link {
  color: #fff;
  background-color: #EBB113;
}

.aht-video--construction .aht-video__link:hover {
  color: #EBB113;
  background-color: #fff;
}

.aheto-member__link:hover {
  color: #EBB113;
}

.aheto-member__icon:hover {
  color: #EBB113;
}

.aheto-member--home-page .aheto-member__contact {
  box-shadow: 0px 11px 30px 0px rgba(235, 177, 19, 0.1);
}

.aheto-member--modern .aheto-member__link:hover {
  color: #EBB113;
}

.aheto-member--home-event .aheto-member__img-holder:after {
  background-color: rgba(17, 21, 57, 0.7);
}

@media screen and (max-width: 767px) {
  .aheto-member--home-event .aheto-member__icon-plus {
    color: #111539;
  }
}

.aheto-member--home-education .aheto-member__position {
  color: #EBB113;
  font-family: "Roboto", sans-serif;
  letter-spacing: 1.5px;
}

.aheto-member--home-education .aheto-member__icon {
  color: #111539;
}

.aheto-member--home-education .aheto-member__icon:hover {
  color: #EBB113;
}

.aheto-member--home-education .aheto-member__link {
  color: #111539;
}

.aheto-member--home-education .aheto-member__link:hover {
  color: #EBB113;
}

.aheto-member--home-education .aheto-member__desc {
  font-family: "Roboto", sans-serif;
}

.aheto-member--home-education .aheto-member__link-plus {
  color: #999999;
}

.aheto-member--home-education .aheto-member__link-plus:hover {
  border: 1px solid #111539;
  background-color: #111539;
}

.aheto-member--saas .aheto-member__contact:after, .aheto-member--saas .aheto-member__contact:before {
  background: rgba(17, 21, 57, 0.6);
}

.aheto-member--saas .aheto-member__link:hover {
  color: #EBB113;
}

.aheto-member--barbershop .aheto-member__name {
  color: #EBB113;
}

.aheto-member--dark .aheto-member__name {
  color: #EBB113;
}

.aheto-member--border:hover {
  box-shadow: 0px 15px 30px 0px rgba(235, 177, 19, 0.1);
}

.aheto-member--border::after {
  background-color: #EBB113;
}

.aheto-member--border-2 .aheto-member__link:hover {
  color: #EBB113;
}

@media screen and (min-width: 481px) {
  .aheto-member--border-2:hover {
    box-shadow: 0px 15px 30px 0px rgba(42, 116, 237, 0.1);
  }
  .aheto-member--border-2::after {
    background-color: #EBB113;
  }
}

.aheto-member--business {
  border: 1px solid #FFC015;
}

@media screen and (min-width: 992px) {
  .aheto-member--business:hover {
    box-shadow: 0px 15px 30px 0px rgba(235, 177, 19, 0.1);
  }
  .aheto-member--business:hover .aheto-member__img {
    box-shadow: 0px 15px 30px 0px rgba(235, 177, 19, 0.1);
  }
}

.aheto-member--business .aheto-member__position {
  color: #EBB113;
}

.aheto-member--business .aheto-member__link-plus {
  border: 1px solid #bdbdbd;
}

.aheto-member--business .aheto-member__link-plus:hover {
  border-color: #EBB113;
  background-color: #EBB113;
}

.aheto-member--business .aheto-member__icon-plus {
  color: #bdbdbd;
}

.aheto-member--business .aheto-member__link {
  color: #bdbdbd;
}

.aheto-member--business .aheto-member__link:hover {
  color: #EBB113;
}

.aheto-member--yoga .aheto-member__position {
  background-color: #EBB113;
}

.aheto-member--yoga .aheto-member__position.c-2 {
  background-color: #2A74ED;
}

.aheto-member--yoga .aheto-member__position.c-3 {
  background-color: #FF704F;
}

.aheto-member--yoga .aheto-member__position.c-4 {
  background-color: #FFC015;
}

.aheto-member--yoga .aheto-member__desc {
  font-weight: 400;
}

.aheto-member--yoga .aheto-btn--dark-2 .aheto-member__icon:hover {
  color: #222;
}

.aheto-member--yoga .aheto-btn--trans i {
  color: #ccc;
}

.aheto-member--yoga .aheto-btn--trans i:hover {
  color: #707070;
}

.aheto-member--restaurant .aheto-member__name {
  color: #EBB113;
}

.aheto-member--restaurant:hover .aheto-member__img-holder {
  transition: ease 0.5s;
  background-color: #EBB113;
}

.aht-team--t1:hover {
  box-shadow: 0px 6px 9px 0px rgba(0, 0, 0, 0.3);
}

.aht-team--t1 .aheto-socials__link:hover i {
  color: #2A74ED;
}

.aht-team--edu .aht-team__pos, .aht-team--construction .aht-team__pos {
  color: #EBB113;
  font-family: "Roboto", sans-serif;
}

.aht-team--edu .aht-team__desc, .aht-team--construction .aht-team__desc {
  font-family: "Roboto", sans-serif;
}

.aht-team--edu:hover .aht-team__caption, .aht-team--construction:hover .aht-team__caption {
  background-color: #111539;
}

.aht-team--edu:hover .aht-team__img:before, .aht-team--construction:hover .aht-team__img:before {
  background-color: rgba(0, 0, 0, 0.2);
}

.aht-team--edu:hover .aht-team__desc, .aht-team--construction:hover .aht-team__desc {
  color: rgba(255, 255, 255, 0.6);
}

.aht-team--edu:hover .aht-team__socials .aheto-socials__link .icon, .aht-team--construction:hover .aht-team__socials .aheto-socials__link .icon {
  color: #fff;
}

.aht-team--edu:hover .aht-team__socials .aheto-socials__link .icon:hover, .aht-team--construction:hover .aht-team__socials .aheto-socials__link .icon:hover {
  color: #EBB113;
}

.aht-team--construction .aht-team__pos {
  color: #ffb92c;
}

.aht-team--t1:before {
  background-color: #EBB113;
}

.aht-team-more {
  background-color: #fff;
}

.aht-team-more .aht-team__header-number {
  color: #EBB113;
}

.aht-team-more .aht-team__header-plus {
  color: #EBB113;
}

.aht-team-more .aht-team__header-text {
  color: #999999;
}

.aht-team-more .aht-team__details-link {
  color: #111539;
}

.aht-team-more .aht-team__details-link:hover {
  color: #EBB113;
}

.about-church-team-wrap-1 .aheto-member--home-page .aheto-member__name,
.about-church-team-wrap-1 .aheto-member--home-page .aheto-member__position,
.church-ministries-wrap .aheto-member--home-page .aheto-member__name,
.church-ministries-wrap .aheto-member--home-page .aheto-member__position,
.church-events-wrap .aheto-member--home-page .aheto-member__name,
.church-events-wrap .aheto-member--home-page .aheto-member__position {
  font-family: "Roboto", sans-serif;
}

.about-church-team-wrap-1 .aheto-member--home-page .aheto-member__contact,
.church-ministries-wrap .aheto-member--home-page .aheto-member__contact,
.church-events-wrap .aheto-member--home-page .aheto-member__contact {
  box-shadow: 0px 15px 30px 0 rgba(42, 116, 237, 0.1);
}

.aht-author__pos {
  color: #999;
}

.yoga .aht-author__img-holder {
  margin-bottom: 40px;
}

.author-yoga .aht-author__pos {
  color: #999;
}

.aheto-404 .fil1 {
  fill: #f3d071;
}

.aheto-404 .fil2 {
  fill: #EBB113;
}

.aheto-404__wrap .aheto-btn.aheto-btn--outline:hover {
  color: #fff;
}

.events-place--item-content::before {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-100%, -50%);
  width: 0;
  height: 0;
  content: '';
  border-style: solid;
  border-width: 30px 40px 30px 0;
  border-color: transparent #ffffff transparent transparent;
}

.events-place--item-text {
  color: #999999;
}

.events-place--item-price .price-value {
  color: #EBB113;
}

.events-place--item-details {
  color: #111539;
}

.events-place--item-details:hover {
  color: #EBB113;
}

@media screen and (max-width: 1200px) {
  .events-place--item-content::before {
    content: none;
  }
}

.aht-table {
  width: 100%;
}

.aht-table__title {
  color: #111539;
}

.aht-table__head-item {
  color: #EBB113;
}

.aht-table__body-row:nth-child(odd) {
  background-color: rgba(255, 248, 251, 0.6);
}

.aht-table--edu .aht-table__content-cat {
  display: inline-block;
}

.aht-table--edu .aht-table__header {
  color: #111539;
  font-family: "Source Sans Pro", sans-serif;
}

.aht-table--edu .aht-table__head-item {
  font-family: "Roboto", sans-serif;
}

.aht-table--edu .aht-table__body-row:nth-child(odd) {
  background-color: rgba(248, 248, 251, 0.6);
}

.aht-table--edu .aht-table__body-cell {
  font-family: "Roboto", sans-serif;
}

.aht-table--edu .aht-table__body-cell:nth-last-child(-n+3) {
  color: #999999;
  font-size: 15px;
}

.label {
  background-color: #2A74ED;
  color: #fff;
}

.search-form i {
  color: #222;
}

.search-form__input {
  font-family: "Roboto", sans-serif;
  color: #999999;
}

.search-form__input::placeholder {
  font-size: inherit;
  color: #999999;
}

.aht-table-classes {
  background-color: #fff;
}

.aht-table-classes .aht-table__head {
  background-color: #111539;
}

.aht-table-classes .aht-table__head-item {
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  letter-spacing: 1.2px;
}

.aht-table-classes .aht-table__title {
  font-family: "Source Sans Pro", sans-serif;
}

.aht-table-classes .aht-table__header-link {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  color: #595955;
  letter-spacing: 1.4px;
  position: relative;
}

.aht-table-classes .aht-table__header-link i {
  font-size: 20px;
  color: #EBB113;
}

.aht-table-classes .aht-table__header-link::after {
  content: '';
  font-family: "ElegantIcons";
  position: absolute;
  right: 1px;
  bottom: -5px;
  width: 85%;
  height: 1px;
  background-color: #ccc;
}

.aht-table-classes .aht-table__header-link:hover {
  color: #EBB113;
}

.aht-table-classes .aht-table__header-link:hover::after {
  background-color: #EBB113;
}

.aht-table-classes .aht-table__body-row:nth-child(odd) {
  background-color: #fff;
}

.aht-table-classes .aht-table__body-time {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: #999999;
}

.aht-table-classes .aht-table__content-cat {
  background-color: #111539;
  font-family: "Roboto", sans-serif;
  color: #fff;
  padding: 6px 22px;
  font-size: 10px;
  font-weight: 500;
  border-radius: 5px;
  margin-bottom: 15px;
  letter-spacing: 1px;
}

.aht-table-classes .aht-table__content-type {
  color: #555555;
  font-size: 14px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  margin-bottom: 30px;
}

.aht-table-classes .aht-table__content-time {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 1.2px;
  text-align: center;
  color: #999999;
  margin-bottom: 25px;
}

.aht-table-classes .aht-table__content-teacher {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #999999;
}

.aht-table-classes .c-dark {
  background-color: #111539;
}

.aht-table-classes .c-grey {
  background-color: #999999;
}

.aht-table-classes .c-active {
  background-color: #EBB113;
}

.aht-table-classes .c-alter {
  background-color: #2A74ED;
}

.aht-table-classes .c-alter-2 {
  background-color: #FF704F;
}

.aht-table-classes .c-alter-3 {
  background-color: #FFC015;
}

.hidden-cell {
  background-color: #f9f9f9;
  border: none;
}

.class-table-yoga {
  background-image: url("../img/yoga/home-yoga/bitmap.png");
  background-repeat: no-repeat;
  background-position: center top;
  padding-top: 40px;
  margin-top: -40px;
}

.class-table-yoga .aht-table__head-item {
  padding-top: 16px;
  padding-bottom: 16px;
}

.class-table-yoga .aht-table__body-cell {
  padding-top: 22px;
  padding-bottom: 10px;
}

.class-table-yoga .aht-table-classes .aht-table__head {
  background-color: #EBB113;
}

.class-table-yoga .aht-table--edu .aht-table__body-cell:first-child {
  padding-left: 57px;
  padding-right: 59px;
}

.class-table-yoga .aht-table-classes .aht-table__head-item:first-child {
  padding-left: 0;
  padding-right: 0;
  text-align: center;
  font-family: "Gilroy", sans-serif, sans-serif;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1px;
  color: #ffffff;
}

.class-table-yoga .aht-table-classes .aht-table__content-cat {
  height: 20px;
  line-height: 21px;
  display: inline-block;
  padding: 0 10px;
  border-radius: 15px;
  font-family: "Futura", sans-serif, sans-serif;
  font-size: 9px;
  font-weight: bold;
  letter-spacing: 1px;
  color: #fff;
}

.class-table-yoga .aht-table__body-cell {
  padding-top: 0;
  padding-bottom: 0;
  height: 180px;
  box-sizing: border-box;
  background-color: #fff;
}

.class-table-yoga .aht-table__body-cell:not(.hidden-cell):not(.aht-table__body-time):hover {
  box-shadow: 0 0 20px 5px rgba(0, 0, 0, 0.1);
}

.class-table-yoga .aht-table__body-cell:not(.aht-table__body-time) {
  padding-left: 10px;
  padding-right: 10px;
  width: 150px;
}

.class-table-yoga .aht-table-classes .aht-table__body-time {
  padding-left: 0;
  padding-right: 0;
  box-sizing: border-box;
  width: 160px;
}

.class-table-yoga .aht-table-classes .aht-table__body-row:nth-child(odd),
.class-table-yoga .aht-table-classes .aht-table__body-row:nth-child(even) {
  background-color: transparent;
}

.class-table-yoga .hidden-cell {
  background-color: transparent;
}

.class-table-yoga .aht-table__body {
  background-color: #EBEBEB;
}

.class-table-yoga .aht-table__content-type {
  font-size: 14px;
  font-weight: bold;
  font-family: "Futura", sans-serif, sans-serif;
  color: #111539;
}

.class-table-yoga .aht-table__content-time {
  font-family: "Gilroy", sans-serif, sans-serif;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: normal;
  color: #999999;
}

.class-table-yoga .aht-table__content-teacher {
  font-family: "Gilroy", sans-serif, sans-serif;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: normal;
  color: #999999;
}

.swiper--yoga-class-table .swiper-button-prev,
.swiper--yoga-class-table .swiper-button-next {
  box-shadow: none !important;
  border: 1px solid #dddddd !important;
  height: 40px !important;
  width: 60px !important;
  line-height: 40px !important;
  font-size: 10px !important;
  top: 85px;
}

.swiper--yoga-class-table .swiper-button-prev:hover,
.swiper--yoga-class-table .swiper-button-next:hover {
  background-color: #EBB113 !important;
  border-color: #EBB113 !important;
}

.swiper--yoga-class-table .swiper-button-prev {
  border-radius: 20px 0 0 20px !important;
}

.swiper--yoga-class-table .swiper-button-next {
  border-radius: 0 20px 20px 0 !important;
}

.class-table-yoga .aht-table__title {
  font-size: 30px !important;
}

@media screen and (max-width: 767px) {
  .class-table-yoga .aht-table__title {
    font-size: 23px !important;
  }
}

.aht-calendar__head {
  background-color: #111539;
}

.aht-calendar__head-item {
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  letter-spacing: 1.2px;
}

.aht-calendar__title {
  font-family: "Source Sans Pro", sans-serif;
  color: #111539;
}

.aht-calendar__header-link {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  color: #595955;
  letter-spacing: 1.4px;
  position: relative;
}

.aht-calendar__header-link i {
  font-size: 20px;
  color: #EBB113;
}

.aht-calendar__header-link::after {
  content: '';
  font-family: "ElegantIcons";
  position: absolute;
  right: 1px;
  bottom: -5px;
  width: 85%;
  height: 1px;
  background-color: #ccc;
}

.aht-calendar__header-link:hover {
  color: #EBB113;
}

.aht-calendar__header-link:hover::after {
  background-color: #EBB113;
}

.aht-calendar__number {
  font-family: "Roboto", sans-serif;
  color: #999999;
  text-align: right;
}

.aht-calendar__cat-item {
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  color: #fff;
  padding: 3px 15px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 5px;
  margin-bottom: 1px;
  letter-spacing: normal;
  position: relative;
}

.aht-calendar__body-cell {
  width: 170px;
  height: 170px;
  padding-top: 0;
  padding-bottom: 0;
}

.aht-calendar .c-dark {
  background-color: #111539;
}

.aht-calendar .c-grey {
  background-color: #999999;
}

.aht-calendar .c-active {
  background-color: #EBB113;
}

.aht-calendar .c-alter-3 {
  background-color: #FFC015;
}

body .swiper-button-prev {
  color: rgba(235, 177, 19, 0.2);
}

body .swiper-button-prev:hover {
  color: #EBB113;
}

body .swiper-button-next {
  color: rgba(235, 177, 19, 0.2);
}

body .swiper-button-next:hover {
  color: #EBB113;
}

body .swiper-pagination-bullet-active {
  background-color: #EBB113;
}

body .swiper-pagination-progressbar-fill {
  background-color: #EBB113;
}

body .swiper--events-posts .swiper-pagination-bullet-active {
  background-color: #999999;
}

body .swiper--home-event .swiper-button-prev, body .swiper--home-event .swiper-button-next {
  border: 1px solid rgba(17, 21, 57, 0.1);
}

body .swiper--home-event .swiper-button-prev i:before, body .swiper--home-event .swiper-button-next i:before {
  color: #111539;
}

body .swiper--home-event .swiper-button-prev:hover, body .swiper--home-event .swiper-button-next:hover {
  background-color: #111539;
}

body .swiper--shop-item .swiper-button-prev,
body .swiper--shop-item .swiper-button-next {
  color: #999999;
}

body .swiper--shop-item .gallery-thumbs .swiper-slide-active > div {
  border: 1px solid #111539;
}

body .swiper--banner-hp .swiper-button-prev:hover i, body .swiper--banner-hp .swiper-button-next:hover i {
  color: #111539;
}

body .swiper--edu .swiper-button-prev, body .swiper--edu .swiper-button-next {
  color: #111539;
}

body .swiper--edu .swiper-button-prev i, body .swiper--edu .swiper-button-next i {
  color: #111539;
}

body .swiper--edu .swiper-button-prev:hover:hover, body .swiper--edu .swiper-button-next:hover:hover {
  background-color: #111539;
  color: #EBB113;
}

body .swiper--edu .swiper-button-prev:hover::before, body .swiper--edu .swiper-button-next:hover::before {
  color: #EBEBEB;
}

body .swiper--edu .swiper-pagination-bullet-active {
  background-color: #111539 !important;
}

body .swiper--edu-tm .swiper-pagination-bullet-active {
  background-color: #111539 !important;
}

body .swiper--edu-courses .swiper-slide > div {
  height: 100%;
}

body .swiper--edu-courses .swiper-button-prev, body .swiper--edu-courses .swiper-button-next {
  color: #111539;
  font-size: 16px;
}

body .swiper--edu-courses .swiper-button-prev:hover, body .swiper--edu-courses .swiper-button-next:hover {
  background-color: #111539;
  color: #EBEBEB;
}

body .swiper--edu-posts .swiper-pagination-bullet {
  margin: 0 5px;
}

body .swiper--edu-posts .swiper-pagination-bullet-active {
  background-color: #111539;
}

body .swiper--edu-class-table {
  background-color: #fff;
}

body .swiper--edu-events .swiper-pagination-progressbar-fill {
  background-color: #111539;
}

body .swiper--edu-events .swiper-button-next, body .swiper--edu-events .swiper-button-prev {
  color: #111539;
}

body .swiper--edu-events .swiper-button-next:hover, body .swiper--edu-events .swiper-button-prev:hover {
  opacity: 0.5;
}

body .swiper--edu-images .swiper-button-prev i, body .swiper--edu-images .swiper-button-next i {
  color: #111539;
}

body .swiper--edu-images .swiper-button-prev:hover, body .swiper--edu-images .swiper-button-next:hover {
  background-color: #111539;
}

body .swiper--edu-blog .swiper-button-prev::before, body .swiper--edu-blog .swiper-button-next::before {
  color: #fff;
}

body .swiper--rest-blog .swiper-button-prev::before, body .swiper--rest-blog .swiper-button-next::before {
  color: #fff;
}

body .swiper--websites .swiper-slide img {
  box-shadow: 0px 10px 30px 0px rgba(235, 177, 19, 0.1);
}

body .swiper--saas {
  width: 100%;
}

body .swiper--saas .swiper-button-prev,
body .swiper--saas .swiper-button-next {
  color: #cccccc;
  top: calc(50% - 43px);
}

@media screen and (max-width: 1024px) {
  body .swiper--saas .swiper-button-prev,
  body .swiper--saas .swiper-button-next {
    display: none;
  }
}

body .swiper--testimonials .swiper-pagination-bullet-active {
  background: rgba(17, 21, 57, 0.4);
}

body .swiper--trvl .swiper-button-next, body .swiper--trvl .swiper-button-prev {
  color: #111539;
}

body .swiper--trvl .swiper-button-next:hover, body .swiper--trvl .swiper-button-prev:hover {
  color: #999999;
}

body .swiper--trvl-3 .swiper-button-prev, body .swiper--trvl-3 .swiper-button-next {
  color: #111539;
}

body .swiper--trvl-3 .swiper-button-prev:hover, body .swiper--trvl-3 .swiper-button-next:hover {
  background-color: #EBB113;
}

body .swiper--business .swiper-container {
  padding: 30px;
}

body .swiper--business .swiper-pagination-bullet {
  border: 1px solid #2A74ED;
}

body .swiper--business .swiper-pagination-bullet-active {
  background-color: #2A74ED;
}

body .swiper--vert-pag .swiper-pagination-bullet {
  border: 1px solid #999999;
}

body .swiper--vert-pag .swiper-pagination-bullet-active {
  background-color: #999999;
}

body .swiper--yoga-2 .swiper-button-prev:before {
  background-image: url("../img/yoga/home-yoga/ion-ios-arrow-thin-left-ionicons.png");
}

body .swiper--yoga-2 .swiper-button-next:before {
  background-image: url("../img/yoga/home-yoga/ion-ios-arrow-thin-right-ionicons.png");
}

body .swiper--yoga-2 .swiper-button-prev, body .swiper--yoga-2 .swiper-button-next {
  background-color: #f7f7f7;
  border: 1px solid #f7f7f7;
  background-repeat: no-repeat;
  background-position: center;
}

body .swiper--yoga-2 .swiper-button-prev:hover, body .swiper--yoga-2 .swiper-button-next:hover {
  background: none;
}

body .swiper--yoga-2 .swiper-button-prev:before, body .swiper--yoga-2 .swiper-button-next:before {
  content: "";
  width: 16px;
  height: 10px;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

body .swiper--yoga-2 .swiper-button-prev i, body .swiper--yoga-2 .swiper-button-next i {
  color: #111539;
}

body .swiper--constrution-service .swiper-button-prev, body .swiper--constrution-service .swiper-button-next {
  border: 1px solid #d9d9d9;
  color: #222222;
}

body .swiper--constrution-service .swiper-button-prev:hover, body .swiper--constrution-service .swiper-button-next:hover {
  background-color: #eeeeee;
  border: 1px solid #eeeeee;
}

body .swiper--twitter .swiper-button-prev, body .swiper--twitter .swiper-button-next {
  color: #999999;
}

body .swiper--twitter .swiper-button-prev:hover, body .swiper--twitter .swiper-button-next:hover {
  box-shadow: 0px 5px 10px 0px rgba(17, 21, 57, 0.1);
}

body .swiper--chr-2 .swiper-pagination-bullet {
  border: 1px solid #c2c2c2;
}

body .swiper--chr-2 .swiper-pagination-bullet-active {
  background-color: #929292;
}

body .swiper--constrution .swiper-button-prev, body .swiper--constrution .swiper-button-next {
  color: #fff;
}

body .swiper--constrution .swiper-button-prev:hover, body .swiper--constrution .swiper-button-next:hover {
  color: #ebb113;
  opacity: 1;
}

body .swiper--chr-3 .swiper-pagination-bullet {
  border: 1px solid #222222;
}

body .swiper--chr-3 .swiper-pagination-bullet-active {
  background-color: #222222;
}

body .swiper--chr-3 .swiper-button-prev:hover, body .swiper--chr-3 .swiper-button-next:hover {
  color: #EBB113;
}

body .swiper--restaurant .swiper-button-prev {
  background-color: #EBB113;
  color: #fff;
}

body .swiper--restaurant .swiper-button-prev:hover {
  background-color: #fff;
  color: #111539;
}

body .swiper--restaurant .swiper-button-next {
  background-color: #fff;
  color: #111539;
}

body .swiper--restaurant .swiper-button-next:hover {
  background-color: #EBB113;
  color: #fff;
}

.swiper.home-banner .swiper-button-prev:hover {
  color: #111539;
}

.swiper.home-banner .swiper-button-next:hover {
  color: #111539;
}

body {
  font-family: "Source Sans Pro", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #111539;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;
  letter-spacing: 0;
}

h1 {
  font-size: 50px;
  line-height: 1.2;
}

h2 {
  font-size: 40px;
  line-height: 1.2;
}

h3 {
  font-size: 30px;
  line-height: 1.2;
}

h4 {
  font-size: 24px;
  line-height: 1.2;
}

h5 {
  font-size: 20px;
  line-height: 1.5;
}

h6 {
  font-size: 16px;
  line-height: 1.5;
}

p {
  color: #999999;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 1.63;
}

blockquote {
  position: relative;
  margin: 0;
  padding: 70px 100px 40px;
  background-color: #EBEBEB;
  text-align: center;
}

blockquote h1,
blockquote h2,
blockquote h3,
blockquote h4,
blockquote h5,
blockquote h6 {
  margin: 0;
  color: #111539;
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0;
}

blockquote.medium {
  padding: 70px 50px 40px;
}

blockquote.large {
  padding: 70px 100px 40px;
}

blockquote.small {
  padding: 70px 40px 40px;
}

blockquote p {
  margin-top: 40px;
  margin-bottom: 0;
  color: #EBB113;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
}

blockquote.q-bg {
  background-color: #111539;
}

blockquote.q-bg h1,
blockquote.q-bg h2,
blockquote.q-bg h3,
blockquote.q-bg h4,
blockquote.q-bg h5,
blockquote.q-bg h6 {
  color: #fff;
  font-family: "Source Sans Pro", sans-serif;
}

blockquote.q-bg p {
  color: #EBB113;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
}

blockquote.q-bg:after {
  color: #fff;
}

blockquote.q-border {
  border: 1px solid #eee;
}

blockquote.q-line {
  padding: 0 100px 0 35px;
  border-left: 2px solid #ebebeb;
  background-color: transparent;
}

@media screen and (max-width: 768px) {
  blockquote.q-line {
    padding: 0 40px 0 35px;
  }
}

blockquote:before {
  color: #111539;
}

@media screen and (max-width: 1230px) {
  blockquote {
    padding: 70px 50px 40px;
  }
}

@media screen and (max-width: 768px) {
  blockquote {
    padding: 70px 40px 40px;
  }
}

ol {
  padding-left: 0;
  counter-reset: item;
}

ol li {
  display: block;
  padding-left: 30px;
  color: #999999;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;
}

ol li:before {
  position: absolute;
  top: 0;
  left: 0;
  color: #111539;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  font-weight: bold;
  content: counter(item) ". ";
  counter-increment: item;
}

ol.ol--lg-nums li {
  margin-bottom: 44px;
  padding-left: 45px;
  line-height: 24px;
}

ol.ol--lg-nums li:before {
  color: #111539;
  font-size: 24px;
  line-height: 28px;
  content: "0" counter(item) ". ";
}

.t-grey {
  color: #999999;
}

/**
 * common variebles
 * and style
 */
a {
  transition: 0.3s ease-out;
  color: #EBB113;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0;
  text-decoration: none;
}

a:hover {
  color: #111539;
}

.aheto-btn {
  border-radius: 5px;
  display: inline-block;
  position: relative;
  padding: 18px 39px;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(235, 177, 19, 0.5);
  outline: none;
  background-color: #EBB113;
  color: #fff;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 1.5px;
  line-height: 1.15;
}

.aheto-btn:hover {
  color: #EBB113;
}

.aheto-btn \\
i,
.aheto-btn span {
  font-size: 14px;
}

.aheto-btn \\
i.aheto-btn__icon--box,
.aheto-btn span.aheto-btn__icon--box {
  font-size: 24px;
}

.aheto-btn.aheto-btn--outline {
  border: 1px solid rgba(153, 153, 153, 0.2);
  color: #EBB113;
}

.aheto-btn.aheto-btn--outline:hover {
  border-color: #EBB113;
  background: #EBB113;
  color: #fff !important;
}

.aheto-btn.aheto-btn--inline {
  color: #EBB113;
}

.aheto-btn.aheto-btn--underline {
  color: #EBB113;
}

.aheto-btn.aheto-btn--underline:after {
  background-color: #EBB113;
}

.aheto-btn.aheto-btn--underline:hover:after {
  background-color: rgba(235, 177, 19, 0);
}

.aheto-btn.aheto-btn--shadow:not(.aheto-btn--circle) {
  box-shadow: 1.169px 3.825px 15.66px 2.34px rgba(235, 177, 19, 0.2);
}

.aheto-btn.aheto-btn--shadow.aheto-btn--circle {
  box-shadow: 0 7px 16px 0 rgba(235, 177, 19, 0.44);
}

.aheto-btn .aheto-btn__icon--box:after {
  background-color: #EBEBEB;
}

.aheto-btn--large {
  border-radius: 5px;
  padding: 23px 45px;
  font-size: 16px;
  letter-spacing: 1.5px;
}

.aheto-btn--small {
  border-radius: 5px;
  padding: 13px 34px;
  font-size: 14px;
  letter-spacing: 1.5px;
}

.aheto-btn--light {
  background-color: #fff;
  color: #111539;
}

.aheto-btn--light:hover {
  color: #fff;
}

.aheto-btn--light.aheto-btn--trans, .aheto-btn--light.aheto-btn--inline, .aheto-btn--light.aheto-btn--underline {
  color: #fff;
}

.aheto-btn--light.aheto-btn--trans:hover, .aheto-btn--light.aheto-btn--inline:hover, .aheto-btn--light.aheto-btn--underline:hover {
  color: #fff;
}

.aheto-btn--light.aheto-btn--underline:after {
  background-color: #fff;
}

.aheto-btn--light.aheto-btn--shadow:not(.aheto-btn--circle) {
  box-shadow: 1.169px 3.825px 15.66px 2.34px rgba(17, 21, 57, 0.2);
}

.aheto-btn--light.aheto-btn--shadow.aheto-btn--circle {
  box-shadow: 0 7px 16px 0 rgba(17, 21, 57, 0.44);
}

.aheto-btn--light.aheto-btn--shadow:hover {
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.aheto-btn--dark {
  border-color: rgba(17, 21, 57, 0.5);
  background-color: #111539;
  color: #fff;
}

.aheto-btn--dark.aheto-btn--trans, .aheto-btn--dark.aheto-btn--inline, .aheto-btn--dark.aheto-btn--underline {
  color: #111539;
}

.aheto-btn--dark.aheto-btn--underline:after {
  background-color: #111539;
}

.aheto-btn--dark.aheto-btn--shadow:not(.aheto-btn--circle) {
  box-shadow: 1.169px 3.825px 15.66px 2.34px rgba(17, 21, 57, 0.2);
}

.aheto-btn--dark.aheto-btn--shadow.aheto-btn--circle {
  box-shadow: 0 7px 16px 0 rgba(17, 21, 57, 0.44);
}

.aheto-btn--grey.aheto-btn--trans, .aheto-btn--grey.aheto-btn--inline, .aheto-btn--grey.aheto-btn--underline {
  color: #999999;
}

.aheto-btn--gradient {
  border: 0;
  background: linear-gradient(-90deg, #111539, #EBB113);
}

.aheto-btn--gradient.aheto-btn--circle {
  background: linear-gradient(120deg, #111539 10%, #EBB113 80%);
}

.aheto-btn--gradient.aheto-btn--shadow.aheto-btn--circle {
  box-shadow: 0 7px 16px 0 rgba(235, 235, 235, 0.44);
}

.aheto-btn--alter {
  border-color: rgba(235, 235, 235, 0.5);
  background-color: #EBEBEB;
  color: #999999;
}

.aheto-btn--alter:hover {
  color: #EBEBEB;
}

.aheto-btn--alter.aheto-btn--trans, .aheto-btn--alter.aheto-btn--inline, .aheto-btn--alter.aheto-btn--underline {
  color: #EBEBEB;
}

.aheto-btn--alter.aheto-btn--underline:after {
  background-color: #EBEBEB;
}

.aheto-btn--alter.aheto-btn--shadow:not(.aheto-btn--circle) {
  box-shadow: 1.169px 3.825px 15.66px 2.34px rgba(235, 235, 235, 0.2);
}

.aheto-btn--alter.aheto-btn--shadow.aheto-btn--circle {
  box-shadow: 0 7px 16px 0 rgba(235, 235, 235, 0.44);
}

.aheto-btn--alter .aheto-btn__icon--box:after {
  background-color: #EBB113;
}

.aheto-btn--trans {
  background-color: transparent;
  color: #EBB113;
}

.aheto-btn--no-padd {
  padding: 0px;
}

.aheto-btn--inline {
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1.5px;
}

.aheto-btn--underline {
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1.5px;
}

.aheto-btn--rect {
  padding-top: 60px;
  padding-right: 23px;
  padding-bottom: 30px;
  padding-left: 23px;
  font-size: 24px;
  letter-spacing: 1px;
}

.aheto-btn--circle {
  width: 115px;
  height: 115px;
}

.aheto-btn--circle i {
  font-size: 24px;
}

.aheto-btn--simple {
  border: none;
  color: #fff;
  border: 1px solid transparent;
}

.aheto-btn--simple:hover {
  color: #fff;
  border: 1px solid #fff;
}

.aheto-btn__icon--left {
  margin-right: 10px;
}

.aheto-btn__icon--right {
  margin-left: 10px;
}

.aheto-btn__icon--box:after {
  border-radius: 5px;
}

.aheto-btn__icon--default-color {
  color: #EBB113;
}

.aheto-video-link {
  display: flex;
  align-items: center;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .aheto-video-link {
    display: inline-flex;
  }
}

.aheto-video-link__btn {
  height: 70px;
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 24px;
  position: relative;
  transition: .5s ease-in-out;
}

.aheto-video-link__btn:before {
  content: '';
  height: 70px;
  width: 70px;
  background: #2A74ED;
  border-radius: 50%;
  position: absolute;
}

.aheto-video-link__btn:hover:before {
  background: transparent;
  border: 1px solid #2A74ED;
}

.aheto-video-link__btn:hover i {
  color: #2A74ED;
}

.aheto-video-link__btn i {
  z-index: 1;
}

.aheto-video-link__btn--big {
  height: 90px;
  width: 90px;
}

.aheto-video-link__btn--pos {
  justify-content: flex-end;
}

@media screen and (max-width: 768px) {
  .aheto-video-link__btn--pos {
    justify-content: center;
  }
}

.aheto-video-link--border {
  height: 130px;
  width: 130px;
  justify-content: center;
}

.aheto-video-link--border:after {
  content: '';
  height: 130px;
  width: 130px;
  border-radius: 50%;
  border: 1px solid #eeeeee;
  position: absolute;
  transition: .5s ease-in-out;
}

.aheto-video-link--border:before {
  content: '';
  height: 90px;
  width: 90px;
  background: #2A74ED;
  border-radius: 50%;
  position: absolute;
}

.aheto-video-link--border span:hover {
  border: none;
}

.aheto-video-link--border:hover:before {
  background: transparent;
  border: 1px solid #fff;
}

.aheto-video-link--border:hover i {
  color: #2A74ED;
}

.aheto-video-link--border:hover span:before {
  background: transparent;
  border: none;
}

.aheto-video-link--border:hover:after {
  content: '';
  height: 90px;
  width: 90px;
  opacity: 0;
}

.aheto-video-link__label {
  font-size: 14px;
  font-weight: 500;
  color: #222222;
  text-transform: uppercase;
  padding-left: 20px;
  letter-spacing: 1.4px;
}

.aheto-btn--nobg {
  color: #fff;
}

.aheto-btn--nobg:hover {
  color: #ff704f;
}

.aheto-video-link__btn--chr:before {
  background: #EBB113;
}

.aheto-video-link--border--chr:before {
  background: #EBB113;
}

.aheto-video-link--border--chr:after {
  border: 1px solid #eeeeee;
  opacity: 0.5;
}

.aheto-video-link--border--chr:hover i {
  color: #EBB113;
}

.aheto-member__dark-hover:hover {
  color: #111539;
}

.aheto-btn-container .aheto-agency__link--dark {
  color: #EBB113;
}

.shop-content-wrap {
  padding: 0 100px;
}

@media screen and (max-width: 1500px) {
  .shop-content-wrap {
    padding: 0 50px;
  }
}

@media screen and (max-width: 767px) {
  .shop-content-wrap {
    padding: 0;
  }
}

.breadcrumb-wrap {
  padding: 10px 0;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}

.woocommerce-tabs-wrap {
  border-top: 1px solid #eee;
}

.woocommerce-navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.woocommerce-navigation .woocommerce-arrows {
  display: flex;
}

.woocommerce-navigation .woocommerce-arrow {
  display: block;
  margin-right: 15px;
  font-size: 34px;
}

@media screen and (max-width: 450px) {
  .woocommerce-navigation .woocommerce-arrow {
    line-height: 27px;
  }
}

.woocommerce-navigation .woocommerce-arrow:last-child {
  margin-right: 0;
}

@media screen and (max-width: 450px) {
  .woocommerce-navigation {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: 10px;
  }
}

.woocommerce-breadcrumb {
  font-size: 15px;
  color: #999999;
}

.woocommerce-breadcrumb span {
  margin: 0 10px;
}

.woocommerce-arrows a {
  color: #222222;
}

.woocommerce-arrows a:hover {
  color: #999999;
}

.woocommerce .woocommerce-info,
.woocommerce .woocommerce-message {
  margin: 30px 0;
  padding: 17px;
}

.woocommerce .woocommerce-info {
  background-color: #f9f9f9;
  border-top: 1px solid #2a74ed;
}

.woocommerce .woocommerce-info i {
  display: inline-block;
  margin-right: 15px;
  font-size: 20px;
  line-height: 1;
  color: #2a74ed;
}

.woocommerce .woocommerce-info a {
  font-size: 16px;
}

.woocommerce .woocommerce-NoticeGroup {
  flex-basis: 100%;
  width: 100%;
}

.woocommerce ul {
  padding: 0;
}

.woocommerce ul li {
  margin: 0;
  padding: 0;
}

.woocommerce ul li:before {
  content: none;
}

.related-products {
  text-align: center;
}

.related-products h3 {
  font-weight: 300;
}

.related-products .related-products__title {
  font-weight: 300;
}

.aheto-products {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
  padding: 0;
  overflow: hidden;
}

@media screen and (max-width: 767px) {
  .aheto-products {
    justify-content: center;
  }
}

.aheto-products__item {
  position: relative;
  flex: 1;
  min-width: 250px;
  max-width: 500px;
  margin: 15px;
  padding-left: 0;
}

.aheto-products__item:before {
  content: none;
}

.aheto-products__item:hover .aheto-products__btn-holder {
  opacity: 1;
}

.aheto-products__img {
  width: 100%;
  height: 420px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.aheto-products__btn-holder {
  display: inline-flex;
  position: absolute;
  top: 350px;
  left: 50%;
  align-items: center;
  transform: translate(-50%, 0);
  transition: all 0.5s;
  opacity: 0;
  z-index: 10;
}

.aheto-products__btn-holder .added_to_cart {
  display: inline-block;
  margin-left: 10px;
  padding: 10px;
  border-radius: 5px;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 1.15;
}

.aheto-products__btn-holder .added_to_cart:hover {
  opacity: 1;
}

.aheto-products__btn {
  display: flex;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #696969;
  text-align: center;
}

.aheto-products__btn:hover {
  opacity: 1;
  background-color: #222222;
}

.aheto-products__btn + .aheto-products__btn {
  margin-left: 20px;
}

.aheto-products__btn.added {
  display: none;
}

.aheto-products__btn-icon {
  margin: auto;
  color: #fff;
  font-size: 18px;
}

.aheto-products__marks {
  position: absolute;
  top: 20px;
  left: 0;
  z-index: 10;
}

.aheto-products__mark {
  display: block;
  width: 55px;
  height: 25px;
  padding: 6px 14px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
}

.aheto-products__mark.aheto-onsale {
  background-color: #46AB5D;
}

.aheto-products__mark.aheto-new {
  background-color: #2A74ED;
}

.aheto-products__mark.aheto-hot {
  background-color: #F24D4C;
}

.aheto-products__mark + .aheto-products__mark {
  margin-top: 10px;
}

.aheto-products__link {
  display: block;
}

.aheto-products__title {
  margin-top: 25px;
  margin-bottom: 5px;
  line-height: 1;
}

.aheto-products__price {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
}

.aheto-products__price del {
  margin-right: 10px;
}

.aheto-products__price del .aheto-products__price-amount {
  color: #999999;
  text-decoration: line-through !important;
}

.aheto-products--barbershop .aheto-products__item {
  width: calc(25% - 30px);
  flex: none;
}

.aheto-products--barbershop .aheto-products__title {
  font-family: "Mukta", sans-serif;
  font-size: 17px;
  font-weight: bold;
  text-transform: uppercase;
}

.aheto-products--barbershop .aheto-products__price-amount {
  font-size: 21px;
  font-weight: bold;
  letter-spacing: 1.68px;
}

.aheto-products--barbershop .aheto-products__price ins {
  text-decoration: none;
}

.aheto-products--barbershop .aheto-products__marks {
  left: auto;
  right: 7px;
}

.aheto-products--barbershop .aheto-products__mark.aheto-onsale {
  padding: 4px;
  border-radius: 0;
  font-size: 17px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
}

@media screen and (max-width: 1500px) {
  .aheto-products .aheto-products__img {
    height: 380px;
  }
  .aheto-products .aheto-products__btn-holder {
    top: 310px;
  }
}

@media screen and (max-width: 767px) {
  .aheto-products .aheto-products__item {
    min-width: 200px;
    max-width: 345px;
  }
  .aheto-products .aheto-products__btn-holder {
    display: flex;
    position: static;
    justify-content: center;
    margin-top: 25px;
    transform: none;
    opacity: 1;
  }
  .aheto-products .aheto-products__img {
    height: 300px;
  }
}

.aheto-products--related .aheto-products__img {
  height: 360px;
}

.aheto-products--related .aheto-products__title {
  margin-top: 35px;
}

.aheto-products--related .aheto-products__btn-holder {
  top: 290px;
}

.aheto-products--political .aheto-products__item {
  background: #f7f7f7;
  padding: 50px 15px 25px;
}

@media screen and (max-width: 767px) {
  .aheto-products--political .aheto-products__item {
    max-width: 100%;
  }
}

@media screen and (min-width: 992px) {
  .aheto-products--political .aheto-products__item:hover img {
    opacity: 0.5;
  }
}

.aheto-products--political .aheto-products__img {
  height: auto;
}

.aheto-products--political .aheto-products__img img {
  transition: opacity .2s linear;
}

.aheto-products--political .aheto-products__link p {
  font-family: 'Oswald';
  font-size: 20px;
  font-weight: 500;
  line-height: 1.3;
  color: #0536a5;
  padding-top: 10px;
}

.aheto-products--political .aheto-products__btn {
  width: auto;
  height: auto;
  padding: 14px 35px;
  color: #fff;
  font-family: 'Oswald';
  border-radius: 5px;
  background-color: #0536a5;
}

.aheto-products--political .aheto-products__btn-holder {
  top: 180px;
  transition: opacity .2s linear;
}

.aheto-products--political .aheto-products__price {
  color: #999999;
  font-size: 16px;
  font-weight: 700;
}

.shop {
  display: flex;
  flex-wrap: wrap;
}

.shop__sidebar {
  width: 275px;
  margin-right: 70px;
}

@media screen and (max-width: 1500px) {
  .shop__sidebar {
    margin-right: 50px;
  }
}

@media screen and (max-width: 991px) {
  .shop__sidebar {
    flex-basis: 100%;
    margin-right: 0;
    margin-bottom: 50px;
  }
}

.shop__content {
  flex: 1;
}

.shop__details {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.shop__products {
  min-width: 10px;
  margin-top: 15px;
  overflow: hidden;
}

.shop__pagination {
  display: flex;
  justify-content: flex-center;
  margin-top: 50px;
}

.shop__load-more {
  display: inline-flex;
  align-items: center;
  margin: 0 auto;
}

.shop__load-more-icon {
  height: 24px;
  margin-right: 15px;
  color: #999;
  font-size: 24px;
  line-height: 3px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

.shop__load-more-text {
  margin: 0;
  color: #999;
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 24px;
}

.widget-area {
  font-family: "Source Sans Pro", sans-serif;
}

.widget-area .widget_price_filter {
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 50px;
}

@media screen and (max-width: 991px) {
  .widget-area .widget_price_filter {
    margin-bottom: 0px;
  }
}

.widget-area .widget_price_filter .widget-title {
  margin: 0 0 30px;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 30px;
  font-weight: 300;
  color: #222;
}

.widget-area .widget_price_filter .ui-slider {
  width: 100%;
  height: 3px;
  margin-bottom: 85px;
  background-color: #eee;
}

.widget-area .widget_price_filter .ui-slider-range {
  height: 3px;
}

.widget-area .widget_price_filter .ui-slider-handle {
  display: block;
  top: -3px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
}

.widget-area .widget_price_filter button[type=submit] {
  border-radius: 5px;
  display: inline-block;
  position: relative;
  padding: 16px 38px;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(42, 116, 237, 0.5);
  outline: none;
  background-color: #2A74ED;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 1.4px;
  line-height: normal;
  box-shadow: 1.2px 3.8px 15.7px 2.3px rgba(42, 116, 237, 0.2);
  text-transform: uppercase;
  cursor: pointer;
}

.widget-area .widget_price_filter button[type=submit]:hover {
  background-color: #fff;
  color: #2A74ED;
}

.widget-area .widget_price_filter .price_label {
  color: #999;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 16px;
}

.widget-area .widget_price_filter .price_label .from,
.widget-area .widget_price_filter .price_label .to {
  color: #999;
}

.widget-area .widget_product_categories {
  border-bottom: 1px solid #eeeeee;
  margin: 0;
  padding: 50px 0px;
}

.widget-area .widget_product_categories .product-categories {
  margin: 0;
  padding: 0;
}

.widget-area .widget_product_categories .widget-title {
  margin: 0 0 33px;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 30px;
  font-weight: 300;
  color: #222222;
}

.widget-area .widget_product_categories .product-categories-item {
  padding-botom: 50px;
}

.widget-area .widget_product_categories .product-categories-item.active {
  color: #2a74ed;
}

.widget-area .widget_product_categories .accordion {
  cursor: pointer;
  border: none;
  text-align: left;
  outline: none;
  font-size: 18px;
  transition: 0.5s;
}

.widget-area .widget_product_categories .panel {
  padding: 10px 18px 0px;
  background-color: #fff;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
}

.widget-area .widget_product_categories .panel li {
  font-family: Roboto;
  font-size: 16px;
  line-height: 2.5;
  color: #ababab;
  cursor: pointer;
}

.widget-area .widget_product_categories .panel .color__blue {
  color: #2a74ed;
}

.widget-area .widget_product_categories .panel li:last-child {
  margin-bottom: 50px;
}

@media screen and (max-width: 766px) {
  .widget-area .widget_product_categories .panel li:last-child {
    margin-bottom: 25px;
  }
}

.woocommerce-result-count {
  margin: 0;
  padding-bottom: 10px;
}

.woocommerce-result-count span {
  color: #222;
}

@media screen and (max-width: 767px) {
  .woocommerce-result-count {
    flex-basis: 100%;
    order: 2;
    margin-top: 20px;
  }
}

.woocommerce-ordering .orderby {
  padding: 0px 18px;
  border: 1px solid #eee;
  border-radius: 5px;
  background-color: #fff;
  color: #999;
  font-family: "Source Sans Pro", sans-serif;
  height: 50px;
  margin-right: 30px;
}

.woocommerce-ordering .orderby:focus {
  outline: none;
}

@media screen and (max-width: 767px) {
  .woocommerce-ordering .orderby {
    flex-basis: 100%;
    padding: 10px;
  }
}

.sort-colum {
  color: #999999;
  margin-right: 15px;
}

.sort-colum.active {
  color: #2a74ed;
}

.sort-row {
  color: #999999;
  margin-right: 5px;
}

.price_slider_wrapper .slider-container .scale {
  display: none;
}

.price_slider_wrapper .slider-container .back-bar {
  height: 3px;
  background-color: #eeeeee;
}

.price_slider_wrapper .slider-container .back-bar .pointer-label {
  top: auto;
  bottom: -30px;
}

.price_slider_wrapper .slider-container .back-bar .selected-bar {
  background-color: #222222;
  background-image: linear-gradient(to bottom, #222222, #222222);
}

.price_slider_wrapper .slider-container .back-bar .pointer {
  width: 16px;
  height: 16px;
  top: -8px;
  background-color: #222222;
  background-image: linear-gradient(to bottom, #222222, #222222);
  border: 1px solid #222222;
  cursor: pointer;
}

.price_slider_wrapper .slider-container .back-bar .pointer-label {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  color: #222222;
}

.price_slider_wrapper .slider-container .back-bar .pointer.low {
  margin-left: 3px;
}

.price_slider_wrapper .slider-container .back-bar .pointer-label.low::before {
  content: '$';
  padding-left: 5px;
}

.price_slider_wrapper .slider-container .back-bar .pointer-label.low::after {
  content: '.00';
}

.price_slider_wrapper .slider-container .back-bar .pointer {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  color: #222222;
  margin-left: 3px;
}

.price_slider_wrapper .slider-container .back-bar .pointer-label.high::before {
  content: '$';
  padding-left: 5px;
}

.price_slider_wrapper .slider-container .back-bar .pointer-label.high::after {
  content: '.00';
}

.widget_product_color {
  padding-top: 50px;
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 50px;
}

.widget_product_color .widget-title {
  color: #222222;
  margin-bottom: 30px;
  font-size: 30px;
  font-weight: 300;
}

.widget_product_color .color-items {
  flex-wrap: wrap;
}

.widget_product_color .color-items .color-item {
  width: 20px;
  height: 20px;
  border-radius: 3px;
  border: solid 1px #e0e0e0;
  margin-right: 20px;
  cursor: pointer;
}

@media screen and (max-width: 450px) {
  .widget_product_color .color-items .color-item {
    margin-right: 15px;
  }
}

.widget_product_color .color-items .border-color {
  border-color: #2a74ed;
}

.widget_product_color .color-items .color-black {
  background-color: #333333;
}

.widget_product_color .color-items .color-white {
  background-color: #ffffff;
}

.widget_product_color .color-items .color-green {
  background-color: #706b55;
}

.widget_product_color .color-items .color-pink {
  background-color: #e4beb4;
}

.widget_product_color .color-items .color-orange {
  background-color: #eed5b6;
}

.widget_product_color .color-items .color-blue {
  background-color: #b5ccd8;
}

.widget_product_color .color-items .color-grey {
  background-color: #ababab;
}

.widget_product_color .color-items .color-brown {
  background-color: #c47d5e;
}

.widget_product_color .color-items .color-purple {
  background-color: #715781;
}

.widget_product_size {
  padding-top: 50px;
}

.widget_product_size .widget-title {
  color: #222222;
  margin-top: 0px;
  margin-bottom: 30px;
  font-size: 30px;
  font-weight: 300;
}

.widget_product_size .size-items {
  flex-wrap: wrap;
}

.widget_product_size .size-items .size-item {
  width: 50%;
}

.widget_product_size label {
  margin-bottom: 12px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 0.7px;
  text-align: left;
  color: #222222;
}

.widget_product_size input[type=checkbox] {
  display: none;
}

.widget_product_size input[type=checkbox] + label:before {
  content: "\2713";
  color: #fff;
  display: inline-block;
  border: 1px solid #eeeeee;
  font-size: 15px;
  line-height: 16px;
  margin: -4px 15px 0 0;
  height: 16px;
  width: 16px;
  text-align: center;
  border-radius: 3px;
  vertical-align: middle;
  transition: color ease .3s;
}

.widget_product_size input[type=checkbox]:checked + label {
  color: #2a74ed;
}

.widget_product_size input[type=checkbox]:checked + label:before {
  color: #2a74ed;
}

.lightgallery .aheto-products__btn {
  margin-right: 20px;
}

.cart_totals .coupon {
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
}

.cart_totals .coupon label {
  display: block;
  flex-basis: 100%;
  margin-bottom: 30px;
  color: #999;
  font-size: 16px;
  line-height: 26px;
}

.cart_totals .coupon input {
  flex-basis: 460px;
  min-width: 1px;
  padding: 20px 30px;
  border: 0;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  outline: none;
  background: #f7f7f7;
  color: #999;
  font-size: 16px;
  line-height: 16px;
}

.cart_totals .coupon input::placeholder {
  color: #999;
  font-size: 16px;
}

@media screen and (max-width: 767px) {
  .cart_totals .coupon input {
    flex-basis: 230px;
  }
}

@media screen and (max-width: 480px) {
  .cart_totals .coupon input {
    flex-basis: 190px;
  }
}

.cart_totals .coupon button[type=submit] {
  padding: 20px 24px;
  border: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  outline: none;
  color: #999;
  line-height: 16px;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .woocommerce-cart-form {
    overflow-x: scroll;
  }
}

.woocommerce-cart-form .product-subtotal .woocommerce-Price-amount {
  color: #222 !important;
}

.woocommerce-cart-form table.shop_table {
  width: 100%;
  border: 0;
  color: #999;
  text-align: left;
}

@media screen and (max-width: 768px) {
  .woocommerce-cart-form table.shop_table {
    width: 800px;
  }
}

.woocommerce-cart-form table.shop_table thead tr th {
  padding: 16px 0;
  font-size: 16px;
  font-weight: 500;
  color: #222;
}

.woocommerce-cart-form table.shop_table thead tr th:last-child {
  width: 50px;
}

.woocommerce-cart-form table.shop_table thead tr th.product-name {
  padding-left: 30px;
}

.woocommerce-cart-form table.shop_table tbody tr td {
  padding: 20px 0;
  border-top: 1px solid #eee;
}

.woocommerce-cart-form table.shop_table tbody tr td:before {
  line-height: 26px;
}

.woocommerce-cart-form table.shop_table tbody tr td.product-thumbnail {
  width: 100px;
}

.woocommerce-cart-form table.shop_table tbody tr td.product-thumbnail a {
  position: relative;
  display: inline-block;
}

.woocommerce-cart-form table.shop_table tbody tr td.product-thumbnail a .aheto-products__marks {
  top: 0;
  left: 0;
}

.woocommerce-cart-form table.shop_table tbody tr td.product-thumbnail a img {
  width: 100px;
  height: 130px;
  object-fit: cover;
}

.woocommerce-cart-form table.shop_table tbody tr td.product-name {
  width: 34%;
  padding-left: 30px;
}

.woocommerce-cart-form table.shop_table tbody tr td.product-name a {
  color: #222;
  font-size: 16px;
  line-height: 26px;
}

.woocommerce-cart-form table.shop_table tbody tr td.product-price {
  width: 17%;
  color: #ababab;
  font-size: 14px;
}

.woocommerce-cart-form table.shop_table tbody tr td.product-quantity {
  width: 26%;
}

.woocommerce-cart-form table.shop_table tbody tr td.product-quantity .quantity {
  display: inline-flex;
  border-radius: 3px;
  background-color: #f7f7f7;
}

.woocommerce-cart-form table.shop_table tbody tr td.product-quantity .quantity label {
  display: none;
}

.woocommerce-cart-form table.shop_table tbody tr td.product-quantity .quantity span {
  display: inline-block;
  width: 42px;
  padding: 14px 0;
  color: #999;
  font-size: 20px;
  text-align: center;
  cursor: pointer;
  user-select: none;
}

.woocommerce-cart-form table.shop_table tbody tr td.product-quantity .quantity input {
  width: 42px;
  border: 0;
  outline: none;
  background-color: #f7f7f7;
  color: #222;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
}

.woocommerce-cart-form table.shop_table tbody tr td.product-quantity .quantity input::-webkit-outer-spin-button, .woocommerce-cart-form table.shop_table tbody tr td.product-quantity .quantity input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.woocommerce-cart-form table.shop_table tbody tr td.product-subtotal {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
}

.woocommerce-cart-form table.shop_table tbody tr td.product-remove {
  width: 50px;
  padding-left: 20px;
}

.woocommerce-cart-form table.shop_table tbody tr td.product-remove a {
  display: block;
  width: 50px;
  height: 50px;
  border: 1px solid #eee;
  border-radius: 5px;
  color: #999999;
  font-size: 20px;
  line-height: 47px;
  text-align: center;
}

.woocommerce-cart-form table.shop_table tbody tr td.actions > button[type=submit] {
  margin-top: 30px;
  text-transform: uppercase;
  cursor: pointer;
}

.woocommerce-cart-form table.shop_table tbody tr:first-child td {
  padding-top: 50px;
}

.woocommerce-shipping-calculator .shipping-calculator-form {
  margin-top: 10px;
}

.woocommerce-shipping-calculator .shipping-calculator-form select {
  width: 100%;
  padding: 5px;
  border: 1px solid #eee;
  border-radius: 5px;
  outline: none;
  color: #999;
  font-family: "Source Sans Pro", sans-serif;
}

.woocommerce-shipping-calculator .shipping-calculator-form input {
  width: 100%;
  margin-top: 5px;
  padding: 5px;
  border: 1px solid #eee;
  border-radius: 5px;
  outline: none;
  color: #999;
  font-family: "Source Sans Pro", sans-serif;
}

.woocommerce-shipping-calculator .shipping-calculator-form input::placeholder {
  color: #999;
  font-family: "Source Sans Pro", sans-serif;
}

.woocommerce-shipping-calculator .shipping-calculator-form input:focus {
  border-radius: 5px;
  box-shadow: 0px 15px 30px 0px rgba(42, 116, 237, 0.1);
}

.woocommerce-shipping-calculator .shipping-calculator-form button[type=submit] {
  display: block;
  width: 100%;
  margin-top: 5px;
  padding: 10px;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  outline: none;
  color: #fff;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 1.5px;
  line-height: 1.15;
  text-decoration: none;
  box-shadow: 1.169px 3.825px 15.66px 2.34px rgba(42, 116, 237, 0.2);
  cursor: pointer;
}

.cart-collaterals .cart_totals {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 60px;
  padding: 40px 50px;
  border: 1px solid #eee;
  border-radius: 5px;
}

.cart-collaterals .cart_totals table {
  margin-top: 10px;
}

.cart-collaterals .cart_totals .button-list a {
  text-transform: uppercase;
}

.cart-collaterals .cart_totals .button-list a.aheto-btn--mod {
  border: 0;
  color: #999;
}

.cart-collaterals .cart_totals .button-list a.aheto-btn--mod i {
  color: #999;
  font-size: 14px;
  line-height: 1;
}

.cart-collaterals .cart_totals table.shop_table tr th {
  padding-right: 20px;
  color: #222;
  font-size: 16px;
  font-weight: 500;
  line-height: 40px;
  text-align: left;
  vertical-align: bottom;
}

.cart-collaterals .cart_totals table.shop_table tr td {
  font-size: 30px;
  font-weight: 300;
  line-height: 1.33;
  color: #2a74ed;
}

.cart-collaterals .cart_totals table.shop_table tr td[data-title=Shipping] {
  font-size: 16px;
  line-height: 40px;
}

.cart-collaterals .cart_totals table.shop_table tr td[data-title=Shipping] form p {
  margin: 0;
  line-height: 26px;
}

.cart-collaterals .cart_totals table.shop_table tr td[data-title=Shipping] form p a {
  font-weight: 500;
}

.cart-collaterals .cart_totals table.shop_table tr.shipping th {
  vertical-align: top;
}

.cart-collaterals .cart_totals table.shop_table tr.order-total th,
.cart-collaterals .cart_totals table.shop_table tr.order-total td {
  padding-top: 20px;
}

.cart-collaterals .cart_totals table.shop_table tr.order-total td strong {
  font-size: 30px;
  font-weight: 300;
}

.cart-collaterals .cart_totals .wc-proceed-to-checkout {
  flex-basis: 100%;
  margin-top: 60px;
  text-align: right;
}

@media screen and (max-width: 991px) {
  .cart-collaterals .cart_totals table {
    margin-top: 10px;
  }
  .cart-collaterals .cart_totals .wc-proceed-to-checkout {
    text-align: left;
  }
}

@media screen and (max-width: 875px) {
  .cart-collaterals {
    padding: 0;
  }
  .cart-collaterals .button-list {
    display: flex;
    width: 100%;
  }
  .cart-collaterals .cart_totals {
    padding: 30px;
    justify-content: flex-end;
  }
  .cart-collaterals .cart_totals table {
    margin-top: 20px;
  }
  .cart-collaterals .cart_totals .wc-proceed-to-checkout {
    margin-top: 30px;
  }
}

@media screen and (max-width: 768px) {
  .cart-collaterals .button-list {
    display: block;
    text-align: center;
  }
  .cart-collaterals .cart_totals {
    padding: 20px 10px;
  }
  .cart-collaterals .aheto-btn--mod {
    margin-top: 20px;
    padding: 20px 10px;
  }
}

.cart-order {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {
  .cart-order {
    display: block;
  }
}

.cart-order p {
  max-width: 530px;
  font-size: 16px;
  line-height: 1.63;
}

.cart-order .coupon {
  max-width: 590px;
}

.cart-order .coupon input {
  width: calc(100% - 60px);
  font-size: 16px;
  padding: 18px 30px;
  border: none;
  background: #f7f7f7;
  border-radius: 5px 0px 0px 5px;
}

.cart-order .coupon .button {
  border: none;
  background-color: #d7d4d4;
  height: 60px;
  width: 60px;
  border-radius: 0px 5px 5px 0px;
  margin-left: -5px;
}

.select2 {
  width: 100%;
  padding: 5px;
  border: 1px solid #eee;
  border-radius: 5px;
  outline: none;
  color: #999;
  font-family: "Source Sans Pro", sans-serif;
}

.select2-dropdown {
  border-color: #eee;
}

.select2 .select2-selection--single {
  border: 0;
}

.select2-results__option:before {
  content: none;
}

.checkout_coupon input {
  width: 100%;
  padding: 20px;
  transition: 0.3s all;
  border: 1px solid #eee;
  border-radius: 5px;
  outline: none;
  color: #999;
  font-family: "Source Sans Pro", sans-serif;
}

.checkout_coupon input::placeholder {
  color: #999;
  font-family: "Source Sans Pro", sans-serif;
}

.checkout_coupon input:focus {
  border: 1px solid #2A74ED;
  border-radius: 5px;
  box-shadow: 0px 15px 30px 0px rgba(42, 116, 237, 0.1);
  transition: 0.3s all;
}

.product-name {
  color: #222222;
}

.woocommerce-checkout {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 95px;
}

@media screen and (max-width: 991px) {
  .woocommerce-checkout {
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 767px) {
  .woocommerce-checkout {
    margin-bottom: 50px;
  }
}

.woocommerce-checkout .woocommerce-Price-amount {
  color: #2A74ED;
}

.woocommerce-checkout .product-total .woocommerce-Price-amount {
  color: #ababab;
}

.woocommerce-checkout .cart-subtotal .woocommerce-Price-amount {
  color: #222;
  font-weight: 500;
}

.woocommerce-checkout .shipping td {
  color: #ababab;
}

.woocommerce-checkout .col2-set {
  display: flex;
  flex: 3;
  flex-direction: column;
}

.woocommerce-checkout .col2-set .col-1,
.woocommerce-checkout .col2-set .col-2 {
  padding-left: 0px;
  max-width: 570px;
  margin-right: 30px;
}

.woocommerce-checkout .col2-set .woocommerce-billing-fields h3 {
  font-weight: 300;
}

.woocommerce-checkout .col2-set .woocommerce-billing-fields__field-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin: -15px;
}

.woocommerce-checkout .col2-set .woocommerce-billing-fields__field-wrapper p {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-end;
  min-width: 200px;
  margin: 15px;
}

.woocommerce-checkout .col2-set .woocommerce-billing-fields__field-wrapper p label {
  margin-bottom: 8px;
  font-weight: 500;
}

.woocommerce-checkout .col2-set .woocommerce-billing-fields__field-wrapper p abbr {
  text-decoration: none;
}

.woocommerce-checkout .col2-set .woocommerce-billing-fields__field-wrapper p input {
  width: 100%;
  padding: 20px;
  transition: 0.3s all;
  border: 1px solid #eee;
  border-radius: 5px;
  outline: none;
  color: #222;
  font-family: "Source Sans Pro", sans-serif;
}

.woocommerce-checkout .col2-set .woocommerce-billing-fields__field-wrapper p input::placeholder {
  color: #999;
  font-family: "Source Sans Pro", sans-serif;
}

.woocommerce-checkout .col2-set .woocommerce-billing-fields__field-wrapper p input:focus {
  border: 1px solid #2A74ED;
  border-radius: 5px;
  box-shadow: 0px 15px 30px 0px rgba(42, 116, 237, 0.1);
}

.woocommerce-checkout .col2-set .woocommerce-billing-fields__field-wrapper p select {
  width: 100%;
  height: 60px;
  padding: 18px;
  transition: 0.3s all;
  border: 1px solid #eee;
  border-radius: 5px;
  outline: none;
  color: #222;
  font-family: "Source Sans Pro", sans-serif;
  background: #FFFFFF;
}

.woocommerce-checkout .col2-set .woocommerce-billing-fields__field-wrapper p select:focus {
  border: 1px solid #2A74ED;
  box-shadow: 0px 15px 30px 0px rgba(42, 116, 237, 0.1);
}

.woocommerce-checkout .col2-set .woocommerce-billing-fields__field-wrapper p select.cf7-selected {
  color: #999;
}

.woocommerce-checkout .col2-set .woocommerce-billing-fields__field-wrapper p#billing_company_field {
  flex-basis: 100%;
}

.woocommerce-checkout .col2-set .woocommerce-billing-fields__field-wrapper p#billing_country_field {
  flex-basis: 100%;
  order: -1;
}

.woocommerce-checkout .col2-set .woocommerce-billing-fields__field-wrapper p#billing_address_1_field {
  flex-basis: 100%;
}

.woocommerce-checkout .col2-set .woocommerce-billing-fields__field-wrapper p#billing_city_field {
  flex-basis: 100%;
}

.woocommerce-checkout .col2-set .woocommerce-billing-fields__field-wrapper p#billing_postcode_field {
  flex-basis: 100%;
}

.woocommerce-checkout .col2-set #creat_an_account {
  font-size: 13px;
}

.woocommerce-checkout .col2-set #creat_an_account label {
  color: #696969;
}

.woocommerce-checkout .col2-set .col-2 {
  margin-top: 20px;
}

.woocommerce-checkout .col2-set .woocommerce-shipping-fields h3 {
  font-size: 14px;
}

.woocommerce-checkout .col2-set .woocommerce-shipping-fields h3 label input[type=checkbox] {
  margin-right: 10px;
  outline: none;
}

.woocommerce-checkout .col2-set .woocommerce-shipping-fields__field-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin: -15px;
}

.woocommerce-checkout .col2-set .woocommerce-shipping-fields__field-wrapper p {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-end;
  min-width: 200px;
  margin: 15px;
}

.woocommerce-checkout .col2-set .woocommerce-shipping-fields__field-wrapper p label {
  margin-bottom: 8px;
}

.woocommerce-checkout .col2-set .woocommerce-shipping-fields__field-wrapper p abbr {
  text-decoration: none;
}

.woocommerce-checkout .col2-set .woocommerce-shipping-fields__field-wrapper p input {
  width: 100%;
  padding: 20px;
  transition: 0.3s all;
  border: 1px solid #eee;
  border-radius: 5px;
  outline: none;
  color: #999;
  font-family: "Source Sans Pro", sans-serif;
}

.woocommerce-checkout .col2-set .woocommerce-shipping-fields__field-wrapper p input::placeholder {
  color: #999;
  font-family: "Source Sans Pro", sans-serif;
}

.woocommerce-checkout .col2-set .woocommerce-shipping-fields__field-wrapper p input:focus {
  border: 1px solid #2A74ED;
  border-radius: 5px;
  box-shadow: 0px 15px 30px 0px rgba(42, 116, 237, 0.1);
}

.woocommerce-checkout .col2-set .woocommerce-shipping-fields__field-wrapper p select {
  width: 100%;
  height: 60px;
  padding: 20px;
  transition: 0.3s all;
  border: 1px solid #eee;
  border-radius: 5px;
  outline: none;
  color: #999;
  font-family: "Source Sans Pro", sans-serif;
}

.woocommerce-checkout .col2-set .woocommerce-shipping-fields__field-wrapper p select:focus {
  border: 1px solid #2A74ED;
  box-shadow: 0px 15px 30px 0px rgba(42, 116, 237, 0.1);
}

.woocommerce-checkout .col2-set .woocommerce-shipping-fields__field-wrapper p select.cf7-selected {
  color: #999;
}

.woocommerce-checkout .col2-set .woocommerce-shipping-fields__field-wrapper p#shipping_company_field {
  flex-basis: 100%;
}

.woocommerce-checkout .col2-set .woocommerce-shipping-fields__field-wrapper p#shipping_country_field {
  flex-basis: 100%;
  order: -1;
}

.woocommerce-checkout .col2-set .woocommerce-shipping-fields__field-wrapper p#shipping_postcode_field {
  flex-basis: 100%;
}

.woocommerce-checkout .col2-set .select2 {
  width: 100%;
  padding: 20px;
  transition: 0.3s all;
  border: 1px solid #eee;
  border-radius: 5px;
  outline: none;
  color: #999;
  font-family: "Source Sans Pro", sans-serif;
}

.woocommerce-checkout .col2-set .select2::placeholder {
  color: #999;
  font-family: "Source Sans Pro", sans-serif;
}

.woocommerce-checkout .col2-set .select2:focus {
  border: 1px solid #2A74ED;
  border-radius: 5px;
  box-shadow: 0px 15px 30px 0px rgba(42, 116, 237, 0.1);
}

.woocommerce-checkout .col2-set .select2-selection {
  outline: none;
}

.woocommerce-checkout .col2-set .notes label {
  font-size: 16px;
  font-weight: 500;
}

.woocommerce-checkout .col2-set .woocommerce-additional-fields__field-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin: -15px;
}

.woocommerce-checkout .col2-set .woocommerce-additional-fields__field-wrapper p {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-end;
  min-width: 200px;
  margin: 15px;
}

.woocommerce-checkout .col2-set .woocommerce-additional-fields__field-wrapper p label {
  margin-bottom: 5px;
  color: #696969;
}

.woocommerce-checkout .col2-set .woocommerce-additional-fields__field-wrapper p textarea {
  width: 100%;
  height: 200px;
  padding: 20px;
  transition: 0.3s all;
  border: 1px solid #eee;
  border-radius: 5px;
  outline: none;
  color: #999;
  font-family: "Source Sans Pro", sans-serif;
  resize: none;
}

.woocommerce-checkout .col2-set .woocommerce-additional-fields__field-wrapper p textarea::placeholder {
  color: #999;
  font-family: "Source Sans Pro", sans-serif;
}

.woocommerce-checkout .col2-set .woocommerce-additional-fields__field-wrapper p textarea:focus {
  border: 1px solid #2A74ED;
  border-radius: 5px;
  box-shadow: 0px 15px 30px 0px rgba(42, 116, 237, 0.1);
}

.woocommerce-checkout .ship-to-different-address {
  line-height: 8px;
}

.woocommerce-checkout .checkout_order {
  display: flex;
  flex: 2;
  flex-direction: column;
  align-self: flex-start;
  padding: 70px 35px;
  background: #f9f9f9;
  border: 1px solid #eeeeee;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .woocommerce-checkout .checkout_order {
    padding: 50px 35px;
  }
}

.woocommerce-checkout .checkout_order h3 {
  margin: 0;
  padding: 0 40px;
  font-weight: 300;
}

.woocommerce-checkout .checkout_order .woocommerce-checkout-review-order {
  margin-top: 30px;
  padding: 0px;
  border-top: 1px solid #eee;
}

.woocommerce-checkout .checkout_order .woocommerce-checkout-review-order .shop_table {
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}

.woocommerce-checkout .checkout_order .woocommerce-checkout-review-order .shop_table thead tr th:last-child,
.woocommerce-checkout .checkout_order .woocommerce-checkout-review-order .shop_table thead tr td:last-child,
.woocommerce-checkout .checkout_order .woocommerce-checkout-review-order .shop_table tbody tr th:last-child,
.woocommerce-checkout .checkout_order .woocommerce-checkout-review-order .shop_table tbody tr td:last-child,
.woocommerce-checkout .checkout_order .woocommerce-checkout-review-order .shop_table tfoot tr th:last-child,
.woocommerce-checkout .checkout_order .woocommerce-checkout-review-order .shop_table tfoot tr td:last-child {
  text-align: right;
}

.woocommerce-checkout .checkout_order .woocommerce-checkout-review-order .shop_table thead tr th {
  font-size: 16px;
  font-weight: 500;
  line-height: 50px;
}

.woocommerce-checkout .checkout_order .woocommerce-checkout-review-order .shop_table tbody tr {
  font-size: 14px;
  font-weight: 400;
  line-height: 40px;
}

.woocommerce-checkout .checkout_order .woocommerce-checkout-review-order .shop_table tbody tr td strong {
  font-weight: 400;
  color: #999;
}

.woocommerce-checkout .checkout_order .woocommerce-checkout-review-order .shop_table tbody tr td:last-child {
  color: #999;
}

.woocommerce-checkout .checkout_order .woocommerce-checkout-review-order .shop_table tfoot tr {
  border-top: 1px solid #eee;
  line-height: 50px;
}

.woocommerce-checkout .checkout_order .woocommerce-checkout-review-order .shop_table tfoot tr th {
  font-weight: 500;
}

.woocommerce-checkout .checkout_order .woocommerce-checkout-review-order .shop_table tfoot tr.shipping td {
  color: #999;
}

.woocommerce-checkout .checkout_order .woocommerce-checkout-review-order .shop_table tfoot tr.order-total {
  border-bottom: 1px solid #eee;
  line-height: 80px;
}

.woocommerce-checkout .checkout_order .woocommerce-checkout-review-order .shop_table tfoot tr.order-total td strong {
  font-size: 30px;
  font-weight: 300;
}

.woocommerce-checkout .checkout_order .woocommerce-checkout-payment .aheto-btn {
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .woocommerce-checkout .col2-set {
    flex-basis: 100%;
  }
  .woocommerce-checkout .col2-set .col-1,
  .woocommerce-checkout .col2-set .col-2 {
    max-width: none;
    margin: 0;
  }
  .woocommerce-checkout .checkout_order {
    margin-top: 50px;
  }
}

.woocommerce-checkout .col2-set .col-1, .woocommerce-checkout .col2-set .col-2 {
  display: table;
  width: 100%;
}

#creat_an_account label {
  color: #696969;
}

#ship-to-different-address-checkbox + span {
  font-size: 13px;
  font-weight: 400;
}

.cheque_payment span {
  display: block;
}

.cheque_payment label {
  font-size: 14px;
  color: #999;
  padding-right: 20px;
}

.cheque_payment input[type=radio] {
  display: none;
}

.cheque_payment input[type=radio] + label:before {
  content: "\f111";
  font-family: FontAwesome;
  border-radius: 50%;
  display: inline-block;
  border: 1px solid #ababab;
  line-height: 20px;
  margin: -5px 10px 0 0;
  height: 14px;
  width: 14px;
  text-align: center;
  vertical-align: middle;
  font-size: 0;
}

.cheque_payment input[type=radio]:checked + label:before {
  font-size: 6px;
  line-height: 13px;
  color: #222222;
}

.product {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  font-family: "Source Sans Pro", sans-serif;
}

.product > .onsale {
  display: block;
  position: absolute;
  top: 20px;
  left: 0;
  width: 55px;
  height: 25px;
  padding: 7px 14px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  z-index: 10;
}

.product .woocommerce-product-gallery {
  flex: 1;
  width: 100%;
  max-width: 570px;
  margin-right: 30px;
}

.product .summary {
  flex: 1;
}

.product .summary .product_title {
  margin: 0;
}

.product .summary .woocommerce-stars-rating {
  display: flex;
  flex-wrap: wrap;
  margin-top: 25px;
}

.product .summary .woocommerce-stars-rating .tm__stars {
  margin-right: 10px;
}

.product .summary .woocommerce-stars-rating .tm__stars i {
  color: #FFC015;
  font-size: 16px;
}

.product .summary .woocommerce-stars-rating .woocommerce-review-link {
  color: #ababab;
  font-size: 14px;
}

.product .summary .woocommerce-product-rating {
  margin-top: 20px;
}

.product .summary .woocommerce-product-rating .star-rating span {
  font-size: 14px;
}

.product .summary .woocommerce-product-rating .woocommerce-review-link {
  font-size: 14px;
}

.product .summary .price {
  margin: 20px 0;
  color: #222;
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
}

.product .summary .price del {
  margin-right: 10px;
  color: #999;
}

.product .summary .price ins {
  text-decoration: none;
}

.product .summary .product_meta {
  margin-top: 25px;
  color: #999;
}

.product .summary .product_meta > span {
  display: flex;
  align-items: center;
}

.product .summary .product_meta-title {
  width: 150px;
  color: #222;
  font-size: 14px;
  font-weight: 500;
  line-height: 2.71;
}

.product .summary .product_meta a {
  color: #999;
  font-size: 14px;
}

.product .summary .product_meta span {
  font-size: 14px;
}

.product .summary .woocommerce-share {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.product .summary .woocommerce-share__title {
  width: 150px;
  margin: 0;
  font-weight: 500;
  line-height: 36px;
  font-size: 14px;
}

.product .summary .woocommerce-share__link {
  margin-right: 20px;
  color: #999;
  font-size: 14px;
}

.product .summary .woocommerce-share__link:hover {
  color: #2a74ed;
}

.product .summary .woocommerce-share__link:last-child {
  margin-right: 0;
}

.product .cart .option-holder {
  margin: 20px 0 30px;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}

.product .cart .quantity {
  display: flex;
  flex-wrap: wrap;
  margin: 25px 0 40px;
  border-radius: 3px;
}

.product .cart .quantity label {
  display: block;
  flex-basis: 100%;
  order: -1;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 500;
}

.product .cart .quantity .quantity-input {
  display: flex;
  background-color: #f7f7f7;
}

.product .cart .quantity .quantity-input span {
  display: inline-block;
  width: 42px;
  padding: 14px 0;
  color: #999;
  font-size: 20px;
  text-align: center;
  cursor: pointer;
  user-select: none;
}

.product .cart .quantity .quantity-input input {
  width: 42px;
  border: 0;
  outline: none;
  background-color: #f7f7f7;
  color: #222;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
}

.product .cart .quantity .quantity-input input::-webkit-outer-spin-button, .product .cart .quantity .quantity-input input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.product .product-color {
  float: left;
  margin: 25px 60px 40px 0px;
}

.product .product-color label {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 36px;
}

.product .product-color input[type=checkbox] {
  display: none;
}

.product .product-color input[type=checkbox] + label:before {
  content: "\2713";
  color: transparent;
  display: inline-block;
  border: 1px solid #e0e0e0;
  font-size: 14px;
  line-height: 19px;
  margin: -6px 20px 0 0;
  height: 20px;
  width: 20px;
  border-radius: 3px;
  text-align: center;
  vertical-align: middle;
  transition: color ease .3s;
}

.product .product-color input[type=checkbox]:checked + label:before {
  color: #222;
}

.product .product-color input[id=black] + label:before {
  background: #181514;
}

.product .product-color input[id=black]:checked + label:before {
  color: #ffffff;
}

.product .product-color input[id=grey] + label:before {
  background: #d7d8da;
}

.product .woocommerce-tabs {
  position: relative;
  flex-basis: 100%;
  margin-bottom: 100px;
}

@media screen and (max-width: 991px) {
  .product .woocommerce-tabs {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 991px) {
  .product .woocommerce-tabs {
    margin-bottom: 50px;
  }
}

.product .woocommerce-tabs .tabs {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  max-width: 400px;
  margin: 50px auto 55px;
  padding: 0;
}

@media screen and (max-width: 768px) {
  .product .woocommerce-tabs .tabs {
    max-width: 100%;
  }
}

.product .woocommerce-tabs .tabs > li {
  margin: 15px 0;
  padding-left: 0;
  position: relative;
}

@media screen and (max-width: 768px) {
  .product .woocommerce-tabs .tabs > li {
    width: 100%;
  }
}

.product .woocommerce-tabs .tabs > li:first-child:before {
  content: '';
  border: 1px solid #eeeeee;
  position: absolute;
  height: 100%;
  right: -50px;
}

@media screen and (max-width: 768px) {
  .product .woocommerce-tabs .tabs > li:first-child:before {
    content: '';
    border: 1px solid #eeeeee;
    position: absolute;
    height: 1px;
    right: 0px;
    width: 100%;
    bottom: -14px;
  }
}

.product .woocommerce-tabs .tabs > li:before {
  content: none;
}

.product .woocommerce-tabs .tabs > li a {
  color: #222;
  font-size: 30px;
  font-weight: 300;
  line-height: 30px;
}

.product .woocommerce-tabs .tabs > li a:hover {
  color: #2A74ED;
}

.product .woocommerce-tabs .tabs > li.active a {
  color: #2a74ed;
}

.product .woocommerce-tabs .woocommerce-Tabs-panel {
  max-width: 770px;
  margin: 0 auto;
}

.product .woocommerce-tabs .woocommerce-Tabs-panel > h2 {
  display: none;
}

.product .woocommerce-tabs .woocommerce-Reviews #comments .commentlist {
  margin: 0;
  padding: 0;
}

.product .woocommerce-tabs .woocommerce-Reviews #comments .commentlist .comment {
  margin-bottom: 30px;
  padding: 0 0 30px;
  border-bottom: 1px solid #eee;
}

.product .woocommerce-tabs .woocommerce-Reviews #comments .commentlist .comment:before {
  content: none;
}

.product .woocommerce-tabs .woocommerce-Reviews #comments .commentlist .comment:last-child {
  margin-bottom: 0;
}

.product .woocommerce-tabs .woocommerce-Reviews #comments .commentlist .comment_container {
  display: flex;
}

.product .woocommerce-tabs .woocommerce-Reviews #comments .commentlist .comment_container .avatar {
  margin-right: 15px;
  border-radius: 50%;
}

.product .woocommerce-tabs .woocommerce-Reviews #comments .commentlist .comment_container .comment-text .meta {
  margin: 0;
}

.product .woocommerce-tabs .woocommerce-Reviews #comments .commentlist .comment_container .comment-text .meta .woocommerce-review__author {
  color: #999;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
}

.product .woocommerce-tabs .woocommerce-Reviews #comments .commentlist .comment_container .comment-text .meta .woocommerce-review__dash {
  color: #999;
}

.product .woocommerce-tabs .woocommerce-Reviews #comments .commentlist .comment_container .comment-text .meta .woocommerce-review__published-date {
  color: #999;
  font-size: 14px;
  line-height: 16px;
}

.product .woocommerce-tabs .woocommerce-Reviews #comments .commentlist .comment_container .comment-text .description p {
  margin-top: 15px;
  margin-bottom: 0;
}

.product .woocommerce-tabs .woocommerce-Reviews #review_form_wrapper {
  margin-top: 75px;
  text-align: center;
}

.product .woocommerce-tabs .woocommerce-Reviews #review_form_wrapper .comment-respond .comment-reply-title {
  font-size: 30px;
  font-weight: 300;
  line-height: 40px;
}

.product .woocommerce-tabs .woocommerce-Reviews #review_form_wrapper .comment-respond .comment-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.product .woocommerce-tabs .woocommerce-Reviews #review_form_wrapper .comment-respond .comment-form input {
  transition: all 0.3s;
  border: 1px solid #eee;
  border-radius: 5px;
  outline: none;
  font-family: "Source Sans Pro", sans-serif;
}

.product .woocommerce-tabs .woocommerce-Reviews #review_form_wrapper .comment-respond .comment-form input::placeholder {
  color: #999;
  font-family: "Source Sans Pro", sans-serif;
}

.product .woocommerce-tabs .woocommerce-Reviews #review_form_wrapper .comment-respond .comment-form input:focus {
  border: 1px solid #2A74ED;
  border-radius: 5px;
  box-shadow: 0px 15px 30px 0px rgba(42, 116, 237, 0.1);
}

.product .woocommerce-tabs .woocommerce-Reviews #review_form_wrapper .comment-respond .comment-form textarea {
  width: 100%;
  height: 140px;
  padding: 20px;
  transition: all 0.3s;
  border: 1px solid #eee;
  border-radius: 5px;
  outline: none;
  color: #999;
  font-family: "Source Sans Pro", sans-serif;
  resize: none;
}

.product .woocommerce-tabs .woocommerce-Reviews #review_form_wrapper .comment-respond .comment-form textarea::placeholder {
  color: #999;
  font-family: "Source Sans Pro", sans-serif;
}

.product .woocommerce-tabs .woocommerce-Reviews #review_form_wrapper .comment-respond .comment-form textarea:focus {
  border: 1px solid #2A74ED;
  border-radius: 5px;
  box-shadow: 0px 15px 30px 0px rgba(42, 116, 237, 0.1);
}

.product .woocommerce-tabs .woocommerce-Reviews #review_form_wrapper .comment-respond .comment-form .comment-notes {
  order: -1;
}

.product .woocommerce-tabs .woocommerce-Reviews #review_form_wrapper .comment-respond .comment-form-author {
  flex: 1;
  margin-right: 30px;
}

.product .woocommerce-tabs .woocommerce-Reviews #review_form_wrapper .comment-respond .comment-form-author label {
  display: none;
}

.product .woocommerce-tabs .woocommerce-Reviews #review_form_wrapper .comment-respond .comment-form-email {
  flex: 1;
}

.product .woocommerce-tabs .woocommerce-Reviews #review_form_wrapper .comment-respond .comment-form-email label {
  display: none;
}

.product .woocommerce-tabs .woocommerce-Reviews #review_form_wrapper .comment-respond .comment-form-rating {
  display: flex;
  flex-basis: 100%;
  order: -1;
  margin-top: 45px;
  margin-bottom: 15px;
}

@media screen and (max-width: 991px) {
  .product .woocommerce-tabs .woocommerce-Reviews #review_form_wrapper .comment-respond .comment-form-rating {
    margin-top: 25px;
  }
}

.product .woocommerce-tabs .woocommerce-Reviews #review_form_wrapper .comment-respond .comment-form-rating label {
  margin-right: 20px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.product .woocommerce-tabs .woocommerce-Reviews #review_form_wrapper .comment-respond .comment-form-rating p {
  margin: 0;
}

.product .woocommerce-tabs .woocommerce-Reviews #review_form_wrapper .comment-respond .comment-form-rating p span {
  display: block;
  direction: ltr;
}

.product .woocommerce-tabs .woocommerce-Reviews #review_form_wrapper .comment-respond .comment-form-rating p span > a:hover:before,
.product .woocommerce-tabs .woocommerce-Reviews #review_form_wrapper .comment-respond .comment-form-rating p span > a:hover ~ a:before {
  color: #999;
  content: '\f4b2';
}

.product .woocommerce-tabs .woocommerce-Reviews #review_form_wrapper .comment-respond .comment-form-rating p span a {
  display: inline-block;
  transition: all 0s ease 0s;
  color: #999;
  font-size: 0;
  line-height: 24px;
}

.product .woocommerce-tabs .woocommerce-Reviews #review_form_wrapper .comment-respond .comment-form-rating p span a:before {
  color: #999999;
  font-family: "Ionicons";
  font-size: 16px;
  content: '\f4b2';
}

.product .woocommerce-tabs .woocommerce-Reviews #review_form_wrapper .comment-respond .comment-form-rating p span a:hover {
  opacity: 1;
}

.product .woocommerce-tabs .woocommerce-Reviews #review_form_wrapper .comment-respond .comment-form-rating p span a:hover:before {
  color: #FFC015;
  content: '\f4b3';
}

.product .woocommerce-tabs .woocommerce-Reviews #review_form_wrapper .comment-respond .comment-form-rating p span a.active ~ a:before {
  color: #999;
  content: '\f4b2';
}

.product .woocommerce-tabs .woocommerce-Reviews #review_form_wrapper .comment-respond .comment-form-comment {
  flex-basis: 100%;
  margin: 15px 0 0;
}

.product .woocommerce-tabs .woocommerce-Reviews #review_form_wrapper .comment-respond .comment-form-comment label {
  display: none;
}

.product .woocommerce-tabs .woocommerce-Reviews #review_form_wrapper .comment-respond .form-submit input[type=submit] {
  display: inline-block;
  margin-top: 25px;
  padding: 21px 40px;
  border: 0;
  border-radius: 5px;
  color: #fff;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 1.5px;
  line-height: 1.15;
  text-transform: uppercase;
  cursor: pointer;
}

.product .woocommerce-tabs .shop_attributes tbody tr {
  line-height: 40px;
}

.product .woocommerce-tabs .shop_attributes tbody tr th {
  padding-right: 50px;
  color: #999;
  text-align: left;
}

.product .woocommerce-tabs .shop_attributes tbody tr td {
  color: #999;
  text-align: left;
}

.product .woocommerce-tabs .shop_attributes tbody tr td p {
  margin: 0;
  color: #999;
}

@media screen and (max-width: 1500px) {
  .product .woocommerce-product-gallery {
    max-width: 460px;
  }
}

@media screen and (max-width: 991px) {
  .product .woocommerce-product-gallery {
    max-width: 345px;
  }
}

@media screen and (max-width: 767px) {
  .product {
    flex-direction: column;
    justify-content: center;
  }
  .product > .onsale {
    display: inline-block;
    position: static;
    flex-basis: 100%;
    order: 2;
    width: auto;
    margin-top: 20px;
    margin-right: auto;
  }
  .product .woocommerce-product-gallery {
    flex-basis: 100%;
    order: 1;
    max-width: 460px;
    margin-right: auto;
    margin-left: auto;
  }
  .product .summary {
    flex-basis: 100%;
    order: 3;
    margin-top: 20px;
  }
  .product .summary .product_meta-title {
    width: auto;
    margin-right: 30px;
  }
  .product .summary .woocommerce-share__title {
    width: auto;
    margin-right: 30px;
    font-size: 14px;
    font-weight: 500;
    line-height: 2.71;
  }
  .product .woocommerce-tabs {
    order: 4;
    margin-bottom: 75px;
  }
  .product .woocommerce-tabs .tabs {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 50px;
  }
  .product .woocommerce-tabs .woocommerce-Reviews #review_form_wrapper {
    margin-top: 50px;
  }
  .product .woocommerce-tabs .woocommerce-Reviews #review_form_wrapper .comment-respond .comment-form-author {
    flex-basis: 100%;
    margin-right: 0;
  }
}

.comment-shop {
  border: none;
  border-bottom: 1px solid #eeeeee;
  margin: 0px;
  padding-bottom: 10px;
}

.comment-shop .comment-author .author-photo {
  margin-right: 20px;
  margin-left: 0px;
}

.comment-shop .btn-reply {
  font-size: 14px;
  color: #bbbbbb;
  line-height: 1.71;
  display: inline-block;
  padding-top: 10px;
}

.comment-shop .btn-reply:hover {
  color: #222;
}

.comment-shop .btn-reply i {
  padding-right: 10px;
}

.comment-shop .comment-content {
  position: relative;
}

.comment-shop .comment-content .tm__stars {
  line-height: 1.5;
  letter-spacing: -1.2px;
  text-align: left;
  color: #ffc015;
  position: absolute;
  top: 0px;
  right: 0px;
}

@media screen and (max-width: 450px) {
  .comment-shop .comment-content .tm__stars {
    top: 19px;
    right: auto;
    left: 0px;
  }
}

.swiper-margin .swiper-wrapper {
  margin-left: 2px;
}

.add_coment {
  width: 100%;
}

.add_coment .aheto-form--default p {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin: -14px;
  margin-top: 0;
  margin-bottom: 0;
}

.add_coment .aheto-form--default p .wpcf7-submit {
  margin-top: 25px !important;
}

.add_coment .Name {
  width: 50%;
}

.add_coment .Email {
  width: 50%;
}

@media (min-width: 990px) and (max-width: 1135px) {
  .cart-related-products-wrap .aheto-products__item:last-child {
    display: none !important;
  }
}

@media (min-width: 840px) and (max-width: 950px) {
  .cart-related-products-wrap .aheto-products__item:last-child {
    display: none !important;
  }
}

@media (min-width: 768px) and (max-width: 855px) {
  .cart-related-products-wrap .aheto-products__item:last-child {
    display: block !important;
  }
}

.aheto-product-images__main {
  margin-bottom: 20px;
}

.aheto-product-images__main-img {
  height: 730px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.aheto-product-images__main-link {
  display: block;
  width: 100%;
  height: 100%;
}

.aheto-product-images__thumbs-img {
  width: 105px;
  height: 130px;
  margin-top: 10px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

@media screen and (max-width: 1500px) {
  .aheto-product-images .aheto-product-images__main-img {
    height: 500px;
  }
}

.widget_aheto__info {
  color: #111539;
}

.widget_aheto__link {
  color: #111539;
}

.widget_aheto__icon {
  color: #EBB113;
}

.widget_aheto__link {
  color: #999999;
}

.widget_aheto .aheto-socials__link {
  color: dimgray;
}

.widget_aheto .aheto-socials__link__icon {
  color: dimgray;
}

.widget_nav_menu .menu-item a {
  color: #999999;
  font-size: 16px;
}

.widget_nav_menu_1 .menu-item a {
  color: #999999;
}

.widget_nav_menu_1 .menu-item a:before {
  border-color: transparent #999999;
}

.widget_nav_menu_1 .menu-item:hover a {
  color: #EBB113;
}

.widget_nav_menu_1 .menu-item:hover a:hover:before {
  border-color: transparent #EBB113;
}

.widget_nav_menu_1 .menu-item.current-menu-item a {
  color: #EBB113;
}

.widget_nav_menu_1 .menu-item.current-menu-item a:before {
  border-color: transparent #EBB113;
}

.widget_nav_menu_2 .menu-item a {
  color: #999999;
}

.widget_nav_menu_3 .menu-item a {
  color: #999999;
}

.widget_nav_menu_3 .menu-item a:before {
  background-color: #EBB113;
}

.widget_nav_menu_3 .menu-item:hover a {
  color: #EBB113;
}

.widget_nav_menu_3 .menu-item:hover a:hover:before {
  border-color: transparent #EBB113;
}

.widget_nav_menu_3 .menu-item.current-menu-item a:before {
  background-color: #EBB113;
}

.widget_recent_entries_1 .post-date {
  color: #999999;
}

.widget_recent_entries_2 a {
  color: #111539;
}

.widget_recent_entries_2 a:hover {
  color: #EBB113;
}

.widget_recent_entries_2 .post-date {
  color: #999999;
  font-family: "Roboto", sans-serif;
}

.widget_recent_entries_3 li {
  border-bottom: 1px solid rgba(153, 153, 153, 0.3);
}

.widget_recent_entries_3 a:hover {
  color: #EBB113;
}

.widget_recent_entries_3 .post-date {
  color: #999999;
}

.widget_mc4wp_form_widget.aheto_mc_1 input[type=submit] {
  background-color: #EBB113;
}

.widget_mc4wp_form_widget.aheto_mc_1 input[type=submit]:hover {
  background: rgba(235, 177, 19, 0.8) !important;
}

.widget_mc4wp_form_widget.aheto_mc_2 button,
.widget_mc4wp_form_widget.aheto_mc_2 input {
  color: #111539;
}

.widget_mc4wp_form_widget.aheto_mc_2 button::placeholder,
.widget_mc4wp_form_widget.aheto_mc_2 input::placeholder {
  color: #999999;
}

.widget_mc4wp_form_widget.aheto_mc_2 button[type=submit],
.widget_mc4wp_form_widget.aheto_mc_2 input[type=submit] {
  background: #2A74ED;
}

.widget_mc4wp_form_widget.aheto_mc_2 button[type=submit]:hover,
.widget_mc4wp_form_widget.aheto_mc_2 input[type=submit]:hover {
  background: rgba(235, 177, 19, 0.8) !important;
}

.widget_mc4wp_form_widget.aheto_mc_3 input {
  color: #111539;
}

.widget_mc4wp_form_widget.aheto_mc_3 input::placeholder {
  color: #999999;
}

.widget_mc4wp_form_widget.aheto_mc_3 button[type=submit] {
  color: #EBB113;
}

.widget_mc4wp_form_widget.aheto_mc_3 button[type=submit]:hover {
  background-color: #EBB113;
}

.widget_time_schedule li {
  color: #999999;
}

.widget_custom_form textarea,
.widget_custom_form input[type=text],
.widget_custom_form input[type=email],
.widget_custom_form input[type=url] {
  font-family: "Source Sans Pro", sans-serif;
}

.widget_custom_form button[type=submit],
.widget_custom_form input[type=submit] {
  background-color: #EBB113;
  font-family: "Source Sans Pro", sans-serif;
}

.widget_custom_form button[type=submit]:hover,
.widget_custom_form input[type=submit]:hover {
  background: rgba(235, 177, 19, 0.8) !important;
}

.widget_twitter .widget-add span,
.widget_twitter .widget-add a {
  font-family: "Source Sans Pro", sans-serif;
}

.widget-courses--edu .widget-title {
  font-family: "Roboto", sans-serif;
}

.widget-courses--edu .aht-courses-wd__link {
  color: #999999;
  font-family: "Roboto", sans-serif;
}

.widget-courses--edu .aht-courses-wd__link:hover {
  color: #111539;
}

.aheto-socials__link .icon {
  color: #999999;
}

.aheto-socials__link:hover .icon {
  color: #EBB113;
}

.aheto-socials .aht-socials__link {
  color: #111539;
}

.aheto-socials .aht-socials__link:hover {
  color: #EBB113;
}

.aheto-footer-menu .menu-item a {
  font-family: "Source Sans Pro", sans-serif;
}

.aheto-footer-menu .menu-item a:hover {
  color: #EBB113;
}

.aht-socials--circle .aht-socials__link {
  border: 1px solid #999999;
  color: #999999;
}

.aheto-footer-1 .widget-title {
  border-bottom: 1px solid rgba(153, 153, 153, 0.25);
}

.aheto-footer-2 .widget_aheto__info {
  color: #999999;
}

.aheto-footer-2 .widget_aheto__info a {
  color: #999999;
}

.aheto-footer-2 .widget-title {
  color: #EBB113;
}

.aheto-footer-4 .widget_aheto__link {
  color: #111539;
}

.aheto-footer-4 .widget_aheto__link:hover {
  color: #EBB113;
}

.aheto-footer-4 .widget-title {
  color: #111539;
}

.aheto-footer-5 .widget_aheto__info {
  color: #999999;
}

.aheto-footer-5 .widget_aheto__link {
  color: #999999;
}

.aheto-footer-5 .widget_mc4wp_form_widget.aheto_mc_2 button[type=submit],
.aheto-footer-5 .widget_mc4wp_form_widget.aheto_mc_2 input[type=submit] {
  background: #EBB113;
}

.aheto-footer-5 .widget_mc4wp_form_widget.aheto_mc_2 button[type=submit]:hover,
.aheto-footer-5 .widget_mc4wp_form_widget.aheto_mc_2 input[type=submit]:hover {
  background: rgba(235, 177, 19, 0.8) !important;
}

.aheto-footer-6 .widget_aheto__link:hover {
  color: #EBB113;
}

.aheto-footer-6 .widget_recent_entries_2 li a:hover {
  color: #EBB113;
}

.aheto-footer-6 .widget_mc4wp_form_widget.aheto_mc_1 button[type=email],
.aheto-footer-6 .widget_mc4wp_form_widget.aheto_mc_1 input[type=email] {
  color: #999999;
}

.aheto-footer-6 .widget_mc4wp_form_widget.aheto_mc_1 button[type=submit],
.aheto-footer-6 .widget_mc4wp_form_widget.aheto_mc_1 input[type=submit] {
  background-color: #EBB113;
  color: #111539;
}

.aheto-footer-6 .widget_mc4wp_form_widget.aheto_mc_1 button[type=submit]:hover,
.aheto-footer-6 .widget_mc4wp_form_widget.aheto_mc_1 input[type=submit]:hover {
  background: rgba(235, 177, 19, 0.8) !important;
}

.aheto-footer-7 .widget_nav_menu .menu-item a {
  color: #999999;
}

.aheto-footer-8 .widget_twitter .widget-add span,
.aheto-footer-8 .widget_twitter .widget-add a {
  font-family: "Source Sans Pro", sans-serif;
}

.aheto-footer-9 .widget_aheto__info {
  color: #999999;
}

.aheto-footer-9 .widget_aheto__info a {
  color: #999999;
  font-size: 16px;
}

.aheto-footer-9 .widget_aheto__info a:hover {
  color: #EBB113;
}

.aheto-footer-9 .widget_aheto__info--tel a {
  color: #EBB113;
  font-size: 20px;
}

.aheto-footer-9 .widget_aheto__info--tel a:hover {
  border-bottom: 1px solid #EBB113;
}

.aheto-footer-9 .widget-title {
  color: #111539;
}

.aheto-footer-9 .widget_nav_menu .menu-item a:hover {
  color: #111539;
  font-size: 16px;
}

.aheto-footer-10 .widget_aheto__info {
  color: #999999;
}

.aheto-footer-10 .widget_aheto__info a {
  color: #999999;
}

.aheto-footer-10 .widget-title {
  color: #EBB113;
}

.aheto-footer-10 .widget_mc4wp_form_widget.aheto_mc_2 button[type=submit],
.aheto-footer-10 .widget_mc4wp_form_widget.aheto_mc_2 input[type=submit] {
  background: #EBB113;
}

.aheto-footer-10 .widget_mc4wp_form_widget.aheto_mc_2 button[type=submit]:hover,
.aheto-footer-10 .widget_mc4wp_form_widget.aheto_mc_2 input[type=submit]:hover {
  background: rgba(235, 177, 19, 0.8) !important;
}

.aheto-footer-10 .widget_nav_menu_3 .menu-item:hover a {
  color: #fff;
}

.aheto-footer-11 .widget_aheto {
  margin-bottom: 45px;
}

.aheto-footer-11 .widget_aheto__link {
  color: #999999;
}

.aheto-footer-11 .widget_aheto__link:hover {
  color: #2A74ED;
}

.aheto-footer-11 .widget_aheto__desc a:hover {
  color: #2A74ED;
}

.aheto-footer-11 .widget_nav_menu .menu-item a {
  color: #999999;
}

.aheto-footer-11 .widget_nav_menu .menu-item a:hover {
  color: #2A74ED;
}

.aheto-footer-12 .widget-title {
  color: #111539;
}

.aheto-footer-12 .widget_aheto__info {
  color: #999999;
}

.aheto-footer-12 .widget_aheto__info a {
  color: #999999;
}

.aheto-footer-12 .widget_aheto__info a:hover {
  color: #EBB113;
}

.aheto-footer-12 .widget .aht-socials__icon {
  color: #EBB113;
}

.aheto-footer-12 .widget .aht-socials__icon:hover i {
  color: #EBB113;
}

.aheto-footer-12 .widget .aht-socials__link:hover__icon {
  color: #EBB113;
}

.aheto-footer-13 .widget_aheto__info {
  color: #999999;
}

.aheto-footer-13 .widget_aheto__info a {
  color: #999999;
}

.aheto-footer-14 .widget_aheto__info {
  color: #999999;
}

.aheto-footer-14 .widget_aheto__info a {
  color: #999999;
}

.aheto-footer-15 .widget_aheto__link:hover {
  color: #EBB113;
}

.aheto-footer-15 .widget_aheto__info {
  color: #999999;
  font-weight: 400;
}

.aheto-footer-15 .widget_recent_entries_2 li a:hover {
  color: #EBB113;
}

.aheto-footer-15 .widget_mc4wp_form_widget.aheto_mc_1 button[type=email],
.aheto-footer-15 .widget_mc4wp_form_widget.aheto_mc_1 input[type=email] {
  color: #999999;
}

.aheto-footer-15 .widget_mc4wp_form_widget.aheto_mc_1 button[type=submit],
.aheto-footer-15 .widget_mc4wp_form_widget.aheto_mc_1 input[type=submit] {
  background-color: #EBB113;
}

.aheto-footer-15 .widget_mc4wp_form_widget.aheto_mc_1 button[type=submit]:hover,
.aheto-footer-15 .widget_mc4wp_form_widget.aheto_mc_1 input[type=submit]:hover {
  background: rgba(235, 177, 19, 0.8) !important;
}

/*.widget_time_schedule li b*/
.aheto-footer-16 {
  background-color: #000;
}

.aheto-footer-16 .pull-right {
  color: rgba(255, 255, 255, 0.5);
}

.aheto-footer-16 .widget_time_schedule li b {
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  font-weight: normal;
}

.aheto-footer-16 .widget_nav_menu .menu-item a {
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  color: #fff;
  opacity: 0.5;
}

.aheto-footer-16 .widget_aheto__info {
  color: #fff;
  font-family: "Open Sans", sans-serif;
}

.aheto-footer-16 .widget_aheto__info a {
  color: #fff;
  font-family: "Open Sans", sans-serif;
}

.aheto-footer-16 .widget-title {
  color: #EBB113;
  font-size: 15px;
}

.aht-course--edu-details .aht-widget-advert--edu {
  padding-top: 31px;
  padding-bottom: 36px;
}

.aht-course--edu-details .aht-widget-advert--edu .aht-widget-advert__subtitle {
  font-family: "Roboto", sans-serif;
  color: #fff;
}

.aht-course--edu-details .aht-widget-advert--edu .aht-widget-advert__title {
  font-family: "Roboto", sans-serif;
}

.aht-course--edu-details .aht-widget-advert--edu .aht-widget-advert__desc {
  font-family: "Roboto", sans-serif;
}

.aht-course--edu-details .aht-widget-advert--edu .aht-widget-advert__link {
  font-family: "Roboto", sans-serif;
}

.aht-course--edu-details .aht-twitter {
  box-shadow: 0px 15px 30px 0 rgba(42, 116, 237, 0.1);
  background-color: #fff;
  margin-bottom: 60px;
}

.aht-course--edu-details .aht-twitter__link {
  color: #fff;
  background-color: #111539;
}

.aht-course--edu-details .aht-twitter .tweets {
  font-family: "Roboto", sans-serif;
}

.aht-course--edu-details .aht-twitter .btn {
  border-top: 1px solid #eeeeee;
  display: flex;
}

.aht-course--edu-details .aht-twitter .btn-left, .aht-course--edu-details .aht-twitter .btn-right {
  padding: 15px 0px;
  width: 50%;
  cursor: pointer;
}

.aht-course--edu-details .aht-twitter .btn-left {
  border-right: 1px solid #eeeeee;
}

.aht-course--edu-details .aht-twitter__follow {
  color: #111539;
  font-family: "Roboto", sans-serif;
}

.aht-course--edu-details .aheto_mc_edu {
  background: #111539 url(../img/inner-pages/blog/icon-envelope.png) no-repeat bottom right;
}

.aht-course--edu-details .aheto_mc_edu .mc4wp-form .mc4wp-form-223 .widget-title {
  font-family: "Source Sans Pro", sans-serif;
  color: #fff;
}

.aht-course--edu-details .aheto_mc_edu .mc4wp-form-fields p {
  font-family: "Roboto", sans-serif;
}

.aht-course--edu-details .aheto_mc_edu .mc4wp-form-fields input[type="submit"] {
  color: #111539;
  background-color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.5);
  font-family: "Roboto", sans-serif;
}

.aht-course--edu-details .aheto_mc_edu .mc4wp-form-fields input[type="submit"]:hover {
  background: transparent;
  color: #fff;
  border: 1px solid #fff;
  transition: 0.3s all;
}
