.aht-breadcrumbs {
  &__item {
    &:before {
      color: $c-grey;
    }
  }

  &--edu {
    border-top: 1px solid #eee;
    padding-top: 23px;
    padding-bottom: 21px;
    .aht-breadcrumbs {
      &__link, &__item {
        font-family: $f-roboto;
      }
    }  
  }

  &--yoga {

    .aht-breadcrumbs {
      
      &__item {
        color: $c-grey;
        font-family: $f-roboto;
      }

      &__link {
        color: $c-active;
      }
    }
  }
}
