.aht-text {
  

  &--classic {
    .aht-text {
      &__text {
        font-family: $fnt-family-1;
      }
    }
  }
  &--trvl-2 {
    .aht-text {
      &__text {
        // font-family: $fnt-family-1;
        font-family: $f-roboto;
        letter-spacing: normal;
        font-size: 300px;
        text-shadow: 0px 18px 46px rgba($c-active, 0.15);

        @include medium-size-max {
          font-size: 200px;
        }
        @include small-size-max {
          font-size: 100px;
        }
      }
    }
  }
}

.list {
  li {
      color: $c-grey;
  }
}

.construction-about-blocks-list {
    li {
      &:before {
        background-color: #dba30a;
      }
    }
}
