.aht-tour {

  &--trvl {
    .aht-tour {
      &__detail-text {
        font-family: $fnt-family-2;
      }
      &__price {
        color: $c-active;
      }
      &__link {
        color: $c-dark;

        &:hover {
          border-color: $c-active;
          background-color: $c-active;
        }
      }
    }
  }
  
  &--trvl-2 {
    .aht-tour {

      &__caption {
        box-sizing: border-box;
      }

      &__promo {
        width: 70px;
        height: 70px;
        background-image: linear-gradient(to left, #fa6742, #ff961a);
        box-sizing: border-box;
        line-height: 1.07;
      }

      &:hover {
        .aht-tour__promo:hover {
          background-image: linear-gradient(to left, #fa6742, #ff961a);
        }        
      }

      &__link {
        color: $c-dark;
        background-color: $c-white;
        border: 1px solid #eee;

        &:hover {
          color: $c-white;
          border-color: $c-active;
          background-color: $c-active;
        }
      }

      &__per {
        font-size: 16px;
      }
    }
  }

  &--trvl {
    .aht-tour {
      &__caption {
        box-sizing: border-box;
      }

      &__icon {
        color: $c-alter;
      }

      &__detail-text {
        font-family: $f-mukta;
      }
    }
  }
}
