.aheto-footer-2 {
  &__main {
    padding: 45px 0 30px;

    @include medium-size-max {
      padding: 50px 0 0;
    }
  }
}

.cheurch-footer-wrap{
  padding-top: 52px;
  margin-bottom: -22px;
}

.footer-church-about,
.footer-church-news,
.footer-church-ministries,
.footer-church-events,
.footer-church-contact,
.footer-church-gallery,
.footer-church-sermons,
.footer-church-ministries-det {
  .aheto-footer-2 {
    &__main {
      padding: 45px 0 30px;
    }
  }
}

@media screen and (max-width: 1024px) {

  .footer-church-sermons,
  .footer-church-ministries-det {
    .aheto-footer-2 {
      &__main {
        padding: 42px 0 30px;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .footer-church-about,
  .footer-church-news,
  .footer-church-ministries,
  .footer-church-events,
  .footer-church-contact,
  .footer-church-gallery,
  .footer-church-sermons,
  .footer-church-ministries-det {
    .aheto-footer-2 {
      &__main {
        padding: 22px 0 30px;
      }
    }
  }
}

@media screen and (max-width: 425px) {
  .footer-church-about,
  .footer-church-news,
  .footer-church-ministries,
  .footer-church-events,
  .footer-church-contact,
  .footer-church-gallery,
  .footer-church-sermons,
  .footer-church-ministries-det {
    .aheto-footer-2 {
      &__main {
        padding: 0;
      }
    }
  }
}
