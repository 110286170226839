.aht-event {

  &--edu {
    .aht-event {
      &__day {
        color: $c-active;
        font-family: $f-roboto-slab;
      }

      &__month {
        color: $c-grey;
        font-family: $f-roboto;
      }

      &__icon {
        color: $c-active;
      }
      &__link {
        font-family: $f-roboto-slab;
        letter-spacing: normal;
      }
      &__text {
        font-family: $f-roboto;
      }
    }
  }

  &--edu-2 {
    .aht-event {
      &__day {
        color: $c-active;
        font-family: $fnt-family-2;
      }

      &__month {
        color: $c-grey;
        font-family: $f-roboto;
      }
      &__icon {
        color: $c-active;
      }
      &__text {
        color: $c-dark;
        font-family: $f-roboto;
      }
      &__desc, &__detail {
        font-family: $f-roboto;
      }
      &__link {
        color: $c-dark;
        border: 2px solid $c-dark;
        font-family: $f-roboto;
        &:hover {
          background-color: $c-dark;
        }
      }
    }
  }
}

.aht-ev-det {

  &--edu {
    b {
      color: $c-active;
    }
    .aht-ev-det {

    &__content {
      p {
        font-family: $f-roboto;
      }
    }
    &__prog {
      &-item {
        font-family: $f-roboto;
        font-size: 18px;
        color: $c-grey;
        &::before {
          content: '';
          display: inline-block;
          border-radius: 50%;
          margin-right: 25px;
          width: 7px;
          height: 7px;
          background-color: $c-dark;
        }
        
      }
    }
    
	  &__sched {
        &-unit {
          &.active {
            border-bottom: 2px solid $c-grey;
          }
        }
        &-day {
          color: $c-grey;
          font-weight: 600;
          font-family: $f-roboto;
        }
        &-date {
          font-weight: 600;
          font-family: $f-roboto;
        }
        &-time {
          font-family: $f-roboto;
          color: rgb(69, 69, 69);
          font-weight: 400;
        }
        &-text {
          font-family: $f-roboto;
        }
      }

      &__sb {
        box-shadow: 0px 0px 40px 0px rgba($c-dark, 0.1);
      }

      &__detail {
        &-icon {
          color: $c-active;
        }

        &-text {
          color: $c-dark;
          font-family: $f-roboto;
        }
      }
      &__label {
        color: $c-dark;
        font-family: $f-roboto;
      }
      &__select {
        color: $c-grey;
        &:focus {
          border-color: $c-dark;
          box-shadow: 0px 15px 30px 0px rgba($c-dark, 0.1);
        }
      }
      &__number {
        color: $c-dark;
      }

      &__number-q {
        border: 2px solid $c-dark;
        color: $c-dark;
        &:hover {
          background-color: $c-dark;
        }
      }

      &__price {
        color: $c-active;
        font-family: $f-roboto;
        font-weight: 300;
      }

      &__submit {
        &:hover {
          color: $c-dark;
        }
      }
    }
  }
}
