// PATHs
$p-img: '../img/'; // Path to image folder

// GRID
$grid-gutter-width:        30px;
$container-large-desktop:  (1170px + $grid-gutter-width);
$screen-lg:                1230px;

.container {
  width: 100% !important;
  max-width: 1200px !important;
}

// COLORS
@import 'socials-colors';

// General
$c-white: #fff;
$c-black: #000;

$f-karla            :'Karla', sans-serif; // 1,2-theme
$f-poppins          :'Poppins', sans-serif; // 3,11-theme
$f-roboto           :'Roboto', sans-serif; // 4,8-theme
$f-girloy           :'Gilroy', sans-serif; // 4-theme
$f-futura           :'Futura', sans-serif; // 5-theme
$f-roboto-slab      :'Roboto Slab', serif; // 6-theme
$f-playfair-display :'Playfair Display', serif;
$f-catamaran        :'Catamaran', sans-serif; // 7-theme
$f-source-sans-pro  :'Source Sans Pro', sans-serif; //10-theme
$f-proxima-nova     :'Proxima Nova Rg', sans-serif; //11 theme
$f-droid-serif      :'Droid Serif', sans-serif; //12 theme
$f-mukta            :'Mukta', sans-serif; //13, 16 theme
$f-open-sans        :'Open Sans', sans-serif; //13 theme
$f-sign-painter     :'SignPainter', sans-serif; //13 theme
$f-oswald           :'Oswald', sans-serif; //14,15 theme
$f-lato             :'Lato', sans-serif; //14,15 theme
$f-montserrat       :'Montserrat', sans-serif;
$f-nunito           :'Nunito', sans-serif;
$f-merriweather     :'Merriweather', sans-serif;
$f-ionicons         :'Ionicons';
$f-font-awesome     :'FontAwesome';
$f-elegant          :'ElegantIcons';
$f-themify          :'themify';
$f-dancing-script   :'Dancing Script';
$f-pe-icon-7-stroke :'Pe-icon-7-stroke';

$f-libre-baskerville: 'Libre Baskerville', serif;
