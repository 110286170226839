.aheto-single {
  &-item {
    &:before {
      font-family: $h-fnt-family;
      font-size: $h2-fnt-size;
      line-height: $h2-ln-height;
    }

    &__ico {
      box-shadow: 0px 15px 30px 0px rgba($c-active, 0.1);

      &:hover {
        background: $c-active;
      }
    }

    &--withIco {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        &:before {
          color: $c-active;
        }
      }
    }

    &--bgIco {
      &:hover {
        background-color: $c-active;
        box-shadow: 0px 15px 30px 0px rgba($c-active, 0.1);
      }
    }
  }
}
