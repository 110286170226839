
.service-single {

  @include large-size-max {
    .swiper-button-prev {
        left: 10px;
    }

    .swiper-button-next {
        right: 10px;
    }
  }
}

.aheto-content-block {

  * {
    transition: 0.2s all;
  }

  &__ico {
	color: $c-active;
  }
  &-hover{
    hr {
      background: $c-active;
    }
  }
  &--bgImg {
	&:hover {
	  &:after {
		background-color: $c-active;
	  }
	}
  }

  &--slider {
	&:before {
	  color: $c-active;
	  font-family: $fnt-family-1;
	}

  &:hover {
    &:before {
      color:$c-white;
    }
  }

  &:hover {
    background-color: $c-active;
    .aheto-content-block {
      &__title {
        color:$c-white;
      }

      &__info-text {
        color:$c-white;
      }

      &__ico {
    	color: fade-out($c-white, 0.90);
      }
    }
  }
  }

  &--list {
	&:before {
	  color: $c-active;
	  font-family: $fnt-family-1;
	}
  }

  &--list-icon {

	&:hover {
	  .aheto-content-block__ico {
		background-color: $c-active;
	  }
	}

	.aheto-content-block__title {
	  &:before {
		color: $c-active;
		font-family: $fnt-family-1;
	  }
	}
  }

  &--business {
	.aheto-btn {
	  color: $c-active;

	  &:hover {
		color: $c-alter;
	  }
	}
  }

  &--construction {
	border-bottom: 1px solid $c-light;
	margin: 0 20px;
	&.active {
	  &:after {
		background-color: $c-active;
	  }
	}
  }

  &--restaurant {
    background-color: #fff;
    box-shadow: 0px 15px 38px 0 rgba(0, 0, 0, 0.08);
    .aheto-content-block {
      &__ico {
        background-color: $c-active;
        &:before {
          color: #fff;
        }
      }
      &__title {
        color: $c-dark;
      }
      &__info-text {
        color: $c-grey;
      }
    }
  }
}
