.aht-ev-det {
  &--political {
    .aht-ev-det {
      &__title{
        span{
          color: $c-alter;
        }
      }
      &__detail-icon {
        color: $c-active;
      }
      &__detail-text {
        font-family: $fnt-family-3;
        color: $c-grey;
      }
      &__date-number {
        font-family: $fnt-family-1;
        color: $c-white;
        background: $c-active;
      }
      &__date-text {
        font-family: $fnt-family-3;
        background: $c-alter;
        color: $c-white;
      }
    }
  }
}
