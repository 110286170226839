.aht-tb {
  &--trvl {
    .aht-tb {
      &__title {
        font-weight: 800;
      }

      &__subtitle {
        font-family: $fnt-family-3;
      }
    }
  }
}
