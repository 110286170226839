.aheto-products {
  $p: &;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
  padding: 0;
  overflow: hidden;
  @media screen and (max-width: 767px) {
    justify-content: center;
  }

  &__item {
    position: relative;
    flex: 1;
    min-width: 250px;
    max-width: 500px;
    margin: 15px;
    padding-left: 0;
    &:before {
      content: none;
    }

    &:hover {
      .aheto-products__btn-holder {
        opacity: 1;
      }
    }
  }

  &__img {
    width: 100%;
    height: 420px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  &__btn-holder {
    display: inline-flex;
    position: absolute;
    top: 350px;
    left: 50%;
    align-items: center;
    transform: translate(-50%, 0);
    transition: all 0.5s;
    opacity: 0;
    z-index: 10;

    // View cart fix
    .added_to_cart {
      display: inline-block;
      margin-left: 10px;
      padding: 10px;
      border-radius: 5px;
      color: $c-white;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      letter-spacing: 1px;
      line-height: 1.15;

      &:hover {
        opacity: 1;
      }
    }
  }

  &__btn {
    display: flex;
    width: 48px;
    height: 48px;
    border-radius: 50%;
	  background-color:#696969;
    text-align: center;

    &:hover {
      opacity: 1;
		background-color:#222222;
    }

    + .aheto-products__btn {
      margin-left: 20px;
    }

    &.added {
      display: none;
    }
  }

  &__btn-icon {
    margin: auto;
    color: $c-white;
    font-size: 18px;
  }

  &__marks {
    position: absolute;
    top: 20px;
    left: 0;
    z-index: 10;
  }

  &__mark {
    display: block;
    width: 55px;
    height: 25px;
    padding: 6px 14px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    color: $c-white;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;

    &.aheto-onsale {
      background-color: #46AB5D;
    }

    &.aheto-new {
      background-color: #2A74ED;
    }

    &.aheto-hot {
      background-color: #F24D4C;
    }

    & + & {
      margin-top: 10px;
    }
  }

  &__link {
    display: block;
  }

  &__title {
    margin-top: 25px;
    margin-bottom: 5px;
    line-height: 1;
  }

  &__price {
    margin: 0;
    font-size: 14px;
    font-weight: 500;

    del {
      margin-right: 10px;
      .aheto-products__price-amount{
        color: #999999;
        text-decoration: line-through!important;
      }
    }
  }

  &__price-amount {

  }

  &--barbershop {
    #{$p}__item {
      width: calc(25% - 30px);
      flex: none;

    }
    #{$p}__title {
      font-family: $f-mukta;
      font-size: 17px;
      font-weight: bold;
      text-transform: uppercase;
    }
    #{$p}__price-amount {
      font-size: 21px;
      font-weight: bold;
      letter-spacing: 1.68px;
    }
    #{$p}__price {
      ins {
        text-decoration: none;
      }
    }
    #{$p}__marks {
      left: auto;
      right: 7px;
    }
    #{$p}__mark {
      &.aheto-onsale {
        padding: 4px;
        border-radius: 0;
        font-size: 17px;
        font-weight: bold;
        text-transform: uppercase;
        text-align: center;
      }
    }
  }

  @media screen and (max-width: 1500px) {
    .aheto-products__img {
      height: 380px;
    }

    .aheto-products__btn-holder {
      top: 310px;
    }
  }

  @media screen and (max-width: 767px) {
    .aheto-products__item {
      min-width: 200px;
      max-width: 345px;
    }

    .aheto-products__btn-holder {
      display: flex;
      position: static;
      justify-content: center;
      margin-top: 25px;
      transform: none;
      opacity: 1;
    }

    .aheto-products__img {
      height: 300px;
    }
  }

  &--related {
    .aheto-products__img {
      height: 360px;
    }

    .aheto-products__title {
      margin-top: 35px;
    }

    .aheto-products__btn-holder {
      top: 290px;
    }
  }
  &--political{
    .aheto-products{
      &__item{
        background: #f7f7f7;
        padding: 50px 15px 25px;
        @include small-size-max {
          max-width: 100%;
        }
        @include large-size-min {
          &:hover {
            img {
              opacity: 0.5;
            }
          }
        }
      }
      &__img{
        height: auto;
        img{
          transition: opacity .2s linear;
        }
      }
      &__link{
        p{
          font-family: 'Oswald';
          font-size: 20px;
          font-weight: 500;
          line-height: 1.3;
          color: #0536a5;
          padding-top: 10px;
        }
      }
      &__btn{
        width: auto;
        height: auto;
        padding: 14px 35px;
        color: #fff;
        font-family: 'Oswald';
        border-radius: 5px;
        background-color: #0536a5;
        &-holder{
          top: 180px;
          transition: opacity .2s linear;
        }
      }
      &__price{
        color: #999999;
        font-size: 16px;
        font-weight:700;
      }
    }
  }
}
