// HIGH PPI DISPLAY BACKGROUNDS
// Source: https://ryanbenhase.com/easily-implement-2x-retina-background-images/

@mixin background-2x($path, $ext: 'png', $size: contain, $pos: left top, $repeat: no-repeat) {

  $at1x-path: '#{$path}.#{$ext}';
  $at2x-path: '#{$path}@2x.#{$ext}';

  background-image: url('#{$at1x_path}');
  background-repeat: $repeat;
  background-position: $pos;
  background-size: $size;

  @media all and (-webkit-min-device-pixel-ratio: 1.5),
    all and (-o-min-device-pixel-ratio: 3/2),
    all and (min--moz-device-pixel-ratio: 1.5),
    all and (min-device-pixel-ratio: 1.5) {
      background-image: url('#{$at2x-path}');
    }
}

// Usage
// @include background-2x( 'path/to/image', 'jpg', 100px 100px, center center, repeat-x );

// placeholder
@mixin optional-at-root($sel) {
  @at-root #{if(not &, $sel, selector-append(&, $sel))} {
    @content;
  }
}
@mixin placeholder {
  @include optional-at-root('::-webkit-input-placeholder') {
    @content;
  }
  @include optional-at-root(':-moz-placeholder') {
    @content;
  }
  @include optional-at-root('::-moz-placeholder') {
    @content;
  }
  @include optional-at-root(':-ms-input-placeholder') {
    @content;
  }
}


// MEDIA QUERY TABLET PORTRAIT
//max-width: 480
@mixin xs-size-all {
  @media screen and (max-width: 570px) {
    @content;
  }
}
@mixin xs-size-max {
  @media screen and (max-width: 480px) {
    @content;
  }
}
@mixin xs-size-min {
  @media screen and (min-width: 200px) {
    @content;
  }
}

// MEDIA QUERY TABLET PORTRAIT
//max-width: 767
@mixin small-size-all {
  @media screen and (min-width: 571px) and (max-width: 767px) {
    @content;
  }
}
@mixin small-size-max {
  @media screen and (max-width: 767px) {
    @content;
  }
}
@mixin small-size-min {
  @media screen and (min-width: 481px) {
    @content;
  }
}

// MEDIA QUERY TABLET LANDSCAPE
//min-width: 768 max-width: 991
@mixin medium-size-all {
  @media screen and (min-width: 768px) and (max-width: 991px) {
    @content;
  }
}
//max-width: 991
@mixin medium-size-max {
  @media screen and (max-width: 991px) {
    @content;
  }
}
//min-width: 768
@mixin medium-size-min {
  @media screen and (min-width: 768px) {
    @content;
  }
}

// MEDIA QUERY MINI DESKTOP
//max-width:1230
@mixin large-size-max {
  @media screen and (max-width: 1230px) {
    @content;
  }
}
//min-width: 768 max-width: 991
@mixin large-size-all {
  @media screen and (min-width: 992px) and (max-width: 1230px) {
    @content;
  }
}
// MEDIA QUERY DESKTOP
//min-width:1231
@mixin large-size-min {
  @media screen and (min-width: 992px) {
    @content;
  }
}

// USAGE MEDIA QUERY
// @include medium-size-max {
//  properties
// }


// mixin for btn border radius
//
@mixin mix-btn-radius($btn-radius, $btn-line-height, $btn-font-size, $btn-padd, $btn-border-width) {
  @if ($btn-radius == true) {
    border-radius: (($btn-line-height * $btn-font-size) + ($btn-padd * 2) + ($btn-border-width * 2)) / 2;
  } @else if (type-of($btn-radius) == 'number') {
    border-radius: $btn-radius;
  } @else {
    border-radius: 0;
  }
}


// MENU
// Mixin for mobile-menu
@mixin menu-size-max {
  @media screen and (max-width: 1024px) {
    @content;
  }
}

@mixin menu-size-min {
  @media screen and (min-width: 1025px) {
    @content;
  }
}
