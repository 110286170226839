.aht-filter-yoga {
  &__filter {
    &.active {
      .aht-filter-yoga__title {
        color: $c-active;
      }

      .aht-filter-yoga__select {

        &:before {
          background-color: $c-active;
        }
      }
    }
  }

  &__select {
    &:after {
      background-color: $c-active;
    }
  }

  &__inner{
    justify-content: center;
  }
}
