.aht-table {
    &-classes {
        background-color: $c-white;
        .aht-table {
            &__head {
                background-color: $c-dark;
                &-item {
                    color: $c-white;
                    font-family: $f-roboto;
                    font-size: 12px;
                    letter-spacing: 1.2px;
                }  
            }
            &__title {
                font-family: $fnt-family-2;
            }
            &__header {
                &-link {
                    font-family: $f-roboto;
                    font-size: 14px;
                    text-transform: uppercase;
                    color: #595955;
                    letter-spacing: 1.4px;
                    position: relative;
                    i {
                        font-size: 20px;
                        color: $c-active;
                    }
                    &::after {
                        content: '';
                        font-family: $f-elegant;
                        position: absolute;
                        right: 1px;
                        bottom: -5px;
                        width: 85%;
                        height: 1px;
                        background-color: #ccc;
                    }
                    &:hover {
                        color: $c-active;
                        &::after {
                            background-color: $c-active;
                        }
                    }
                }
            }
            &__body {
                &-row:nth-child(odd) {
                    background-color: #fff;
                }
                &-time {
                    font-family: $f-roboto;
                    font-size: 16px;
                    color: $c-grey;
                }
            }
            &__content {
                &-cat {
                    background-color: $c-dark;
                    font-family: $f-roboto;
                    color: $c-white;
                    padding: 6px 22px;
                    font-size: 10px;
                    font-weight: 500;
                    border-radius: 5px;
                    margin-bottom: 15px;
                    letter-spacing: 1px;
                }
                &-type {
                    color: #555555;
                    font-size: 14px;
                    font-weight: 500;
                    font-family: $f-roboto;
                    margin-bottom: 30px;
                }
                &-time {
                    font-family: $f-roboto;
                    font-size: 12px;
                    font-weight: bold;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: normal;
                    letter-spacing: 1.2px;
                    text-align: center;
                    color: #999999;
                    margin-bottom: 25px;
                }
                &-teacher {
                    font-family: $f-roboto;
                    font-size: 14px;
                    font-weight: 500;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    text-align: center;
                    color: #999999;
                }
            }
        }
        .c-dark {
            background-color: $c-dark;
        }
        .c-grey {
            background-color: $c-grey;
        }
        .c-active {
            background-color: $c-active;
        }
        .c-alter {
            background-color: $c-alter;
        }
        .c-alter-2 {
            background-color: $c-alter-2;
        }
        .c-alter-3 {
            background-color: $c-alter-3;
        }
    }
    
}  
.hidden-cell {
    background-color: #f9f9f9;
    border: none;
}

.class-table-yoga {
    background-image: url('../img/yoga/home-yoga/bitmap.png');
    background-repeat: no-repeat;
    background-position: center top;
    padding-top: 40px;
    margin-top: -40px;


    .aht-table__head-item {
        padding-top: 16px;
        padding-bottom: 16px;
    }

    .aht-table__body-cell {
        padding-top: 22px;
        padding-bottom: 10px;
    }
    
    .aht-table-classes .aht-table__head {
        background-color: $c-active;
    }

    .aht-table--edu .aht-table__body-cell:first-child {
        padding-left: 57px;
        padding-right: 59px;
    }

    .aht-table-classes .aht-table__head-item:first-child {
        padding-left: 0;
        padding-right: 0;
        text-align: center;
        font-family: $f-girloy, sans-serif;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 1px;
        color: #ffffff;
    }

    .aht-table-classes .aht-table__content-cat {
        height: 20px;
        line-height: 21px;
        display: inline-block;
        padding: 0 10px;
        border-radius: 15px;
        font-family: $f-futura, sans-serif;
        font-size: 9px;
        font-weight: bold;
        letter-spacing: 1px;
        color: $c-white;
    }

    .aht-table__body-cell {
        padding-top: 0;
        padding-bottom: 0;
        height: 180px;
        box-sizing: border-box;
        background-color: $c-white;

        &:not(.hidden-cell):not(.aht-table__body-time):hover {
            box-shadow: 0 0 20px 5px rgba(0, 0, 0, 0.1);
        }

        &:not(.aht-table__body-time ) {
            padding-left: 10px;
            padding-right: 10px;
            width: 150px;
        }
    }

    .aht-table-classes .aht-table__body-time {
        padding-left: 0;
        padding-right: 0;
        box-sizing: border-box;
        width: 160px;
    }

    .aht-table-classes .aht-table__body-row:nth-child(odd),
    .aht-table-classes .aht-table__body-row:nth-child(even) {
        background-color: transparent;
    }

    .hidden-cell {
        background-color: transparent;
    }

    .aht-table__body {
        background-color: $c-light;
    }

    .aht-table__content-type {
        font-size: 14px;
        font-weight: bold;
        font-family: $f-futura, sans-serif;
        color: $c-dark;
    }

    .aht-table__content-time {
        font-family: $f-girloy, sans-serif;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: normal;
        color: $c-grey;
    }

    .aht-table__content-teacher {
        font-family: $f-girloy, sans-serif;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: normal;
        color: $c-grey;
    }
}

.swiper--yoga-class-table {
    .swiper-button-prev,
    .swiper-button-next {
        box-shadow: none !important;
        border: 1px solid #dddddd !important;
        height: 40px !important;
        width: 60px !important;
        line-height: 40px !important;
        font-size: 10px !important;
        top: 85px;

        &:hover {
            background-color: $c-active !important;
            border-color: $c-active !important;
        }
    }

    .swiper-button-prev {
        border-radius: 20px 0 0 20px !important;
    }

    .swiper-button-next {
        border-radius: 0 20px 20px 0 !important;
    }
}

.class-table-yoga {
    .aht-table__title {
        font-size: 30px !important;

        @include small-size-max {
            font-size: 23px !important;
        }
    }
}