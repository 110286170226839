.aheto-footer-9 {
  background-color: $c-white;
  &__main {
    padding: 110px 0 65px;
    @include medium-size-max {
      padding: 50px 0 40px;
    }
  }
  .aht-socials {
    &__icon {
      font-size: 20px;
    }
  }
  &__bottom {
    .aheto-footer-menu {
      .menu {
        &-item {
          margin-right: 50px;
          @include medium-size-max {
            margin-right: 0px;
            margin-left: 20px;
          }
          @include small-size-max {
            margin-right: 10px;
            margin-left: 10px;
          }
          a {
            font-weight: 500;
          }
        }
      }
    }
  }
}
