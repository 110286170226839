// .aheto-content {
//   $k: &;
//   .post {
//     font-family: $fnt-family-1;
//
//     // Video
//     .video {
//       &-play-btn {
//         //box-shadow: 0px 20px 30px 0px rgba($color-1, 0.1);
//         &:before {
//           //color: $color-1;
//         }
//       }
//     }
//
//     .blqt {
//     //  background-color: $color-2;
//     }
//
//     &-title {
//       a {
//         //color: $color-2;
//       }
//     }
//     &-likes {
//
//       span {
//         //color: $color-2;
//       }
//
//       i {
//         //color: lighten($color-4, 20%);
//       }
//     }
//
//     &-comments {
//
//       span {
//       //  color: $color-2;
//       }
//
//       i {
//       //  color: lighten($color-4, 20%);
//       }
//     }
//
//     &-cats {
//
//       a {
//       //  background-color: $color-1;
//       }
//     }
//
//     &-content {
//     //  color: $color-4;
//     }
//
//     // Post bottom info
//     &-additional {
//       .tags {
//         span {
//         //  color: $color-1;
//         }
//       }
//
//       .likes {
//         button {
//           //color: $color-2;
//           font-family: $fnt-family-1;
//         }
//       }
//     }
//
//     // Share
//     &-share {
//       button {
//       //  border: 1px solid $color-4;
//     //    color: $color-4;
//         &:hover {
//       //    border-color: $color-1;
//       //    background-color: $color-1;
//         }
//       }
//     }
//   }
//
//   // About author
//   .comm {
//     &-txt {
//       .author {
//       //  color: $color-2;
//       }
//     }
//   }
//
//   // Leave a comment
//   .comment {
//
//     &-reply-link {
//     //  color: $color-2;
//     }
//
//     &-form {
//       input {
//       //  color: $color-2;
//         font-family: $fnt-family-1;
//
//         &::placeholder {
//         //  color: $color-4;
//           font-family: $fnt-family-1;
//         }
//
//         &:focus {
//           border: 1px solid $color-1;
//       //    box-shadow: 0px 15px 30px 0px rgba($color-1, 0.1);
//         }
//
//         &[type=submit] {
//       //    background-color: $color-1;
//         }
//       }
//
//       textarea {
//       //  color: $color-2;
//         font-family: $fnt-family-1;
//
//         &::placeholder {
//         //  color: $color-4;
//           font-family: $fnt-family-1;
//         }
//
//         &:focus {
//       //    border: 1px solid $color-1;
//       //    box-shadow: 0px 15px 30px 0px rgba($color-1, 0.1);
//         }
//       }
//     }
//   }
//
//   &--grid {
//     .post {
//       &:hover {
//       //  box-shadow: 0px 15px 30px 0px rgba($color-1, 0.1);
//       }
//     }
//   }
//
//   &--masonry {
//     .post {
//       &:hover {
//       //  box-shadow: 0px 15px 30px 0px rgba($color-1, 0.1);
//       }
//     }
//   }
//
//   &--single-post {
//     &.aheto-content--saas {
//       blockquote {
//         h3 {
//           &:before {
//         //    background: $color-1;
//           }
//         }
//       }
//     }
//   }
//
//   &--recent-posts {
//     .post {
//       &:hover {
//       //  box-shadow: 0px 15px 30px 0px rgba($color-1, 0.1);
//       }
//     }
//
//     &--sidebar {
//       max-width: 1030px;
//       padding: 0 30px 0px;
//
//       @include medium-size-max {
//         padding-right: 15px;
//         padding-left: 15px;
//       }
//     }
//   }
//
//   &--saas {
//     .post {
//     //  box-shadow: 0px 6px 17px 0 rgba($color-1, 0.07);
//       .blqt {
//         background: $color-5;
//       }
//       .video-play-btn {
//         &:before {
//           color: $color-5;
//         }
//       }
//     }
//     .post-title {
//       color: $color-5;
//
//       a {
//         color: $color-5;
//       }
//     }
//     .post-additional {
//       .likes {
//         button {
//           color: $color-5;
//         }
//       }
//     }
//     .post-share {
//       button {
//         border-color: $color-6;
//         color: $color-6;
//       }
//     }
//     .format-quote {
//       .post-info {
//         display: block;
//         .post-author {
//           &__name {
//             color: $color-1;
//           }
//         }
//       }
//     }
//     .mejs__container {
//       background: $color-5;
//     }
//     .comm {
//       &-txt {
//         .author {
//           b {
//             color: $color-5;
//           }
//         }
//       }
//     }
//     .comment-reply-link {
//       color: $color-5;
//     }
//     .comment-form {
//       input,
//       textarea {
//         color: $color-5;
//         &::placeholder {
//           color: $color-6;
//         }
//       }
//     }
//   }
//
//   &--business {
//     .post {
//
//       &-date {
//         background-color: $color-2;
//       }
//
//       &-cats {
//         a {
//           color: $color-4;
//         }
//       }
//
//       &-title {
//
//         a {
//           color: $color-3;
//         }
//       }
//
//       .blqt {
//         background-color: $color-3;
//       }
//
//       .content-wrapper {
//         .aheto-btn {
//           color: $color-1;
//           &:hover {
//             color: $color-2;
//           }
//         }
//       }
//     }
//
//     .mejs {
//       &__container {
//         background-color: $color-3;
//       }
//     }
//   }
// }
//
// .blog-pagination-wrapper {
//   font-family: $fnt-family-1;
//   .pagination {
//     .prev,
//     .next {
//       span {
//         color: $color-2;
//       }
//     }
//     .wrap {
//
//       .page-numbers {
//         color: $color-2;
//         &.current,
//         &:hover {
//           background-color: $color-1;
//         }
//       }
//     }
//
//     &__learn-more {
//       color: $color-6;
//       &:after,
//       &:before {
//         background: $color-6;
//       }
//       &:before {
//         background: $color-1;
//       }
//       &:hover {
//         color: $color-1;
//       }
//       &--uppercase {
//         font-size: $p-fnt-size;
//         letter-spacing: $aheto-link-letter-space;
//         text-transform: uppercase;
//       }
//     }
//   }
// }
//
// // BLOG Crutches
// .aheto-page {
//
//   &--blogs {
//     background-color: #f6f9ff;
//
//     .aheto-sidebar-wrapper {
//       background-color: $color-5;
//     }
//   }
//
//   &--single-post {
//     .aheto-sidebar-wrapper {
//       background-color: $color-5;
//     }
//   }
// }

.c-1 {
  background: $c-active;
}


.church-post-wrap-1 {
  &__icon-link {
    &:hover {
      color: $c-active;
    }
  }

  .church-post-wrap-1__link {
    &:hover {
      color: $c-active;
    }
  }
}