.aht-grid-1 {
  &--edu {
    .aht-grid-1 {
      &__filter {
        font-family: $f-roboto;
        &:hover {
          color: $c-dark;
        }
        &.active {
          color: $c-dark;
        }
      }

      &__item {
        &-inner {
          @include menu-size-min {
            &:hover {
              box-shadow: 0px 0px 43px 0px rgba($c-dark, 0.09);
            }
          }

          @include menu-size-max {
            box-shadow: 0px 0px 43px 0px rgba($c-dark, 0.09);
          }
        }
        &-cat {
          color: $c-grey;
          font-family: $f-roboto;
          letter-spacing: normal;
        }
        &-title {
          font-family: $fnt-family-2;
          line-height: 26px;
        }
      }
    }
  }
}
