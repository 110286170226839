.aht-td {
  &__menu-item {
    font-family: $f-open-sans;
  }

  &__sb {
    @include large-size-max {
      padding-bottom: 0;
    }
  }

  &__units {
    @include small-size-max {
      padding-bottom: 0;
    }
  }

  &__unit {
    @include small-size-max {
      margin-top: 50px;
      margin-bottom: 30px;
    }
  }

  &__content {
    @include medium-size-max {
      width: 100%;
      margin-bottom: 50px;
    }
  }

  &__unit-title {
    font-weight: 800;
    line-height: 0.6;
  }

  &__detail {
    &-check {
      color: $c-alter;
    }

    @include small-size-max {
      &-left,
      &-right {
        width: 100% !important;
      }
    }
  }

  &__specs {
    position: relative;

    @include menu-size-min {
      &:before,
      &:after {
        display: block;
        content: "";
        position: absolute;
        top: 50%;
        left: 24%;
        width: 1px;
        height: 58px;
        background-color: #eee;
        transform: translateY(-50%);
      }

      &:before {
        left: 24%;
      }

      &:after {
        left: 54%;
      }
    }
  }

  &__spec {
    position: relative;
    padding-left: 30px;

    &-icon {
      position: absolute;
      top: 5px;
      left: 0;
    }

    &-text {
      font-family: $f-open-sans;
    }
  }

  &__info {
    font-family: $f-open-sans;
    font-size: 13px;
    font-weight: 600;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;

    &-icon {
      color: #ffbb49;
      font-size: 24px;
    }
  }

  &__tb {
    &-star {
      color: $c-alter;
    }

    &__tb-title {
      font-weight: 800;
    }
  }

  &__spec {
    &-icon {
      color: $c-alter;
    }

    &-text {
      color: $c-dark;
    }
  }

  &__menu {
    @include medium-size-min {
      &:before {
        width: 500%;
      }
    }

    &:before {
      background-color: $c-light;
    }

    &-text {
      color: $c-dark;
      &:before {
        background-color: $c-active;
      }

      &:hover,
      &.active {
        color: $c-active;
      }
    }
  }

  &__detail {
    &-left {
      width: 56%;
    }

    &-right {
      width: 44%;
    }

    &-close {
      color: $c-grey;
    }
  }

  &__route {
    &-caption {
      .aht-td__route-icon {
        transform: rotate(180deg);
      }

      &.active {
        .aht-td__route-title {
          color: $c-active;
        }

        .aht-td__route-icon {
          transform: rotate(0);
        }
      }
    }

    &-icon {
      color: $c-grey;
    }
    
    &-desc {
      line-height: 1.6;
    }
  }

  &__book {
    &-caption {
      &--discount {
        &:before {
          background-color: $c-active;
        }
      }
    }
    
    &-cost {
      background-image: linear-gradient(24deg, $c-alter, $c-active, $c-active);
    }

    &-discount {
      font-family: $fnt-family-1;
      flex-direction: column;
      font-size: 15px;
      line-height: 1.2;

      span {
        font-size: 18px;
      }
    }
  }

  &__btn {
    flex: 1;
    color: $c-dark;
    font-family: $fnt-family-2;
    &.active,
    &:hover {
      color: $c-active;
    }
  }

  &__element {
    color: $c-dark;
    font-family: $fnt-family-2;
    -webkit-appearance: none;
    height: 40px;

    &:focus {
      border: 1px solid $c-active;
      box-shadow: 0px 10px 20px 0px rgba($c-dark, 0.07);
    }
  }

  &__submit {
    font-family: $fnt-family-1;
    height: 55px;
    padding: 0 50px;
    line-height: 55px;
    border-radius: 28px;

    &:hover {
      border-color: $c-active;
      background-color: $c-active;
    }
  }

  &__why {
    &-icon {
      color: $c-alter;
      position: absolute;
      top: 2px;
    }

    &-title {
      line-height: 1.78;
    }

    &-item {
      position: relative;
      height: 53px;
      line-height: 53px;

      p {
        padding-left: 30px;
      }
    }
  }

  &__element-wrapper {
    width: 100%;

    .aht-td__element {
      width: 100%;
    }

    &.select-wrapper {
      position: relative;

      &:before {
        display: block;
        content: "";
        position: absolute;
        right: 20px;
        top: 20px;
        width: 6px;
        height: 1px;
        background-color: #999;
        transform: rotate(40deg);
      }

      &:after {
        display: block;
        content: "";
        position: absolute;
        right: 16px;
        top: 20px;
        width: 6px;
        height: 1px;
        background-color: #999;
        transform: rotate(-40deg);
      }
    }
  }

  &__wrapper-half {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .aht-td__element-wrapper {
      min-width: 150px;
      max-width: 47%;
    }
  }

  &__widgets {
    max-width: 400px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    
    .aht-widget-advert {
      height: 340px;
      padding: 50px;

      &__title {
        margin-bottom: 95px;
        font-family: $fnt-family-1;
        font-size: 20px;
        font-weight: 800;
        line-height: 0.7;
        letter-spacing: 4px;
      }

      &__desc-wrap {
        font-weight: bold;

        p {
          font-family: $fnt-family-1;
          font-size: 18px;
          letter-spacing: 1.8px;
        }
      }
    }
  }
}
