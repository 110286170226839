.aht-ev-det {
  &--yoga {
    .aht-ev-det {

      &__detail-icon {
        color: $c-active;
      }
      &__detail-text {
        color: $c-dark;
      }
      &__date-number {
        color: $c-active;
      }

      &__content {
        @include menu-size-max {
          margin-top: 50px;
        }
      }
    }
  }
}
