$testimonial-bg: #fff;
$testimonial-avatar-size: 50px;
$testimonial-author-name-color: $c-dark;
$testimonial-author-name-size: 18px;
$testimonial-padding: 26px 25px 40px;
$testimonials-transition: 0.2s all;
$stars-color: #ffc015;

.tm {

  &--default {
    padding: $testimonial-padding;
    transition: $testimonials-transition;
    background: $testimonial-bg;

    &:after {
      transition: $testimonials-transition;
      background: $c-active;
    }

    &:hover {
      box-shadow: 0px 15px 30px 0px rgba($btn-default-bg, 0.1);
    }

    .tm__avatar {
      width: $testimonial-avatar-size;
      height: $testimonial-avatar-size;
    }

    .tm__name {
      margin: 0;
      color: $testimonial-author-name-color;
      font-size: $testimonial-author-name-size;
    }

    .tm__stars {
      i {
        color: $stars-color;
        font-size: $p-fnt-size;
      }
    }
  }

  &--home-event {
    .tm__stars {
      i {
        color: $stars-color;
      }
    }
  }

  &--barbershop {
    .tm {
      &__stars {
        .ion {
          color: $c-active;
        }
      }
      // &__text {
        //color: $c-dark;
      // }
      &__position {
        color: $c-active;
      }
    }
  }

  &--business {
    .tm__content {
      box-shadow: 0.5px 4px 50px 0 rgba(0, 0, 0, 0.07);
  }
  }
  &--construction{
    .tm {
      &__name {
        color: $c-dark-2;
      }
    }
  }
  &--yoga {
    .tm {
      &__text {
        font-weight: 400;
      }

      &__stars {
        margin-bottom: 10px;
      }

      &__name {
        letter-spacing: 0.8px;
      }

      &__position {
        color: $c-active;
        font-family: $fnt-family-2;
        letter-spacing: 0;
      }

      &__content {
        &:before {
          color: $c-alter-3;
        }
      }
    }
  }

  &--edu {

    @include menu-size-min {
      &:hover {
        background-color: $c-dark;

        &:before {
          color: $c-active;
        }
      }
    }
  }

  &--edu-2 {
    .tm__content {
      box-shadow: 0px 0px 15px 10px rgba($c-dark, 0.08);
    }
    .tm__text, .tm__position {
      font-family: $f-roboto;
    }
  }

  &--restaurant {
    .tm {
      &__stars {
        color: #fff;
      }
      &__name {
        color: #fff;
      }
    }
  }
}


.aht-tm {

  &--trvl {
    .aht-tm {

      &__stars {
        color: $c-alter;
      }
    }
  }


}

// hover agency
.swiper {
  .swiper-slide {

    &:hover {
      .aht-tm--agency {
        background-color: $c-active;
      }

      .aht-tm__desc {
        color: #fff;
      }
    
      .aht-tm__name {
        color: #fff;
      }
    
      .aht-tm__pos {
        color: #fff;
      }
    }
  }
}


// hover agency end