.aheto-footer-11 {
  padding: 145px 0 100px;
  @include medium-size-max {
    padding: 50px 0;
  }
  &__cr-text {
    font-size: 12px;
    letter-spacing: 0.2px;
    line-height: 2;
    opacity: 1;
    font-weight: 600;
  }
}
