.woocommerce-navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .woocommerce-arrows {
    display: flex;
  }

  .woocommerce-arrow {
    display: block;
    margin-right: 15px;
    font-size: 34px;
    @media screen and (max-width: 450px) {
      line-height: 27px;
    }
    &:last-child {
      margin-right: 0;
    }
  }

  @media screen and (max-width: 450px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: 10px;
  }
}

.woocommerce-breadcrumb {
  font-size: 15px;
  color: #999999;

  span {
    margin: 0 10px;
  }
}

.woocommerce-arrows {
  a {
    color: #222222;
  }
  a:hover{
    color: #999999;
  }

}

.woocommerce {

  .woocommerce-info,
  .woocommerce-message {
    margin: 30px 0;
    padding: 17px;
  }

  .woocommerce-info {
      background-color: #f9f9f9;
      border-top: 1px solid #2a74ed;
    i {
      display: inline-block;
      margin-right: 15px;
      font-size: 20px;
      line-height: 1;
      color: #2a74ed;
    }
    a{
      font-size: 16px;
    }
  }

  .woocommerce-NoticeGroup {
    flex-basis: 100%;
    width: 100%;
  }

  ul {
    padding: 0;
    li {
      margin: 0;
      padding: 0;
      &:before {
        content: none;
      }
    }
  }
}

.related-products {
  text-align: center;
  h3{
    font-weight: 300;
  }
  .related-products__title{
    font-weight: 300;
  }
}
