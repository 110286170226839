.aheto-footer {
  button,
  input {
	&[type=submit] {
	  transition: .3s;
	  cursor: pointer;
	}
  }
   :hover {
	text-decoration: none;
  }
  [class*="__bottom"] {
	position: relative;
	@include small-size-max {
	  text-align: center;
	}
  }
}
