.search {
    &-form {
        i {
            color: #222;
        }
        &__input {
            font-family: $f-roboto;
            color: $c-grey;
            &::placeholder {
                font-size: inherit;
                color: $c-grey;
                
            }
        }
    }
}