.aht-bq-img {
  &__bq {
    background-color: $c-active;

    &:before,
    &:after {
      color: rgb(184, 0, 11);
      line-height: 100px;
    }
  }
}
