.aheto-footer-14 {
  &__main {
    padding: 120px 0 30px;
    @include medium-size-max {
      padding: 50px 0 0;
    }
    .aht-socials{
      &--circle{
        .aht-socials__link{
          width: 45px;
          height: 45px;
          color: #fff;
          line-height: 3em;
          font-size: 14px;
        }
      }
    }
  }
  .aht-socials {
    &__link {
      color: $c-white;
    }
  }
  p{
    font-size: 15px;
  }

}
