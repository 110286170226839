.aht-event {

  &--chr {
    .aht-event {

      &__date {
        background-color: $c-active;
        box-shadow: 0 5px 20px 0 rgba(252, 142, 68, 0.4);
      }

      &__icon {
        color: $c-active;
      }
    }
  }

  &--chr-1 {
    .aht-event {
      &__link {
        color: $c-active;

        &:hover {
          border-color: $c-dark-2;
          background-color: $c-dark-2;
        }
      }
    }
  }

  &--chr-2 {
    .aht-event {
      &__link {
        &:hover {
          border-color: $c-dark-2;
          background-color: $c-dark-2;
        }
      }
    }
  }
}


.event-slider--church-wrap-2 {
  .aheto-btn-container {
    .aheto-btn {
      &--inline {
        background-color: transparent;
        color: $c-active;
        border: none;
        padding: 0;

        &:hover {
          color: $c-dark-2;
        }
      }
    }
  }

  .aht-event {
    &--chr-2 {
      .aht-event {

        &__link {
          color: $c-active;
          border-color: #eeeeee;

          &:hover {
            color: $c-white;
          }
        }
      }
    }
  }

}

.church-events-wrap {
  .aht-event {
    &--chr {
      .aht-event {

        &__date {
          box-shadow: 0 0 32px 0 rgba(252, 142, 68, 0.3);
        }
      }
    }

    &--chr-2 {
      .aht-event {
        &__link {
          border-color: #eeeeee;
        }
      }
    }
  }

  .church-events-btn {
    .aheto-btn-container {
      .aheto-btn {
        border-color: #eeeeee;
      }
    }
  }
}