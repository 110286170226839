.aht-blog {
    &-evts {
        &__date {
            color: $c-active;
        }
        &__title {
            h3:hover {
                color: $c-grey;
                transition: color 0.5s;
            }
        }
        &__icon {
            color: $c-active;
        }
        &__text {
            color: $c-grey;
        }
        &__details {
            border-top: 1px solid $c-light;
        }
    }
}