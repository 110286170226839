.aht {
  &__sidebar {

    // Search widget
    .search {
      &-field {
        font-family: $fnt-family-3;
        background-color: $c-white;
        color: $c-dark;
        font-weight: normal;

        &::placeholder {
          color: $c-grey;
        }

        &:focus {
          border: 1px solid $c-active;
          box-shadow: 0px 15px 30px 0px rgba($c-active, 0.1);
        }
      }

      &-submit {
        background-color: $c-white;
      }
    }

    // Posts
    .aht-widget-posts {
      &__buttons {
        box-shadow: 0px 15px 30px 0px rgba($c-active, 0.1);
      }

      &__button {
        border-bottom: 2px solid $c-white;
        background-color: $c-white;
        color: $c-dark;
        font-family: $fnt-family-3;

        &.active {
          border-bottom: 2px solid $c-active;
          color: $c-active;
        }
      }

      &__title {
        color: $c-dark;

        &:hover {
          color: $c-active;
        }
      }

    }

    // calendar
    .calendar {
      background: $c-white;
      box-shadow: 0px 15px 30px 0 rgba($c-active, 0.1);
      background-color: $c-white;

      table {
        tr {
          th {
            font-family: $fnt-family-3;
            color: #333333;
            padding: 10px 0px;
          }

          th:first-child {
            color: #e04040;
          }

          td {
            font-family: $fnt-family-3;
          }

          td.active {
            background: $c-alter;
            color: $c-white;
          }

          td:first-child {
            color: #e04040;
          }
        }
      }

      .calendar_header {
        color: #cccccc;

        .month {
          color: $c-active;

          i {
            color: #cccccc;
          }
        }
      }

    }

    // Tags widget
    .widget_tags {

      .widget-title {
        font-family: $fnt-family-3;
        color: #333333;
      }

      a {
        background: $c-white;
        border: 1px solid #eee;
        color: $c-grey;

        &:hover {
          color: $c-white;
          background: $c-active;
        }
      }
    }

    // Advertisement
    .aht-widget-advert {
      &__title {
        font-family: $fnt-family-3;
      }

      &__link {
        &:hover {
          color: $c-white;
        }
      }
    }

    // twitter
    .aht-twitter {
      background-color: $c-white;
      box-shadow: 0px 15px 30px 0px rgba($c-active, 0.1);

      .tweets {
        font-family: $fnt-family-3;

        span {
          color: $c-grey;
        }
      }

      &__follow {
        &:after{
          background: $c-active;
        }
      }

      &__link {
        background: $c-active;
        color: $c-white;
      }

      &__post,
      &__hashtag {
        color: $c-dark;
      }

      &__date {
        color: #bbb;
      }

      .btn {
        border-top: 1px solid #eeeeee;

        &-left {
          border-right: 1px solid #eeeeee;

          &:hover {
            i {
              color: $c-active;
            }
          }
        }

        &-right {
          &:hover {
            i {
              color: $c-active;
            }
          }
        }
      }
    }
  }
}
