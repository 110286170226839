.aht-calendar {
    &__head {
        background-color: $c-dark;
        &-item {
            color: $c-white;
            font-family: $f-roboto;
            font-size: 12px;
            letter-spacing: 1.2px;
        }  
    }
    &__title {
        font-family: $fnt-family-2;
        color: $c-dark;
    }
    &__header {
        &-link {
            font-family: $f-roboto;
            font-size: 14px;
            text-transform: uppercase;
            color: #595955;
            letter-spacing: 1.4px;
            position: relative;
            i {
                font-size: 20px;
                color: $c-active;
            }
            &::after {
                content: '';
                font-family: $f-elegant;
                position: absolute;
                right: 1px;
                bottom: -5px;
                width: 85%;
                height: 1px;
                background-color: #ccc;
            }
            &:hover {
                color: $c-active;
                &::after {
                    background-color: $c-active;
                }
            }
        }
    }
    &__number {
        font-family: $f-roboto;
        color: $c-grey;
        text-align: right;
    }
    &__cat {
        &-item {
            box-sizing: border-box;
            font-family: $f-roboto;
            color: $c-white;
            padding: 3px 15px;
            font-size: 14px;
            font-weight: 500;
            border-radius: 5px;
            margin-bottom: 1px;
            letter-spacing: normal;
            position: relative;
        }
    }
    &__body {
        &-cell {
            width: 170px;
            height: 170px;
            padding-top: 0;
            padding-bottom: 0;
        }
    }
    .c-dark {
        background-color: $c-dark;
    }
    .c-grey {
        background-color: $c-grey;
    }
    .c-active {
        background-color: $c-active;
    }
    .c-alter-3 {
        background-color: $c-alter-3;
    }
}