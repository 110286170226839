.aheto-portfolio-single-item {

	&--construction {
		.aheto-portfolio-single-item {
			&__title {
				color: $c-active;
			}
		}
	}
	&__cover-icon {
		background-color: $c-white;
		color: $c-dark;

	}
}
