.aheto-footer-15 {
  background-color: #222222;
  &__main {
    padding: 105px 0 75px;
    @include medium-size-max {
      padding: 50px 0 0;
    }
  }
  .aht-socials {
    margin-top: 40px;
    @include medium-size-max {
      margin-top: 0px;
    }
    &__link {
      color: $c-white;
    }
  }
  &__bottom {
    &:before {
      display: block;
      position: absolute;
      width: 100%;
      border-top: 1px solid rgba(255, 255, 255, 0.1);
      content: '';
    }
  }
  .aheto-footer-15__cr-text{
    font-size: 15px;
  }
}
