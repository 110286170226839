.aheto-blockquote {
  &--author-bio {
    background-color: $c-active;
  }

  &--portfolio-01 {
    background-color: $c-active;
  }

  &--restaurant {
    blockquote {
      background-color: #fff;

    }
    .aheto-heading--double_item_top {
      .aheto-heading__subtitle {
        color: $c-active;
      }
      .aheto-heading__title {
        color: $c-dark;
      }
      .aheto-heading__desc {
        color: $c-grey;
      }
    }
    .aheto-heading--double_item_bottom {
      .aheto-heading__desc {
        color: $c-grey;
      }
    }
  }

  &--restaurant-awards {
    blockquote {

    }
  }
}
