.cart_totals  {
.coupon {
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;

  label {
    display: block;
    flex-basis: 100%;
    margin-bottom: 30px;
    color: #999;
    font-size: 16px;
    line-height: 26px;
  }

  input {
    flex-basis: 460px;
    min-width: 1px;
    padding: 20px 30px;
    border: 0;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    outline: none;
    background: #f7f7f7;

    color: #999;
    font-size: 16px;
    line-height: 16px;
    &::placeholder {
      color: #999;
      font-size: 16px;
    }

    @include small-size-max {
      flex-basis: 230px;
    }
    @include xs-size-max {
      flex-basis: 190px;
    }
  }

  button[type=submit] {
    padding: 20px 24px;
    border: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    outline: none;
    // background-color: lighten($color-4, 25);
    color: #999;
    line-height: 16px;
    cursor: pointer;
  }
}

}

.woocommerce-cart-form {
  @media screen and (max-width: 768px) {
    overflow-x: scroll;
  }
  .product-subtotal .woocommerce-Price-amount {
  color:#222 !important;
  }

  table.shop_table {
    width: 100%;
    border: 0;
    color: #999;
    text-align: left;
    @media screen and (max-width: 768px) {
      width: 800px;
    }
    thead {
      tr {
        th {
          padding: 16px 0;
          font-size: 16px;
          font-weight: 500;
          color:#222;
          &:last-child {
            width: 50px;
          }

          &.product-name {
            padding-left: 30px;
          }
        }
      }
    }

    tbody {
      tr {

        td {
          padding: 20px 0;
          border-top: 1px solid #eee;

          &:before {
            line-height: 26px;
          }

          &.product-thumbnail {
            width: 100px;
            a {
              position: relative;
              display: inline-block;

              .aheto-products__marks {
                  top: 0;
                  left: 0;
              }

              img {
                width: 100px;
                height: 130px;
                object-fit: cover;
              }
            }
          }

          &.product-name {
            width: 34%;
            padding-left: 30px;
            a {
              color: #222;
              font-size: 16px;
              line-height: 26px;
            }
          }

          &.product-price {
            width: 17%;
            color: #ababab;
            font-size: 14px;
          }

          &.product-quantity {
            width: 26%;
            .quantity {
              display: inline-flex;
              border-radius: 3px;
              background-color: #f7f7f7;

              label {
                display: none;
              }

              span {
                display: inline-block;
                width: 42px;
                padding: 14px 0;
                color: #999;
                font-size: 20px;
                text-align: center;
                cursor: pointer;
                user-select: none;
              }

              input {
                width: 42px;
                border: 0;
                outline: none;
                background-color: #f7f7f7;
                color: #222;
                font-family: $fnt-family-1;
                font-size: 16px;
                font-weight: 500;
                text-align: center;

                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }
              }
            }
          }

          &.product-subtotal {
            font-size: 14px;
            font-weight: 500;
            line-height: 18px;
          }

          &.product-remove {
            width: 50px;
            padding-left: 20px;
            a {
              display: block;
              width: 50px;
              height: 50px;
              border: 1px solid #eee;
              border-radius: 5px;
              color: #999999;
              font-size: 20px;
              line-height: 47px;
              text-align: center;
            }
          }

          &.actions {
            > button[type=submit] {
              margin-top: 30px;
              text-transform: uppercase;
              cursor: pointer;
            }
          }

        }

        &:first-child {
          td {
            padding-top: 50px;
          }
        }
      }
    }
  }

}

.woocommerce-shipping-calculator {
  .shipping-calculator-form {
    margin-top: 10px;

    select {
      width: 100%;
      padding: 5px;
      // transition: $aheto-form-transition;
      border: 1px solid #eee;
      border-radius: 5px;
      outline: none;
      color: #999;
      font-family: $fnt-family-1;

      &:focus {
        // border: 1px solid $color-1;
      }
    }

    input {
      width: 100%;
      margin-top: 5px;
      padding: 5px;
      // transition: $aheto-form-transition;
      border: 1px solid #eee;
      border-radius: 5px;
      outline: none;
      color: #999;
      font-family: $fnt-family-1;

      &::placeholder {
        color: #999;
        font-family: $fnt-family-1;
      }

      &:focus {
        // border: 1px solid $color-1;
        border-radius: 5px;
        box-shadow: 0px 15px 30px 0px rgba(42, 116, 237, 0.1);
      }
    }

    button[type=submit] {
      display: block;
      width: 100%;
      margin-top: 5px;
      padding: 10px;
      border-width: 1px;
      border-style: solid;
      border-radius: 5px;
      // border-color: rgba($color-1, 0.5);
      outline: none;
      // background-color: $color-1;
      color: $c-white;
      font-family: 'Roboto', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      letter-spacing: 1.5px;
      line-height: 1.15;
      text-decoration: none;
      box-shadow: 1.169px 3.825px 15.66px 2.34px rgba(42, 116, 237, 0.2);
      cursor: pointer;
    }
  }
}

.cart-collaterals {

  .cart_totals {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 60px;
    padding: 40px 50px;
    border: 1px solid #eee;
    border-radius: 5px;
    table{
      margin-top: 10px;
    }
    .button-list {
      a {
        text-transform: uppercase;

        &.aheto-btn--mod {
          border: 0;
          color: #999;

          i {
            color: #999;
            font-size: 14px;
            line-height: 1;
          }
        }
      }

    }

    table.shop_table {
      tr {

        th {
          padding-right: 20px;
          color: #222;
          font-size: 16px;
          font-weight: 500;
          line-height: 40px;
          text-align: left;
          vertical-align: bottom;
        }

        td {
          font-size: 30px;
          font-weight: 300;
          line-height: 1.33;
          color: #2a74ed;

          &[data-title=Shipping] {
            font-size: 16px;
            line-height: 40px;

            form {
              p {
                margin: 0;
                line-height: 26px;
                a {
                  font-weight: 500;
                }
              }
            }
          }
        }

        &.shipping {
          th {
            vertical-align: top;
          }
        }

        &.order-total {
          th,
          td {
            padding-top: 20px;
          }

          td {
            strong {
              font-size: 30px;
              font-weight: 300;
            }
          }
        }
      }
    }

    .wc-proceed-to-checkout {
      flex-basis: 100%;
      margin-top: 60px;
      text-align: right;
    }
  }
  @media screen and (max-width: 991px) {
    .cart_totals {



      table {
        margin-top: 10px;
      }

      .wc-proceed-to-checkout {
        text-align: left;
      }
    }
  }
  @media screen and (max-width: 875px) {
    padding: 0;

    .button-list {
      display: flex;
      width: 100%;
    }

    .cart_totals {
      padding: 30px;
      justify-content: flex-end;
      table {
        margin-top: 20px;
      }

      .wc-proceed-to-checkout {
        margin-top: 30px;
      }
    }
  }
  @media screen and (max-width: 768px) {
    .button-list {
      display: block;
      text-align: center;
    }
    .cart_totals {
      padding: 20px 10px;
    }
    .aheto-btn--mod{
      margin-top: 20px;
      padding: 20px 10px;
    }
  }
}
.cart-order{
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 767px) {
    display: block;
  }
  p{
    max-width: 530px;
    font-size: 16px;
    line-height: 1.63;
  }
  .coupon{
    max-width: 590px;
    input{
      width: calc(100% - 60px);
      font-size: 16px;
      padding: 18px 30px;
      border: none;
      background: #f7f7f7;
      border-radius: 5px 0px 0px 5px;
    }
    .button{
      border: none;
      background-color: #d7d4d4;
      height: 60px;
      width: 60px;
      border-radius: 0px 5px 5px 0px;
      margin-left: -5px;
    }
  }
}
.select2 {
  width: 100%;
  padding: 5px;
  // transition: $aheto-form-transition;
  border: 1px solid #eee;
  border-radius: 5px;
  outline: none;
  color: #999;
  font-family: $fnt-family-1;

  &:focus {
    // border: 1px solid $color-1;
  }

  &-dropdown {
    border-color: #eee;
  }

  .select2-selection {

    &--single {
      border: 0;
    }
  }

  &-results {

    &__option {
      &:before {
        content: none;
      }
    }
  }
}
