.aheto-footer {
  background-color: $c-dark;
}

.aheto-footer-4 {
  background-color: $c-light;
  .aheto-socials {
    &__link {
      .icon {
        &:hover {
          color: $c-active;
        }
      }
    }
  }
}

.aheto-footer-5 {
  background-color: darken($c-dark, 7%);

  &__main {
    padding-bottom: 70px !important;
  }
  
  .widget_mc4wp_form_widget {
    .widget-title {
      margin-bottom: 40px;
    }
  }

  .menu-main-container,
  .aheto-footer-5__cr-text {
    line-height: 2.5;
  }

  .textwidget {
    li {
      margin-bottom: 14px;

      &:last-child {
        margin-top: 50px;
        margin-bottom: 6px;
      }
    }
  }

  .menu-item {
    margin-bottom: 14px;
  }

  .aheto-footer-menu {
    .menu-item {
      a {
        font-family: $f-futura, sans-serif;
        font-weight: 400;
        font-size: 16px;
        letter-spacing: 1px;
      }
    }
  }

  .widget_aheto__info--address {
    color: #999;
  }

  .widget-title {
    font-family:$f-futura;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.8px;
  }

  .contact {
    .widget-title {
      margin-bottom: 50px;
    }

    .widget_aheto__info {
      line-height: 1.88;
      margin-bottom: 8px;
    }
  }
}

.aheto-footer-5__bottom {
  @media screen and (min-width: 769px) {
    p, ul {
      margin-bottom: 0;
    }
  }
}

.aheto-footer-6 {
  background-color: $c-dark;
  .widget_mc4wp_form_widget {
    p {
      font-family: $f-roboto;
    }
  }
  &__cr-text {
    font-family: $f-roboto;
  }
  .aheto-footer-menu .menu-item {
    font-family: $fnt-family-2;
  }
  
}

.aheto-footer-7 {
  .aht-socials {
    &__icon {
      &:hover {
        color: $c-grey;
      }
    }
  }
}

.aheto-footer-8 {
  background-color: $c-active;
}

.aheto-footer-9 {

  .aht-socials {
    &__icon {
      color: rgba($c-grey, 0.5);
      &:hover {
        color: $c-active;
      }
    }
  }
  &__bottom {
    .aheto-footer-menu {
      .menu {
        &-item {
          a {
            color: $c-grey;
            &:hover {
              color: $c-dark;
            }
          }
          &.current-menu-item {
            a {
              color: $c-dark;
            }
          }
        }
      }
    }
  }
  &__cr-text {
    font-size: 15px;
    a {
      color: $c-active;
    }
  }
}

.aheto-footer-10 {
  .aheto-socials {
    &__link {
      .icon {
        &:hover {
          color: $c-active;
        }
      }
    }
  }
}

.aheto-footer-11 {
  &__cr-text {
    color: $c-grey;
  }
}

.aheto-footer-12 {
  .aht-socials--circle {
    .aht-socials__link:hover {
        color:$c-white;
    }
  }
}

.aheto-footer-13 {
  .aheto-socials {
    &__link {
      &:first-child {
        margin-left: 0;
      }

      .icon {
        &:hover {
          color: $c-active;
        }
      }
    }
  }
}
